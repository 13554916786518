import React, {Component} from 'react'
import {base} from '../../../constants/Data'
import {SubscriptionData} from '../../../components'
import {MiscService, OrderService, UserService, RouterService, MetaService} from '../../../services'
import {Img, Button} from '../../../elements'
const uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService(),
oS = new OrderService()
export default class DashBoard extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state = {
            userData: this.user,
            recommendData: []
        }
    }
    componentDidMount () {
        mS.setTitle('Dashboard', 'public')
        this._getUserRecommendation()
    }
    _getUserRecommendation = async () => {
        let query = {
            uid: this.user.uid
        }
        const result = await oS.getUserRecommendation({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data.length === 0) {
                this.setState({ recommendData: null })
            } else {
                this.setState({ recommendData: result.data })
            }
        }
    }
    _renderServiceList = serviceFiles => {
        let { activeService } = this.state
        return serviceFiles.map(( item, index ) => {
            if (item.type === 'service' || item.type === 'micro-service') {
                if (item.status === 'publish')
                return <div className={activeService===item.slug?'service-box-new mb30 active': 'service-box-new mb30'} key={index} id={item.slug}>
                    <div className="service-content flex">
                        <div className="image">
                            <Img src={base.imageBaseUrl+JSON.parse(item.image)[0]} alt={item.title} />
                        </div>
                        <div className="title-box">
                            <div className="title flex">
                                <div className="title-item"><h1>{item.title}</h1></div>
                                <div className="hrsTaken"><ion-icon name="alarm-outline"></ion-icon> Service Time - {item.serviceTime} Hrs</div>
                            </div>
                            <div className="about">
                                <div dangerouslySetInnerHTML={{__html: item.userNote}}></div>
                            </div>
                            <div className="points" dangerouslySetInnerHTML={{__html: item.excerpt}}></div>
                            <div className="addToCartbtn-service-page">
                                {
                                    uS.getActiveSubscription() === null &&
                                    <p className="get-off" onClick={() => miS.openModal("get-subscriptions-modal")}>Get 10% off <ion-icon name="information-circle"></ion-icon> </p>
                                }
                                <Button className="btn btn-primary" onClick= {() => {
                                    if (item.type === 'service') {
                                        rS.navigateTo('/services/'+item.slug)
                                    } else {
                                        rS.navigateTo('/microservices/'+item.slug)
                                    }
                                }}><ion-icon name="eye"></ion-icon> Explore</Button>
                            </div>
                        </div>
                    </div>
                </div>
                else return true
            } else if (item.type === 'product') {
                return <div className={activeService===item.slug?'service-box-new mb30 active': 'service-box-new mb30'} key={index} id={item.slug}>
                    <div className="service-content flex">
                        <div className="image">
                            <Img src={base.imageBaseUrl+JSON.parse(item.image)[0]} alt={item.title} />
                        </div>
                        <div className="title-box">
                            <div className="title flex">
                                <div className="title-item"><h1>{item.title}</h1></div>
                            </div>
                            <div className="about">
                                <div dangerouslySetInnerHTML={{__html: item.userNote}}></div>
                            </div>
                            <div className="addToCartbtn-service-page">
                                <Button className="btn btn-primary" onClick= {() => rS.navigateTo('/product/'+item.slug)}><ion-icon name="eye"></ion-icon> Explore</Button>
                            </div>
                        </div>
                    </div>
                </div>
            } else if (item.type === 'tag') {
                return <div className={activeService===item.slug?'service-box-new mb30 active': 'service-box-new mb30'} key={index} id={item.slug}>
                    <div className="service-content flex">
                        <div className="image">
                            <h2>
                                <span>
                                    {item.label}
                                </span>
                            </h2>
                        </div>
                        <div className="title-box">
                            <div className="addToCartbtn-service-page">
                                <Button className="btn btn-primary" onClick= {() => rS.navigateTo('/accessories/tag/'+item.value)}><ion-icon name="eye"></ion-icon> Explore</Button>
                            </div>
                        </div>
                    </div>
                </div>
            } else return true
        })
    }
    render() {
        let { userData, recommendData } = this.state
        return <>
            <div className="right-side-profile-content">
                <h1>Dashboard</h1>
                <div className="profile-sec wrapper">
                    <div className="profile-name-section">
                        <div className="form-box">
                            <div className="profile-avatar">
                                <div className="profile-image">
                                    <Img src={base.imageBaseUrl+JSON.parse(this.user.image)[0]} alt={userData.name} />
                                </div>
                            </div>
                            <div className="name-sec">
                                <h4>{userData.name}</h4>
                                <p>{userData.email}</p>
                            </div>
                            {
                                uS.getActiveSubscription() !== null &&
                                <div className="subscription-dashbord">
                                    <p className="subs-heading">Active Subscription: </p>
                                    <span className="pointer" onClick={() => miS.openModal("get-subscriptions-modal")}>{uS.getActiveSubscription()}</span>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="form-editable-section">
                        <div className="form-box" id="recommended-services">
                            <div className="services">
                                { this._renderServiceList(recommendData || []) }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal" id="get-subscriptions-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content big">
                    <Button className="close" onClick={() => miS.closeModal("get-subscriptions-modal")}><i className="hi-close"></i></Button>
                    <div className="modal-body p20">
                        <SubscriptionData activeSubscription={uS.getActiveSubscription()} /> 
                    </div>
                </div>
            </div>
        </>
    }
}