import React, {Component} from 'react'
import {Banner} from '../../../widgets'
import {base} from '../../../constants/Data'
import {SubscriptionData} from '../../../components'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {ServiceService, MiscService, OrderService, UserService, RouterService, MetaService} from '../../../services'
import {Img, Button} from '../../../elements'
const timeSlots = [
    {
        id: '1',
        slot: '9:00 AM - 11:00 AM'
    },
    {
        id: '2',
        slot: '11:00 AM - 1:00 PM'
    },
    {
        id: '3',
        slot: '1:00 PM - 3:00 PM'
    },
    {
        id: '4',
        slot: '3:00 PM - 5:00 PM'
    },
    {
        id: '5',
        slot: '5:00 PM - 7:00 PM'
    }
],
uS = new UserService(),
miS = new MiscService(),
mS = new MetaService(),
sS = new ServiceService(),
rS = new RouterService(),
oS = new OrderService()
export default class Services extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state= {
            serviceList: [],
            activeService: '',
            orderDate: '',
            chooseServiceTime: false,
            activeTimeSlot: timeSlots[0],
            selectPick: false,
            showPick: false
        }
    }
    componentDidMount () {
        mS.setTitle('Car Services', 'public')
        this._getServicesData()
    }
    _getServicesData = async () => {
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        const result = await sS.getServices()
        if (result.status) {
            if (result.data.length !== 0) {
                let serviceList = []
                result.data.reverse().forEach(e => {
                    if (typeof e.trash === "undefined")
                        serviceList.push(e)
                    else if (!e.trash)
                        serviceList.push(e)
                })
                serviceList.sort( this._compareByPublishDate )
                this.setState({ serviceList:serviceList}, () => {
                    if (urlData[2] !== null && urlData[2] !== '' && urlData[2]) {
                        let element = document.querySelector("#"+urlData[2])
                        this.setState({ activeService: urlData[2] })
                        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }
                })
            }
        }
    }
    _compareByPublishDate = ( a, b ) => {
        if ( (new Date(a.publishDate)) < (new Date(b.publishDate)) )
            return 1
        if ( (new Date(a.publishDate)) > (new Date(b.publishDate)) )
            return -1
        return 0
    }
    _chooseActiveCar = () => {
        let activeCar = miS.getActiveCar(),
        { activeService, serviceList } = this.state,
        prices = '[]', activeServiceData = null, price = 0, found = false
        serviceList.forEach(e => {
            if (activeService === e.slug)
                activeServiceData = e
        })
        prices = JSON.parse(JSON.parse(activeServiceData.prices))
        if (activeCar === null) {
            miS.openCarSelectModal()
            this._checkForActiveCar()
        } else {
            prices.forEach(c => {
                if (activeCar.Segment.toLowerCase().includes(c.priceGroup.toLowerCase()) && !found) {
                    found = true
                    if (typeof c.saleprice === "undefined" || c.saleprice === 0 || c.saleprice === '') {
                        c.saleprice = c.price
                        if (uS.getActiveSubscription() !== null) {
                            c.saleprice = c.saleprice*0.9
                        }
                    }
                    let mrp = Number(c.price)
                    price = Number(c.saleprice)
                    // price += price*Number(activeServiceData.tax)*0.01
                    price = String(price.toFixed(2))
                    c.saleprice = Number(c.saleprice)
                    // c.saleprice = c.saleprice + c.saleprice*Number(activeServiceData.tax)*0.01
                    c.saleprice = Number(c.saleprice.toFixed(2))
                    c.price = Number(c.price)
                    // c.price = c.price + c.price*Number(activeServiceData.tax)*0.01
                    c.price = String(c.price.toFixed(2))
                    this._serviceAddedTemplate(price, activeServiceData, mrp, c.saleprice)
                }
            })
        }
    }
    _checkForActiveCar = () => {
        let activeCar = miS.getActiveCar(),
        { activeService, serviceList } = this.state,
        prices = '[]', activeServiceData = null, price = 0, found = false
        serviceList.forEach(e => {
            if (activeService === e.slug)
                activeServiceData = e
        })
        prices = JSON.parse(JSON.parse(activeServiceData.prices))
        if (activeCar === null) {
            miS.openCarSelectModal()
            this._checkForActiveCar()
        } else {
            prices.forEach(c => {
                if (activeCar.Segment.toLowerCase().includes(c.priceGroup.toLowerCase()) && !found) {
                    found = true
                    if (typeof c.saleprice === "undefined" || c.saleprice === 0 || c.saleprice === '') {
                        c.saleprice = c.price
                        if (uS.getActiveSubscription() !== null)
                            c.saleprice = c.saleprice*0.9
                    }
                    let mrp = Number(c.price)
                    price = Number(c.saleprice)
                    // price += price*Number(activeServiceData.tax)*0.01
                    price = String(price.toFixed(2))
                    c.saleprice = Number(c.saleprice)
                    // c.saleprice = c.saleprice + c.saleprice*Number(activeServiceData.tax)*0.01
                    c.saleprice = Number(c.saleprice.toFixed(2))
                    c.price = Number(c.price)
                    // c.price = c.price + c.price*Number(activeServiceData.tax)*0.01
                    c.price = String(c.price.toFixed(2))
                    this._serviceAddedTemplate(price, activeServiceData, mrp, c.saleprice)
                }
            })
        }
    }
    _serviceAddedTemplate = async (derivedPrice, item, mrp, saleprice) => {
        let { orderDate, activeTimeSlot, selectPick, activeService } = this.state,
        activeCar = miS.getActiveCar(),
        price = Number(derivedPrice)
        let service = {
            orderDate: orderDate,
            uid: item.uid,
            activeTimeSlot: activeTimeSlot,
            activeCar,
            title: activeCar.registrationNo ? item.title + ' ['+activeCar.registrationNo+']' : item.title,
            image: JSON.parse(item.image)[0],
            price: price,
            purchaseQuantity: 1,
            type: 'service',
            tempUid: new Date().getTime(),
            tax: item.tax,
            hsn: item.hsn,
            mrp: mrp,
            saleprice: saleprice,
            selectPick: selectPick,
            taxAmount: Number((price * (Number(item.tax))/100).toFixed(2))
        };
        service.displayPrice = (service.price + service.taxAmount).toFixed(2)
        let productAddedTemplate = '<div class="alert-msg"><ion-icon name="shield-checkmark-outline"></ion-icon><p>Service added to cart!</p></div>'+
        '<div class="service-box">'+
        '<img src="'+base.imageBaseUrl+JSON.parse(item.image)[0]+'" alt="'+item.title+'" class="service-image" />'+
        '<div class="service-content">'+
        '<p class="service-title">'+item.title+'</p>'+
        '<p class="service-price">₹ '+service.displayPrice+'</p>'+
        '</div>'+
        '</div>'
        await oS.addToCart(service)
        if (activeService !== 'pick-drop' && selectPick)
            this.setState({ activeService: 'pick-drop' }, this._chooseActiveCar)
        else
            this.setState({ chooseServiceTime: false, activeService: '', orderDate: '', activeTimeSlot: timeSlots[0], activeCar: '', selectPick: false, showPick: false })
        miS.showAlert({ type: 'custom', template: productAddedTemplate })
    }
    _buyItem = (buyType, item) => {
        if (item.slug !== 'pick-drop') {
            this.setState({ showPick: true })
        }
        let activeCar = miS.getActiveCar()
        if (activeCar !== null) {
            this.setState({ activeService: item.slug }, () => miS.openModal("add-to-cart-modal"))
        } else
            miS.openCarSelectModal()
    }
    _renderServiceList = serviceFiles => {
        let { activeService } = this.state,
        activeCar = miS.getActiveCar(),
        pricesItem = [], prices = [], activePrice = 0, found = false
        let count = 0
        return serviceFiles.map(( item, index ) => {
            activePrice = 0
            found = false
            pricesItem = JSON.parse(item.prices)
            prices = JSON.parse(pricesItem)
            prices.forEach(c => {
                if (activeCar !== null) {
                    if (activeCar.Segment.toLowerCase().includes(c.priceGroup.toLowerCase()) && !found) {
                        found = true
                        activePrice = c
                    }
                }
            })
            if (item.status === 'publish') {
                count++
                return <div key={index}>
                    <div className={activeService===item.slug?'service-box-new mb30 active': 'service-box-new mb30'} key={index} id={item.slug}>
                        <div className="service-content flex">
                            <div className="image">
                                <Img src={base.imageBaseUrl+JSON.parse(item.image)[0]} alt={item.title} />
                            </div>
                            <div className="title-box">
                                <div className="title flex">
                                    <div className="title-item"><h1>{item.title}</h1></div>
                                    <div className="hrsTaken"><ion-icon name="alarm-outline"></ion-icon> Service Time - {item.serviceTime} Hrs</div>
                                </div>
                                <div className="about">
                                    <div dangerouslySetInnerHTML={{__html: item.userNote}}></div>
                                </div>
                                <div className="points" dangerouslySetInnerHTML={{__html: item.content}}></div>
                                <div className="addToCartbtn-service-page">
                                    {
                                        activePrice !== 0?
                                        <>
                                            {
                                                typeof activePrice.saleprice === "undefined" || activePrice.saleprice === 0 || activePrice.saleprice === '' || activePrice.saleprice === activePrice.price ?
                                                <div className="mrp">
                                                    <span className="black">₹ {activePrice.price} <small>+18% GST</small></span>
                                                </div>:
                                                <>
                                                    <div className="mrp">
                                                        <span className="black">₹ {activePrice.saleprice}</span>
                                                    </div>
                                                    <div className="sale-price">
                                                        <span className="black">₹ {activePrice.price}</span>
                                                    </div>
                                                    <div className="per-off">
                                                        <span className="green">{((activePrice.price - activePrice.saleprice)/activePrice.price * 100).toFixed(0)} % off</span>
                                                    </div>
                                                </>
                                            }
                                        </>:<>
                                        {
                                            activeCar === null &&
                                            <span className="get-price" onClick={miS.openCarSelectModal}><ion-icon name="car-sport-outline"></ion-icon> Get Price</span>
                                        }
                                        </>
                                    }
                                    {
                                        uS.getActiveSubscription() === null &&
                                        <p className="get-off" onClick={() => miS.openModal("get-subscriptions-modal")}>Get 10% off <ion-icon name="information-circle"></ion-icon> </p>
                                    }
                                    {(!uS.isUserLoggedIn() || (uS.isUserLoggedIn() && this.user?.role && !['manager', 'company', 'branch', 'representative'].includes(this.user?.role))) && <Button className="btn btn-primary" onClick= {() => this._buyItem('add-to-cart', item)}><ion-icon name="cart"></ion-icon> Add to Cart</Button>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        count % 5 === 0 && <a href="/accessories" style={{display: 'block',width: '100%',marginBottom: '40px'}}>
                        <Img src={base.imageBaseUrl+"india-sale-banner.jpg"} style={{display: 'block',width: '100%'}} />
                    </a>
                    }
                </div>
            }
            else return true
        })
    }
    _renderTimeSlots = timeSlots => {
        let { activeTimeSlot } = this.state
        return timeSlots.map(( item, index ) => {
            return <div className={activeTimeSlot.slot === item.slot ? 'timeSlotSingle active': 'timeSlotSingle'} onClick={() => {
                this.setState({ activeTimeSlot: item })
            }} key={index}>
                {
                    activeTimeSlot.slot === item.slot?
                    <ion-icon name="checkmark-circle" class="success"></ion-icon>:
                    <ion-icon name="ellipse" class="ashgray"></ion-icon>
                }
                {item.slot}
            </div>
        })
    }
    render() {
        let { serviceList, orderDate, selectPick, chooseServiceTime, showPick } = this.state,
        today = new Date(),
        tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        return <>
            <Banner title="Services" image="yellow-back-1.jpg" />
            <div className="jumbo-container">
                <section className="service-offer-section pt30">
                    <div className="wrapper">
                        <div className="middle-setup">
                            <div className="section-title-wrap tac">
                                <div className="section-title pb30">
                                    <h2 className="heading-02 pb10">Best Services</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="jumbo-container">
                {this._renderServiceList(serviceList || [] )}
            </div>
            <div className="modal" id="get-subscriptions-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content big">
                    <Button className="close" onClick={() => miS.closeModal("get-subscriptions-modal")}><i className="hi-close"></i></Button>
                    <div className="modal-body p20">
                        <SubscriptionData /> 
                    </div>
                </div>
            </div>
            <div className="modal add-to-cart-modal" id="add-to-cart-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content overflown small">
                    <Button className="close" onClick={() => miS.closeModal("add-to-cart-modal")}><i className="hi-close"></i></Button>
                    <div className="modal-body">
                        <div className="add-to-cart-modal-header">
                            <div className="modal-header">
                                <div className="heading">Select Service Date</div>
                            </div>
                        </div>
                        <div className="select-date">
                            <div className="datebox">
                                <label className="date-label">Select Service Date</label>
                                <DatePicker
                                    onChange={orderDate => this.setState({ orderDate, chooseServiceTime: true })}
                                    minDate={today}
                                    startDate={today}
                                    selected={orderDate}
                                />
                            </div>
                            {
                                chooseServiceTime &&
                                <div className="datebox">
                                    <label className="date-label">Select ServiceTime Slot</label>
                                    <div className="time-slots">
                                        { this._renderTimeSlots(timeSlots||[]) }
                                    </div>
                                    {
                                        showPick &&
                                        <div className="pick-and-drop" onClick={() => this.setState({ selectPick: !selectPick})}>
                                            {
                                                selectPick ?
                                                <ion-icon name="checkmark-circle" class="success"></ion-icon>:
                                                <ion-icon name="ellipse" class="ashgray"></ion-icon>
                                            }
                                            <span>Pick & Drop</span>
                                        </div>
                                    }
                                    <Button className="btn btn-primary mt20" onClick={() => {
                                        miS.closeModal("add-to-cart-modal")
                                        this._chooseActiveCar()
                                    }}>Proceed</Button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}