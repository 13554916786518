import React from 'react'
import {Img, Button} from '../../../elements'
import {MetaService} from '../../../services'
const mS = new MetaService()
const Thankyou = () => {
    mS.setTitle('Thank You', 'public')
    return <div className="body-background flex">
        <div className="container">
            <div className="form-box-setup thankyou-page">
                <div className="form-box">
                    <Img alt="Thank you" src={require('../../../../assets/images/thankyou.png')} />
                    <div id="content-box" className="content">
                        <h1 className="title">
                            <span>Congrats, you are in!</span>
                        </h1>
                        <p>Thnakyou for the purchasing service, Your details will sending on your mail </p>
                    </div>
                    <Button className="btn-primary btn mv30" type="submit">Okay</Button>
                </div>
            </div>
        </div>
    </div>
}
export default Thankyou