import React, {Component} from 'react'
import {base,brandName} from '../../constants/Data'
import {MiscService, ValidationService, ServiceService} from '../../services'
import {Button, Img} from '../../elements'
const miS = new MiscService(),
vS = new ValidationService(),
sS = new ServiceService()
export default class PremiumServices extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checkOne: false,
            activeDownloadState: 'mobile',
            serviceList: [],
            receiver: ''
        }
    }
    componentDidMount () {
        this._getServicesData()
    }
    _getServicesData = async () => {
        const result = await sS.getServices()
        if (result.status) {
            if (result.data.length !== 0) {
                let serviceList = result.data
                serviceList.sort( this._compareByPublishDate )
                this.setState({ serviceList:serviceList.reverse() })
            }
        }
    }
    _renderServiceList = serviceFiles => {
        return serviceFiles.map(( item, index ) => {
            if (item.status === 'publish' && item.isFeatured)
            return <a href={"/services#"+item.slug} key={index}>
                <span className="premium-cards">
                    {
                        item.icon && <Img src={base.imageBaseUrl+JSON.parse(item.icon)[0]} alt={item.title} />
                    }
                    <span>{item.title}</span>
                </span>
            </a>
            else return true
        })
    }
    _compareByPublishDate = ( a, b ) => {
        if ( (new Date(a.publishDate)) < (new Date(b.publishDate)) )
            return -1
        if ( (new Date(a.publishDate)) > (new Date(b.publishDate)) )
            return 1
        return 0
    }
    _shareApp = async () => {
        let { receiver, activeDownloadState } = this.state, error = false, errorMsg = '',
        shareObject = {
            to: receiver,
            type: activeDownloadState === 'mobile' ? 'sms' : 'email'
        }
        if (activeDownloadState === 'mobile') {
            if (!vS.isValidMobile(receiver)) {
                error = true
                errorMsg = 'Please enter a valid mobile number'
            }
        } else if (!vS.isValidEmail(receiver)) {
            error = true
            errorMsg = 'Please enter a valid email appdress'
        }
        if (!error) {
            const result = await miS.sendAppShare(shareObject)
            if (result.status) {
                this.setState({ receiver: '' }, () => {
                    miS.showAlert({ type: 'info', msg: shareObject.type === 'email' ? 'App download link has been sent on your Email address!' : 'App download link has been sent to your mobile number in SMS!' })
                })
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to send app link, try after some time.' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    render() {
        let { serviceList, activeDownloadState, receiver } = this.state
        return <>
            { miS.isModuleEnabled(['PremiumServices']) && <section className="car-adds-section mb60-mbs40">
                <div className="">
                    <div className="wrapper jumbo-container">
                        <div className="section-title-wrap tac">
                            <div className="section-title">
                                <div className="padding-15">
                                    <h2 className="title-bar tal heading-02">Premium Services</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="premium-service-box jumbo-container">
                        { this._renderServiceList(serviceList) }
                    </div>
                </div>
            </section>}
            {miS.isModuleEnabled(["MobileApp"]) && <section className="mb60-mbs40 back-light flex block-m">
                <div className="flex mobile-jumbo width-full">
                    <a className="choose-package p20" href="/packages">
                        <h2 className="title-bar border0 monthly-heading heading-02 tac pr20">Monthly Package</h2>
                        <span></span>
                    </a>
                    <div className="getApp-box">
                        <div className="flex p20 mtauo pb0">
                            <div className="right-side-app-detail mb50">
                                <h2 className="title-bar border0  heading-02">Get the {brandName.capital} App</h2>
                                <p className="subtitle">We will you send you a link, open it on your phone to download the app</p>
                                <div className="mail-number-section flex">
                                    <div className="emailDownload pointer" onClick={() => this.setState({ activeDownloadState: 'email', receiver: '' })}>
                                        <ion-icon name={activeDownloadState === 'email' ? "checkmark-circle-outline" : "ellipse-outline"} class={activeDownloadState === 'email'? 'orange': ''}></ion-icon>
                                        <span>Email</span>
                                    </div>
                                    <div className="mobileDownload pointer" onClick={() => this.setState({ activeDownloadState: 'mobile', receiver: '' })}>
                                        <ion-icon name={activeDownloadState === 'mobile' ? "checkmark-circle-outline" : "ellipse-outline"} class={activeDownloadState === 'mobile'? 'orange': ''}></ion-icon>
                                        <span>Mobile</span>
                                    </div>
                                </div>
                                <div className="inputLink flex">
                                    <input value={receiver} onChange={(e) => this.setState({ receiver: e.target.value })} className="input" type="text" name="receiver" placeholder={ activeDownloadState === 'mobile' ? 'Enter Mobile Number' : 'Enter Email' } />
                                    <Button className="bttn" onClick={this._shareApp}>Share App Link</Button>
                                </div>
                                {miS.isModuleEnabled(["MobileApp"]) && <li className="appImgDiv">
                                    <span>Download App from</span>
                                    <ul className="flex">
                                        <li className="mr20"><a href={base.iosApp} rel="noopener noreferrer" target="_blank"> <Img src={require('../../../assets/images/apple.png')} alt={'Download '+ brandName.capital+ 'App for iPhone'} /></a></li>
                                        <li><a href={base.androidApp} rel="noopener noreferrer" target="_blank"><Img src={require('../../../assets/images/android.png')} alt={'Download '+ brandName.capital+ 'App for Android'} /></a></li>
                                    </ul>
                                </li>}
                            </div>
                            <div className="mobile-front">
                                <Img src={require('../../../assets/images/download-mob-min.png')} alt={`${brandName.capital} app mockup`} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>}
        </>
    }
}