import React, {Component} from 'react'
import {TrashConfirmModal, Table} from '../../../components'
import {MiscService, DataService, MetaService} from '../../../services'
import {Actor, Button} from '../../../elements'
const dS = new DataService('chat'),
mS = new MetaService(),
miS = new MiscService()
export default class GenericChatBot extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chatList: [],
            question: '',
            answer: '',
            uid: '',
            mode: 'Add',
            showTrashConfirm: false
        }
    }
    componentDidMount () {
        mS.setTitle('Generic Chat', 'admin')
        this._getChatsData()
    }
    _getChatsData = async () => {
        const result = await dS.fetchAll()
        if (result.status) {
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No chats placed yet!' })
            } else {
                let chatList = []
                result.data.forEach(e => {
                    if (e.type === 'generic') chatList.push(e)
                })
                this.setState({ chatList })
            }
        }
    }
    _trashCancel = () => {
        this.setState({ activeItem: null, showTrashConfirm: false })
    }
    _trashChat = async () => {
        let { activeItem } = this.state,
        query = {
            uid: activeItem.uid
        }
        const result = await dS.delete({ query: JSON.stringify(query) })
        if (result.status) {
            this._getChatsData()
            miS.showAlert({ type: 'success', msg: 'Chat deleted successfully!' })
            this.setState({ activeItem: null, showTrashConfirm: false })
        } else miS.showAlert({ type: 'error', msg: 'Unable to delete chat!' })
    }
    _addChat = async e => {
        e.preventDefault()
        let { question, answer, mode, uid } = this.state,
        error = false,
        errorMsg = '',
        chatObject = {
            question,
            answer,
            type: 'generic'
        }
        if (question === '' || answer === '') {
            errorMsg = 'Please enter chat content!'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                chatObject.uid = new Date().getTime()
                const result = await dS.save(chatObject)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Chat added successfully!' })
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to add chat!' })
                }
                this.setState({ question: '', answer: '', uid: '' }, () => {
                    miS.closeModal("chat-modal")
                    this._getChatsData()
                })
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    chatData: JSON.stringify(chatObject)
                }
                const result = await dS.update(data)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Chat updated successfully!' })
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to update chat!' })
                }
                this.setState({ question: '', answer: '', uid: '' }, () => {
                    miS.closeModal("chat-modal")
                    this._getChatsData()
                })
            }
        } else miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _getTableData = filterData => {
        let data = []
        filterData.forEach(( item ) => {
            let e = {
                data: <>
                    <p className="query"><b>Q: </b>{item.question}</p>
                    <p className="ans"><b>A: </b>{item.answer}</p>
                </>,
                action: <div className="action-bar" style={{bottom: 10}}>
                    <Actor type='edit' onClick={() => {
                        this.setState({ mode: 'Edit', question: item.question, answer: item.answer, uid: item.uid }, () => {
                            miS.openModal("chat-modal")
                        })
                    }} />
                    <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    render() {
        let { chatList, answer, question, mode, showTrashConfirm } = this.state
        let tableContent = {
            headers: [
                {
                    label: 'Queries',
                    key: 'queries'
                },
                {
                    label: 'Action',
                    key: 'action'
                }
            ],
            content: this._getTableData(chatList)
        }
        return <>
            <h1 className="title flex">Chatbot: Generic <Button onClick={() => {
                this.setState({ mode: 'Add' }, () => miS.openModal("chat-modal"))
            }} className="btn btn-primary btn-yellow btn-small ml20">Add</Button></h1>
            <Table
                data={tableContent}
            />
            <TrashConfirmModal trashOkAction={this._trashChat} trashCancelAction={this._trashCancel} showTrashConfirm={showTrashConfirm} />
            <div className="modal chat-modal" id="chat-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content small">
                    <Button className="close" onClick={() => this.setState({ question: '', answer: '', uid: '' }, () => miS.closeModal("chat-modal"))}>
                        <i className="hi-close"></i>
                    </Button>
                    <div className="modal-body">
                        <div className="chat-modal-header">
                            <div className="modal-header">
                                <div className="heading">{mode} Query</div>
                                <form noValidate onSubmit={this._addChat}> 
                                    <div className="form-group">
                                        <input type="text" className="input" required name="question" value={question} onChange={(e) => this.setState({ question: e.target.value }) } />
                                        <label>Query</label>
                                        <small className="msg text-danger">Please enter a Query</small>
                                    </div>
                                    <div className="form-group">
                                            <textarea className="" placeholder="Type the notification here" required name="answer" value={answer} onChange={(e) => this.setState({ answer: e.target.value })}></textarea>
                                            <label>Answer</label>
                                            <small className="msg text-danger">Please enter related Answer</small>
                                    </div>
                                    <div className="buttons">
                                            <Button type="submit" title="Click here to Add Chat" className="btn btn-primary btn-small">Submit</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}