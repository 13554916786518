import React from 'react'
import {Banner} from '../../../widgets'
import {TestimonialWeb} from '../../../components'
import {MetaService} from '../../../services'
import {Img} from '../../../elements'
import { brandName } from '../../../constants/Data'
const mS = new MetaService()
const About = () => {
    mS.setTitle('About Us', 'public')
    return <div className="about-page">
        <Banner title="About Us" image="blue-shutter-1.jpg" />
        <div className="main-about-sections jumbo-container">
            <section className="about-company mt70">
                <div className="wrapper">
                    <div className="content-left-part">
                        <div className="content_element description-p">
                            <div className="wpb_wrapper">
                                <p>CarMitra is India’s leading network of crafty mechanics and workshops that comes together to provide car services at your doorstep. We at CarMitra go by the three A’s of automobiles - Affordable, Accessible and Authentic.</p>
                                <p>We promise to deliver affordable, easily accessible and efficient on-demand car services anytime, anywhere, be it at your home or workspace. We ensure to go above and beyond to keep you and your wheels happy by providing high-quality car washes, active repairs, top-notch spares and personal visits and inspections carried out by our team of expert mechanics. CarMitra is an online portal that proves to be your all in one pitch stop for your four-wheeler needs and requirements to be fulfilled at your doorstep. </p>
                                <p>We are known for our excellent customer service that encompasses a wide range of facilities from regular car inspections, thorough washes to servicing, repairs, inspections, denting/painting, roadside assistance and car care.</p>
                                <p> We are one of India’s leading online car service provider. CarMitra comes with the promise of satisfying all your car requirements and providing you amazing deals for your wheels as well!! Avail your first car service now .</p>
                            </div>
                        </div>
                        <div className="our-service-btn">
                            <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                    <a className="btn btn-primary btn-big" href="/services">Services</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="img-right-part">
                        <div className="wpb_wrapper">
                            <div className="wpb_single_image wpb_content_element vc_align_left cs-lightbox">
                                <figure className="wpb_wrapper vc_figure">
                                    <span  className="vc_box_border_gray">
                                        <Img width="570" height="380" src={require('../../../../assets/images/image_05-570x380.jpg')} />
                                    </span>
                                </figure>
                            </div>
                            <div className="img-section-2">
                                <div className="col-6">
                                    <div className="wpb_wrapper">
                                        <figure className="wpb_wrapper vc_figure">
                                            <span className="vc_box_border_gray">
                                                <Img width="390" height="260" src={require('../../../../assets/images/1image_07-390x260.jpg')} />
                                            </span>
                                        </figure>
                                    </div>
                                </div>
                                <div className="col-6 ml30">
                                    <div className="wpb_wrapper">
                                        <figure className="wpb_wrapper vc_figure">
                                            <span className="vc_box_border_gray">
                                                <Img width="390" height="260" src={require('../../../../assets/images/image_02-480x320.jpg')} />
                                            </span>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div className="jumbo-container">
            <section className="our-mission-section pt50">
                <div className="col-6">
                    <div className="our-mission-section-left">
                        <div className="our-mission-section-left-items white for-mobile">
                            <div className="section-title">
                                <h2 className="heading-02">AT CARMITRA : WE PROVIDE</h2>
                            </div>
                        </div>
                        <div className="our-mission-section-left-items">
                            <div className="work-times-inner">
                                <div className="pb20">
                                    <h4 className="items-title">CUSTOMER FRIENDLY</h4>
                                </div>
                            </div>
                            <div className="items-number">01</div>
                        </div>
                        <div className="our-mission-section-left-items white for-desktop">
                            <div className="section-title">
                                <h2 className="heading-02">AT CARMITRA : WE PROVIDE</h2>
                            </div>
                        </div>
                        <div className="our-mission-section-left-items border">
                            <div className="work-times-inner">
                                <div className="pb20">
                                    <h4 className="items-title">AFFORDABLE PRICES & DELECTABLE DEALS</h4>
                                </div>
                            </div>
                            <div className="items-number">02</div>
                        </div>
                        <div className="our-mission-section-left-items border">
                            <div className="work-times-inner">
                                <div className="pb20">
                                    <h4 className="items-title">HAPPINESS GUARANTEED</h4>
                                </div>
                            </div>
                            <div className="items-number">03</div>
                        </div>
                        <div className="our-mission-section-left-items border">
                            <div className="work-times-inner">
                                <div className="pb20">
                                    <h4 className="items-title">EASY PAYMENT</h4>
                                </div>
                            </div>
                            <div className="items-number">04</div>
                        </div>
                        <div className="our-mission-section-left-items border">
                            <div className="work-times-inner">
                                <div className="pb20">
                                    <h4 className="items-title">BOOK WITH CONFIDENCE</h4>
                                </div>
                            </div>
                            <div className="items-number">05</div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="thumb">
                        <Img src={require('../../../../assets/images/details-02.png')} alt="img" />
                    </div>
                    <div className="our-mission-section__right">
                        <div className="paragraph">
                            <h4 className=" box-header mt50">AT CARMITRA : WE PROVIDE</h4>
                            <ul className="list mt30 pl30">
                                <li className="template-bullet"><span>CUSTOMER FRIENDLY – Contact us and book your choices of service anytime, anywhere. YOU WANT IT, YOU GOT IT. Our team at CarMitra will be on your doorstep, from home to the office, be it weekdays or weekends.</span></li>
                                <li className="template-bullet"><span>AFFORDABLE PRICES & DELECTABLE DEALS – We offer lower prices than mainstream car retailers. We also provide customised deals exclusively for your car needs.</span></li>
                                <li className="template-bullet"><span>HAPPINESS GUARANTEED – our team of car experts is curated especially to provide top-notch services and help our customers understand the basics of active car care and servicing. All our service are backed by a warranty period.</span></li>
                                <li className="template-bullet"><span>EASY PAYMENT – payment is simplified as we accept all modes of card or cash payments including online apps.</span></li>
                                <li className="template-bullet"><span>BOOK WITH CONFIDENCE- we are known for providing premium quality spares and use genuine OES/OEM parts. Your car is in safe hands at CarMitra!</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div className="jumbo-container">
            <div className="page-margin-top-section pt50">
                <div className="company-overview-section">
                    <div className="wpb_wrapper section-title">
                        <h2 className=" box-header heading-02">THE CARMITRA WAY: A NOVEL EXPERIENCE</h2>
                        <div className="wpb_text_column wpb_content_element  description align-center">
                            <div className="wpb_wrapper"></div>
                        </div>
                        <div className=" page-margin-top-section">
                            <div className="col-4">
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        <div className="feature-item">
                                            <h5>CAR DRY CLEANING</h5>
                                            <div className="icon flaticon-car-service-1"></div>
                                        </div>
                                        <div className="feature-item">
                                            <h5>CAR SANITIZING</h5>
                                            <div className="icon flaticon-car-service-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        <div className="feature-item">
                                            <h5>CAR WASHING AND CLEANING</h5>
                                            <div className="icon flaticon-car-service-3"></div>
                                        </div>
                                        <div className="feature-item">
                                            <h5>PICK AND DROP SERVICES</h5>
                                            <div className="icon flaticon-car-service-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        <div className="feature-item">
                                            <h5>BALANCING AND ALIGNMENT</h5>
                                            <div className="icon flaticon-car-service-3"></div>
                                        </div>
                                        <div className="feature-item">
                                            <h5>BREAKDOWN SERVICES</h5>
                                            <div className="icon flaticon-car-service-1"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div className="jumbo-container">
            <div className="page-margin-top-section pt50">
                <div className="company-overview-section">
                    <div className="wpb_wrapper section-title">
                        <h2 className=" box-header heading-02">JOIN THE {brandName.uppercase} FAMILY NOW!</h2>
                        <p>Get an instant price and book your CarMitra experience now comfortably from the vicinity of your home or workspace. We’re not just a car service company; we strive to create a better and smoother way of life for you and your wheels.</p>
                        <p>We are our customers first choice as we provide never seen before prices, quality of service and personal assistance and guidance. The wait is over!! Book your CARMITRA DEAL FOR YOUR HAPPY WHEELS.</p>
                    </div>
                </div>
            </div>
        </div>
        <TestimonialWeb />
    </div>
}
export default About