import React, {Component} from 'react'
import {base, brandName} from '../../../constants/Data'
import DatePicker from 'react-datepicker'
import {OrderService, RouterService, MiscService, UserService, DataService, MetaService} from '../../../services'
import {Img, Button, BackIcon} from '../../../elements'
import {Box, Table} from '../../../components'
const dS = new DataService('packageWork'),
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService(),
oS = new OrderService()
export default class PackageDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderContent: null,
            activeAuthorIndex: 0,
            assignWorkers: [],
            status: '',
            invoiceData: [],
            assignWorker: '',
            feedbackContent: [],
            preventiveCheckList: [],
            packageId: null,
            orderDate: '' ,
            chosenPreventiveCheckListDates: [],
            chosenServiceDates: [],
            workedPackagedata: [],
            activeServiceDate: '',
            packageData: null,
            serviceWorkAwaitedStatus: false,
            serviceData: '',
            packageWork: null,
            upgradedData: null,
            partialPackage: '',
            upgraded: false
        }
    }
    componentDidMount () {
        mS.setTitle('Package Details', 'admin')
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname),
        orderData = rS.urlToSplitData(atob(urlData[5])),
        query = {
            uid: orderData[0]
        }
        this.setState({ packageId: orderData[1] }, () => {
            this._getServiceDates(orderData[1])
            this._getOrderData(query, orderData[1])
            this._checkForUpgrade({
                orderId: orderData[0],
                packageId: orderData[1]
            })
        })
        this._getPreventiveCheckList()
    }
    _checkForUpgrade = async (query) => {
        const result = await oS.getUpgradedPackage({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data !== '') {
                let upgradedData = result.data
                this.setState({ upgradedData: upgradedData, upgraded: true })
            }
        }
    }
    _getPackageWorkData = async (packageId, packageData = false) => {
        let query = {
            tempUid: String(packageId)
        }
        const result = await dS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data.length > 0) {
                if (result.data[0].title.toLowerCase() !== packageData.title.toLowerCase()) {
                    query = {
                        title: packageData.title
                    }
                    let newResult = await dS.fetchByAttr({ query: JSON.stringify(query) })
                    if (newResult.status) {
                        if (newResult.data.length > 0) {
                            this.setState({ workedPackagedata: newResult.data })
                        }
                    }
                } else {
                    this.setState({ workedPackagedata: result.data })
                }
            }
        }
    }
    _getUsersData = async () => {
        let tableData = []
        const result = await uS.getUsers()
        if (result.status) {
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No users added yet!' })
            } else {
                result.data.forEach(result => {
                    if (result.role === 'worker') {
                        tableData.push({label: result.name, value: result.uid})
                        this.setState({ assignWorkers: tableData })
                    }
                })
            }
        }
    }
    _getOrderData = async (query, packageId) => {
        const result = await oS.getOrder({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data.length > 0) {
                let orderContent = result.data[0],
                packageData = null,
                orderData = JSON.parse(orderContent.orderData)
                orderData.cartData.forEach(e => {
                    if (e.tempUid === Number(packageId)) {
                        packageData = e
                    }
                })
                this.setState({ orderContent, packageData }, () => this._getPackageWorkData(packageId, packageData))
            }
        }
    }
    _getServiceDates = async packageId => {
        let query = {
            tempUid: packageId
        }
        const result = await oS.getServiceDates({ query: JSON.stringify(query) })
        if (result.status) {
            let resultTable = result.data.reverse()
            if (resultTable.length > 0) {
                if (typeof resultTable[0].preventiveCheckListDates !== 'undefined' && resultTable[0].preventiveCheckListDates !== 'undefined') {
                    this.setState({ chosenPreventiveCheckListDates: JSON.parse(resultTable[0].preventiveCheckListDates) })
                }
                if (typeof resultTable[0].serviceDates !== 'undefined' && resultTable[0].serviceDates !== 'undefined') {
                    this.setState({ chosenServiceDates: JSON.parse(resultTable[0].serviceDates) })
                }
            }
        }
    }
    _getCompletedWorkData = serviceDate => {
        let found = false, { workedPackagedata } = this.state
        workedPackagedata.forEach(e => {
            if (miS.getFormattedDate(e.serviceDate) === miS.getFormattedDate(new Date(serviceDate))) {
                found = true
                let packageWork = e
                this.setState({ packageWork, serviceWorkAwaitedStatus: false })
            }
        })
        if (!found)
        this.setState({ serviceWorkAwaitedStatus: true })
        miS.openModal("car-service-status-modal")
    }
    _renderServiceData = (packageData, todayOnly = false) => {
        let datesCollection = [], { chosenServiceDates, chosenPreventiveCheckListDates, upgradedData } = this.state,
        buildBilling = 30
        if (packageData.billing === 'quarterly') {
            buildBilling = 90
        }
        for (let i = 0; i < buildBilling; i++) {
            let newDate = new Date(packageData.startDate).getTime() + i * 1000 * 60 * 60 * 24,
            e = {
                date: miS.getFormattedDate(newDate),
                day: new Date(newDate).getDay()
            }
            if (upgradedData !== null && upgradedData !== '') {
                e.newUid = upgradedData.uid
            }
            datesCollection.push(e)
        }
        return datesCollection.map(( item, index ) => {
            let found = false, preventiveFound = false
            chosenServiceDates.forEach(e => {
                if (miS.getFormattedDate(new Date(e)) === miS.getFormattedDate(new Date(item.date)))
                found = true
            })
            chosenPreventiveCheckListDates.forEach(e => {
                if (miS.getFormattedDate(new Date(e)) === miS.getFormattedDate(new Date(item.date)))
                preventiveFound = true
            })
            if ((item.day !== 0 && !todayOnly) || (todayOnly && miS.getFormattedDate(new Date()) === miS.getFormattedDate(new Date(item.date)))) {
                return <Box key={index} title={<>{miS.getDay(item.day)} <span className="datePreventive-checklist">({item.date})</span></>}>
                    <ul className="daily-service-data">
                        { preventiveFound && <li><span onClick={() => this._getPreventiveAnswers(item.date)} className="link">Preventive Checklist</span> {todayOnly && <ion-icon class="checked-services " name="checkmark-circle"></ion-icon>}</li> }
                        { found && <li>Car Wash {todayOnly && <ion-icon class="checked-services " name="checkmark-circle"></ion-icon>}</li> }
                        <li>Car Cleaning (Waterless) {todayOnly && <ion-icon class="checked-services " name="checkmark-circle"></ion-icon>}</li>
                        {
                            upgradedData !== null && upgradedData !== '' ?
                            ([6, 22].includes(index) && upgradedData.uid === 'PACKAGE__002') ||
                            ([6, 14, 22].includes(index) && upgradedData.uid === 'PACKAGE__003')?
                            <li>Vacuuming {todayOnly && <ion-icon class="checked-services " name="checkmark-circle"></ion-icon>}</li>:<></>:
                            (index === 6 && packageData.uid === 'PACKAGE__001') ||
                            ([6, 22].includes(index) && packageData.uid === 'PACKAGE__002') ||
                            ([6, 14, 22].includes(index) && packageData.uid === 'PACKAGE__003')?
                            <li>Vacuuming {todayOnly && <ion-icon class="checked-services " name="checkmark-circle"></ion-icon>}</li>:<></>
                        }
                        <li>Air Pressure {todayOnly && <ion-icon class="checked-services " name="checkmark-circle"></ion-icon>}</li>
                        {
                            upgradedData !== null && upgradedData !== '' ?
                            ([6, 3].includes(item.day) && upgradedData.uid === 'PACKAGE__002') ||
                            ([6, 4, 2].includes(item.day) && upgradedData.uid === 'PACKAGE__003')?
                            <li>Interior Dusting {todayOnly && <ion-icon class="checked-services " name="checkmark-circle"></ion-icon>}</li>:<></>:
                            (item.day === 6 && packageData.uid === 'PACKAGE__001') ||
                            ([6, 3].includes(item.day) && packageData.uid === 'PACKAGE__002') ||
                            ([6, 4, 2].includes(item.day) && packageData.uid === 'PACKAGE__003')?
                            <li>Interior Dusting {todayOnly && <ion-icon class="checked-services " name="checkmark-circle"></ion-icon>}</li>:<></>
                        }
                    </ul>
                    {!todayOnly && <p className="view-service-status" onClick={() => this._getCompletedWorkData(item.date)}>Status</p>}
                </Box>
            } else return true
        })
    }
    _getPreventiveCheckList = async () => {
		const result = await miS.getPreventiveCheckList()
        if (result.status) {
            let preventiveCheckList = result.data
            this.setState({ preventiveCheckList })
        } else
            miS.showAlert({ type: 'error', msg: 'Unable to get preventiveCheckList!' })
    }
    _renderPriventiveCheckList = preventiveQueries => {
        return preventiveQueries.map(( item, index ) => {
            return <div key={index}>
                <h3>{index+1}. {item.serviceTitle}</h3>
                { this._renderPreventiveQueriesHead(item.serviceQueries || [] )}
            </div>
        })
    }
    _renderPreventiveQueriesHead = queriesFiles => {
        return queriesFiles.map(( item, index ) => {
            let tableContent = {
                content: this._getTableData(item.status)
            }
            return <div key={index}>
                <p>{index+1}. {item.query}</p>
                <Table
                    showTotal={false}
                    data={tableContent}
                />
            </div>
        })
    }
    _getTableData = filterData => {
        let data = []
        filterData.forEach(( item ) => {
            let e = {
                type: <b>{item.type}</b>,
                answers: <div className="query-list-icon-Box">
                    {
                        item.answers[0] === 'Perfect' ?
                        <ion-icon class="success" name="checkmark-circle"></ion-icon> : 
                        item.answers[0] === 'Not Available' ?
                        <ion-icon class="error" name="close-circle"></ion-icon>:
                        item.answers[0] === 'Need attention' ?
                        <ion-icon class="error" name="close-circle"></ion-icon>:
                        item.answers[0] === 'Available' ?
                        <ion-icon class="success" name="checkmark-circle"></ion-icon>:<></>
                    }
                    <span className="ml10">{item.answers[0]}</span>
                </div>
            }
            data.push(e)
        })
        return data
    }
    _getPreventiveAnswers = (date, mode = 'detail') => {
        let { preventiveCheckList, workedPackagedata } = this.state, activePreventiveData = null
        workedPackagedata.forEach(e => {
            if (miS.getFormattedDate(e.serviceDate) === miS.getFormattedDate(new Date(date))) {
                activePreventiveData = JSON.parse(e.workData)[0].preventiveWorkData
            }
        })
        if (activePreventiveData === null && mode === 'detail') {
            miS.showAlert({ type: 'info', msg: 'Preventive check is yet not done for this date.' })
        } else {
            preventiveCheckList.forEach(e => {
                e.serviceQueries.forEach(el => {
                    el.status.forEach(eq => {
                        if (mode === 'view') {
                            eq.answers[0] = '...'
                        } else {
                            activePreventiveData?.forEach(ed => {
                                if (ed.title === el.query && ed.query === eq.type) {
                                    eq.answers[0] = ed.status
                                }
                            })
                        }
                    })
                })
            })
            this.setState({ preventiveCheckList }, () => miS.openModal("preventive-checklist-modal"))
        }
    }
    _renderImages = serviceImages => {
        return serviceImages.map(( item, index ) => {
            return <li key={index}>
                    <Img src={base.imageBaseUrl+item} alt={item} hasZoom={true} />
              </li>
        })
    }
    _updatePackage = async () => {
        let { chosenServiceDates, packageId, activeServiceDate } = this.state,
        error = false,
        errorMsg = ''
        chosenServiceDates.push(miS.getFormattedDate(activeServiceDate))
        let serviceDatesObject = {
            userId: uS.getLoggedInUserData().uid,
            tempUid: packageId,
            preventiveCheckListDates: JSON.stringify(chosenServiceDates)
        }
        if (new Date(activeServiceDate).getDay() === 0) {
            error = true
            errorMsg = 'We do not serve on Sundays'
        }
        if (error) {
            miS.showAlert({ type: 'error', msg: errorMsg })
        } else {
            if (chosenServiceDates.length === 1) {
                serviceDatesObject.uid = new Date().getTime()
                const result = await oS.addServiceDates(serviceDatesObject)
                if (result.status) {
                    this._getServiceDates(packageId)
                    miS.showAlert({ type: 'success', msg: 'Preventive Checklist dates submitted successfully' })
                    rS.getLocationData().reload()
                }
            } else {
                let data = {
                    query: JSON.stringify({tempUid: packageId}),
                    serviceDatesData: JSON.stringify(serviceDatesObject)
                }
                const result = await oS.updateServiceDates(data)
                if (result.status) {
                    this._getServiceDates(packageId)
                    miS.showAlert({ type: 'success', msg: 'Preventive Checklist dates submitted successfully' })
                    rS.getLocationData().reload()
                }
            }
        }
    }
    render() {
        let { packageData, activeServiceDate, upgradedData, chosenServiceDates, preventiveCheckList, packageWork, serviceWorkAwaitedStatus } = this.state,
        carNotAvailable = false,
        needDate = true,
        today = new Date(),
        activeMonth = 0,
        activeYear = 0,
        activeDate = 0,
        tomorrow = new Date(today)
        activeMonth = today.getMonth() + 1
        activeYear = today.getFullYear()
        activeDate = today.getDate()
        tomorrow.setDate(tomorrow.getDate() + 1)
        if (packageData!== null) {
            if (miS.getFormattedDate(tomorrow) < miS.getFormattedDate(new Date(packageData.startDate))) {
                tomorrow = new Date(packageData.startDate)
            }
            if (packageData !== null) {
                if (packageData.billing === 'monthly') {
                    if (chosenServiceDates.length === 0 || (packageData.uid === 'PACKAGE__003' && chosenServiceDates.length < 2)) {
                    } else {
                        needDate = false
                    }
                } else {
                    if (chosenServiceDates.length === 0 || (((packageData.uid === 'PACKAGE__001' || packageData.uid === 'PACKAGE__002') && chosenServiceDates.length < 3)) || (packageData.uid === 'PACKAGE__003' && chosenServiceDates.length < 6)) {
                        if (packageData.uid === 'PACKAGE__001' || packageData.uid === 'PACKAGE__002') {
                            if (activeMonth === 12) {
                                activeYear++
                                activeServiceDate = new Date('01-01-'+activeYear)
                            } else {
                                activeMonth++
                                activeServiceDate = new Date(activeMonth+'-01-'+activeYear)
                            }
                        } else {
                            if (activeMonth === 12 && activeDate === 31) {
                                activeYear++
                                activeServiceDate = new Date('01-01-'+activeYear)
                            } else {
                                if (chosenServiceDates.length % 2 === 0) {
                                    activeMonth++
                                    activeServiceDate = new Date(activeMonth+'-01-'+activeYear)
                                } else {
                                    activeServiceDate = new Date(chosenServiceDates[chosenServiceDates.length - 1])
                                    activeServiceDate.setDate(activeServiceDate.getDate() + 1)
                                }
                            }
                        }
                    } else {
                        needDate = false
                    }
                }
            }
        }
        if (activeServiceDate === '') {
            activeServiceDate = tomorrow
        }
        let serviceData = null, workData = null
        if (packageWork !== null) {
            serviceData = JSON.parse(packageWork.activeCar)
            if (typeof workData !== 'undefined')
            workData =  JSON.parse(packageWork.workData)
            if (typeof workData[0].workedServices === 'undefined') {
                carNotAvailable = true
            }
        }
        if (packageWork) {
            console.log('packageWork.workData, serviceData', JSON.parse(packageWork.workData), serviceData)
        }
        return <>
            {
                packageData !== null &&
                <>
                    <h1 className="flex">
                        <span>
                            <BackIcon backLink='/admin/package/order/list' />
                            {
                                packageData.title
                            }
                            {
                                upgradedData !== null && upgradedData !== ''?
                                '('+upgradedData.title+')': ''
                            }
                            Service Schedule
                        </span>
                    </h1>
                    <div className="columns">
                        <div className="left">
                            { this._renderServiceData(packageData) }
                        </div>
                        <div className="right">
                            {
                                needDate &&
                                <Box title="Choose Preventive Checklist Date" bodyClass="select-date-mypackages">
                                    <DatePicker
                                        onChange={activeServiceDate => this.setState({ activeServiceDate })}
                                        maxDate={new Date(packageData.endDate)}
                                        selected={activeServiceDate}
                                    />
                                    <Button className="btn-primary btn tac" onClick={this._updatePackage}>Submit</Button>
                                </Box>
                            }
                            <Box title="Preventive Checklist">
                                <span onClick={() => {
                                    this._getPreventiveAnswers(new Date().getTime(), 'view')
                                }} className="link">View Preventive Checklist</span>
                            </Box>
                        </div>
                    </div>
                </>
            }
            <div className="modal" id="preventive-checklist-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content small">
                    <Button className="close" onClick={() => miS.closeModal("preventive-checklist-modal")}><i className="hi-close"></i></Button>
                    <div className="modal-body p20">
                        { this._renderPriventiveCheckList(preventiveCheckList || [] )}
                    </div>
                </div>
            </div>
            <div className="modal" id="car-service-status-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content medium">
                    <Button className="close" onClick={() => miS.closeModal("car-service-status-modal")}><i className="hi-close"></i></Button>
                    <div className="modal-body p20">
                        {
                            serviceWorkAwaitedStatus ?
                            <>
                            <div className="no-data-icon">
                                <ion-icon name="hourglass-outline"></ion-icon>
                            </div>
                            <p className="awited-text">Work is still awaited for the day for this service</p></>: 
                            <div>
                                {
                                    packageWork !== null &&
                                    <>
                                        {
                                            carNotAvailable?
                                            <>
                                                <div className="no-data-icon">
                                                    <ion-icon name="car-sport-outline"></ion-icon>
                                                </div>
                                                <p className="awited-text">Car Not Available</p>
                                            </>:
                                            <>
                                                <div className="form-group flex">
                                                    <label className="col-3 control-label">Worked Services</label>
                                                    <div className="col-9 controls ml20">
                                                        {
                                                            typeof workData[0].workedServices !== 'undefined' && workData[0].workedServices.length !== 0?
                                                            workData[0].workedServices.map((e, index) => {
                                                                return <p key={index}>{e} <ion-icon class="checked-services " name="checkmark-circle"></ion-icon></p>
                                                            })
                                                            :this._renderServiceData(packageData, true)
                                                        }
                                                    </div>
                                                </div>
                                                <div className="form-group flex">
                                                    <label className="col-3 control-label">Service Date</label>
                                                    <div className="col-9 controls ml20">
                                                        {miS.getFormattedDate(packageWork.serviceDate)}
                                                    </div>
                                                </div>
                                                <div className="form-group flex">
                                                    <label className="col-3 control-label">Start Time</label>
                                                    <div className="col-9 controls ml20">
                                                        {
                                                            typeof workData[0].startTime !== 'undefined'?
                                                            miS.getFormattedTime(workData[0].startTime) :
                                                            miS.getFormattedTime(workData[0].time)
                                                        }
                                                    </div>
                                                </div>
                                                <div className="form-group flex">
                                                    <label className="col-3 control-label">{brandName.capital} Id</label>
                                                    <div className="col-9 controls ml20">
                                                        {packageWork.workerId}
                                                    </div>
                                                </div>
                                                <div className="form-group flex">
                                                    <label className="col-3 control-label">Active Car</label>
                                                    <div className="col-9 controls ml20">
                                                        <li className="my-packages-assign-car">
                                                            {
                                                                serviceData.image_path === null || typeof serviceData.image_path === 'undefined' || serviceData.image_path === ''?<></>:
                                                                <Img src={base.carImageBase+(JSON.parse(packageWork.activeCar).image_path)} alt={(packageWork.activeCar).name} />
                                                            }
                                                            <div className="ml10">
                                                                <p>{serviceData.name+' '+serviceData.Segment}</p>
                                                                {
                                                                    serviceData.registrationNo && serviceData.registrationNo !== '' && serviceData.registrationNo !== null?
                                                                    <small>{serviceData.registrationNo}</small>:<></>
                                                                }
                                                            </div>
                                                        </li>
                                                    </div>
                                                </div>
                                                <div className="form-group flex">
                                                    <label className="col-3 control-label">Comments</label>
                                                    <div className="col-9 controls ml20">
                                                        {workData[0].comments}
                                                    </div>
                                                </div>
                                                <div className="form-group flex">
                                                    <label className="col-3 control-label">Images</label>
                                                    <div className="col-9 controls ml20">
                                                        <ul className="media-box">
                                                            { this._renderImages(workData[0].carImages ?? workData[0].carImagesName ?? []) }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    }
}