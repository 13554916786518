import React from 'react'
import { Button } from '../../cms/elements'
import Register from '../../cms/pages/front/Register'
import {DataService, MetaService, MiscService, OrderService, RouterService, UserService} from '../../cms/services'
const dS = new DataService('log'),
uS = new UserService(),
miS = new MiscService(),
mS = new MetaService(),
rS = new RouterService(),
oS = new OrderService()

Register.prototype.componentDidMount = async function() {
    mS.setTitle('Register', 'public')
    let urlData = rS.urlToSplitData(rS.getLocationData().pathname), email = '', additionalData= []
    if (atob(urlData[2]) === 'social-user') {
        let socialData = localStorage.getItem('socialData'),
        query = {
            email: JSON.parse(socialData)._profile.email
        }
        const userResult = await uS.getUser({ query: JSON.stringify(query) })
        if (userResult.status) {
            if (userResult.data.lenght === 0) {
                this.setState({
                    name: JSON.parse(socialData)._profile.name,
                    email: JSON.parse(socialData)._profile.email
                })
            } else {
                let userData = userResult.data[0]
                const userObject = await uS.login(userData)
                let logData = {
                    log: userObject.name+' logged in with website',
                    timing: new Date().getTime(),
                    type: 'success',
                    userId: userObject.uid,
                    uid: new Date().getTime()
                }
                dS.save(logData)
                if (userObject !== null) {
                    let nextUrl = uS.isAdmin() || uS.isSuperVisor() ? '/admin/dashboard' : '/user/profile'
                    localStorage.removeItem('otpData')
                    if (oS.getCartData().length !== 0)
                        miS.proceedToCheckOut()
                    else
                        rS.navigateTo(nextUrl)
                }
            }
        } else
            miS.showAlert({ type: 'error', msg: 'Unable to login!' })
        additionalData.push({
            label: 'socialData',
            value: socialData
        })
        this.setState({ showMobileField: true, additionalData: JSON.stringify(additionalData) })
    } else {
        email = atob(urlData[2]).replace('new', '')
        this.setState({ email })
    }
}

Register.prototype.render = function() {
    let { mobile, email, name, terms } = this.state
    return <div className="login-page">
        <div className="left-box-login left-box-login-register"></div>
        <div className="body-background flex">
            <div className="mauto p30 max-width-loginBox">
                <div className="loginIcon">
                    <i className="hi-user-plus"></i>
                </div>
                <h1 className="form-title tac">Please enter your details</h1>
                <div className="">
                    <form onSubmit={e => {
                        e.preventDefault()
                        this._registerUser()
                    }}>
                        <div className="form-group">
                            <input className="input" placeholder="Full Name" autoFocus type="text" required name="name" value={name||''} onChange={(e) => this.setState({ name: e.target.value })} />
                        </div>
                        <div className="form-group">
                            <input className="input" placeholder="Email" type="email" readOnly name="email" value={email||''} onChange={(e) => this.setState({ email: e.target.value })} />
                        </div>
                        <div className="form-group">
                            <input autoFocus onChange={(e) => this.setState({ mobile: e.target.value })} className="input" type="number" name="mobile" placeholder="Enter Mobile Number" maxLength={10} minLength={10} max={9999999999} min={1000000000} />
                        </div>
                        <div className="flex-box">
                            <label className="switch">
                                <input type="checkbox" onChange={() => this.setState({ terms: !terms })} checked={terms?'checked':false} />
                                <span className="slider round"></span>
                            </label>
                            <span>I accept the <a target="_blank" href="/page/terms-and-conditions" title="Terms and Conditions">Terms and Conditions</a></span>
                        </div>
                        <Button className="btn-primary btn width-full" type="submit">Submit</Button>
                    </form>
                </div>
            </div>
            <i className="hi-arrow-circle-left" class="footer-back-btn" onClick={() => {
                rS.navigateTo('/login')
            }}></i>
            <div className="modal" id="otp-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content small">
                    <div className="modal-body p20">
                        <ion-icon name="alert-circle-outline"></ion-icon>
                        <h1 className="form-title tac">Please enter OTP received on your mobile {mobile}</h1>
                        <form onSubmit={() => this.setState({ showMobileField: false}, this._checkOtp)} noValidate className="relative">
                            <div className="form-group">
                                <input autoFocus onChange={(e) => this.setState({ otp: e.target.value })} className="input" type="number" name="otp" placeholder="Enter OTP" maxLength={6} minLength={6} max={100000} min={999999} />
                            </div>
                            <Button type="submit" className="width-full btn btn-primary mt30">Proceed</Button>
                            <span className="link resend-otp" onClick={this._resendOtp}> Resend OTP</span>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
}