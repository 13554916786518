import React, {Component} from 'react'
import {TrashConfirmModal, Table} from '../../../components'
import Select from 'react-select'
import {DataService, MiscService, RouterService, MetaService} from '../../../services'
import {Button, Actor, Badge} from '../../../elements'
const dS = new DataService('app'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
const commonPageSize = 50
const appStatus = require('./appStatus.json')
export default class AppList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showTrashConfirm: false,
            tableData: [],
            filteredList: [],
            checkedList: [],
            searchKey: '',
            activeFilterIndex: 0,
            isMultipleDelete: false,
            checkedAll: false,
            searchList: [],
            activeShownTotal: commonPageSize,
            activeFilter: null
        }
    }
    componentDidMount () {
        mS.setTitle('App List', 'admin')
        this.setState({ activeFilter: appStatus[0] }, this._getAppListData)
        document.getElementById('content-box').addEventListener('scroll', this._trackScrolling)
    }
    componentWillUnmount() {
        document.getElementById('content-box').removeEventListener('scroll', this._trackScrolling)
    }
    _trackScrolling = () => {
        let { filteredList, activeShownTotal } = this.state,
        wrappedElement = document.getElementById('list-wrapper')
        if (this._isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                this._showMore()
        }
    }
    _isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    _showMore = () => {
        let { activeShownTotal } = this.state
        miS.showPreloader()
        setTimeout(() => {
            miS.hidePreloader()
            this.setState({ activeShownTotal: activeShownTotal + commonPageSize})
        }, 300)
    }
    _trashCancel = () => {
        this.setState({ activeItem: null, showTrashConfirm: false })
    }
    _getAppListData = async () => {
        let { activeFilterIndex } = this.state
        const result = await dS.fetchAll()
        if (result.status) {
            let tableData = result.data
            this.setState({ tableData, filteredList: tableData }, () => {
            let activefilterValue = 'all'
            appStatus.forEach(e => {
                if (Number(e.id) === activeFilterIndex+1) {
                    activefilterValue = e.value
                }
            })
            this._filterItems( activefilterValue, activeFilterIndex)
        })
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No apps added yet!' })
            }
        } else
            miS.showAlert({ type: 'error', msg: 'No apps added yet!' })
    }
    _deleteSelecthApp = () => {
        let { activeItem } = this.state
        this._deleteApp(activeItem.uid)
    }
    _deleteApp = async (uid) => {
        let query = {
            uid
        }
        const result = await dS.delete({ query: JSON.stringify(query) })
        if (result.status) {
            this._getAppListData()
            miS.showAlert({ type: 'success', msg: 'App deleted successfully!' })
            this.setState({ activeItem: null }, this._getAppListData)
        } else
            miS.showAlert({ type: 'error', msg: 'Unable to delete app!' })
    }
    _toggleCheck = uid => {
        let {checkedList, filteredList} = this.state, newCheckedList = [], found = false
        checkedList.forEach(e => {
            if (e === uid)
                found = true
            else
                newCheckedList.push(e)
        })
        if (!found)
            newCheckedList.push(uid)
        this.setState({ checkedAll: (filteredList.length === newCheckedList.length), checkedList: newCheckedList })
    }
    _deleteChecked = () => {
        let { checkedList, checkedAll, filteredList } = this.state
        if (checkedAll)
            checkedList = filteredList
        checkedList.forEach(e => {
            this._deleteApp(e.uid)
        })
        this.setState({ checkedList: [], isMultipleDelete: false })
    }
    _cloneApp = async item => {
        let appObject = {
            allowedApis: item.title,
            author: item.author,
            status: item.status,
            description: item.sender,
            id: item.receiver,
            secret: item.message,
            title: item.time
        }
        appObject.uid = new Date().getTime()
        const result = await dS.save(appObject)
        if (result.status) {
            miS.showAlert({ type: 'success', msg: 'App clonsed successfully!' })
            this._getAppListData()
        } else
            miS.showAlert({ type: 'error', msg: 'Unable to add app!' })
    }
    _getTableData = filterData => {
        let data = [], { checkedAll, checkedList } = this.state
        filterData.forEach(( item ) => {
            let e = {
                checkAction: <span className='check-all' onClick={() => this._toggleCheck(item.uid)}>
                    {
                        checkedAll || checkedList.includes(item.uid) ?
                        <ion-icon class="orange" name="checkmark-circle-outline"></ion-icon>:<ion-icon name="ellipse-outline"></ion-icon>
                    }
                </span>,
                title: item.title,
                id: item.id,
                author: item.author,
                status: <Badge item={item.status} />,
                action: <div className="action-bar">
                    <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                    <Actor type='edit' onClick={() => rS.navigateTo('/admin/app/edit/'+item.uid)} />
                    <Actor type='copy' onClick={() => this._cloneApp(item)} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    _renderappStatus = filterData => {
        let { activeFilterIndex, tableData } = this.state
        return filterData.map(( item, index ) => {
            return <span className={ index === activeFilterIndex ? 'link active' : 'link item'} key={index} onClick={() => this._filterItems(item.key, index)}>{item.item} ({
                (item.key === 'all') ? tableData.length:
                tableData.reduce(( count, e) => {
                return item.key === e.status ? count+1:count
            },0) })</span>
        })
    }
    _filterItems = ( key, activeFilterIndex ) => {
        let { tableData } = this.state, filteredList = []
        if (key === 'all')
            filteredList = tableData
        else {
            filteredList = tableData.filter(e => {
                return e.status === key
            })
        }
        this.setState({ filteredList, activeFilterIndex: activeFilterIndex })
    }
    _search = () => {
        let { searchKey, tableData } = this.state,
        tempTitle = '', filteredList = []
        tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = e.title.toLowerCase()
            if (tempTitle.search(searchKey) !== -1)
                filteredList.push(e)
        })
        this.setState({ filteredList })
    }
    render() {
        let { activeFilter, tableData, showTrashConfirm, checkedList, checkedAll, isMultipleDelete, filteredList, searchKey, activeShownTotal } = this.state
        let tableContent = {
            headers: [
                {
                    template:<span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll)
                            this.setState({ checkedAll: !checkedAll, checkedList: [] })
                        else {
                            filteredList.forEach(e => {
                                newCheckedList.push(e.uid)
                            })
                            this.setState({ checkedAll: !checkedAll, checkedList: newCheckedList })
                        }
                    }}>
                    {
                        checkedAll || checkedList.length === filteredList.length?
                        <ion-icon class="orange" name="checkmark-circle-outline"></ion-icon>:
                        <ion-icon name="ellipse-outline"></ion-icon>
                    }
                    </span>
                },
                {
                    label: 'Title',
                    key: 'title'
                },
                {
                    label: 'App Id',
                    key: 'id'
                },
                {
                    label: 'Author',
                    key: 'author'
                },
                {
                    label: 'App Status',
                    key: 'status'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: this._getTableData(filteredList)
        }
        return <>
            <h1 className="title flex mb50">
                All Apps
                <a className="btn btn-primary btn-small ml20" href="/admin/app/add">Add</a>
                {
                    (checkedList.length !== 0 || checkedAll) &&
                    <Button type="button" onClick={() => {
                        this.setState({ isMultipleDelete:true, showTrashConfirm: true})
                    }} className="btn btn-outline btn-small">Delete</Button>
                }
            </h1>
            <div className="filter-box">
                <div className="filters relative">
                <label className="filter-label-add">Status: </label>
                    <Select
                        value={activeFilter}
                        defaultValue={activeFilter}
                        onChange={(e, i) => this.setState({ activeFilter: e }, () => {
                            this._filterItems(e.value, i)
                        })}
                        options={appStatus}
                    />
                </div>
                <div className="search-box">
                    <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                            const reg = /^[A-Za-z0-9 ]+$/
                            if (e.target.value === '' || reg.test(e.target.value))
                                this.setState({ searchKey: e.target.value }, this._search)
                        }} />
                    <Button type="button" onClick={() => this.setState({ filteredList: tableData, searchKey: '' }, this._search)}><ion-icon name="close"></ion-icon></Button>
                </div>
            </div>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <TrashConfirmModal trashOkAction={() => {
                isMultipleDelete?this._deleteChecked():this._trashCampaign()
            }} trashCancelAction={this._trashCancel} showTrashConfirm={showTrashConfirm} isMultipleDelete={isMultipleDelete} />
        </>
    }
}