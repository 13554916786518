import React, {Component} from 'react'
import {TrashConfirmModal, Table} from '../../../components'
import Select from 'react-select'
import {DataService, MiscService, UserService, MetaService} from '../../../services'
import {Button, Badge} from '../../../elements'
const dS = new DataService('event'),
lS = new DataService('log'),
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService()
const commonPageSize = 50
const filterTags = require('./filterTags.json')
export default class LogList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableData: [],
            image: '',
            filteredList: [],
            activeFilterIndex: 0,
            showTrashConfirm: false,
            searchList: [],
            activeShownTotal: commonPageSize,
            tableDataUsers: [],
            activeFilter: null
        }
    }
    componentDidMount () {
        mS.setTitle('Activity Logs', 'admin')
        this.setState({ activeFilter: filterTags[0] })
        this._getLogsData()
        this._getUsersData()
        document.getElementById('content-box').addEventListener('scroll', this._trackScrolling)
    }
    componentWillUnmount() {
        document.getElementById('content-box').removeEventListener('scroll', this._trackScrolling)
    }
    _trackScrolling = () => {
        let { filteredList, activeShownTotal } = this.state,
        wrappedElement = document.getElementById('list-wrapper')
        if (this._isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                this._showMore()
        }
    }
    _isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    _showMore = () => {
        let { activeShownTotal } = this.state
        miS.showPreloader()
        setTimeout(() => {
            miS.hidePreloader()
            this.setState({ activeShownTotal: activeShownTotal + commonPageSize})
        }, 300)
    }
    _getLogsData = async () => {
        const result = await lS.fetchAll()
        if (result.status) {
            let tableData = result.data.reverse()
            this.setState({ tableData, filteredList: tableData, carList: tableData })
        }
    }
    _getUsersData = async () => {
        const result = await uS.getUsers()
        if (result.status) {
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No users added yet!' })
            } else
                this.setState({ tableDataUsers: result.data })
        }
    }
    _trashCancel = () => {
        this.setState({ activeItem: null, showTrashConfirm: false })
    }
    _trashEvent = async () => {
        let { activeItem } = this.state,
        query = {
            uid: activeItem.uid
        }
        const result = await dS.delete({ query: JSON.stringify(query) })
        if (result.status) {
            this._getLogsData()
            miS.showAlert({ type: 'success', msg: 'Event deleted successfully!' })
            this.setState({ activeItem: null, showTrashConfirm: false })
        } else
            miS.showAlert({ type: 'error', msg: 'Unable to delete event!' })
    }
    _getTableData = filterData => {
        let data = [], { tableDataUsers } = this.state, name= '', role= ''
        filterData.forEach(( item, index ) => {
            tableDataUsers.forEach(e => {
                if (e.uid === item.userId) {
                    name = e.name
                    role = e.role
                }
            })
            let e = {
                srNo: index+1,
                log: item.log,
                timing: <>{miS.getFormattedDate(item.timing)+' '+miS.getFormattedTime(item.timing)}</>,
                name,
                role,
                logType: <Badge item={item.type} />
            }
            data.push(e)
        })
        return data
    }
    _renderFilterTags = filterData => {
        let { activeFilterIndex, tableData } = this.state
        return filterData.map(( item, index ) => {
            return <span className={index === activeFilterIndex ? 'link active' : 'link item'} key={index} onClick={() => this._filterItems(item.key, index)}>
                {item.item}
                ({
                    (item.key === 'all') ? tableData.length :
                    tableData.reduce(( count, e) => {
                        return item.key === e.type ? count + 1 : count
                    }, 0)
                })
            </span>
        })
    }
    _filterItems = ( key, activeFilterIndex ) => {
        let { tableData } = this.state, filteredList = []
        if (key === 'all') {
            filteredList = tableData
        } else {
            filteredList = tableData.filter(e => {
                return e.type === key
            })
        }
        this.setState({ filteredList, activeFilterIndex: activeFilterIndex })
    }
    _search = () => {
        let { searchKey, tableData } = this.state,
        tempTitle = '', filteredList = []
        tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = e.registrationNo.toLowerCase()
            if (tempTitle.search(searchKey) !== -1) {
                filteredList.push(e)
            }
        })
        this.setState({ filteredList })
    }
    render() {
        let { activeFilter, tableData, showTrashConfirm, filteredList, searchKey, activeShownTotal } = this.state
        let tableContent = {
            headers: [
                {
                    label: 'S.No.',
                    key: 'srNo'
                },
                {
                    label: 'Log Detail',
                    key: 'logDetails'
                },
                {
                    label: 'Time',
                    key: 'time'
                },
                {
                    label: 'User',
                    key: 'user'
                },
                {
                    label: 'Role',
                    key: 'role'
                },
                {
                    label: 'Log Type',
                    key: 'logType'
                }
            ],
            content: this._getTableData(filteredList)
        }
        return <>
            <h1 className="title flex mb50">
                All Logs
            </h1>
            <div className="filter-box">
                <div className="filters relative">
                <label className="filter-label-add">Status: </label>
                    <Select
                        value={activeFilter}
                        defaultValue={activeFilter}
                        onChange={(e, i) => this.setState({ activeFilter: e }, () => {
                            this._filterItems(e.value, i)
                        })}
                        options={filterTags}
                    />
                </div>
                <div className="search-box">
                    <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                        const reg = /^[A-Za-z0-9 ]+$/
                        if (e.target.value === '' || reg.test(e.target.value))
                            this.setState({ searchKey: e.target.value }, this._search)
                    }} />
                    <Button type="button" onClick={() => this.setState({ filteredList: tableData, searchKey: '' }, this._search)}><ion-icon name="close"></ion-icon></Button>
                </div>
            </div>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <TrashConfirmModal trashOkAction={this._trashEvent} trashCancelAction={this._trashCancel} showTrashConfirm={showTrashConfirm} />
        </>
    }
}