import React, {Component} from 'react'
import { base } from '../../constants/Data';
import {Img} from '../../elements';
export default class HomeBannerTop extends Component {
    constructor(props) {
        super(props)
        this.state = {
            serviceSliderList: null,
            subscriptionData: null,
            banners: []
        }
    }
    componentDidMount () {
        // this._getProductsData()
    }
    // _getProductsData = async () => {
    //     let result = await getProducts()
    //     if (result.status) {
    //         let tableData = []
    //         result.data.reverse().forEach(( e, i ) => {
    //             if (typeof e.trash === "undefined" && e.isFeatured && i<10) {
    //                 tableData.push(e)
    //             } else if (!e.trash && i<10) {
    //                 tableData.push(e)
    //             }
    //         })
    //         this.setState({ products: tableData })
    //     }
    // }
    // _renderProductBanners = (items) => {
    //     return items.map((item, index)=> {
    //         return <div className="banner-box" key={index}>
    //             <div className="left-banner-box">
    //                 <div className="product-content">
    //                     <h4>{item.title}</h4>
    //                     <p className="">{((item.price - item.saleprice)/item.price * 100).toFixed(0)}% off</p>
    //                     <button className="btn btn-outline btn-big mauto mt20" onClick={() => {this._addToCart(item)}}>Buy Now</button>
    //                 </div>
    //             </div>
    //             <div className="right-banner-box">
    //                 <Img src={base.imageBaseUrl+JSON.parse(item.image)[0]} alt={item.title} className="mobile-image-home-banner" />
    //             </div>
    //         </div>
    //     })
    // }
    // _productAddedTemplate = item => {
    //     let price = item.price
    //     if ( item.saleprice && item.saleprice !== 0 && item.saleprice !== '' && item.saleprice !== item.mrp ) {
    //         price = item.saleprice
    //     }
    //     let prodcutAddedTemplate = '<div class="alert-msg"><ion-icon name="shield-checkmark-outline"></ion-icon><p>Product added to cart!</p></div>'+
    //     '<div class="product-box">'+
    //     '<Img src="'+base.imageBaseUrl+item.image+'" alt="'+item.title+'" class="product-image" />'+
    //     '<div class="product-content">'+
    //     '<p class="product-title">'+item.title+'</p>'+
    //     '<p class="product-price">₹ '+price+'</p>'+
    //     '</div>'+
    //     '</div>'
    //     miS.showAlert({
    //         type: 'custom',
    //         template: prodcutAddedTemplate
    //     })
    //     this.setState({ activeCarType: null })
    //     setTimeout(() => {
    //         this.setState({ activeCarType: null })
    //         this.setState({ activeSubscriptionType: null })
    //     }, 2000)
    // }
    // _renderCouponBanners = (items) =>{
    //     return items.map((item, index)=> {
    //         return <a href={item.url} key={index}><Img src={item.image} /></a>
    //     })
    // }
    // _addToCart = item => {
    //     let price = item.price, mrp = price, saleprice = item.saleprice
    //     if ( item.saleprice && item.saleprice !== 0 && item.saleprice !== '' && item.saleprice !== item.mrp ) {
    //         price = saleprice
    //     }
    //     let product = {
    //     uid: item.uid,
    //     title: item.title,
    //     image: JSON.parse(item.image)[0],
    //     purchaseQuantity: 1,
    //     type: 'product',
    //     tempUid: new Date().getTime(),
    //     mrp: Number(mrp),
    //     saleprice: saleprice,
    //     tax: item.tax,
    //     hsn: item.hsn,
    //     sku: item.sku,
    //     taxAmount: Number((price * Number(item.tax)*0.01).toFixed(2))
    // };
    // product.displayPrice = price
    // product.price = price - product.taxAmount
    // addToCart(product)
    // this._productAddedTemplate(product)
    // }
    render() {
        // let { banners, products } = this.state,
        // settingsProductSlider = {
        //     dots: true,
        //     infinite: true,
        //     speed: 500,
        //     arrows: true,
        //     slidesToShow: 1,
        //     pauseOnHover:false,
        //     slidesToScroll: 1,
        //     autoplay: true,
        //     autoplaySpeed: 10000
        // },
        // settingsCouponSlider = {
        //     dots: false,
        //     infinite: true,
        //     speed: 500,
        //     arrows: true,
        //     slidesToShow: 1,
        //     pauseOnHover:false,
        //     slidesToScroll: 1,
        //     autoplay: true,
        //     autoplaySpeed: 10000
        // }
        return <div className="mb60-mbs40">
            <div className="jumbo-container">
                <h2 className="title-bar heading-02">Recent Services</h2>
                <div className="featured-group-sidebar">
                    <a href="/services/jump-start">
                        <Img src={base.imageBaseUrl+"jump-start.jpg"} />
                    </a>
                    <a href="/services/interior-detailing">
                        <Img src={base.imageBaseUrl+"Interior%20Detailing.jpg"} />
                    </a>
                    <a href="/services/sanitization-deodorization">
                        <Img src={base.imageBaseUrl+"sanitization-deodorization.jpg"} />
                    </a>
                </div>
            </div>
        </div>
    }
}