import React, {Component} from 'react'
import {MiscService, ValidationService, UserService, RouterService, OrderService, DataService, MetaService} from '../../../services'
import {Button} from '../../../elements'
const dS = new DataService('log'),
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
vS = new ValidationService(),
rS = new RouterService(),
oS = new OrderService()
export default class Register extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            name: '',
            otp: '',
            role: 'user',
            gstin: '',
            mobile: '',
            uid: '',
            terms: false,
            showMobileField: false,
            additionalData: ''
        }
    }
    async componentDidMount () {
        mS.setTitle('Register', 'public')
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname), mobile = '', additionalData= []
        if (atob(urlData[2]) === 'social-user') {
            let socialData = localStorage.getItem('socialData'),
            query = {
                email: JSON.parse(socialData)._profile.email
            }
            const userResult = await uS.getUser({ query: JSON.stringify(query) })
            if (userResult.status) {
                if (userResult.data.lenght === 0) {
                    this.setState({
                        name: JSON.parse(socialData)._profile.name,
                        email: JSON.parse(socialData)._profile.email
                    })
                } else {
                    let userData = userResult.data[0]
                    const userObject = await uS.login(userData)
                    let logData = {
                        log: userObject.name+' logged in with website',
                        timing: new Date().getTime(),
                        type: 'success',
                        userId: userObject.uid,
                        uid: new Date().getTime()
                    }
                    dS.save(logData)
                    if (userObject !== null) {
                        let nextUrl = uS.isAdmin() || uS.isSuperVisor() ? '/admin/dashboard' : '/user/profile'
                        localStorage.removeItem('otpData')
                        if (oS.getCartData().length !== 0)
                            miS.proceedToCheckOut()
                        else
                            rS.navigateTo(nextUrl)
                    }
                }
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to login!' })
            additionalData.push({
                label: 'socialData',
                value: socialData
            })
            this.setState({ showMobileField: true, additionalData: JSON.stringify(additionalData) })
        } else {
            mobile = atob(urlData[2]).replace('new', '')
            this.setState({ mobile })
        }
    }
    _registerUser = async () => {
        let { email, name, role, gstin, terms, mobile, additionalData, showMobileField } = this.state,
        error = false,
        errorMsg = ''
        if (!terms) {
            errorMsg = 'Please accept terms first!'
            error = true
            miS.showAlert({ type: 'error', msg: errorMsg })
        } else {
            if (showMobileField) {
                let userObject = {
                    mobile
                }
                if (mobile === '') {
                    errorMsg = 'Please enter your Mobile Number'
                    error = true
                } else if (!vS.isValidMobile(mobile)) {
                    errorMsg = 'Please enter a valid Mobile Number'
                    error = true
                }
                if (!error) {
                    const result = await uS.sendOtp(userObject)
                    if (result.status) {
                        miS.showAlert({ type: 'info', msg: 'An OTP has been sent to your mobile number!' })
                        let otpData = result.data[0]
                        localStorage.setItem('otpData', JSON.stringify(otpData))
                        miS.openModal('otp-modal')
                    } else
                        miS.showAlert({ type: 'error', msg: 'Unable to send OTP, try after some time.' })
                } else
                    miS.showAlert({ type: 'error', msg: errorMsg })
            } else {
                let userObject = {
                    email,
                    name,
                    role,
                    gstin,
                    additionalData: additionalData,
                    mobile,
                    image: JSON.stringify(["no-image-found.png"])
                }
                if (email === '') {
                    errorMsg = 'Please enter user email!'
                    error = true
                } else if (name === '') {
                    errorMsg = 'Please enter the Name'
                    error = true
                }
                if (!error) {
                    userObject.uid = new Date().getTime()
                    const result = await uS.addUser(userObject)
                    let logData = {
                        log: userObject.name+' registered with website',
                        timing: new Date().getTime(),
                        type: 'success',
                        userId: userObject.uid,
                        uid: new Date().getTime()
                    }
                    dS.save(logData)
                    if (result.status)
                        this._loginUser(userObject)
                    else
                        miS.showAlert({ type: 'error', msg: 'Mobile number already registered, please try loging in.' })
                } else
                    miS.showAlert({ type: 'error', msg: errorMsg })
            }
        }
    }
    _resendOtp = async () => {
        let error = false,
        errorMsg = '',
        { mobile }= this.state,
        userObject = {
            mobile
        }
        if (!error) {
            const result = await uS.sendOtp(userObject)
            if (result.status) {
                miS.showAlert({ type: 'info', msg: 'An OTP has been sent to your mobile number!' })
                let otpData = result.data[0]
                localStorage.setItem('otpData', JSON.stringify(otpData))
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to send OTP, try after some time.' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _checkOtp = async () => {
        let { otp } = this.state,
        otpData = JSON.parse(localStorage.getItem('otpData')),
        derivedOtp = otpData.otp,
        derivedUid = otpData.uid,
        errorMsg = '',
        error = false,
        query = {},
        userType = otpData.userType;
        if (userType === 'old') {
            query = {
                uid: derivedUid
            }
        }
        if (otp === '') {
            errorMsg = 'Please enter OTP'
            error = true
        }
        if (Number(otp) !== derivedOtp) {
            errorMsg = 'OTP does not match'
            error = true
        }
        if (!error) {
            if (userType === 'new')
                this._registerUser()
            else {
                const result = await uS.getUser({ query: JSON.stringify(query) })
                if (result.status) {
                    let userData = result.data[0]
                    const userObject = await uS.login(userData)
                    if (userObject !== null) {
                        let nextUrl = uS.isAdmin() || uS.isSuperVisor() ? '/admin/dashboard' : '/user/profile'
                        localStorage.removeItem('otpData')
                        if (oS.getCartData().length !== 0)
                            miS.proceedToCheckOut()
                        else
                            rS.navigateTo(nextUrl)
                    }
                } else
                    miS.showAlert({ type: 'error', msg: 'Unable to login!' })
            }
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _loginUser = async userObject => {
        localStorage.removeItem('otpData')
        localStorage.removeItem('socialData')
        let query = {
            uid: String(userObject.uid)
        }
        const result = await uS.getUser({ query: JSON.stringify(query) })
        if (result.status) {
            let userData = result.data[0]
            const userObject = await uS.login(userData)
            if (userObject !== null)
                rS.navigateTo('/user/profile')
        } else
            miS.showAlert({ type: 'error', msg: 'Unable to login!' })
    }
    render() {
        let { mobile, email, name, gstin, terms, showMobileField } = this.state
        return <div className="login-page">
            <div className="left-box-login left-box-login-register"></div>
            <div className="body-background flex">
                <div className="mauto p30 max-width-loginBox">
                    <div className="loginIcon">
                        <i className="hi-user-plus"></i>
                    </div>
                    <h1 className="form-title tac">Please enter your details</h1>
                    <div className="">
                        <form onSubmit={e => {
                            e.preventDefault()
                            this._registerUser()
                        }}>
                            <div className="form-group">
                                <input className="input" placeholder="Full Name" autoFocus type="text" required name="name" value={name||''} onChange={(e) => this.setState({ name: e.target.value })} />
                            </div>
                            <div className="form-group">
                                <input className="input" placeholder="Email" type="email" required name="email" value={email||''} onChange={(e) => this.setState({ email: e.target.value })} />
                            </div>
                            <div className="form-group">
                                <input className="input" placeholder="GSTIN" type="text" name="gstin" value={gstin||''} onChange={(e) => this.setState({ gstin: e.target.value })} />
                            </div>
                            {
                                showMobileField &&
                                <div className="form-group">
                                    <input autoFocus onChange={(e) => this.setState({ mobile: e.target.value })} className="input" type="number" name="mobile" placeholder="Enter Mobile Number" maxLength={10} minLength={10} max={9999999999} min={1000000000} />
                                </div>
                            }
                            <div className="flex-box">
                                <label className="switch">
                                    <input type="checkbox" onChange={() => this.setState({ terms: !terms })} checked={terms?'checked':false} />
                                    <span className="slider round"></span>
                                </label>
                                <span>I accept the <a target="_blank" href="/page/terms-and-conditions" title="Terms and Conditions">Terms and Conditions</a></span>
                            </div>
                            <Button className="btn-primary btn width-full" type="submit">Submit</Button>
                        </form>
                    </div>
                </div>
                <i className="hi-arrow-circle-left" class="footer-back-btn" onClick={() => {
                    rS.navigateTo('/login')
                }}></i>
                <div className="modal" id="otp-modal">
                    <div className="modal-backdrop"></div>
                    <div className="modal-content small">
                        <div className="modal-body p20">
                            <ion-icon name="alert-circle-outline"></ion-icon>
                            <h1 className="form-title tac">Please enter OTP received on your mobile {mobile}</h1>
                            <form onSubmit={() => this.setState({ showMobileField: false}, this._checkOtp)} noValidate className="relative">
                                <div className="form-group">
                                    <input autoFocus onChange={(e) => this.setState({ otp: e.target.value })} className="input" type="number" name="otp" placeholder="Enter OTP" maxLength={6} minLength={6} max={100000} min={999999} />
                                </div>
                                <Button type="submit" className="width-full btn btn-primary mt30">Proceed</Button>
                                <span className="link resend-otp" onClick={this._resendOtp}> Resend OTP</span>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}