import React, {Component} from 'react'
import {Banner} from '../../../widgets'
import {PackagesData} from '../../../components'
import {CarService, MetaService} from '../../../services'
import {Img} from '../../../elements'
import { base, brandName } from '../../../constants/Data'
const mS = new MetaService(),
cS = new CarService()
export default class Pricing extends Component {
    constructor(props) {
        super(props)
        this.state = {
            vehicleClassList: null,
        }
    }
    componentDidMount () {
        mS.setTitle('Packages', 'public')
        this._getVehicleClassList()
    }
    _getVehicleClassList = async () => {
        const result = cS.getVehicleClassList()
        if (result.status)
            this.setState({ vehicleClassList: result.data })
    }
    render() {
        return <>
            <Banner  title="PACKAGES AND SERVICES" image="yellow-back-1.jpg"/>
            <div className="pricing-page">
                <div className="jumbo-container">
                    <div className="heading-section-pricing">
                        <p className="mt20 heading-table-price-p">Choose the plan that's right for you</p>
                        <p className="mt40">{brandName.capital} understands and aims at providing affordable and accessible servicing . <br /> Hence there are 3 types of packages designed according to the budget and need of every customer</p>
                        <PackagesData />
                    </div>
                </div>
                <a href="/accessories" style={{display: 'block',width: '100%',marginBottom: '40px'}}>
                    <Img src={base.imageBaseUrl+"india-sale-banner.jpg"} style={{display: 'block',width: '100%'}} />
                </a>
            </div>
        </>
    }
}
