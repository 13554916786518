import React, { useEffect, useState } from 'react'
import {UserService, MiscService} from '../../services'
import { profileNav } from '../../constants/Enums'
const uS = new UserService(),
miS = new MiscService()
// const routes = require('../../widgets/Content/routes.json')
const ProfileSideBar = () => {
    const user = uS.getLoggedInUserData()
    const [temporaryUser, settemporaryUser] = useState(false)
    // profileNav.forEach(e => {
    //     routes.forEach(r => {
    //         if (r.pathName === e.link)
    //             e.accessRoles = r.accessRoles
    //     })
    // })
    useEffect(() => {
        if (localStorage.getItem('tempLogin') === 'true') {
            settemporaryUser(true)
        }
    }, [])
    const _logout = () => {
        uS.logout()
    },
    _renderProfileMenu = () => {
        return profileNav.map(( item, index ) => {
            if (((item.accessRoles && item.accessRoles.includes(user.role)) || (item.accessRoles.includes('user') && ['admin', 'developer'].includes(user?.role))) && miS.isModuleEnabled([item.module]))
                return <li title={item.title} key={index}><a href={item.link}><ion-icon name={item.icon}></ion-icon><span className="profile-sidebar-name">{item.title}</span></a></li>
            else return true
        })
    }
    return <div className="left-menu-section">
        <div className="nav-list">
            <div className="menus">
                <ul>
                    { _renderProfileMenu() }
                    {!temporaryUser && <li title='Logout'><i onClick={_logout}><ion-icon name="log-out-outline"></ion-icon><span className="profile-sidebar-name">Logout</span></i></li>}
                </ul>
            </div>
        </div>
    </div>
}
export default ProfileSideBar