import React, {Component} from 'react'
import {MiscService} from '../../../services'
import {base} from '../../../constants/Data'
import {DataService, RouterService, MetaService} from '../../../services'
import {Img, Button, BackIcon} from '../../../elements'
import {Box, MediaBox} from '../../../components'
const tS = new DataService('testimonial'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
export default class AddTestimonial extends Component {
    constructor(props) {
        super(props)
        this.state = {
            content: '',
            uid: '',
            mode: 'Add',
            name: '',
            mediaFiles: '',
            searchKey: '',
            tableData: [],
            image: '[]',
            activeFile: null
        }
    }
    componentDidMount () {
        mS.setTitle('Add Testimonial', 'admin')
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        if (urlData[3] === 'edit') {
            let query = {
                uid: urlData[4]
            }
            this._getTestimonialData(query)
        }
    }
    _getTestimonialData = async query => {
        const result = await tS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            let testimonialData = result.data[0]
            this.setState({
                name: testimonialData.name,
                mode: 'Edit',
                content: testimonialData.content,
                image: testimonialData.image,
                uid: testimonialData.uid
            })
        } else miS.showAlert({ type: 'error', msg: 'No testimonials added yet!' })
    }
    _addTestimonial = async e => {
        e.preventDefault()
        let { content, name, image, mode, uid } = this.state,
        error = false,
        errorMsg = '',
        testimonialObject = {
            content,
            name,
            image
        }
        if (content === '') {
            errorMsg = 'Please enter testimonial content!'
            error = true
        }
        if (name === '') {
            errorMsg = 'Please enter user name!'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                testimonialObject.uid = new Date().getTime()
                const result = await tS.save(testimonialObject)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Testimonial added successfully!' })
                    rS.navigateTo('/admin/testimonial/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to add testimonial!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    testimonialData: JSON.stringify(testimonialObject)
                }
                const result = await tS.update(data)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Testimonial updated successfully!' })
                    rS.navigateTo('/admin/testimonial/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to update testimonial!' })
                }
            }
        } else miS.showAlert({ type: 'error', msg: errorMsg })
    }
    render() {
        let { content, mode, activeFile, name, image } = this.state,
        title = mode + ' Testimonial'
        return <>
            <h1>
                <BackIcon backLink='/admin/testimonial/list' />
                {title}
            </h1>
            <div className="columns">
                <div className="left">
                    <Box title='Name' required>
                        <input type="text" required name="name" value={name} onChange={(e) => this.setState({ name: e.target.value })} />
                    </Box>
                    <Box title='Content' required>
                        <textarea className="description" placeholder="Type the testimonial here" required name="content" value={content} onChange={(e) => this.setState({ content: e.target.value })}></textarea>
                    </Box>
                </div>
                <div className="right">
                    <form noValidate onSubmit={this._addTestimonial}>
                        <Box title='Meta'>
                            <Button className="btn-primary btn" type="submit" title="Click here to Add testimonial">Submit</Button>
                        </Box>
                        <MediaBox image={image} setimage={image => this.setState({image})} />
                    </form>
                </div>
            </div>
        </>
    }
}