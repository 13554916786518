import {postData, getData} from '../Ops'
import OrderService from '../Order'
import RouterService from '../Router'
const rS = new RouterService(),
oS = new OrderService()
export default class UserService {
    addUser = async data => {
        const formData = new FormData()
        formData.append('email', data.email)
        formData.append('name', data.name)
        formData.append('role', data.role)
        formData.append('gstin', data.gstin)
        formData.append('mobile', data.mobile)
        formData.append('uid', data.uid)
        formData.append('image', data.image)
        formData.append('additionalData', data.additionalData)
        return await postData('user/save', formData)
    }
    updateUser = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        formData.append('userData', data.userData)
        return await postData('user/update', formData)
    }
    getUser = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('user/get', formData)
    }
    deleteUser = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('user/delete', formData)
    }
    getCompanyBranches = async (params = false) => {
        if (params) {
            const formData = new FormData()
            formData.append('query', params.query)
            return await postData('company/branch/list', formData)
        } else
        return await getData('user/list')
    }
    getCompanyRepresentatives = async (params = false) => {
        if (params) {
            const formData = new FormData()
            formData.append('query', params.query)
            return await postData('company/representative/list', formData)
        } else
        return await getData('user/list')
    }
    getUsers = async (params = false) => {
        if (params) {
            const formData = new FormData()
            formData.append('query', params.query)
            formData.append('pagination', params.pagination)
            return await postData('user/list', formData)
        } else
        return await getData('user/list')
    }
    logout = (nextUrl = '/home') => {
        localStorage.removeItem('tempUserData')
        localStorage.removeItem('tempLogin')
        localStorage.removeItem('userData')
        localStorage.removeItem('activeCar')
        oS.emptyCart()
        rS.navigateTo(nextUrl)
        return true
    }
    login = async userData => {
        localStorage.setItem('userData', JSON.stringify(userData))
        return userData
    }
    setGlobalActiveBranches = async activeBranches => {
        localStorage.setItem('activeBranches', JSON.stringify(activeBranches))
        return activeBranches
    }
    getLoggedInUserData = () => {
        let userData = (localStorage.getItem('tempLogin') === 'true') ? localStorage.getItem('tempUserData') : localStorage.getItem('userData')
        return userData === null ? userData : JSON.parse(userData)
    }
    getActiveBranches = () => {
        let activeBranches = localStorage.getItem('activeBranches')
        return activeBranches === null ? null : JSON.parse(localStorage.getItem('activeBranches'))
    }
    getActiveSubscription = () => {
        let subscriptionsName = ''
        if (this.isUserLoggedIn()) {
            if (this.getLoggedInUserData().activeSubscription) {
                let activeSubscription = JSON.parse(this.getLoggedInUserData().activeSubscription)
                if (activeSubscription.title.toLowerCase().includes('gold'))
                    subscriptionsName = 'gold'
                else
                    subscriptionsName = 'silver'
                if (subscriptionsName !== '') {
                    if (new Date(activeSubscription.endDate).getTime()>=new Date().getTime())
                        return subscriptionsName
                    else return null
                } else return null
            } else return null
        } else return null
    }
    isUserLoggedIn = () => {
        return localStorage.getItem('userData') !== null || localStorage.getItem('tempUserData') !== null
    }
    isAdmin = () => {
        if (localStorage.getItem('userData') !== null)
            return this.getLoggedInUserData().role === 'admin'
        else return false
    }
    isDeveloper = () => {
        if (localStorage.getItem('userData') !== null)
            return this.getLoggedInUserData().role === 'developer'
        else return false
    }
    isSuperVisor = () => {
        if (localStorage.getItem('userData') !== null)
            return this.getLoggedInUserData().role === 'supervisor'
        else return false
    }
    isAllowedAdmin = () => {
        let accessRoles = ['supervisor', 'worker', 'admin', 'developer']
        if (localStorage.getItem('userData') !== null)
            return accessRoles.includes(this.getLoggedInUserData().role)
        else return false
    }
    sendOtp = async data => {
        const formData = new FormData()
        if(data.mobile) formData.append('mobile',data.mobile)
        else if(data.email) formData.append('email',data.email)
        console.log('formData', formData)
        return await postData('user/sendotp', formData)
    }
    getBranchManagers = async data => {
        const formData = new FormData()
        formData.append('query',data.query)
        return await postData('branch/manager/list', formData)
    }
}