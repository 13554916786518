import React, {Component} from 'react'
import {Banner} from '../../../widgets'
import {base} from '../../../constants/Data'
import {robotsoptions,authoroptions,statusOptions} from '../../../constants/Enums'
import {MiscService, DataService, RouterService, MetaService} from '../../../services'
import {Img} from '../../../elements'
import {Share} from '../../../components'
const dS = new DataService('event'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
export default class EventDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeRobotsIndex: 0,
            activeStatusIndex: 0,
            activeAuthorIndex: 0,
            image: '[]',
            uid: '',
            renderSlug: true,
            mode: 'Add',
            title: '',
            slug: '',
            content: '',
            excerpt: '',
            css: '',
            js: '',
            canonical: '',
            robots: '',
            metaDescription: '',
            metaKeyword: '',
            location: '',
            author: '',
            publishDate: new Date(),
            status: '',
            categories: [],
            tag: '',
            mediaFiles: []
        }
    }
    componentDidMount () {
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname),
        query = {
            slug: urlData[2]
        }
        this._getEventData(query)
    }
    _getEventData = async query => {
        const result = await dS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data[0].status !== 'publish') {
                rS.navigateTo('/error404')
            } else {
                let eventData = result.data[0],
                metaKeyword = [],
                tag = [],
                categories = [],
                activeRobotsIndex = 0,
                activeAuthorIndex = 0,
                activeStatusIndex = 0
                robotsoptions.forEach(( e, i ) => {
                    if (e.value === eventData.robots) {
                        activeRobotsIndex = i
                    }
                })
                authoroptions.forEach(( e, i ) => {
                    if (e.value === eventData.robots) {
                        activeAuthorIndex = i
                    }
                })
                statusOptions.forEach(( e, i ) => {
                    if (e.value === eventData.robots) {
                        activeStatusIndex = i
                    }
                })
                if (eventData.metaKeyword === '""' || eventData.metaKeyword === '') {
                    metaKeyword = []
                } else {
                    JSON.parse(eventData.metaKeyword).forEach(e => {
                        metaKeyword.push(e)
                    })
                }
                if (eventData.tag === '""' || eventData.tag === '') {
                    tag = []
                } else {
                    JSON.parse(eventData.tag).forEach(e => {
                        tag.push(e)
                    })
                }
                if (eventData.categories === '""' || eventData.categories === '') {
                    categories = []
                } else {
                    JSON.parse(eventData.categories).forEach(e => {
                        categories.push(e)
                    })
                }
                mS.setTitle(eventData.title, 'public')
                this.setState({
                    title: eventData.title,
                    mode: 'Edit',
                    slug: eventData.slug,
                    content: eventData.content,
                    excerpt: eventData.excerpt,
                    css: eventData.css,
                    js: eventData.js,
                    canonical: eventData.canonical,
                    robots: eventData.robots,
                    metaDescription: eventData.metaDescription,
                    metaKeyword,
                    author: eventData.author,
                    publishDate: new Date(eventData.publishDate),
                    status: eventData.status,
                    categories,
                    tag,
                    location: eventData.location,
                    uid: eventData.uid,
                    image: eventData.image,
                    activeRobotsIndex,
                    activeAuthorIndex,
                    activeStatusIndex
                })
            }
        }
    }
    render() {
        let { publishDate, title, slug, content, image, location } = this.state
        return <>
            <Banner title={title} image="white-car-banner.jpg" />
            <div className="blog-page jumbo-container">
                <div className="blog-page-content pt120 pb100">
                    <div className="wrapper">
                        <div className="col-12 width-full">
                            <div className="blog-details-wrap">
                                <div className="blog-details-items">
                                    <div className="content bg-none">
                                        <h3 className="title">{title}</h3>
                                        <div className="post-meta border-none pt20">
                                            <ul className="list-wrap">
                                                <li>
                                                    <div className="author"><ion-icon name="time-outline"></ion-icon> {miS.getFormattedTime(new Date(publishDate))}</div>
                                                </li>
                                                <li>
                                                    <div className="author"><ion-icon name="location-outline"></ion-icon> {location}</div>
                                                </li>
                                            </ul>
                                    </div>
                                    </div>
                                    <div className="thumb mt20">
                                        {
                                            image === '' || image === '[]' || typeof image === "undefined"?
                                            <Img src={base.imageBaseUrl+'thumb-medium.png'} alt={title} />:
                                            <Img src={base.imageBaseUrl+JSON.parse(image)[0]} alt={title} />
                                        }
                                    </div>
                                    <div className="blog-details-inner">
                                        <div dangerouslySetInnerHTML={{__html: content}}></div>
                                    </div>
                                </div>
                                <Share shareUrl={"/post/"+slug} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}