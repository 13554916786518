import React, {Component} from 'react'
import * as XLSX from 'xlsx'
import {Button} from '../../../elements'
import {Box, ImportResult, Table} from '../../../components'
import {MiscService, RouterService, MetaService, DataService} from '../../../services'
const make_cols = refstr => {
	let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1;
	for(var i = 0; i < C; ++i) o[i] = {name:XLSX.utils.encode_col(i), key:i}
	return o
},
SheetJSFT = [
	"xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
].map(function(x) { return "." + x; }).join(",")
const dS = new DataService('b2bDailyStockData'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
export default class ImportB2BStockData extends Component {
    constructor(props) {
        super(props)
        this.state = {
            file: {},
            data: [],
            cols: [],
            excelWorkList: [],
            fileImported: false,
            fileSelected: false,
            renderSlug: true,
            mode: 'add',
            importStockData: [],
            tableData: [],
            status: 'publish',
            publishDate: miS.getFormattedDate(new Date()),
            showTable: false,
            importDone: false,
            carModels: [],
            resultWorkList: []
        }
    }
    componentDidMount() {
        mS.setTitle('Import Daily B2b Stock Data', 'admin')
    }
    _handleChange = e => {
        const files = e.target.files;
        if (files && files[0]) this.setState({ file: files[0], fileSelected: true })
    }
    _handleFile = () => {
        miS.showPreloader()
        this.setState({ showTable: true})
        let excelWorkList = [],
        reader = new FileReader(),
        rABS = !!reader.readAsBinaryString
        reader.onload = e => {
            let bstr = e.target.result,
            wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true, cellDates: true }),
            wsname = wb.SheetNames[0],
            ws = wb.Sheets[wsname],
            data = XLSX.utils.sheet_to_json(ws, {raw: false, rawNumbers: true})
            this.setState({ data, cols: make_cols(ws['!ref']) }, () => {
                data.forEach(e => {
                    if (e['Vehicle No'] !== '') {
                        e['Date'] = String(miS.getFormattedDate(new Date(e['Date'])))
                        e['Yard name'] = String(e['Yard name'])
                        e.ownerId = e['Yard name']
                        e.registrationNo = e['Vehicle no']
                        excelWorkList.push(e)
                    }
                })
                this.setState({ excelWorkList, fileImported: true }, miS.hidePreloader)
            })
        }
        if (rABS) {
            reader.readAsBinaryString(this.state.file)
        } else {
            reader.readAsArrayBuffer(this.state.file)
        }
    }
    _processExcel = () => {
        let _this = this
        this.setState({showTable: false}, () => {
            let { excelWorkList, tableData } = _this.state,
            mode = 'add', activeItem = null
            excelWorkList.forEach((e, i) => {
                tableData.forEach(item => {
                    if (String(e.uid) === item.uid) {
                        mode = 'edit'
                        activeItem = item;
                    }
                })
                setTimeout(() => {
                    _this._addStock(e, mode, activeItem, i)
                }, i * 10);
            })
        })
    }
    _addStock = async (item, mode, oldCar = null, i) => {
        let {excelWorkList, resultWorkList} = this.state, success = false
        if(typeof(item.ownerId) === Number) {
            item.ownerId = String(item.ownerId)
        }
        let b2bDailyStockDataObject = item
        try {
            if (mode === 'add') {
                b2bDailyStockDataObject.uid = new Date().getTime()
                const result = await dS.save(b2bDailyStockDataObject)
                if (result.status) {
                    success = true
                    this.setState({ importStockData: result })
                }
            } else {
                if(typeof(oldCar.uid) === Number) {
                    oldCar.uid = String(oldCar.uid)
                }
                let data = {
                    query: JSON.stringify({uid: oldCar.uid}),
                    b2bDailyStockDataData: JSON.stringify(b2bDailyStockDataObject)
                }
                const result = await dS.update(data)
                if (result.status) {
                    success = true
                    this.setState({ importStockData: result })
                }
            }
            if (excelWorkList.length - 1 === i) {
                this.setState({ showTable: false, importDone: true })
            }
            resultWorkList.push({...item, ...{ success }})
            this.setState({ resultWorkList })
        } catch (error) {
            console.log('error', error);
        }
    }
    _getTableData = filterData => {
        let data = []
        try {
            filterData.forEach(( item, i ) => {
                let e = {
                    'Sr. No.' : i + 1,
                    'Date': item['Date'],
                    'Yard name': item['Yard name'],
                    'Vehicle no': item['Vehicle no'].toUpperCase(),
                    'Vehicle model': item['Vehicle model'],
                    'Inventory Status': item['Inventory Status']
                }
                data.push(e)
            })
        } catch(e) {
            console.log('error', e)
        }
        return data
    }

    render() {
        let { resultWorkList, fileSelected, fileImported, excelWorkList, showTable, importDone, activeShownTotal } = this.state
        let tableContent = {
            headers: [
                {
                    label: 'Sr. No.',
                    key: 'Sr. No.'
                },
                {
                    label: 'Date',
                    key: 'Date'
                },
                {
                    label: 'Yard name',
                    key: 'Yard name'
                },
                {
                    label: 'Vehicle no',
                    key: 'Vehicle no'
                },
                {
                    label: 'Vehicle model',
                    key: 'Vehicle model'
                },
                {
                    label: 'Inventory Status',
                    key: 'Inventory Status'
                }
            ],
            content: this._getTableData(excelWorkList)
        }
        return <>
            {
                fileSelected?
                <>
                    {
                        fileImported?
                        <>
                        {
                            !importDone &&
                            <div className='flex'>
                                <Button type='submit' className="btn btn-primary mv20" onClick={this._processExcel}>Import</Button>
                                <span className='mlauto'>Rows Attempted: <b>{excelWorkList.length}</b></span>
                            </div>
                        }
                        </>:
                        <Button type='submit' className="btn btn-primary mv20" onClick={this._handleFile}>Parse</Button>
                    }
                </>:
                <Box title="Import Daily B2b Stock Data">
                    <input type="file" className="hidden form-control inputfile-1" id="file" accept={SheetJSFT} onChange={this._handleChange} />
                    <label className="btn btn-primary" htmlFor="file">
                        <ion-icon name="cloud-upload-outline"></ion-icon>
                        <span>Choose a file…</span>
                    </label>
                </Box>
            }
            {
                showTable?
                <Table
                    className="import-table"
                    data={tableContent}
                    pageSize={activeShownTotal}
                />
                :<>
                    {
                        importDone &&
                        <>
                            <p>All Cars imported successfully</p>
                            <div className="flex mb20">
                                <Button className="btn btn-outline" onClick={() => rS.getLocationData().reload()}>Import More</Button>
                            </div>
                        </>
                    }
                </>
            }
            <ImportResult resultList={resultWorkList} excelList={excelWorkList} />
        </>
    }
}