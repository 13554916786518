import {postData,getData} from '../Ops'
export default class MicroServiceService {
    addMicroService = async data => {
        const formData = new FormData()
        formData.append('title', data.title)
        formData.append('slug', data.slug)
        formData.append('content', data.content)
        formData.append('excerpt', data.excerpt)
        formData.append('css', data.css)
        formData.append('js', data.js)
        formData.append('canonical', data.canonical)
        formData.append('robots', data.robots)
        formData.append('userNote', data.userNote)
        formData.append('metaDescription', data.metaDescription)
        formData.append('metaKeyword', data.metaKeyword)
        formData.append('author', data.author)
        formData.append('publishDate', data.publishDate)
        formData.append('status', data.status)
        formData.append('categories', data.categories)
        formData.append('isFeatured', data.isFeatured)
        formData.append('inclusiveTax', data.inclusiveTax)
        formData.append('sku', data.sku)
        formData.append('microServiceTime', data.microServiceTime)
        formData.append('hsn', data.hsn)
        formData.append('tax', data.tax)
        formData.append('tag', data.tag)
        formData.append('uid', data.uid)
        formData.append('image', data.image)
        formData.append('prices', data.prices)
        if (data.fuelPrices)
            formData.append('fuelPrices', JSON.stringify(data.fuelPrices))
        if (data.company)
            formData.append('company', JSON.stringify(data.company))
        return await postData('micro-service/save', formData)
    }
    updateMicroService = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        formData.append('microServiceData', data.microServiceData)
        return await postData('micro-service/update', formData)
    }
    getMicroService = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('micro-service/get', formData)
    }
    deleteMicroService = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('micro-service/delete', formData)
    }
    getMicroServices = async () => {
        return await getData('micro-service/list')
    }
    getMicroServiceCategory = async () => {
        return await getData('micro-service/category/list')
    }
    addMicroServiceCategory = async categoryObject => {
        const formData = new FormData()
        formData.append('catName', categoryObject.catName)
        formData.append('catSlug', categoryObject.catSlug)
        formData.append('parentCat', categoryObject.parentCat)
        formData.append('uid', categoryObject.uid)
        return await postData('micro-service/category/add', formData)
    }
    deleteMicroServiceCategory = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('micro-service/category/delete', formData)
    }
}