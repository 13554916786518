import React from 'react'
import {MetaService} from '../../../services'
import './style.scss';
const mS = new MetaService()
const ErrorPage = () => {
    mS.setTitle('Error 404', 'public')
    return <div className="body-background">
        <div className="error-page">
            <h1>404</h1>
            <p className="oops-text">Oops!</p>
            <p>Page you're looking for is not found</p>
        </div>
    </div>
}
export default ErrorPage