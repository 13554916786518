import {MiscService} from "../../services"
const miS = new MiscService()
const Table = props => {
    let {data, pageSize, tableClass = '', total, showHeader = false, showTotal = true} = props
    const _renderHeader = () => {
        return <tr>{
            data?.headers?.map((e, i) => {
                return <th key={i}>
                    {e.label ?? e.template}
                </th>
            })
        }</tr>
    }
    const _renderContent = () => {
        return data?.content?.map((e, i) => {
            if ((pageSize && i < pageSize) || !pageSize)
            return <tr key={i}>
                {
                    Object.entries(e).map(([key, value]) => {
                        return <td key={key+'_'+i}>
                            {miS.checkIfEmpty(value)}
                        </td>
                    })
                }
            </tr>
        })
    }
    const _renderFooter = () => {
        return <tr> {
            data?.footers?.map((e, i) => {
                return <th key={i}>
                    {e.label ?? e.template}
                </th>
            })
        }</tr>
    }
    return <div className="table-wrapper overflow-auto">
        {showHeader && <div className="table-metadata">
            <span className="widget">Total: <b>{total}</b></span>
        </div>}
        {showTotal && <p>Total: <b>{data?.content?.length ?? 0}</b></p>}
        <table id="list-wrapper" className={tableClass}>
            {data?.headers && <thead>
                {_renderHeader()}
            </thead>}
            {(data?.content || data?.addonBodyContent) && <tbody>
                {_renderContent()}
                {data?.addonBodyContent}
            </tbody>}
            {data?.footers && <tfoot>
                {_renderFooter()}
            </tfoot>}
        </table>
    </div>
}

export default Table