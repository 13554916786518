import React, {Component} from 'react'
import {Banner} from '../../../widgets'
import {base, brandName} from '../../../constants/Data'
import {BlogSideBar, Share} from '../../../components'
import {DataService, MiscService, RouterService, MetaService} from '../../../services'
import {Img} from '../../../elements'
const dS = new DataService('post'),
commonPageSize = 5,
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
export default class Blog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            postList: [],
            activeShownTotal: commonPageSize,
            filteredList: []
        }
    }
    componentDidMount () {
        mS.setTitle('Blog', 'public')
        this._getPostsData()
        document.addEventListener('scroll', this._trackScrolling)
    }
    componentWillUnmount() {
        document.removeEventListener('scroll', this._trackScrolling)
    }
    _trackScrolling = () => {
        let { filteredList, activeShownTotal } = this.state,
        wrappedElement = document.getElementById('list-wrapper')
        if (this._isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                this._showMore()
        }
    }
    _isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    _showMore = () => {
        let { activeShownTotal } = this.state
        miS.showPreloader()
        setTimeout(() => {
            miS.hidePreloader()
            this.setState({ activeShownTotal: activeShownTotal + commonPageSize})
        }, 300)
    }
    _getPostsData = async () => {
        const result = await dS.fetchAll()
        if (result.status) {
            let postData = result.data.reverse()
            this.setState({ postList: postData, filteredList: postData })
        }
    }
    _renderBlogList = blogFiles => {
        let {activeShownTotal} = this.state
        return blogFiles.map(( item, index ) => {
            if (index < activeShownTotal && item.status === 'publish')
            return <div className="blog-single-item" key={index}>
                <div>
                    <div>
                        <div className="thumb-area-wrap">
                            <div className="thumb-area">
                                <div className="thumb">
                                    {
                                        item.image === '' || item.image === '[]' || typeof item.image === "undefined"?
                                        <Img src={base.imageBaseUrl+'product-placeholder.png'} alt={item.title} />:
                                        <Img className="pointer" onClick={() => rS.navigateTo('/post/'+item.slug)} src={base.imageBaseUrl+JSON.parse(item.image)[0]} alt={item.title} />
                                    }
                                </div>
                                <div className="date">
                                    <span className="day">{new Date(item.publishDate).getDate()}</span>
                                    <span className="month">{miS.getMonthName(new Date(item.publishDate).getMonth())}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div id="content-box" className="content">
                            <h4 className="title"><a href={"/post/"+item.slug}>{item.title}</a></h4>
                            <div className="post-meta">
                                <ul className="list-wrap">
                                    <li>
                                        <div className="author">{brandName.capital}</div>
                                    </li>
                                </ul>
                            </div>
                            <div className="pv30">
                            <div dangerouslySetInnerHTML={{__html: item.excerpt}}></div>
                            </div>
                            <div className="read-more-area">
                                <div className="read-more">
                                    <a href={"/post/"+item.slug}>Read More</a>
                                </div>
                                <Share shareUrl={"/post/"+item.slug} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            else return true
        })
    }
    render() {
        let { postList } = this.state
        return <>
            <Banner title='Blog' image="blog.jpg" />
            <div className="blog-page jumbo-container">
                <div className="blog-page-content pt40 pb40">
                    <div className="wrapper">
                        <div className="col-8" id="list-wrapper">
                            { this._renderBlogList(postList || []) }
                        </div>
                        <div className="col-4">
                            <BlogSideBar />
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}