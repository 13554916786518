import React from 'react'
import {getData,postData} from '../Ops'
import OrderService from '../Order'
import RouterService from '../Router'
import activeModules from '../../../module/activeModules.json'
const rS = new RouterService(),
oS = new OrderService()
export default class MiscService {
    language = {
        en: require('../../../language/en.json'),
        hi: require('../../../language/hi.json')
    }
    generateRandomString = (size = 10) => {
        let strSet = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let dataString = '';
        for (let i = 0; i <= size; i++) {
            let randomNumber = Math.floor(Math.random() * strSet.length);
            dataString += strSet.substring(randomNumber, randomNumber +1);
        }
        return dataString
    }
    toIndianCurrency = (num) => {
        const curr = num.toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR'
        })
        return curr;
    }
    getDateDifference = (date1, date2, type = 'day') => {
        date1 = new Date(date1);
        date2 = new Date(date2);
        if (this.getFormattedDate(date1) === this.getFormattedDate(date2)) {
            return 0
        } else {
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
            const diffMonths = Math.floor(diffDays/30);
            const diffYears = Math.floor(diffMonths/12);
            if (type === 'time') {
                return diffTime
            } else if (type === 'day') {
                return diffDays
            } else if (type === 'month') {
                return diffMonths
            } else if (type === 'year') {
                return diffYears
            }
        }
    }
    hasImageExtension = (fileName) => {
        let found = false, imageExtenstions = ['jpg', 'jpeg', 'png', 'webp', 'gif']
        imageExtenstions.forEach(e => {
            if(fileName?.toLowerCase().includes(e))
                found = true
        })
        return found
    }
    sendMail = async (to, subject, content, selectAll) => {
        const formData = new FormData()
        formData.append('to', to)
        formData.append('subject', subject)
        formData.append('content', content)
        formData.append('userCount', selectAll?'all':1)
        return await postData('utils/sendmail', formData)
    }
    sendAppShare = async data => {
        const formData = new FormData()
        formData.append('to', data.to)
        formData.append('type', data.type)
        return await postData('utils/send-app-share', formData)
    }
    getContactRequest = async data => {
        const formData = new FormData()
        formData.append('name', data.name)
        formData.append('name', data.email)
        formData.append('name', data.message)
        formData.append('name', data.mobile)
        formData.append('name', data.subject)
        formData.append('name', data.company)
        return await postData('misc/get-contact-request', formData)
    }
    translate = key => {
        return this.language[this.getActiveLanguage()][key]
    }
    getActiveLanguage = () => {
        return localStorage.getItem('activeLanguage')?localStorage.getItem('activeLanguage'): 'en'
    }
    getPreventiveCheckList = async () => {
        return await getData('misc/getpreventivechecklist')
    }
    openCarSelectModal = () => {
        document.getElementsByClassName('selectCar')[0].click()
    }
    toggleCartBox = () => {
        this.toggleBodyClass('cart-open')
    }
    toggleBodyClass = (class1, class2 = class1, flushClass = '') => {
        document.getElementsByTagName('body')[0].classList = (document.getElementsByTagName('body')[0].classList["value"] === class1)? flushClass : class2
    }
    parseToHtml = unparsedHtml => {
        let parsedHtml = unparsedHtml.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;/g, ' ').replace(/&amp;/g, '&').replace(/&quot;/g, '"').replace(/&apos;/g, "'").replace(/&cent;/g, '¢').replace(/&pound;/g, '£').replace(/&yen;/g, '¥').replace(/&euro;/g, '€').replace(/&copy;/g, '©').replace(/&reg;/g, '®')
        return parsedHtml
    }
    addDays = (date, days) => {
        const copy = new Date(Number(date))
        copy.setDate(date.getDate() + days)
        return copy
    }
    renderRatingSystem = (rating = 0) => {
        if(typeof rating === 'string')
            rating = Number(rating)
        else if(isNaN(rating))
            rating = 0
        return <>
            { [...Array(rating)].map(i => <ion-icon key={i} name="star" class="orange"></ion-icon>) }
            { [...Array(5 - rating)].map(i => <ion-icon key={i} name="star-outline"></ion-icon>) }
        </>
    }
    showPreloader = () => {
        document.getElementById('preloader').style.display = "block"
    }
    hidePreloader = () => {
        document.getElementById('preloader').style.display = "none"
    }
    openModal = modalId => {
        document.getElementsByTagName('body')[0].classList.add("modal-open")
        document.getElementById(modalId).classList.add("open")
    }
    slugify = str => {
        str = str.replace(/^\s+|\s+$/g, "")
        str = str.toLowerCase()
        var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;",
        to = "aaaaaaeeeeiiiioooouuuunc------"
        for (var i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
        }
        str = str.replace(/[^a-z0-9 -]/g, "").replace(/\s+/g, "-").replace(/-+/g, "-").replace(/^-+/, "").replace(/-+$/, "")
        return str
    }
    closeModal = modalId => {
        document.getElementsByTagName('body')[0].classList.remove("modal-open")
        document.getElementById(modalId).classList.remove("open")
    }
    showAlert = params => {
        let alert = document.createElement("div"),
        alertIcon = ''
        switch (params.type) {
            case 'success':
                alertIcon = 'shield-checkmark-outline'; break
            case 'error':
                alertIcon = 'bug'; break
            case 'info':
                alertIcon = 'information-circle-outline'; break
            case 'warning':
                alertIcon = 'warning-outline'; break
            case 'custom':
                alertIcon = null; break
            default:
                break
        }
        alert.classList = 'alert alert-'+params.type
        alert.style.opacity = 1
        alert.innerHTML = (alertIcon !== null)?'<ion-icon name="'+alertIcon+'"></ion-icon><p>'+params.msg+'</p>':params.template
        document.getElementsByClassName('alert-box')[0].appendChild(alert)
        setTimeout(() => {
            this.fadeAlert(alert)
        }, 2000)
    }
    getActiveCar = () => {
        return JSON.parse(localStorage.getItem('activeCar'))
    }
    setActiveCar = activeCar => {
        localStorage.setItem('activeCar', JSON.stringify(activeCar))
    }
    getThemeMode = () => {
        return localStorage.getItem('themeMode') ?? "theme-light"
    }
    setThemeMode = (themeMode = "theme-light") => {
        document.getElementById('root').className = document.getElementById('root').className.replace(" theme-light", "").replace(" theme-dark", "") + ' ' + themeMode
        localStorage.setItem('themeMode', themeMode)
    }
    fadeAlert = alert => {
        if (alert.style.opacity > 0) {
            setTimeout(() => {
                alert.style.opacity -= 0.1
                this.fadeAlert(alert)
            }, 50)
        } else alert.remove()
    }
    blobToFile = (theBlob, fileName) => {
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        theBlob.lastModifiedDate = new Date()
        theBlob.name = fileName
        return theBlob
    }
    getMonthName = m => {
        const month = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ]
        return month[m]
    }
    getBase64 = file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }
    getFormattedDate = date => {
        if (typeof date !== "date") {
            if (typeof date !== "number")
                date = Number(date)
            date = new Date(date)
        }
        let d = date.getDate()>9?date.getDate(): '0'+date.getDate(),
        y = date.getFullYear()
        return String(d+', '+this.getMonthName(date.getMonth())+', '+y)
    }
    getFormattedTime = date => {
        if (typeof date !== "date") {
            if (typeof date !== "number")
                date = Number(date)
            date = new Date(date)
        }
        date = new Date(date)
        var hours = date.getHours()
        var minutes = date.getMinutes()
        var seconds = date.getSeconds()
        let amPm = hours >= 12 ? 'PM': 'AM',
        timeString = this.pad(hours, 'hour')+":"+this.pad(minutes)+":"+this.pad(seconds)+' '+ amPm
        return timeString
    }
    checkIfEmpty = value => {
        let newValue = value
        if (
            value === undefined ||
            value === 'undefined' ||
            typeof value === 'undefined' ||
            value === 'null'||
            value === null
        )
        newValue = ''
        return newValue
    }
    isSameDate = (first, second) => {
        first = new Date(first)
        second = new Date(second)
        return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate()
    }
    pad = (num, type = '') => {
        if (type === 'hour') {
            num = num > 12?num-12: num
        }
        return ("0"+num).slice(-2)
    }
    getDashboardData = async () => {
        return await getData('misc/getdashboarddata')
    }
    getPreviousDate = (prevDays = 1) => {
        let d = new Date()
        return d.setDate(d.getDate() - prevDays);
    }
    getLast7Days = () => {
        return '0123456'.split('').map(function(n) {
            var d = new Date();
            d.setDate(d.getDate() - n);
            return (function(day, month, year) {
                return [day<10 ? '0'+day : day, month<10 ? '0'+month : month, year].join('/');
            })(d.getMonth()+1, d.getDate(), d.getFullYear());
        }).reverse();
    }
    proceedToCheckOut = () => {
        let cartData = oS.getCartData(),
        cartTotal = 0
        cartData.forEach(e => {
            cartTotal += Number(e.displayPrice * e.purchaseQuantity)
        })
        oS.createOrderData({
            cartData: oS.getCartData(),
            cartTotal,
            couponCode: '',
            discount: 0
        })
        setTimeout(() => {
            rS.navigateTo('/checkout')
        }, 500)
    }
    getPath = path => {
        // console.log('path', path)
        if (path)
            return path.includes('#') ? path.substr(0, path.indexOf('#')): path;
        return ''
    }
    toggleActiveClass = id => {
        document.getElementById(id).classList.toggle("active")
    }
    getDay = num => {
        let daysList = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ]
        return daysList[num]
    }
    parseAddress = (addressObject) => {
        if (addressObject !== "undefined" && addressObject) {
            let activeSocietyTitle = '', activeSociety= ''
            if (addressObject.activeSociety !== null && addressObject.activeSociety && addressObject.activeSociety !== "undefined" && addressObject.activeSociety !== "[object Object]" && addressObject.activeSociety !== "null" && addressObject.activeSociety !== null) {
                activeSociety = JSON.parse(addressObject.activeSociety)
                if (activeSociety !== null)
                    activeSocietyTitle = activeSociety.title
            }
            let parsedAddress = addressObject.building+', '+activeSocietyTitle+', '+addressObject.locality+', '+addressObject.city+', '+addressObject.state+' - '+addressObject.pinCode
            return parsedAddress
        }
        return ''
    }
    catchError = e => {
        console.error('e', e)
        this.showAlert({
            type: 'error',
            msg: 'Unable to connect to server! Please try again after some time.'
        })
        this.miS.hidePreloader()
    }
    setActiveLanguage = language => {
        localStorage.setItem('activeLanguage', language)
    }
    isAdminUrl = activeRoute => {
        return activeRoute.href.search("admin") !== -1
    }
    isModuleEnabled = modules => {
        if(modules && modules[0]) {
            let allMoudules = 0
            modules?.forEach(m => {
                if(activeModules.includes(m))
                    allMoudules++
            })
            return (allMoudules === modules.length)
        }
        else return true
    }
}