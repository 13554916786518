import React, { useEffect, useState } from 'react'
import {DataService, MetaService} from '../../../services'
import {base} from '../../../constants/Data'
import {Img} from '../../../elements'
const dS = new DataService('notification'),
mS = new MetaService()
const Notifications = (props) => {
    const [notificationList, setnotificationList] = useState([])
    useEffect(() => {
        mS.setTitle('My Notifications', 'public')
        _getNotificationsData()
    }, [])

    const _getNotificationsData = async () => {
        const result = await dS.fetchAll()
        if (result.status)
            setnotificationList(result.data)
    }

    const _renderNotificationsList = () => {
        return notificationList.map(( item, index ) => {
            return <div key={index} className="form-box">
                <div className="notifications">
                    <div className="notification-image-box">
                        <Img src={base.imageBaseUrl+JSON.parse(item.image)} alt={item.title} />
                    </div>
                    <div className="notification-content">
                        <h1>{item.title}</h1>
                        <p>{item.body}</p>
                    </div>
                </div>
            </div>
        })
    }
    return <div className="right-side-profile-content">
        <h1>Notifications</h1>
        <div className="profile-sec wrapper notification">
            <div className="form-editable-section">
                {_renderNotificationsList()}
            </div>
        </div>
    </div>
}

export default Notifications