import React from 'react'
import { brandInfo, brandName } from '../../cms/constants/Data'
import { Button, Img } from '../../cms/elements'
import Login from '../../cms/pages/front/Login'
import {MiscService, RouterService, UserService, ValidationService} from '../../cms/services'
import './style.scss'
const uS = new UserService(),
miS = new MiscService(),
vS = new ValidationService(),
rS = new RouterService()
Login.prototype._sendOtp = async function(e) {
    e.preventDefault()
    let { email } = this.state,
    error = false,
    errorMsg = '',
    userObject = {
        email
    }
    if (email === '') {
        errorMsg = 'Please enter your Email Address'
        error = true
    } else if (!vS.isValidEmail(email)) {
        errorMsg = 'Please enter a valid Email Address'
        error = true
    }
    if (!error) {
        const result = await uS.sendOtp(userObject)
        if (result.status) {
            miS.showAlert({ type: 'info', msg: 'An OTP has been sent to your Email Address!' })
            let otpData = result.data[0]
            localStorage.setItem('otpData', JSON.stringify(otpData))
            rS.navigateTo('/otp/'+btoa(email+otpData.userType))
        } else
            miS.showAlert({ type: 'error', msg: 'Unable to send OTP, try after some time.' })
    } else
        miS.showAlert({ type: 'error', msg: errorMsg })
}

Login.prototype.render = function() {
    return <div className="login-page">
        <div className="left-box-login left-box-login-login"></div>
        <div className="body-background flex">
            <div className="mauto p30 max-width-loginBox tac">
                <a href="/" className="logo"><Img alt={brandName.capital} className="logo-right-banner" src={brandInfo.logo} /></a>
                <h1 className="welcomText">Login to Continue</h1>
                <div className="loginIcon">
                    <i className='hi-mailru'></i>
                </div>
                <p className="form-title tac">Please enter your Email Address</p>
                <form onSubmit={this._sendOtp} noValidate>
                    <div className="form-group">
                        <input autoFocus onChange={(e) => this.setState({ email: e.target.value })} className="input" type="email" name="email" placeholder="Enter Email Address" />
                    </div>
                    <Button className="btn-primary btn width-full" type="submit">Submit</Button>
                </form>
            </div>
            <i className="hi-arrow-circle-left" class="footer-back-btn" onClick={() => rS.navigateTo('/home')}></i>
        </div>
    </div>
}