import React from 'react'
import {MiscService} from '../../cms/services'
import {Content} from '../../cms/widgets'
import '../index'
import routes from '../routes.json'

const miS = new MiscService();
const App = () => {
	if (localStorage.getItem('activeLanguage') === null)
	miS.setActiveLanguage('en')
	return <Content subRoutes={routes} />
}
export default App