import React, {Component} from 'react'
import {UserService, MiscService, MetaService} from '../../../services'
import {Img} from '../../../elements'
import { base } from '../../../constants/Data'
import Select from 'react-select'
import {Table} from '../../../components'
import makeAnimated from 'react-select/animated';
import {activeDateFilters} from '../../../constants/Enums'
const animatedComponents = makeAnimated();
const commonPageSize = 50,
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService()
export default class CompanyManagers extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state = {
            activeDateFilter: activeDateFilters[0],
            activeBranches: [],
            branches: [],
            tableData: [],
            filteredList: [],
            checkedList: [],
            searchKey: '',
            activeFilterIndex: 0,
            activeShownTotal: commonPageSize,
            checkedAll: false,
            isMultipleDelete: false,
            searchList: [],
            showTrashConfirm: false,
            activeFilter: null
        }
    }
    componentDidMount () {
        mS.setTitle('Company Managers', 'public')
        let orgId = false
        if (this.user?.role === 'representative') {
            orgId = this.user.parent?.value
            this._getCompanyBranches(orgId)
        } else if (this.user?.role === 'company') {
            orgId = this.user.uid
            this._getCompanyBranches(orgId)
        } else {
            this._getBranchManagersData()
        }
        document.getElementById('content-box').addEventListener('scroll', this._trackScrolling)
    }
    componentWillUnmount() {
        document.getElementById('content-box').removeEventListener('scroll', this._trackScrolling)
    }
    _trackScrolling = () => {
        let { filteredList, activeShownTotal } = this.state,
        wrappedElement = document.getElementById('list-wrapper')
        if (this._isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                this._showMore()
        }
    }
    _isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    _showMore = () => {
        let { activeShownTotal } = this.state
        miS.showPreloader()
        setTimeout(() => {
            miS.hidePreloader()
            this.setState({ activeShownTotal: activeShownTotal + commonPageSize})
        }, 300)
    }
    _getDashboardData = (activeDateFilter, activeBranches) => {
        let activeBranchIds = []
        activeBranches?.forEach( e => {
            activeBranchIds.push(e.value)
        })
        this._getBranchManagersData(activeBranchIds)
    }
    _getCompanyBranches = async v => {
        let query = {
            parentId: v
        }, branches = [], { activeDateFilter } = this.state
        const result = await uS.getCompanyBranches({ query: JSON.stringify(query) })
        if (result.status) {
            result.data.forEach(e => {
                branches.push({
                    label: e.name,
                    value: e.uid
                })
            });
            let activeBranches = uS.getActiveBranches() === null ? uS.getActiveBranches() : branches
            this.setState({ branches, activeBranches }, () => {
                uS.setGlobalActiveBranches(activeBranches)
                this._getDashboardData(activeDateFilter, activeBranches)
            })
        }
    }
    _getBranchManagersData = async (branches) => {
        let query = {
            parentId: this.user.uid
        }
        if (branches) {
            query.parentId = {
                $in: branches
            }
        }
        const result = await uS.getBranchManagers({ query: JSON.stringify(query) })
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(e => {
                tableData.push(e)
            })
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No managers added yet!' })
            } else {
                this.setState({ tableData, filteredList: tableData })
            }
        }
    }
    _getTableData = filterData => {
        let data = []
        filterData.map(( item, index ) => {
            let e = {
                name: item.name,
                email: item.email,
                mobile: item.mobile,
                image: item.image?
                    <Img alt="Blank Placeholder" src={base.imageBaseUrl+JSON.parse(item.image)[0]} style={{maxHeight: 40, maxWidth: 40}} />:
                <p>No Image</p>
            }
            data.push(e)
        })
        return data
    }
    render() {
        let {activeDateFilter, branches, isMultipleDelete, activeBranches, filteredList, showTrashConfirm, activeShownTotal} = this.state
        let tableContent = {
            headers: [
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Email',
                    key: 'email'
                },
                {
                    label: 'Mobile',
                    key: 'mobile'
                },
                {
                    label: 'Image',
                    key: 'image'
                }
            ],
            content: this._getTableData(filteredList)
        }
        return <>
            <div id="content-box" className="content">
                <div className='flex overflow-visible'>
                    <h1 className="nowrap mr20">Company Managers</h1>
                    {
                        ['company', 'representative'].includes(this.user.role) && branches.length > 0 &&
                        <Select
                            isClearable
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            placeholder='Select Company'
                            defaultValue={activeBranches}
                            onChange={e => {
                                this.setState({ activeBranches: e }, () => {
                                    uS.setGlobalActiveBranches(e)
                                    this._getDashboardData(activeDateFilter, e)
                                })
                            }}
                            options={branches}
                            isMulti
                        />
                    }
                </div>
                <div className="order-table">
                    <Table
                        data={tableContent}
                        pageSize={activeShownTotal}
                    />
                </div>
            </div>
        </>
    }
}