import React, {Component} from 'react'
import {TrashConfirmModal, Table} from '../../../components'
import {UserService, DataService, MiscService, RouterService, MetaService} from '../../../services'
import {base} from '../../../constants/Data'
import Select from 'react-select'
import {Img, Button, Actor} from '../../../elements'
import { userRoles } from '../../../constants/Enums'
const filterTags = [{label:'All', value: 'all'}, ...userRoles]
const dS = new DataService('log'),
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
const commonPageSize = 50
export default class Users extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state = {
            tableData: [],
            filteredList: [],
            checkedList: [],
            searchKey: '',
            activeFilterIndex: 0,
            activeShownTotal: commonPageSize,
            checkedAll: false,
            isMultipleDelete: false,
            searchList: [],
            trashAction: false,
            showTrashConfirm: false,
            activeFilter: null,
            urlHash: rS.getLocationData().hash.replace('#', ''),
            pagination: { size: commonPageSize, number: 0 },
            query: {}
        }
    }
    componentDidMount () {
        mS.setTitle('Users List', 'admin')
        let {urlHash} = this.state,
        urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        this.setState({ trashAction: urlData[2] === 'trash', activeFilter:filterTags[0] }, () => {
            if (urlHash !== '')
                this._getUsersData(urlHash)
            else
                this._getUsersData()
        })
        document.getElementById('content-box').addEventListener('scroll', this._trackScrolling)
    }
    componentWillUnmount() {
        document.getElementById('content-box').removeEventListener('scroll', this._trackScrolling)
    }
    _trackScrolling = () => {
        let { filteredList, activeShownTotal } = this.state,
        wrappedElement = document.getElementById('list-wrapper')
        if (this._isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                this._showMore()
        }
    }
    _isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    _showMore = () => {
        let { activeShownTotal } = this.state
        miS.showPreloader()
        setTimeout(() => {
            miS.hidePreloader()
            this.setState({ activeShownTotal: activeShownTotal + commonPageSize})
        }, 300)
    }
    _getUsersData = async (hash = '') => {
        let { activeFilterIndex,
            trashAction,
            // pagination,
            // query
        } = this.state
        // let params = {
        //     pagination: JSON.stringify(pagination),
        //     query: JSON.stringify(query)
        // }
        const result = await uS.getUsers()
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(e => {
                if ((this.user.role === 'supervisor' && e.supervisor && JSON.parse(e.supervisor).value === this.user.uid) || this.user.role !== 'supervisor') {
                    if (!trashAction) {
                        if (!e.trash)
                            tableData.push(e)
                    } else {
                        if (e.trash)
                            tableData.push(e)
                    }
                }
            })
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No users added yet!' })
            } else {
                this.setState({ tableData, filteredList: tableData }, () => {
                    let activefilterValue = 'all'
                    if (hash !== '') {
                        activefilterValue = hash
                    }
                    filterTags.forEach(( e, i ) => {
                        if (e.value === activefilterValue) {
                            activeFilterIndex = i
                        }
                    })
                    filterTags.forEach(e => {
                        if (Number(e.id) === activeFilterIndex+1) {
                            activefilterValue = e.value
                        }
                    })
                    this._filterItems( activefilterValue, activeFilterIndex)
                })
            }
        }
    }
    _trashCancel = () => {
        this.setState({ activeItem: null, showTrashConfirm: false })
    }
    _deleteSelectUser = () => {
        let { activeItem } = this.state
        this._deleteUser(activeItem.uid)
    }
    _deleteUser = async (uid, task = 'trash') => {
        let query = {
            uid
        }, { trashAction, tableData } = this.state
        if (trashAction && task === 'trash') {
            const result = await uS.deleteUser({ query: JSON.stringify(query) })
            if (result.status) {
                miS.showAlert({ type: 'success', msg: 'Order deleted successfully!' })
                this.setState({ activeItem: null, showTrashConfirm: false }, this._getUsersData)
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to delete order!' })
        } else {
            let userObject = null
            tableData.forEach(e => {
                if (e.uid === uid)
                    userObject = e
            })
            userObject.trash = task === 'trash'
            delete userObject._id
            let data = {
                query: JSON.stringify({uid}),
                userData: JSON.stringify(userObject)
            }
            const result = await uS.updateUser(data)
            if (result.status) {
                let logData = {
                    log: userObject.name+' updated by admin with website',
                    timing: new Date().getTime(),
                    type: 'info',
                    userId: userObject.uid,
                    uid: new Date().getTime()
                }
                dS.save(logData)
                miS.showAlert({ type: 'success', msg: 'User '+task+'d successfully!' })
                this.setState({ activeItem: null, showTrashConfirm: false }, this._getUsersData)
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to update user!' })
        }
    }
    _toggleCheck = uid => {
        let {checkedList, filteredList} = this.state, newCheckedList = [], found = false
        checkedList.forEach(e => {
            if (e === uid)
                found = true
            else
                newCheckedList.push(e)
        })
        if (!found)
        newCheckedList.push(uid)
        this.setState({ checkedAll: (filteredList.length === newCheckedList.length), checkedList: newCheckedList })
    }
    _deleteChecked = () => {
        let { checkedList, checkedAll, filteredList } = this.state
        if (checkedAll)
            checkedList = filteredList
        checkedList.forEach(e => {
            this._deleteUser(e.uid)
        })
        this.setState({ checkedList: [], isMultipleDelete: false })
    }
    _editUser = userData => {
        rS.navigateTo('/admin/user/edit/'+userData.uid)
    }
    _renderFilterTags = filterData => {
        let { activeFilterIndex, tableData } = this.state
        return filterData.map(( item, index ) => {
            return <span className={ index === activeFilterIndex ? 'link active' : 'link item'} key={index} onClick={() => this._filterItems(item.key, index)}>
                {item.item}
                ({ (item.key === 'all') ? tableData.length:
                tableData.reduce(( count, e) => {
                    return item.key === e.role ? count+1:count
                },0) })
            </span>
        })
    }
    _filterItems = ( key, activeFilterIndex ) => {
        let { tableData } = this.state, filteredList = []
        if (key === 'all') {
            filteredList = tableData
        } else {
            filteredList = tableData.filter(e => {
                return e.role === key
            })
        }
        this.setState({ filteredList, activeFilterIndex: activeFilterIndex })
    }
    _search = () => {
        let { searchKey, tableData } = this.state,
        tempTitle = '', filteredList = []
        tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = e.name.toLowerCase()
            if (tempTitle.search(searchKey) !== -1 || e.mobile.search(searchKey) !== -1) {
                filteredList.push(e)
            }
        })
        this.setState({ filteredList })
    }
    _tempLogin = tempUserData => {
        miS.showPreloader()
        localStorage.setItem('tempLogin', 'true')
        localStorage.setItem('tempUserData', JSON.stringify(tempUserData))
        setTimeout(() => {
            miS.hidePreloader()
            rS.navigateTo('/user/profile')
        }, 2000)
    }
    _getTableData = filterData => {
        let data = [], {checkedAll, checkedList, trashAction} = this.state
        filterData.forEach(( item ) => {
            let e = {
                checkAction: <span className="check-all" onClick={() => this._toggleCheck(item.uid)}>
                    {
                        checkedAll || checkedList.includes(item.uid) ?
                        <ion-icon class="orange" name="checkmark-circle-outline"></ion-icon>:<ion-icon name="ellipse-outline"></ion-icon>
                    }
                </span>,
                name: item.name,
                email: item.email,
                uid: item.uid,
                role: item.role,
                mobile: item.mobile,
                image: item.image?
                    <Img alt="Blank Placeholder" src={base.imageBaseUrl+JSON.parse(item.image)[0]} style={{maxHeight: 40, maxWidth: 40}} />:
                    <p>No Image</p>,
                action: this.user.role !== "supervisor" ?
                    <>
                        {
                            trashAction ?
                            <div className="action-bar">
                                <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                                <Actor type='undo' onClick={() => this._deleteUser(item.uid, 'restore')} />
                            </div>:
                            <div className="action-bar">
                                <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                                <Actor type='edit' onClick={() => rS.navigateTo('/admin/user/edit/'+item.uid, true)} />
                                {
                                    item.role !== 'admin' &&
                                    <Actor type='login' onClick={() => this._tempLogin(item)} title={"Login As "+item.name} />
                                }
                            </div>
                        }
                    </>:
                    <div className="action-bar">
                        <Actor type='car' onClick={() => rS.navigateTo('/admin/car/list/'+item.uid, true)} title="View Cars" />
                        <Actor type='work' onClick={() => rS.navigateTo('/admin/work/list/'+item.uid, true)} title="View Work Details" />
                    </div>
            }
            data.push(e)
        })
        return data
    }
    render() {
        let { trashAction, activeShownTotal, urlHash, activeFilter, tableData, isMultipleDelete, checkedList, checkedAll, filteredList, searchKey, showTrashConfirm} = this.state
        let tableContent = {
            data: filteredList,
            headers: [
                {
                    template: <span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll)
                            this.setState({ checkedAll: !checkedAll, checkedList: [] })
                        else {
                            filteredList.forEach(e => {
                                newCheckedList.push(e.uid)
                            })
                            this.setState({ checkedAll: !checkedAll, checkedList: newCheckedList })
                        }
                    }}>
                        {
                            checkedAll || checkedList.length === filteredList.length?
                            <ion-icon class="orange" name="checkmark-circle-outline"></ion-icon>:
                            <ion-icon name="ellipse-outline"></ion-icon>
                        }
                    </span>
                },
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Email',
                    key: 'email'
                },
                {
                    label: 'UID',
                    key: 'uid'
                },
                {
                    label: 'Role',
                    key: 'role'
                },
                {
                    label: 'Mobile',
                    key: 'mobile'
                },
                {
                    label: 'Image',
                    key: 'image'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: this._getTableData(filteredList)
        }
        return <>
            <h1 className="title flex mb50">
                {
                    trashAction?'Trashed': ''
                } {urlHash !== ''?urlHash:'User'} List
                {
                    !trashAction && this.user.role !== "supervisor" &&
                    <>
                        <a className="btn btn-primary btn-small" href="/admin/user/add">Add</a>
                        {
                            (checkedList.length !== 0 || checkedAll) &&
                            <Button type="button" onClick={() => this.setState({ isMultipleDelete:true, showTrashConfirm: true})} className="btn btn-outline btn-small">Delete</Button>
                        }
                    </>
                }
            </h1>
            <div className="filter-box">
                {urlHash === '' && <div className="filters relative">
                    <label className="filter-label-add">Role: </label>
                    <Select
                        value={activeFilter}
                        defaultValue={activeFilter}
                        onChange={(e, i) => this.setState({ activeFilter: e }, () => {
                            this._filterItems(e.value, i)
                        })}
                        options={filterTags}
                    />
                </div>}
                <div className="search-box mlauto">
                    <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                        const reg = /^[A-Za-z0-9 ]+$/
                        if (e.target.value === '' || reg.test(e.target.value))
                            this.setState({ searchKey: e.target.value }, this._search)
                    }} />
                    <Button type="button" onClick={() => this.setState({ filteredList: tableData, searchKey: '' }, this._search)}><ion-icon name="close"></ion-icon></Button>
                </div>
            </div>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <TrashConfirmModal trashOkAction={() => {
                isMultipleDelete?this._deleteChecked():this._deleteSelectUser()
            }} trashCancelAction={this._trashCancel} showTrashConfirm={showTrashConfirm} isMultipleDelete={isMultipleDelete} />
        </>
    }
}