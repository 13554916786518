import React, {Component} from 'react'
import {base, brandInfo, brandName} from '../../../constants/Data'
import {SocialButton} from '../../../widgets'
import {MiscService, UserService, ValidationService, RouterService, MetaService} from '../../../services'
import {Img, Button} from '../../../elements'
const uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
vS = new ValidationService(),
rS = new RouterService()
export default class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            mobileNumber: '',
            userData: null
        }
        this._sendOtp = this._sendOtp.bind(this)
    }
    componentDidMount() {
        mS.setTitle('Login', 'public')
    }
    _handleSocialLogin = user => {
        if (typeof user._provider === 'google') {
            user = user._profile
        }
        this.setState({ userData: user }, () => {
            localStorage.setItem('socialData', JSON.stringify(user))
            rS.navigateTo('/register/'+btoa('social-user'))
        })
    }
    _handleSocialLoginFailure = e => {
        miS.showAlert({ type: 'error', msg: 'Deauthorized social login' })
        console.log('error', e)
    }
    async _sendOtp(e) {
        e.preventDefault()
        let { mobileNumber } = this.state,
        error = false,
        errorMsg = '',
        userObject = {
            mobile: mobileNumber
        }
        if (mobileNumber === '') {
            errorMsg = 'Please enter your Mobile Number'
            error = true
        } else if (!vS.isValidMobile(mobileNumber)) {
            errorMsg = 'Please enter a valid Mobile Number'
            error = true
        }
        if (!error) {
            const result = await uS.sendOtp(userObject)
            if (result.status) {
                miS.showAlert({ type: 'info', msg: 'An OTP has been sent to your mobile number!' })
                let otpData = result.data[0]
                localStorage.setItem('otpData', JSON.stringify(otpData))
                rS.navigateTo('/otp/'+btoa(mobileNumber+otpData.userType))
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to send OTP, try after some time.' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    render() {
        return <div className="login-page">
            <div className="left-box-login left-box-login-login"></div>
            <div className="body-background flex">
                <div className="mauto p30 max-width-loginBox tac">
                    <a href="/" className="logo"><Img alt={brandName.capital} className="logo-right-banner" src={brandInfo.logo} /></a>
                    <p className="welcomText">Login to Continue</p>
                    <div className="loginIcon">
                        <ion-icon name="call-outline"></ion-icon>
                    </div>
                    <h1 className="form-title tac">Please enter your mobile number</h1>
                    <form onSubmit={this._sendOtp} noValidate>
                        <div className="form-group">
                            <input autoFocus onChange={(e) => this.setState({ mobileNumber: e.target.value })} className="input" type="number" name="mobile" placeholder="Enter Mobile Number" maxLength={10} minLength={10} max={9999999999} min={1000000000} />
                        </div>
                        <Button className="btn-primary btn width-full" type="submit">Submit</Button>
                    </form>
                    {miS.isModuleEnabled(["SocialLogin"]) && <div className="social-media-box mv20">
                        <p>Or</p>
                        <ul>
                            <li className="fb-logo">
                                <SocialButton provider='facebook' appId={base.fbAppId} onLoginSuccess={this._handleSocialLogin} onLoginFailure={this._handleSocialLoginFailure}>
                                    <Img src={require('../../../../assets/images/btn_fb.png')} alt="Login with facebook" />
                                </SocialButton>
                                <SocialButton provider='google' appId={base.googleSignInCleintId} onLoginSuccess={this._handleSocialLogin} onLoginFailure={this._handleSocialLoginFailure}>
                                    <Img src={require('../../../../assets/images/btn_google.png')} alt="Sign with google" />
                                </SocialButton>
                            </li>
                        </ul>
                    </div>}
                </div>
                <i className="hi-arrow-circle-left" class="footer-back-btn" onClick={() => rS.navigateTo('/home')}></i>
            </div>
        </div>
    }
}