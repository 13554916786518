import React, {Component} from 'react'
import {TrashConfirmModal, Table} from '../../../components'
import Select from 'react-select'
import {DataService, MiscService, RouterService, MetaService} from '../../../services'
import {Button, Badge, Actor} from '../../../elements'
const oS = new DataService('offer'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
const commonPageSize = 50
const filterTags = require('./filterTags.json')
export default class OfferList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableData: [],
            activeFilterIndex: 0,
            showTrashConfirm: false,
            trashAction: false,
            checkedAll: false,
            isMultipleDelete: false,
            searchList: [],
            checkedList: [],
            filteredList: [],
            searchKey: '',
            activeShownTotal: commonPageSize,
            activeFilter: null
        }
    }
    componentDidMount () {
        mS.setTitle('Offer List', 'admin')
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        this.setState({ trashAction: urlData[2] === 'trash', activeFilter: filterTags[0] }, this._getOfferListData)
        document.getElementById('content-box').addEventListener('scroll', this._trackScrolling)
    }
    componentWillUnmount() {
        document.getElementById('content-box').removeEventListener('scroll', this._trackScrolling)
    }
    _trackScrolling = () => {
        let { filteredList, activeShownTotal } = this.state,
        wrappedElement = document.getElementById('list-wrapper')
        if (this._isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                this._showMore()
        }
    }
    _isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    _showMore = () => {
        let { activeShownTotal } = this.state
        miS.showPreloader()
        setTimeout(() => {
            miS.hidePreloader()
            this.setState({ activeShownTotal: activeShownTotal + commonPageSize})
        }, 300)
    }
    _cloneOffer = async item => {
        let offerObject = {
            code: item.code,
            content: item.content,
            discount: item.discount,
            offerApplicable: item.offerApplicable,
            discountType: item.discountType,
            expiryDate: item.expiryDate,
            status: item.status,
            image: item.image
        }
        offerObject.uid = new Date().getTime()
        const result = await oS.save(offerObject)
        if (result.status) {
            miS.showAlert({ type: 'success', msg: 'Offer clone successfully!' })
            this._getOfferListData()
        } else
            miS.showAlert({ type: 'error', msg: 'Unable to clone offer!' })
    }
    _getOfferListData = async () => {
        let { activeFilterIndex, trashAction } = this.state
        const result = await oS.fetchAll()
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(e => {
                if (!trashAction) {
                    if (!e.trash)
                        tableData.push(e)
                } else {
                    if (e.trash)
                        tableData.push(e)
                }
            })
            this.setState({ tableData, filteredList: tableData }, () => {
                let activefilterValue = 'all'
                filterTags.forEach(e => {
                    if (Number(e.id) === activeFilterIndex+1)
                        activefilterValue = e.value
                })
                this._filterItems( activefilterValue, activeFilterIndex)
            })
            if (result.data.length === 0)
                miS.showAlert({ type: 'error', msg: 'No offers added yet!' })
        } else miS.showAlert({ type: 'error', msg: 'No offers added yet!' })
    }
    _trashCancel = () => {
        this.setState({ activeItem: null, showTrashConfirm: false })
    }
    _deleteSelectOffer = () => {
        let { activeItem } = this.state
        this._deleteOffer(activeItem.uid)
    }
    _deleteOffer = async (uid, task = 'trash') => {
        let query = {
            uid
        }, { trashAction, tableData } = this.state
        if (trashAction && task === 'trash') {
            const result = await oS.delete({ query: JSON.stringify(query) })
            if (result.status) {
                miS.showAlert({ type: 'success', msg: 'Offer deleted successfully!' })
                this.setState({ activeItem: null, showTrashConfirm: false }, this._getOfferListData)
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to delete offer!' })
        } else {
            let offerObject = null
            tableData.forEach(e => {
                if (e.uid === uid) {
                    offerObject = e
                }
            })
            offerObject.trash = task === 'trash'
            delete offerObject._id
            let data = {
                query: JSON.stringify({uid}),
                offerData: JSON.stringify(offerObject)
            }
            const result = await oS.update(data)
            if (result.status) {
                miS.showAlert({ type: 'success', msg: 'Offer '+task+'d successfully!' })
                this.setState({ activeItem: null, showTrashConfirm: false }, this._getOfferListData)
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to '+task+' offer!' })
        }
    }
    _toggleCheck = uid => {
        let {checkedList, filteredList} = this.state, newCheckedList = [], found = false
        checkedList.forEach(e => {
            if (e === uid) {
                found = true
            } else {
                newCheckedList.push(e)
            }
        })
        if (!found)
        newCheckedList.push(uid)
        this.setState({ checkedAll: (filteredList.length === newCheckedList.length), checkedList: newCheckedList })
    }
    _deleteChecked = () => {
        let { checkedList, checkedAll, filteredList } = this.state
        if (checkedAll) {
            checkedList = filteredList
        }
        checkedList.forEach(e => {
            this._deleteOffer(e.uid)
        })
        this.setState({ checkedList: [], isMultipleDelete: false })
    }
    _getTableData = filterData => {
        let data = [], { checkedAll, checkedList, trashAction } = this.state
        filterData.forEach(( item ) => {
            if (item.status) {
                let e = {
                    checkAction: <span className="check-all" onClick={() => this._toggleCheck(item.uid)}>
                        {
                            checkedAll || checkedList.includes(item.uid) ?
                            <ion-icon class="orange" name="checkmark-circle-outline"></ion-icon>:<ion-icon name="ellipse-outline"></ion-icon>
                        }
                    </span>,
                    code: item.code,
                    offerRestriction: item.offerRestriction,
                    discount: item.discountType === "fixedprice" ? "₹"+item.discount:item.discount+'%',
                    expiryDate: miS.getFormattedDate(new Date(item.expiryDate)),
                    status: <Badge item={item.status} />,
                    action: trashAction ?
                    <div className="action-bar">
                        <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                        <Actor type='undo' onClick={() => this._deleteOffer(item.uid, 'restore')} />
                    </div>:
                    <div className="action-bar">
                        <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                        <Actor type='copy' onClick={() => this._cloneOffer(item)} />
                        <Actor type='edit' onClick={() => rS.navigateTo('/admin/offer/edit/'+item.uid)} />
                    </div>
                }
                data.push(e)
            }
        })
        return data
    }
    _renderFilterTags = filterData => {
        let { activeFilterIndex, tableData } = this.state
        return filterData.map(( item, index ) => {
            return <span className={ index === activeFilterIndex ? 'link active' : 'link item'} key={index} onClick={() => this._filterItems(item.key, index)}>{item.item} ({
                (item.key === 'all') ? tableData.length:
                tableData.reduce(( count, e) => {
                return item.key === e.status ? count+1:count
            },0) })</span>
        })
    }
    _filterItems = ( key, activeFilterIndex ) => {
        let { tableData } = this.state, filteredList = []
        if (key === 'all') {
            filteredList = tableData
        } else {
            filteredList = tableData.filter(e => {
                return e.status === key
            })
        }
        this.setState({ filteredList, activeFilterIndex: activeFilterIndex })
    }
    _search = () => {
        let { searchKey, tableData } = this.state,
        tempTitle = '', filteredList = []
        tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = e.code.toLowerCase()
            if (tempTitle.search(searchKey) !== -1) {
                filteredList.push(e)
            }
        })
        this.setState({ filteredList })
    }
    render() {
        let { activeFilter, tableData, trashAction, showTrashConfirm, isMultipleDelete, checkedList, checkedAll, filteredList, searchKey, activeShownTotal} = this.state
        let tableContent = {
            headers: [
                {
                    template: <span  className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll)
                            this.setState({ checkedAll: !checkedAll, checkedList: [] })
                        else {
                            filteredList.forEach(e => {
                                newCheckedList.push(e.uid)
                            })
                            this.setState({ checkedAll: !checkedAll, checkedList: newCheckedList })
                        }
                    }}>
                        {
                            checkedAll || checkedList.length === filteredList.length?
                            <ion-icon class="orange" name="checkmark-circle-outline"></ion-icon>:
                            <ion-icon name="ellipse-outline"></ion-icon>
                        }
                    </span>
                },
                {
                    label: 'Code',
                    key: 'code'
                },
                {
                    label: 'Offer Restrictions',
                    key: 'offerRestrictions'
                },
                {
                    label: 'Discount',
                    key: 'discount'
                },
                {
                    label: 'Expiry Date',
                    key: 'expiryDate'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: this._getTableData(filteredList)
        }
        return <>
            <h1 className="title flex mb50">{
                    trashAction?'Trashed': 'All'
                } Offers
            {
                    !trashAction &&
                    <>
                    <a className="btn btn-primary btn-small ml20" href="/admin/offer/add">Add</a>
                    <a href="/offers" target="_blank" title="Click to view"><span className="back-icon"><i className='hi-paper-plane'></i></span></a>
                    {
                        (checkedList.length !== 0 || checkedAll) &&
                        <Button type="button" onClick={() => this.setState({ isMultipleDelete:true, showTrashConfirm: true})} className="btn btn-outline btn-small">Delete</Button>
                    }
                    </>
                    }
            </h1>
            <div className="filter-box">
                <div className="filters relative">
                    <label className="filter-label-add">Status: </label>
                    <Select
                        value={activeFilter}
                        defaultValue={activeFilter}
                        onChange={(e, i) => this.setState({ activeFilter: e }, () => this._filterItems(e.value, i) )}
                        options={filterTags}
                    />
                </div>
                <div className="search-box">
                    <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                        const reg = /^[A-Za-z0-9 ]+$/
                        if (e.target.value === '' || reg.test(e.target.value))
                            this.setState({ searchKey: e.target.value }, this._search)
                    }} />
                    <Button type="button" onClick={() => this.setState({ filteredList: tableData, searchKey: '' }, this._search)}><ion-icon name="close"></ion-icon></Button>
                </div>
            </div>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <TrashConfirmModal trashOkAction={() => {
                isMultipleDelete?this._deleteChecked():this._deleteSelectOffer()
            }} trashCancelAction={this._trashCancel} showTrashConfirm={showTrashConfirm} isMultipleDelete={isMultipleDelete} />
        </>
    }
}