import React, { useState } from 'react'
import {UserService, MiscService} from '../../../services'
import {base} from '../../../constants/Data'
import {Button, Img} from '../../../elements'
const uS = new UserService(),
miS = new MiscService(),
_toggleNav = () => {
    miS.toggleBodyClass('', '', 'closed')
}

const _toggleThemeMode = () => {
    if (miS.getThemeMode() === "theme-light") {
        miS.setThemeMode("theme-dark")
    } else miS.setThemeMode("theme-light")
}

const AdminHeader = props => {
    const user = uS.getLoggedInUserData();
    const [showUserNav, setshowUserNav] = useState(false)
    const userProfileImage = ''
    const _toggleUserNav = () => {
        setshowUserNav(!showUserNav)
    }

    const _logout = () => {
        setshowUserNav(false)
        uS.logout('/login')
    }

    return <div className="header">
        <Button onClick={_toggleNav} className="nav-btn">
            <i className="hi-menu"></i>
        </Button>
        <Button onClick={_toggleThemeMode} className="theme-btn">
            <i className="hi-bulb"></i>
        </Button>
        <nav>
            { uS.isUserLoggedIn() && <div className="profile-box" onClick={_toggleUserNav}>
                {
                    userProfileImage ?
                    <ion-icon name="person-circle-outline"></ion-icon>:
                    <Img className="user-profile-picture" src={base.imageBaseUrl+JSON.parse(user?.image)[0]} alt={user?.name} />
                }
                <span title={user?.name}>{user?.name}</span>
            </div>}
            {
                showUserNav &&
                <ul>
                    <li title="Profile">
                        <a href="/admin/account">
                            <i className='hi-user1'></i>
                            <span>Profile</span>
                        </a>
                    </li>
                    <li title="Visit Site">
                        <a href="/">
                            <i className='hi-network'></i>
                            <span>Visit Site</span>
                        </a>
                    </li>
                    <li title="Logout">
                        <i onClick={_logout}>
                            <i className='hi-logout'></i>
                            <span>Logout</span>
                        </i>
                    </li>
                </ul>
            }
        </nav>
    </div>
}

export default AdminHeader