import React, {Component} from 'react'
import {Banner} from '../../../widgets'
import Slider from 'react-slick'
import {mainUrl} from '../../../constants/Data'
import {MiscService, OrderService, UserService, LocationService, RouterService, DataService, MetaService} from '../../../services'
import {Img, Button} from '../../../elements'
import './style.scss'
import {Table} from '../../../components'
const dS = new DataService('log'),
ofS = new DataService('offer'),
uS = new UserService(),
locS = new LocationService(),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService(),
oS = new OrderService()
export default class Checkout extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state = {
            addressList: [],
            userData: null,
            orderData: null,
            activeAddress: null,
            building: '',
            locality: '',
            pinCode: '',
            city: '',
            state: '',
            landmark: '',
            alternateNo: '',
            mobile: '',
            couponCode: '',
            email: '',
            name: '',
            couponApplied: false,
            role: '',
            gstin: '',
            terms: false,
            userType: 'new',
            registerNewUser: true,
            isTermsAccepted: true,
            cartItemDataTypeModal: false,
            societyData: [],
            activeSociety: null,
            offerList: null,
            showSocietyListBox: false,
            fromSociety: true,
            fullAddress: '',
            discount: 0,
            serviceTotal: 0,
            productTotal: 0,
            serviceDiscount: 0,
            productDiscount: 0,
            discounData: null
        }
    }
    componentDidMount () {
        mS.setTitle('Checkout', 'public')
        if (uS.isUserLoggedIn())
            this.setState({ userData: this.user, userType: 'old', registerNewUser: false }, this._getLocationsData)
        else
            this._getSocietiesListData()
        this._getOffersData()
        let orderData = oS.getOrderData(), productTotal = 0, serviceTotal = 0, cartTotal = 0
        if (orderData?.cartData) {
            if (orderData.cartData.length > 0) {
                orderData.cartData.forEach(e => {
                    cartTotal += Number(e.price * e.purchaseQuantity);
                    if (e.type === 'service')
                        serviceTotal += Number(e.price * e.purchaseQuantity);
                    else
                        productTotal += Number(e.price * e.purchaseQuantity);
                })
            }
            this.setState({ orderData: orderData, serviceTotal: serviceTotal, productTotal: productTotal})
        }
    }
    _getLocationsData = async () => {
        let query = {
            ownerId: this.user.uid
        }
        const result = await locS.getLocations({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data.length === 0)
                this._getSocietiesListData()
            else
                this.setState({ addressList: result.data, activeAddress: result.data[0] })
        }
    }
    _renderSocietyList = societyData => {
        let { activeSociety } = this.state
        return societyData.map(( item, index ) => {
            let activeClass = ''
            if (activeSociety !== null)
                activeClass = activeSociety.title === item.title? 'active': ''
            return <li key={index} className={activeClass} onClick={() => {
                this.setState({ activeSociety: item, showSocietyListBox: false }, () => {
                    let activeaddress = JSON.parse(item.addressDetail), pinCode ='', locality= '', city= '', state = ''
                    activeaddress.address_components.forEach(e => {
                        if (e.types[0]==="postal_code")
                            pinCode = e.long_name
                        if (e.types[0]==="administrative_area_level_1")
                            state = e.long_name
                        if (e.types[0]==="administrative_area_level_2")
                            city = e.long_name
                        if (e.types[0]==="locality")
                            locality = e.long_name
                    })
                    this.setState({ locality, pinCode, city, state, fullAddress: activeaddress.formatted_address })
                })
            }}>
                <p>{item.title}</p>
            </li>
        })
    }
    _getSocietiesListData = async () => {
        const result = await locS.getSocities()
        if (result.status) {
            let societyData = result.data.reverse()
            this.setState({ societyData })
            if (result.data.length === 0)
                miS.showAlert({ type: 'error', msg: 'No societies added yet!' })
        } else
            miS.showAlert({ type: 'error', msg: 'No societies added yet!' })
    }
    _renderAddressList = addressFiles => {
        let _this = this,
        { activeAddress } = this.state
        return addressFiles.map(( item, index ) => {
            return <div className="p15" key={index} onClick={() =>_this.setState({ activeAddress: item})}>
                <div className="form-box form-select-box">
                    <div className="account-detail service-single-item locationlistdetail">
                        <div className="col-9">
                            <div className="service-content p0">
                                {
                                    activeAddress.uid === item.uid?
                                    <ion-icon name="checkmark-circle" class="success"></ion-icon>:<ion-icon name="ellipse" class="ashgray"></ion-icon>
                                }
                                <div className="item-number">{index>9?(index+1): '0'+(index+1)}</div>
                            </div>
                            <div className="form-group">
                                <label className="col-3 control-label">Address</label>
                                <p>{miS.parseAddress(item)}</p>
                            </div>
                            <div className="row">
                                <div className="form-group col">
                                    <label className="col-3 control-label">Landmark </label>
                                    <p>{item.landmark}</p>
                                </div>
                                <div className="form-group col">
                                    <label className="col-3 control-label">Alternate Phone No. </label>
                                    <p>{item.alternateNo}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        })
    }
    _renderCartData = cartData => {
        return cartData.map(( item, index ) => {
            if (!item.extraDiscount)
                item.extraDiscount = 0
            return <tr className="cart_item" key={index}>
                <td className="product-name">
                    {item.title} × {item.purchaseQuantity}
                </td>
                <td className="product-total">
                    <span className="Price-amount amount">
                        ₹ {((item.displayPrice - item.extraDiscount) * item.purchaseQuantity).toFixed(2)}
                    </span>						
                </td>
            </tr>
        })
    }
    _placeOrder = async () => {
        let { activeAddress, orderData, userData } = this.state,
        error = false,
        errorMsg = '',
        orderObject = null
        if (userData !== null && activeAddress !== null) {
            error = false;
            errorMsg = ''
            orderObject = {
                activeAddress: JSON.stringify(activeAddress),
                orderData: JSON.stringify(orderData),
                userData: JSON.stringify(userData),
                status: 'initialised',
                timing: new Date().getTime()
            }
        } else {
            error = true
            errorMsg = 'Please enter user and delivery address details.'
        }
        // return false
        if (!error) {
            orderObject.uid = new Date().getTime()
            const result = await oS.addOrder(orderObject)
            let amount = 0
            if (result.status) {
                if (!isNaN(orderData.discount) && orderData.discount && orderData.discount !== "" && orderData.discount !== 0 && orderData.discount !== '0')
                    amount = (orderData.cartTotal - orderData.discount).toFixed(2)
                else
                    amount = orderData.cartTotal.toFixed(2)
                let logData = {
                    log: userData.name+' initialised and order with Id #'+orderObject.uid+ ' of amount Rs.'+amount,
                    timing: new Date().getTime(),
                    type: 'info',
                    userId: userData.uid,
                    uid: new Date().getTime()
                }
                dS.save(logData)
                rS.navigateTo(mainUrl+'/api/payment/save?mobile='+userData.mobile+'&email='+userData.email+'&userId='+userData.uid+'&orderId='+orderObject.uid+'&txnAmount='+amount+'&platform=WEB')
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to place Order! Please try after some time.' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _addLocation = async () => {
        let { building, locality, pinCode, city, state, landmark, alternateNo } = this.state,
        error = false,
        errorMsg = '',
        locationObject = {
            building,
            locality,
            pinCode,
            city,
            state,
            landmark,
            alternateNo
        }
        if (building === '') {
            errorMsg = 'Please enter Building / Flat No.'
            error = true
        } else if (locality === '') {
            errorMsg = 'Please enter the Locality'
            error = true
        } else if (pinCode === '') {
            errorMsg = 'Please select the PinCode'
            error = true
        } else if (city === '') {
            errorMsg = 'Please select a City'
            error = true
        } else if (state === '') {
            errorMsg = 'Please select a State'
            error = true
        }
        if (!error) {
            locationObject.uid = new Date().getTime()
            locationObject.ownerId = this.user.uid
            const result = await locS.addLocation(locationObject)
            if (result.status)
                this.setState({ activeAddress: locationObject }, this._placeOrder)
            else
                miS.showAlert({ type: 'error', msg: 'Unable to add address!' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _registerUser = async () => {
        let { email, name, role, gstin, mobile, terms } = this.state,
        error = false,
        errorMsg = '',
        userObject = {
            email,
            name,
            role,
            gstin,
            mobile,
            image: JSON.stringify(["no-image-found.png"])
        }
        if (!terms) {
            errorMsg = 'Please accept terms and conditions first!'
            error = true
        } else if (email === '') {
            errorMsg = 'Please enter user email!'
            error = true
        } else if (name === '') {
            errorMsg = 'Please enter the Name'
            error = true
        }
        if (!error) {
            userObject.uid = new Date().getTime()
            const result = await uS.addUser(userObject)
            if (result.status) {
                localStorage.removeItem('otpData')
                this._loginUser(userObject)
            } else
                miS.showAlert({ type: 'error', msg: 'Mobile number already registered, please try loging in.' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _loginUser = async userObject => {
        let query = {
            uid: String(userObject.uid)
        }
        const result = await uS.getUser({ query: JSON.stringify(query) })
            if (result.status) {
                let userData = result.data[0]
                let userObject = await uS.login(userData)
                if (userObject !== null)
                    this.setState({ userData: this.user, registerNewUser: false }, this._addLocation)
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to login!' })
    }
    _getOffersData = async () => {
        const result = await ofS.fetchAll()
        if (result.status) {
            let tempOfferList = result.data.reverse(), offerList = []
            tempOfferList.forEach(e => {
                if (typeof e.offerRestriction !== 'undefined' && e.status === 'publish' && new Date().getTime() < miS.addDays(new Date(e.expiryDate), 1).getTime() )
                    offerList.push(e)
            })
            this.setState({ offerList: offerList })
        }
    }
    _applyCouponCode = () => {
        let { orderData, couponCode, offerList, productDiscount, serviceDiscount } = this.state,
        offerApplicable = true, couponFound = false, activeOffer = null, discount = 0, serviceCount = 0, otherDiscount = 0, productCount = 0, subscriptionCount = 0, subscriptionDiscount = 0, subscriptionTotal = 0
        if (offerList) {
            offerList.forEach(e => {
                // console.log('error', e)
                if (e.code.toLowerCase() === couponCode.toLowerCase()) {
                    let foundUser = false
                    if (e.offerForUser) {
                        if (e.offerForUser !== 'null' && e.offerForUser !== 'undefined' && e.offerForUser !== null) {
                            JSON.parse(e.offerForUser).forEach(e => {
                                if (e.value === this.user.uid)
                                    foundUser = true
                            })
                        }
                    }
                    if (
                        e.offerRestriction &&
                        (e.offerRestriction === 'public' || (e.offerRestriction === 'private' && foundUser )) &&
                        e.status !== 'expired' &&
                        new Date().getTime() < miS.addDays(new Date(e.expiryDate), 1).getTime() &&
                        !e.trash
                    ) {
                        couponFound = true
                        activeOffer = e
                    }
                }
            })
        }
        if (couponFound) {
            // console.log('pre activeOffer', activeOffer)
            if (activeOffer.discountType === "fixedprice" ) {
                if (activeOffer.discount > orderData.cartTotal) {
                    offerApplicable = false
                } else {
                    activeOffer.discount = activeOffer.discount * 100 / (orderData.cartTotal)
                    activeOffer.discountType = "percent"
                }
            } else {
                if (activeOffer.discount > 99.99) {
                    offerApplicable = false
                }
            }
            // console.log('post activeOffer, offerApplicable', activeOffer, offerApplicable)
            if (offerApplicable) {
                orderData.cartData.forEach(c => {
                    if (c.type === 'service' || c.type === 'servicePackage')
                        serviceCount++
                    else if (c.type === 'product')
                        productCount++
                    else if (c.type === 'subscription')
                        subscriptionCount++
                })
                // if (productCount !== 0 && serviceCount !== 0 && activeOffer.offerApplicable === 'both')
                //     otherDiscount = Number((activeOffer.discount * orderData.cartTotal/100).toFixed(2))
                // else if (productCount !== 0 && (activeOffer.offerApplicable === 'product' || activeOffer.offerApplicable === 'both'))
                //     productDiscount = Number((activeOffer.discount * productTotal/100).toFixed(2))
                // else if (serviceCount !== 0 && (activeOffer.offerApplicable === 'service' || activeOffer.offerApplicable === 'both'))
                //     serviceDiscount = Number((activeOffer.discount * serviceTotal/100).toFixed(2))
                // else {
                //     otherDiscount = Number((activeOffer.discount * orderData.cartTotal/100).toFixed(2))
                //     if (subscriptionCount !== 0)
                //         subscriptionDiscount = (activeOffer.discount * subscriptionTotal/100).toFixed(2)
                //     otherDiscount -= subscriptionDiscount
                // }
                let tempCartData = []
                orderData.cartData.forEach(c => {
                    if ((activeOffer.offerApplicable === 'both' || activeOffer.offerApplicable === 'service') && (c.type === 'service' || c.type === 'servicePackage')) {
                        c.extraDiscount = Number((activeOffer.discount * (c.price + c.taxAmount)/100).toFixed(2))
                        c.tax = Number(c.tax)
                        c.taxAmount = Number((c.tax * (c.price - c.extraDiscount)/(100+c.tax)).toFixed(2))
                        c.taxableAmount = c.price - c.taxAmount
                        serviceDiscount += Number(c.extraDiscount)
                    } else if ((activeOffer.offerApplicable === 'both' || activeOffer.offerApplicable === 'product') && (c.type === 'product')) {
                        c.extraDiscount = Number((activeOffer.discount * (c.price + c.taxAmount)/100).toFixed(2))
                        c.tax = Number(c.tax)
                        c.taxAmount = Number((c.tax * (c.price - c.extraDiscount)/(100+c.tax)).toFixed(2))
                        c.taxableAmount = c.price - c.taxAmount
                        productDiscount += Number(c.extraDiscount)
                    }
                    tempCartData.push(c)
                })
                serviceDiscount = serviceDiscount.toFixed(2)
                productDiscount = productDiscount.toFixed(2)
                orderData.cartData = tempCartData
                // console.log('serviceDiscount', serviceDiscount)
                // console.log('productDiscount', productDiscount)
                // console.log('otherDiscount', otherDiscount)
                discount = Number(serviceDiscount) + Number(productDiscount)
                // console.log('discount', discount)
                if (discount > 0) {
                    this.setState({ orderData, couponApplied: true, discount, serviceDiscount, productDiscount }, () => {
                        orderData.discount = discount
                        orderData.offerData = activeOffer
                        this.setState({ orderData: orderData })
                    })
                    miS.showAlert({ type: 'success', msg: 'Coupon applied successfully' })
                } else
                    miS.showAlert({ type: 'error', msg: 'Offer cannot be applied to your cart' })
            } else
                miS.showAlert({ type: 'error', msg: 'Offer cannot be applied to your cart' })
        } else
            miS.showAlert({ type: 'error', msg: 'Invalid coupon code' })
    }
    _removeCouponCode = () => {
        let { orderData } = this.state, tempCartData = []
        orderData.discount = 0
        orderData.cartData.forEach(c => {
            c.tax = Number(c.tax)
            c.taxAmount = Number((c.tax * (c.price)/(100+c.tax)).toFixed(2))
            c.taxableAmount = c.price - c.taxAmount
            delete c.extraDiscount
            tempCartData.push(c)
        })
        orderData.cartData = tempCartData
        this.setState({
            orderData: orderData,
            couponApplied: false,
            couponCode: '',
            discount: 0,
            serviceDiscount: 0,
            productDiscount: 0
        })
    }
    render() {
        let checkoutSettings = {
            dots: true,
            infinite: false,
            arrows: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 4000
        },
        { serviceDiscount, productDiscount, discount, societyData, showSocietyListBox, activeSociety, couponCode, couponApplied, fromSociety, fullAddress, isTermsAccepted, registerNewUser, userType, email, mobile, name, gstin, terms, building, locality, pinCode, city, state, landmark, alternateNo, addressList, orderData, userData, activeShownTotal } = this.state, netPayable = ''
        if (orderData) {
            if (orderData.cartTotal)
                netPayable = orderData.cartTotal.toFixed(2)
            else
                rS.navigateTo('/cart')
        }
        let tableContent = {
            
        }
        return <>
            <Banner title='Checkout' image="blue-road-1.jpg"/>
            <div className="checkout-page pv40">
                <div className="jumbo-container">
                    {
                        orderData === null || orderData === []?
                        <div className="empty-cart">
                            <Img src={require('../../../../assets/images/emptycart.png')} alt="empty-cart" />
                            <p className="tac">No items in the cart yet!</p>
                        </div>:
                        <div className="wrapper mh-20">
                            <div className="left-checkout">
                                <div className="billing-address">
                                    <h2 className="title-bar">Delivery Details</h2>
                                    {
                                        userData === null &&
                                        <>
                                            <p className="active-options" onClick={() => {
                                                this.setState({ registerNewUser: false }, () => {
                                                    rS.navigateTo('/login')
                                                })
                                            }}>
                                                {
                                                    registerNewUser?
                                                    <ion-icon name="ellipse"></ion-icon>:<ion-icon name="checkmark-circle"></ion-icon>
                                                }
                                                Already a member?
                                            </p>
                                            <p className="active-options" onClick={() => this.setState({ registerNewUser: true })}>
                                                {
                                                    !registerNewUser?
                                                    <ion-icon name="ellipse"></ion-icon>:<ion-icon name="checkmark-circle"></ion-icon>
                                                }
                                                New User
                                            </p>
                                            <hr className="mh0" />
                                        </>
                                    }
                                    {
                                        userData !== null?
                                        <>
                                            <h2>User Details</h2>
                                            <div className="form-group">
                                                <label>Full Name</label>
                                                <p>{userData.name}</p>
                                            </div>
                                            <div className="form-group">
                                                <label>Email</label>
                                                <p>{userData.email}</p>
                                            </div>
                                            <div className="form-group">
                                                <label>Mobile Phone</label>
                                                <p>{userData.mobile}</p>
                                            </div>
                                        </>:
                                        registerNewUser &&
                                        <>
                                            <h2>User Details</h2>
                                            <div className="form-group">
                                                <input className="input" placeholder="Full Name" type="text" required name="name" value={name||''} onChange={(e) => this.setState({ name: e.target.value })} />
                                            </div>
                                            <div className="form-group">
                                                <input className="input" placeholder="Email" type="email" required name="email" value={email||''} onChange={(e) => this.setState({ email: e.target.value })} />
                                            </div>
                                            <div className="form-group">
                                                <input className="input" placeholder="Mobile" type="number" required name="mobile" value={mobile||''} onChange={(e) => this.setState({ mobile: e.target.value })} />
                                            </div>
                                            <div className="form-group">
                                                <input className="input" placeholder="GSTIN" type="text" required name="gstin" value={gstin||''} onChange={(e) => this.setState({ gstin: e.target.value })} />
                                            </div>
                                            <div className="flex-box">
                                                <label className="switch">
                                                    <input type="checkbox" onChange={() => this.setState({ terms: !terms })} checked={terms?'checked':false} />
                                                    <span className="slider round"></span>
                                                </label>
                                                <span>I accept the <a target="_blank" href="/page/terms-and-conditions" title="Terms and Conditions">Terms and Conditions</a></span>
                                            </div>
                                        </>
                                    }
                                    {
                                        (userData !== null) && (addressList.length !== 0)?
                                        <>
                                            <hr className="mh0" />
                                            <h2>Delivery Address</h2>
                                            <div className="address-slider-checkout">
                                                <Slider {...checkoutSettings}>
                                                    {this._renderAddressList(addressList || [] )}
                                                </Slider>
                                            </div>
                                        </>:
                                        (registerNewUser || addressList.length === 0) &&
                                        <>
                                            <hr className="mh0" />
                                            <h2>Delivery Address</h2>
                                            {
                                                miS.isModuleEnabled(["Society"]) && fromSociety ?
                                                <div className="relative">
                                                    <div className="form-group">
                                                        <label className="col-3 control-label">Select your society</label>
                                                        <div className="col-9 controls relative">
                                                            {
                                                                activeSociety === null ?
                                                                <Button type="button" className="btn btn-primary" onClick={() => this.setState({ showSocietyListBox: !showSocietyListBox})}>Show Societies List</Button>
                                                                :
                                                                <><div className="selected-society-type" onClick={() => this.setState({ showSocietyListBox: !showSocietyListBox})}>
                                                                    <p>{activeSociety.title}</p>
                                                                </div>
                                                                <small className="ashgray">({ fullAddress })</small></>
                                                            }
                                                            {
                                                                showSocietyListBox &&
                                                                <ul className="scroll-box mt-10 full">
                                                                    { this._renderSocietyList(societyData || []) }
                                                                </ul>
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        activeSociety !== null &&
                                                        <div className="filled-society-data">
                                                            <div className="form-group">
                                                                <label className="col-3 control-label">Address (Building/Flat) <sup>*</sup>: </label>
                                                                <div className="col-9 controls">
                                                                    <input className="input" autoFocus placeholder="Address (Building/Flat)" type="text" required name="building" value={building||''} onChange={(e) => this.setState({ building: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="col-3 control-label">Locality (Street/Area) <sup>*</sup></label>
                                                                <div className="col-9 controls">
                                                                    <input className="input" placeholder="Locality (Street/Area)" type="text" name="locality" value={locality||''} onChange={(e) => this.setState({ locality: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="col-3 control-label">Pincode <sup>*</sup></label>
                                                                <div className="col-9 controls">
                                                                    <input className="input" placeholder="Pincode" type="number" disabled name="pinCode" value={pinCode||''} onChange={(e) => this.setState({ pinCode: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="col-3 control-label">City/District/Town <sup>*</sup></label>
                                                                <div className="col-9 controls">
                                                                    <input className="input" placeholder="City/District/Town" type="text" disabled name="city" value={city||''} onChange={(e) => this.setState({ city: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="col-3 control-label">State <sup>*</sup></label>
                                                                <div className="col-9 controls">
                                                                    <input className="input" placeholder="State" type="text" disabled name="state" value={state||''} onChange={(e) => this.setState({ state: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className="form-group">
                                                                <label className="col-3 control-label">Alternate Phone No. </label>
                                                                <div className="col-9 controls">
                                                                    <input className="input" placeholder="Alternate Phone No." type="number" name="alternateNo" value={alternateNo||''} onChange={(e) => this.setState({ alternateNo: e.target.value })} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="link mt20" onClick={() => this.setState({ fromSociety: !fromSociety})}>Not living in above listed societies? Click Here</div>
                                                </div>:
                                                <>
                                                    <div className="form-group">
                                                        <label className="col-3 control-label">Address (Building/Flat) <sup>*</sup>: </label>
                                                        <div className="col-9 controls">
                                                            <input className="input" autoFocus placeholder="Address (Building/Flat)" type="text" required name="building" value={building||''} onChange={(e) => this.setState({ building: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-3 control-label">Locality (Street/Area) <sup>*</sup></label>
                                                        <div className="col-9 controls">
                                                            <input className="input" placeholder="Locality (Street/Area)" type="text" required name="locality" value={locality||''} onChange={(e) => this.setState({ locality: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-3 control-label">Pincode <sup>*</sup></label>
                                                        <div className="col-9 controls">
                                                            <input className="input" placeholder="Pincode" type="number" required name="pinCode" value={pinCode||''} onChange={(e) => this.setState({ pinCode: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-3 control-label">City/District/Town <sup>*</sup></label>
                                                        <div className="col-9 controls">
                                                            <input className="input" placeholder="City/District/Town" type="text" required name="city" value={city||''} onChange={(e) => this.setState({ city: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-3 control-label">State <sup>*</sup></label>
                                                        <div className="col-9 controls">
                                                            <input className="input" placeholder="State" type="text" required name="state" value={state||''} onChange={(e) => this.setState({ state: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="form-group">
                                                        <label className="col-3 control-label">Landmark </label>
                                                        <div className="col-9 controls">
                                                            <input className="input" placeholder="Landmark" type="text" required name="landmark" value={landmark||''} onChange={(e) => this.setState({ landmark: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-3 control-label">Alternate Phone No. </label>
                                                        <div className="col-9 controls">
                                                            <input className="input" placeholder="Alternate Phone No." type="number" name="alternateNo" value={alternateNo||''} onChange={(e) => this.setState({ alternateNo: e.target.value })} />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="right-checkout">
                                <h2 className="title-bar">Your Order</h2>
                                <div id="order_review" className="checkout-review-order">
                                    {/* <Table
                                        data={tableContent}
                                        pageSize={activeShownTotal}
                                    /> */}
                                    <table className="shop-table checkout-review-order-table">
                                        <thead>
                                            <tr>
                                                <th className="product-name">Particulars</th>
                                                <th className="product-total">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { this._renderCartData(orderData.cartData || []) }
                                        </tbody>
                                        <tfoot>
                                            {
                                                !isNaN(orderData.discount) && orderData.discount && orderData.discount !== "" && orderData.discount !== 0 && orderData.discount !== '0' ?
                                                <>
                                                    <tr className="cart-subtotal">
                                                        <th>Total</th>
                                                        <td><span className="Price-amount amount"><span className="Price-currencySymbol">₹</span>{orderData.cartTotal.toFixed(2)}</span></td>
                                                    </tr>
                                                    <tr className="cart-subtotal">
                                                        <th>Discount</th>
                                                        <td><span className="Price-amount amount"><span className="Price-currencySymbol">₹</span>{orderData.discount}</span></td>
                                                    </tr>
                                                </>:<></>
                                            }
                                            <tr className="order-total">
                                                <th>Coupon Apply</th>
                                                <td>
                                                    <div className="coupon">
                                                        <input type="text" name="couponCode" className="input-text" placeholder="Coupon code" value={couponCode} onChange={(e) => this.setState({ couponCode: e.target.value })} />
                                                        {
                                                            couponCode !== '' &&
                                                            <>
                                                                {
                                                                    couponApplied?
                                                                    <Button className="btn btn-primary" onClick={this._removeCouponCode}>Remove</Button>:
                                                                    <Button className="btn btn-primary" onClick={this._applyCouponCode}>Apply</Button>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                            {
                                                (serviceDiscount > 0) &&
                                                <tr>
                                                    <th>Service Discount</th>
                                                    <td>₹ {serviceDiscount}</td>
                                                </tr>
                                            }
                                            {
                                                (productDiscount > 0) &&
                                                <tr>
                                                    <th>Product Discount</th>
                                                    <td>₹ {productDiscount}</td>
                                                </tr>
                                            }
                                            {
                                                discount > 0?
                                                <>
                                                    <tr>
                                                        <th><strong>Overall Discount</strong></th>
                                                        <td><strong>₹ {(discount).toFixed(2)}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Net Payable</th>
                                                        <td>₹ {((orderData.cartTotal) - orderData.discount).toFixed(2)}</td>
                                                    </tr>
                                                </>:
                                                <tr className="order-total">
                                                    <th>Net Payable</th>
                                                    <td><strong><span className="Price-amount amount"><span className="Price-currencySymbol">₹</span>{netPayable}</span></strong> </td>
                                                </tr>
                                            }
                                        </tfoot>
                                    </table>
                                    <Button className="btn-block btn-primary btn proccedtopay-btn" onClick={() => {
                                        if (isTermsAccepted) {
                                            if (userType === 'new' && registerNewUser) {
                                                this._registerUser()
                                            } else if ( addressList.length === 0) {
                                                this._addLocation()
                                            } else {
                                                this._placeOrder()
                                            }
                                        } else {
                                            miS.showAlert({ type: 'error', msg: 'Please accept terms and conditions first.' })
                                        }
                                    }}>Proceed to Pay</Button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    }
}