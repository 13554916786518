import React, {Component} from 'react'
import {MetaService} from '../../../services'
import {brandName} from '../../../constants/Data'
const mS = new MetaService()
export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: []
        }
    }
    componentDidMount () {
        mS.setTitle(brandName.capital, 'home')
    }
    render() {
        return <div className="main-section">
            Home
        </div>
    }
}