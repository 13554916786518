import React, {Component} from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {Button, BackIcon} from '../../../elements'
import {Box, MediaBox} from '../../../components'
import {MiscService, DataService, UserService, LocationService, RouterService, MetaService, ValidationService} from '../../../services'
const oS = new DataService('offer'),
uS = new UserService(),
mS = new MetaService(),
locS = new LocationService(),
miS = new MiscService(),
vS = new ValidationService(),
rS = new RouterService(),
statusOptions = [
    { value: 'expired', label: 'Expired' },
    { value: 'publish', label: 'Publish' }
],
offerRestrictionOptions = [
    { value: 'public', label: 'Public' },
    { value: 'private', label: 'Private' }
],
discountOptions = [
    { value: 'percent', label: 'Percentage' },
    { value: 'fixedprice', label: 'Fixed Price' }
],
applicableOptions = [
    { value: 'service', label: 'Service' },
    { value: 'product', label: 'Product' },
    { value: 'both', label: 'All' }
]
export default class AddOffer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeStatusIndex: 0,
            activeDiscountIndex: 0,
            activeOfferSocietyIndex: 0,
            activeOfferUserIndex: 0,
            discount: 0,
            discountType: '',
            offerApplicable: '',
            image: '[]',
            searchKey: '',
            tableData: [],
            uid: '',
            mode: 'Add',
            code: '',
            content: '',
            expiryDate: new Date(),
            offerRestriction: offerRestrictionOptions[0].value,
            status: '',
            userRestrictionOptions: [],
            societyRestrictionOptions: [],
            mediaFiles: [],
            offerPublic: false,
            offerRestrictionFor: '',
            offerPrivate: false,
            offerForSociety: null,
            offerForUser: null
        }
    }
    componentDidMount () {
        mS.setTitle('Add Offer', 'admin')
        let { activeStatusIndex} = this.state,
        urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        this.setState({ status: statusOptions[activeStatusIndex].value})
        if (urlData[3] === 'edit') {
            let query = {
                uid: urlData[4]
            }
            this._getOfferData(query)
        }
        this._getUsersData()
        this._getSocietiesListData()
    }
    _getUsersData = async () => {
        let tableData = []
        const result = await uS.getUsers()
        if (result.status) {
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No users added yet!' })
            } else {
                result.data.forEach(result => {
                    tableData.push({label: result.name, value: result.uid})
                })
                this.setState({ userRestrictionOptions: tableData })
            }
        }
    }
    _getSocietiesListData = async () => {
        let tableDataSociety = []
        const result = await locS.getSocities()
        if (result.status) {
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No users added yet!' })
            } else {
                result.data.forEach(result => {
                    tableDataSociety.push({label: result.title, value: result.uid})
                })
                this.setState({ societyRestrictionOptions: tableDataSociety})
            }
        }
    }
    _getOfferData = async query => {
        let { userRestrictionOptions, societyRestrictionOptions } = this.state
        const result = await oS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            let offerData = result.data[0],activeStatusIndex = 0,activeOfferSocietyIndex = 0,activeOfferUserIndex = 0, activeDiscountIndex = 0
            statusOptions.forEach(( e, i ) => {
                if (e.value === offerData.status) {
                    activeStatusIndex = i
                }
            })
            discountOptions.forEach(( e, i ) => {
                if (e.value === offerData.discountOptions) {
                    activeDiscountIndex = i
                }
            })
            userRestrictionOptions.forEach(( e, i ) => {
                if (e.value === offerData.userRestrictionOptions) {
                    activeOfferUserIndex = i
                }
            })
            societyRestrictionOptions.forEach(( e, i ) => {
                if (e.value === offerData.societyRestrictionOptions) {
                    activeOfferSocietyIndex = i
                }
            })
            applicableOptions.forEach(( e, i ) => {
                if (e.value === offerData.applicableOptions) {
                    activeStatusIndex = i
                }
            })
            this.setState({
                code: offerData.code,
                mode: 'Edit',
                discount: offerData.discount,
                discountType: offerData.discountType,
                offerApplicable: offerData.offerApplicable,
                content: offerData.content,
                expiryDate: new Date(offerData.expiryDate),
                status: offerData.status,
                offerRestrictionFor: offerData.offerRestrictionFor?offerData.offerRestrictionFor: '',
                uid: offerData.uid,
                image: offerData.image,
                activeStatusIndex,
                activeOfferSocietyIndex,
                activeOfferUserIndex,
                activeDiscountIndex,
                offerRestriction: offerData.offerRestriction,
                userRestrictionOptions: offerData.userRestrictionOptions,
                societyRestrictionOptions: offerData.societyRestrictionOptions,
                offerForSociety: offerData.offerForSociety && vS.isValidJson(offerData.offerForSociety)?JSON.parse(offerData.offerForSociety): null,
                offerForUser: offerData.offerForUser && vS.isValidJson(offerData.offerForUser) ?JSON.parse(offerData.offerForUser): null
            })
        } else miS.showAlert({ type: 'error', msg: 'No offers added yet!' })
    }
    _addOffer = async e => {
        e.preventDefault()
        let { offerRestrictionFor, offerForUser, offerForSociety, mode, code, discount, offerRestriction, offerApplicable, discountType, content, expiryDate, status, uid, image } = this.state,
        error = false,
        errorMsg = '',
        offerObject = {
            code,
            content,
            discount,
            discountType,
            offerApplicable,
            expiryDate,
            status,
            image,
            offerRestriction,
            offerRestrictionFor,
            offerForSociety: JSON.stringify(offerForSociety),
            offerForUser: JSON.stringify(offerForUser)
        }
        if (code === '') {
            errorMsg = 'Please enter the code'
            error = true
        } else if (discount === '' || discount === 0) {
            errorMsg = 'Please enter valid discount'
            error = true
        } else if (discountType === '') {
            errorMsg = 'Please select valid discount Type'
            error = true
        } else if (offerApplicable === '') {
            errorMsg = 'Please select applicable discount'
            error = true
        } else if (content === '') {
            errorMsg = 'Please enter the offer description'
            error = true
        } else if (status === '') {
            errorMsg = 'Please select the status'
            error = true
        } else if (expiryDate === '') {
            errorMsg = 'Please select the expiry Date'
            error = true
        } else if (image === '') {
            errorMsg = 'Please select image'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                offerObject.uid = new Date().getTime()
                const result = await oS.save(offerObject)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Offer added successfully!' })
                    rS.navigateTo('/admin/offer/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to add offer!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    offerData: JSON.stringify(offerObject)
                }
                const result = await oS.update(data)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Offer updated successfully!' })
                    rS.navigateTo('/admin/offer/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to update offer!' })
                }
            }
        } else miS.showAlert({ type: 'error', msg: errorMsg })
    }
    render() {
        let { discountType,searchKey, tableData, status, offerForSociety, offerForUser, offerRestriction, offerApplicable, mode, code, userRestrictionOptions, activeDiscountIndex, societyRestrictionOptions, offerRestrictionFor, discount, content, activeStatusIndex, image, mediaFiles } = this.state,
        pageTitle = mode + ' Offer',
        activeOfferAplicable = applicableOptions[activeStatusIndex],
        activeDiscount = discountOptions[activeDiscountIndex],
        activeOfferRestrictions = offerRestrictionOptions[activeStatusIndex],
        activeStatus = statusOptions[activeStatusIndex]
        discountOptions.forEach(e => {
            if (e.value === discountType)
                activeDiscount = e
        })
        applicableOptions.forEach(e => {
            if (e.value === offerApplicable)
                activeOfferAplicable = e
        })
        offerRestrictionOptions.forEach(e => {
            if (e.value === offerRestriction)
                activeOfferRestrictions = e
        })
        statusOptions.forEach(e => {
            if (e.value === status)
                activeStatus = e
        })
        return <>
            <h1>
                <BackIcon backLink='/admin/offer/list' />
                {pageTitle}
            </h1>
            <form className="columns" noValidate onSubmit={this._addOffer}>
                <div className="left">
                    <Box title='Coupon Code' required>
                        <input autoFocus type="text" required name="code" value={code} onChange={(e) => this.setState({ code: e.target.value })} />
                    </Box>
                    <Box title='Discount' required>
                        <input type="number" required name="discount" value={discount||''} onChange={(e) => this.setState({ discount: e.target.value })} min={0} max={100} minLength={1} maxLength={3} />
                    </Box>
                    <Box title='Discount Type' required>
                        <Select
                            value={activeDiscount}
                            defaultValue={activeDiscount}
                            onChange={e => {
                                this.setState({ discountType: e.value })
                            }}
                            options={discountOptions}
                        />
                    </Box>
                    <Box title='Offer Applicable' required>
                        <Select
                            value={activeOfferAplicable}
                            defaultValue={activeOfferAplicable}
                            onChange={e => this.setState({ offerApplicable: e.value })}
                            options={applicableOptions}
                        />
                    </Box>
                    <Box title='Description'>
                        <textarea className="description" placeholder="Enter coupon description text" required name="content" value={content} onChange={(e) => this.setState({ content: e.target.value })}></textarea>
                    </Box>
                    <Box title='Offer Restriction'>
                        <Select
                            value={activeOfferRestrictions}
                            defaultValue={activeOfferRestrictions}
                            onChange={e => this.setState({ offerRestriction: e.value })}
                            options={offerRestrictionOptions}
                        />
                        {
                            offerRestriction === 'private' &&
                            <>
                                <div className="body overflown">
                                    <span className="pointer" onClick={() => this.setState({ offerRestrictionFor: 'user' })}>
                                        {
                                            offerRestrictionFor === 'user' ?
                                            <ion-icon class="icon-check orange" name="checkmark-circle-outline"></ion-icon>:
                                            <ion-icon class=" icon-check" name="ellipse-outline"></ion-icon> 
                                        }
                                        For User </span>
                                </div>
                                <div className="body overflown">
                                    <span className="pointer" onClick={() => this.setState({ offerRestrictionFor: 'society' })}>
                                        {
                                            offerRestrictionFor === 'society' ?
                                            <ion-icon class="icon-check orange" name="checkmark-circle-outline"></ion-icon>:
                                            <ion-icon class=" icon-check" name="ellipse-outline"></ion-icon> 
                                        }
                                        For Society </span>
                                </div>
                                {
                                    offerRestrictionFor === 'user' ?
                                    <Select
                                        placeholder="Select Users"
                                        value={offerForUser}
                                        defaultValue={offerForUser}
                                        onChange={e => {
                                            this.setState({ offerForUser: e, offerForSociety: null })
                                        }}
                                        options={userRestrictionOptions}
                                        isMulti
                                    />:
                                    offerRestrictionFor === 'society' &&
                                    <Select
                                        placeholder="Select Societies"
                                        value={offerForSociety}
                                        defaultValue={offerForSociety}
                                        onChange={e => {
                                            this.setState({ offerForSociety: e, offerForUser: null })
                                        }}
                                        options={societyRestrictionOptions}
                                        isMulti
                                    />
                                }
                            </>
                        }
                    </Box>
                </div>
                <div className="right">
                    <Box title='Meta'>
                        <Button className="btn-primary btn btn-yellow has-icon" type="submit" code="Click here to save">Save</Button>
                    </Box>
                    <Box title='Expiry Date' required>
                        <DatePicker
                            onChange={expiryDate => this.setState({ expiryDate })}
                            selected={this.state.expiryDate}
                        />
                    </Box>
                    <Box title='Status'>
                        <Select
                            value={activeStatus}
                            defaultValue={activeStatus}
                            onChange={e => this.setState({ status: e.value })}
                            options={statusOptions}
                        />
                    </Box>
                    <MediaBox image={image} setimage={image => this.setState({image})} />
                </div>
            </form>
            <div className="modal media-modal" id="media-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content">
                    <Button className="close" onClick={() => miS.closeModal("media-modal")}>
                        <i className="hi-close"></i>
                    </Button>
                    <div className="modal-body filter-box">
                        <div className="modal-header">
                            <h2 className="heading">Select Image</h2>
                            <div className="search-box">
                                <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                                    const reg = /^[A-Za-z0-9 ]+$/
                                    if (e.target.value === '' || reg.test(e.target.value))
                                        this.setState({ searchKey: e.target.value }, this._search)
                                }} />
                                <Button type="button" onClick={() => this.setState({ filteredList: tableData, searchKey: '' }, this._search)}><ion-icon name="close"></ion-icon></Button>
                            </div>
                        </div>
                        <div className="modal-box">
                            <ul className="media-box">
                                {
                                    mediaFiles.length !== 0 &&
                                    this._renderImages(mediaFiles||[])
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}