import React, {Component} from 'react'
import {Banner} from '../../../widgets'
import {faqCategories} from '../../../constants/Enums'
import {MiscService, DataService, MetaService} from '../../../services'
const categories = faqCategories,
dS = new DataService('faq'),
mS = new MetaService(),
miS = new MiscService()
export default class Faq extends Component {
    constructor(props) {
        super(props)
        this.state = {
            faqList: [],
            activeFaq: {},
            activeCategory: categories[0],
            searchKey: ''
        }
    }
    componentDidMount () {
        mS.setTitle('FAQs', 'public')
        this._getFaqsData()
    }
    _getFaqsData = async () => {
        let faqList = [], result = await dS.fetchAll()
        if (result.status) {
            if (result.data.length === 0)
                miS.showAlert({ type: 'error', msg: 'No faqs added yet!' })
            else
                result.data.forEach(e => faqList.push(e))
            this.setState({ faqList: faqList })
        }
    }
    _renderFaq = faqList => {
        let { activeFaq } = this.state
        return faqList.map(( item, index ) => {
            return <div className={activeFaq.uid === item.uid ? "accordion active": "accordion"} key={index} onClick={() => {
                this.setState({ activeFaq: (activeFaq.uid === item.uid? {} : item)})
            }}>
                <p className="accordion-title">{item.query} <ion-icon name="add-circle-outline"></ion-icon></p>
                <div className={activeFaq.uid === item.uid ? "accordion-text active": "accordion-text"}>
                    <p>{item.ans}</p>
                </div>
            </div>
        })
    }
    _renderFaqCategories = faqCategories => {
        let { activeCategory } = this.state
        return faqCategories.map(( item, index ) => {
            return <li key={index} className={activeCategory.value === item.value? "active faq-heading "+item.value : "faq-heading "+item.value} onClick={() => this.setState({ activeCategory: item})}>
                <div className="heading">
                    <ion-icon name={item.icon}></ion-icon>
                    <span>{item.label}</span>
                </div>
            </li>
        })
    }
    render() {
        let { faqList, activeCategory, searchKey } = this.state, filteredFaqList = []
        faqList.forEach(e => {
            if (JSON.parse(e.category).value === activeCategory.value) {
                if (searchKey !== '') {
                    if (e.query.includes(searchKey) || e.ans.includes(searchKey))
                        filteredFaqList.push(e)
                }
                else
                    filteredFaqList.push(e)
            }
        })
        return <>
            <Banner title="FAQ" image="faq.jpg" />
            <div className="faq-page">
                <div className="jumbo-container">
                    <div className="flex">
                        <div className="faq-categories">
                            <ul>{ this._renderFaqCategories(categories||[]) }</ul>
                        </div>
                        <div className="faq-details">
                            { this._renderFaq(filteredFaqList || []) }
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}