import React, {Component} from 'react'
import {base} from '../../../constants/Data'
import {TrashConfirmModal, Table} from '../../../components'
import {CSVLink} from 'react-csv'
import {Img, Button, Actor} from '../../../elements'
import { statusOptions } from '../../../constants/Enums'
import {DataService, RouterService, MetaService, MiscService} from '../../../services'
const dS = new DataService('carModel'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
const commonPageSize = 50
const filterTags = statusOptions
export default class CarModelList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableData: [],
            image: '',
            filteredList: [],
            checkedList: [],
            searchKey: '',
            activeFilterIndex: 0,
            showTrashConfirm: false,
            trashAction: false,
            isMultipleDelete: false,
            checkedAll: false,
            searchList: [],
            activeShownTotal: commonPageSize,
            activeFilter: null
        }
    }
    componentDidMount () {
        mS.setTitle('CarModels List', 'admin')
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        this.setState({ trashAction: urlData[2] === 'trash', activeFilter: filterTags[0] }, this._getCarModelListData)
        document.getElementById('content-box').addEventListener('scroll', this._trackScrolling)
    }
    componentWillUnmount() {
        document.getElementById('content-box').removeEventListener('scroll', this._trackScrolling)
    }
    _trackScrolling = () => {
        let { filteredList, activeShownTotal } = this.state,
        wrappedElement = document.getElementById('list-wrapper')
        if (this._isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                this._showMore()
        }
    }
    _isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    _showMore = () => {
        let { activeShownTotal } = this.state
        miS.showPreloader()
        setTimeout(() => {
            miS.hidePreloader()
            this.setState({ activeShownTotal: activeShownTotal + commonPageSize})
        }, 300)
    }
    _getCarModelListData = async () => {
        let { activeFilterIndex, trashAction } = this.state
        const result = await dS.fetchAll()
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(e => {
                if (!trashAction) {
                    if (!e.trash)
                        tableData.push(e)
                } else {
                    if (e.trash)
                        tableData.push(e)
                }
            })
            this.setState({ tableData, filteredList: tableData }, () => {
                let activefilterValue = 'all'
                filterTags.forEach(e => {
                    if (Number(e.id) === activeFilterIndex+1) {
                        activefilterValue = e.value
                    }
                })
                this._filterItems( activefilterValue, activeFilterIndex)
            })
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No carModels added yet!' })
            }
        } else
            miS.showAlert({ type: 'error', msg: 'No carModels added yet!' })
    }
    _trashCancel = () => {
        this.setState({ activeItem: null, showTrashConfirm: false })
    }
    _deleteSelecthCarModel = () => {
        let { activeItem } = this.state
        this._deleteCarModel(activeItem.uid)
    }
    _deleteCarModel = async (uid, task = 'trash') => {
        let query = {
            uid
        }, { trashAction, tableData } = this.state
        if (trashAction && task === 'trash') {
            const result = await dS.delete({ query: JSON.stringify(query) })
            if (result.status) {
                this._getCarModelListData()
                miS.showAlert({ type: 'success', msg: 'CarModel deleted successfully!' })
                this.setState({ activeItem: null, showTrashConfirm: false }, this._getCarModelListData)
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to delete carModel!' })
        } else {
            let carModelObject = null
            tableData.forEach(e => {
                if (e.uid === uid) {
                    carModelObject = e
                }
            })
            carModelObject.trash = task === 'trash'
            delete carModelObject._id
            let data = {
                query: JSON.stringify({uid}),
                carModelData: JSON.stringify(carModelObject)
            }
            const result = await dS.update(data)
            if (result.status) {
                miS.showAlert({ type: 'success', msg: 'CarModel '+task+'d successfully!' })
                this.setState({ activeItem: null, showTrashConfirm: false }, this._getCarModelListData)
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to '+task+' carModel!' })
        }
    }
    _toggleCheck = uid => {
        let {checkedList, filteredList} = this.state, newCheckedList = [], found = false
        checkedList.forEach(e => {
            if (e === uid)
                found = true
            else
                newCheckedList.push(e)
        })
        if (!found)
            newCheckedList.push(uid)
        this.setState({ checkedAll: (filteredList.length === newCheckedList.length), checkedList: newCheckedList })
    }
    _deleteChecked = () => {
        let { checkedList, checkedAll, filteredList } = this.state
        if (checkedAll)
            checkedList = filteredList
        checkedList.forEach(e => {
            this._deleteCarModel(e.uid)
        })
        this.setState({ checkedList: [], isMultipleDelete: false })
    }
    _cloneCarModel = async item => {
        let carModelObject = {
            end_year: item.end_year,
            Segment: item.Segment,
            modelKey: item.modelKey+'-copy',
            fuel: item.fuel,
            name: item.name,
            image_path: item.image_path,
            start_year: item.start_year,
            year_of_manufacture: item.year_of_manufacture,
            manufacturer: item.manufacturer,
            uid: new Date().getTime()
        }
        carModelObject.uid = new Date().getTime()
        const result = await dS.save(carModelObject)
        if (result.status) {
            miS.showAlert({ type: 'success', msg: 'CarModel clonsed successfully!' })
            this._getCarModelListData()
        } else
            miS.showAlert({ type: 'error', msg: 'Unable to add carModel!' })
    }
    _getTableData = filterData => {
        let data = [], { checkedAll, checkedList, trashAction } = this.state
        filterData.forEach(( item ) => {
            let e = {
                checkAction: <span className='check-all' onClick={() => this._toggleCheck(item.uid)}>
                    {
                        checkedAll || checkedList.includes(item.uid) ?
                        <ion-icon class="orange" name="checkmark-circle-outline"></ion-icon>:<ion-icon name="ellipse-outline"></ion-icon>
                    }
                </span>,
                name: item.name,
                modelKey: item.modelKey,
                Segment: item.Segment,
                image_path: <Img src={base.carImageBase+item.image_path} />,
                manufacturer: item.manufacturer,
                action: trashAction?
                <div className="action-bar">
                    <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                    <Actor type='undo' onClick={() => this._deleteCarModel(item.uid, 'restore')} />
                </div>:
                <div className="action-bar">
                    <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                    <Actor type='edit' onClick={() => rS.navigateTo('/admin/car-model/edit/'+item.uid)} />
                    <Actor type='copy' onClick={() => this._cloneCarModel(item)} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    _renderFilterTags = filterData => {
        let { activeFilterIndex, tableData } = this.state
        return filterData.map(( item, index ) => {
            return <span className={ index === activeFilterIndex ? 'link active' : 'link item'} key={index} onClick={() => this._filterItems(item.key, index)}>{item.item} ({
                (item.key === 'all') ? tableData.length:
                tableData.reduce(( count, e) => {
                return item.key === e.status ? count+1:count
            },0) })</span>
        })
    }
    _filterItems = ( key, activeFilterIndex ) => {
        let { tableData } = this.state, filteredList = []
        if (key === 'all')
            filteredList = tableData
        else {
            filteredList = tableData.filter(e => {
                return e.status === key
            })
        }
        this.setState({ filteredList, activeFilterIndex: activeFilterIndex })
    }
    _search = () => {
        let { searchKey, tableData } = this.state,
        tempTitle = '', filteredList = []
        tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = e.modelKey?.toLowerCase() + ' ' + e.Segment?.toLowerCase()
            if (tempTitle.search(searchKey) !== -1)
                filteredList.push(e)
        })
        this.setState({ filteredList })
    }
    render() {
        let { tableData, showTrashConfirm, trashAction, checkedList, checkedAll, isMultipleDelete, filteredList, searchKey, activeShownTotal } = this.state,
        exportedData = [], exportedFileName = 'exported_carModels_'+new Date().getDate()+'_'+new Date().getMonth()+'_'+new Date().getFullYear()+'.csv'
        filteredList.forEach(e => {
            let ex = {
                name: e.name,
                modelKey: e.modelKey,
                Segment: e.Segment,
                manufacturer: e.manufacturer,
                year_of_manufacture: e.year_of_manufacture,
                start_year: e.start_year,
                end_year: e.end_year
            }
            exportedData.push(ex)
        })
        let tableContent = {
            headers: [
                {
                    template:<span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll)
                            this.setState({ checkedAll: !checkedAll, checkedList: [] })
                        else {
                            filteredList.forEach(e => {
                                newCheckedList.push(e.uid)
                            })
                            this.setState({ checkedAll: !checkedAll, checkedList: newCheckedList })
                        }
                    }}>
                    {
                        checkedAll || checkedList.length === filteredList.length?
                        <ion-icon class="orange" name="checkmark-circle-outline"></ion-icon>:
                        <ion-icon name="ellipse-outline"></ion-icon>
                    }
                    </span>
                },
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Model Key',
                    key: 'modelKey'
                },
                {
                    label: 'Segment',
                    key: 'Segment'
                },
                {
                    label: 'Image',
                    key: 'image_path'
                },
                {
                    label: 'Manufacturer',
                    key: 'manufacturer'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: this._getTableData(filteredList)
        }
        return <>
            <h1 className="title flex mb50">
                {
                    trashAction?'Trashed': 'All'
                } Car Models
                {
                    !trashAction &&
                    <>
                    <a className="btn btn-primary btn-small ml20" href="/admin/car-model/add">Add</a>
                    {
                        (checkedList.length !== 0 || checkedAll) &&
                        <Button type="button" onClick={() => {
                            this.setState({ isMultipleDelete:true, showTrashConfirm: true})
                        }} className="btn btn-outline btn-small">Delete</Button>
                    }
                    </>
                }
                {
                    filteredList.length !== 0 &&
                    <span className="btn btn-primary btn-small ml20 export-btn">
                        <CSVLink data={exportedData} filename={exportedFileName} className="btn export-btn" target="_blank">Export</CSVLink>
                    </span>
                }
            </h1>
            <div className="filter-box">
                <div className="search-box mlauto">
                    <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                            const reg = /^[A-Za-z0-9 ]+$/
                            if (e.target.value === '' || reg.test(e.target.value))
                                this.setState({ searchKey: e.target.value }, this._search)
                        }} />
                    <Button type="button" onClick={() => this.setState({ filteredList: tableData, searchKey: '' }, this._search)}><ion-icon name="close"></ion-icon></Button>
                </div>
            </div>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <TrashConfirmModal trashOkAction={() => {
                isMultipleDelete?this._deleteChecked():this._deleteSelecthCarModel()
            }} trashCancelAction={this._trashCancel} showTrashConfirm={showTrashConfirm} isMultipleDelete={isMultipleDelete} />
        </>
    }
}