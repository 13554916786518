import React, {Component} from 'react'
import Select from 'react-select'
import { Editor } from '@tinymce/tinymce-react'
import {base,mainUrl} from '../../../constants/Data'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import CreatableSelect from 'react-select/creatable'
import {robotsoptions,authoroptions,statusOptions} from '../../../constants/Enums'
import {MiscService, DataService, RouterService, UserService, MetaService} from '../../../services'
import {Img, Button, BackIcon} from '../../../elements'
import {Box, MediaBox} from '../../../components'
const pS = new DataService('job'),
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService(),
keywordOptions =  [],
categoriesOptions = [],
tagOptions = []
export default class AddJob extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeRobotsIndex: 0,
            activeStatusIndex: 0,
            activeAuthorIndex: 0,
            image: '[]',
            searchKey: '',
            tableData: [],
            uid: '',
            renderSlug: true,
            mode: 'Add',
            title: '',
            slug: '',
            content: "",
            excerpt: "",
            css: '',
            js: '',
            canonical: '',
            robots: '',
            metaDescription: '',
            metaKeyword: '',
            author: '',
            publishDate: new Date(),
            assignWorkers: [],
            status: '',
            categories: '',
            tag: '',
            mediaFiles: [],
            activeFile: null
        }
    }
    componentDidMount () {
        mS.setTitle('Add Job', 'admin')
        let { activeStatusIndex } = this.state
        this.setState({ status: statusOptions[activeStatusIndex].value })
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        if (urlData[3] === 'edit') {
            let query = {
                uid: urlData[4]
            }
            this._getJobData(query)
        }
        this._getUsersData()
    }
    _getUsersData = async () => {
        let tableData = []
        const result = await uS.getUsers()
        if (result.status) {
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No users added yet!' })
            } else {
                result.data.forEach(result => {
                    tableData.push({label: result.name, value: result.uid})
                })
                this.setState({ assignWorkers: tableData })
            }
        }
    }
    _getJobData = async query => {
        const result = await pS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            let jobData = result.data[0],
            metaKeyword = [],
            tag = [],
            categories = [],
            activeRobotsIndex = 0,
            activeAuthorIndex = 0,
            activeStatusIndex = 0
            robotsoptions.forEach(( e, i ) => {
                if (e.value === jobData.robots)
                    activeRobotsIndex = i
            })
            authoroptions.forEach(( e, i ) => {
                if (e.value === jobData.author) {
                    activeAuthorIndex = i
                }
            })
            statusOptions.forEach(( e, i ) => {
                if (e.value === jobData.status) {
                    activeStatusIndex = i
                }
            })
            if (jobData.metaKeyword === '""' || jobData.metaKeyword === '') {
                metaKeyword = []
            } else {
                metaKeyword = JSON.parse(jobData.metaKeyword)
            }
            if (jobData.categories === '""' || jobData.categories === '') {
                categories = []
            } else {
                categories = JSON.parse(jobData.categories)
            }
            if (jobData.tag === '""' || jobData.tag === '') {
                tag = []
            } else {
                tag = JSON.parse(jobData.tag)
            }
            this.setState({
                title: jobData.title,
                mode: 'Edit',
                slug: jobData.slug,
                content: jobData.content,
                excerpt: jobData.excerpt,
                css: jobData.css,
                js: jobData.js,
                canonical: jobData.canonical,
                robots: jobData.robots,
                metaDescription: jobData.metaDescription,
                metaKeyword,
                author: jobData.author,
                publishDate: new Date(jobData.publishDate),
                status: jobData.status,
                categories,
                tag,
                uid: jobData.uid,
                image: jobData.image,
                activeRobotsIndex,
                activeAuthorIndex,
                activeStatusIndex
            })
        } else miS.showAlert({ type: 'error', msg: 'No jobs added yet!' })
    }
    _addJob = async e => {
        e.preventDefault()
        let { mode, title, slug, content, excerpt, css, js, canonical, robots, metaDescription, metaKeyword, author, publishDate, status, categories, tag, uid, image } = this.state,
        error = false,
        errorMsg = '',
        jobObject = {
            title,
            slug,
            content,
            excerpt,
            css,
            js,
            canonical,
            robots,
            metaDescription,
            metaKeyword: JSON.stringify(metaKeyword),
            author,
            publishDate,
            status: status.value,
            categories: JSON.stringify(categories),
            tag: JSON.stringify(tag),
            image
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (slug === '') {
            errorMsg = 'Please enter the slug'
            error = true
        } else if (content === '') {
            errorMsg = 'Please enter the content'
            error = true
        } else if (excerpt === '') {
            errorMsg = 'Please enter the excerpt'
            error = true
        } else if (author === '') {
            errorMsg = 'Please select the author'
            error = true
        } else if (image === '') {
            errorMsg = 'Please select image'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                jobObject.uid = new Date().getTime()
                const result = await pS.save(jobObject)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Job added successfully!' })
                    rS.navigateTo('/admin/job/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to add job!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    jobData: JSON.stringify(jobObject)
                }
                const result = await pS.update(data)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Job updated successfully!' })
                    rS.navigateTo('/admin/job/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to update job!' })
                }
            }
        } else miS.showAlert({ type: 'error', msg: errorMsg })
    }
    render() {
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname),
        {status, robots, author, publishDate, activeFile, assignWorkers, mode, renderSlug, title, slug, content, excerpt, css, js, canonical, activeRobotsIndex, activeStatusIndex, activeAuthorIndex, metaDescription, metaKeyword, categories, tag, image } = this.state,
        pageTitle = mode + ' Job'
        if (canonical === '') {
            canonical = mainUrl+ urlData[2]+'/'+slug
        }
        let activeRobots = robotsoptions[activeRobotsIndex],
        activeAuthor = authoroptions[activeAuthorIndex],
        activeStatus = statusOptions[activeStatusIndex]
        if (mode === 'Edit') {
            statusOptions.forEach(e => {
                if (e.value === status) {
                    activeStatus = e
                }
            })
            robotsoptions.forEach(e => {
                if (e.value === robots) {
                    activeRobots = e
                }
            })
            assignWorkers.forEach(e => {
                if (e.value === author) {
                    activeAuthor = e
                }
            })
        }
        return <>
            <h1>
                <BackIcon backLink='/admin/job/list' />
                {pageTitle}
            </h1>
            <form className="columns" noValidate onSubmit={this._addJob}>
                <div className="left">
                    <Box title='Title' required>
                        <input autoFocus type="text" required name="title" value={title} onChange={e => {
                            let tempTitle = e.target.value, tempSlug = slug
                            if (renderSlug) {
                                tempSlug = miS.slugify(tempTitle)
                            }
                            this.setState({ title: tempTitle, slug: tempSlug})
                        }} />
                    </Box>
                    <Box title='Slug' required>
                        <input type="text" required name="slug" value={slug} onChange={(e) => this.setState({ slug: e.target.value })} />
                    </Box>
                    <Box title='Content' required>
                        <Editor
                            apiKey={base.tinyMceApiKey}
                            value={content}
                            init={base.tinyMceEditorOptions}
                            onEditorChange={(content, editor) => {
                                this.setState({ content })
                            }}
                        />
                    </Box>
                    <Box title='Excerpt' required>
                        <Editor
                            apiKey={base.tinyMceApiKey}
                            init={base.tinyMceEditorOptions}
                            value={excerpt}
                            onEditorChange={(excerpt, editor) => {
                                this.setState({ excerpt })
                            }}
                        />
                    </Box>
                    <Box title='CSS'>
                        <textarea className="description" placeholder=".selector {property:value}" required name="css" value={css} onChange={(e) => this.setState({ css: e.target.value })}></textarea>
                    </Box>
                    <Box title='JS'>
                        <textarea className="description" placeholder="alert('Type your script here')" required name="js" value={js} onChange={(e) => this.setState({ js: e.target.value })}></textarea>
                    </Box>
                    <Box title='Canonical URL'>
                        <input type="text" required name="canonical" value={canonical} onChange={(e) => this.setState({ canonical: e.target.value })} />
                    </Box>
                    <Box title='Robots'>
                        <Select
                            value={activeRobots}
                            defaultValue={activeRobots}
                            onChange={e => this.setState({ robots: e.value })}
                            options={robotsoptions}
                        />
                    </Box>
                    <Box title='Meta Description'>
                        <textarea placeholder="Enter meta description here" required name="meta descrition" value={metaDescription} onChange={(e) => this.setState({ metaDescription: e.target.value })}></textarea>
                    </Box>
                    <Box title='Meta Keywords'>
                        <CreatableSelect
                            isMulti
                            onChange={e => this.setState({ metaKeyword: e })}
                            options={keywordOptions}
                            value={metaKeyword}
                        />
                    </Box>
                </div>
                <div className="right">
                    <Box title='Meta'>
                        <p>
                            Render Slug:
                            <span className={renderSlug?"switch on":"switch off"} title="Click to Enable/Disable auto slug rendering" onClick={() => this.setState({ renderSlug:!renderSlug})}></span>
                        </p>
                        <Button className="btn-primary btn btn-yellow has-icon" type="submit" title="Click here to save">Save</Button>
                    </Box>
                    <Box title='Author' required>
                        <Select
                            value={activeAuthor}
                            defaultValue={activeAuthor}
                            onChange={e => this.setState({ author: e.value })}
                            options={assignWorkers}
                        />
                    </Box>
                    <Box title='Publish Date'>
                        <DatePicker
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeFormat="HH:mm"
                            onChange={publishDate => this.setState({ publishDate })}
                            selected={publishDate}
                        /> 
                    </Box>
                    <Box title='Status'>
                        <Select
                            value={status}
                            // defaultValue={activeStatus}
                            onChange={e => this.setState({ status: e })}
                            options={statusOptions}
                        />
                    </Box>
                    <Box title='Categories'>
                        <CreatableSelect
                            isMulti
                            value={categories}
                            onChange={e => this.setState({ categories: e })}
                            options={categoriesOptions}
                        />
                    </Box>
                    <Box title='Tags'>
                        <CreatableSelect
                            isMulti
                            value={tag}
                            onChange={e => this.setState({ tag: e })}
                            options={tagOptions}
                        />
                    </Box>
                    <MediaBox image={image} setimage={image => this.setState({image})} />
                </div>
            </form>
        </>
    }
}