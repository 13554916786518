import React, {Component} from 'react'
import {SubscriptionData, Table} from '../../../components'
import {Button, Badge, Actor} from '../../../elements'
import {OrderService, MiscService, MetaService, UserService} from '../../../services'
const uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
oS = new OrderService()
export default class MySubscription extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableData: null
        }
    }
    componentDidMount () {
        mS.setTitle('My Subscription', 'public')
        this._getSubscriptionData()
    }
    _getSubscriptionData = async () => {
        let query = {
            uid: uS.getLoggedInUserData().uid
        }
        const result= await oS.getUserOrders({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data.length === 0)
                this.setState({ tableData: [] })
            else
                this.setState({ tableData: result.data.reverse() })
        }
    }
    _getTableData = filterData => {
        let data = []
        filterData.forEach(( item ) => {
            let e = {
                order: `#${item.uid}`,
                name: JSON.parse(item.userData).name,
                date: miS.getFormattedDate(item.timing),
                subscription: item.subscriptionData,
                status: <Badge item={item.status} />,
                total: `₹ ${JSON.parse(item.orderData).cartTotal}`,
                action: <div className="action-bar">
                    <Actor type='view' onClick={() => miS.openModal("get-subscriptions-modal")} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    render() {
        let {tableData, activeShownTotal} = this.state
        let tableContent = {
            headers: [
                {
                    label: 'Order',
                    key: 'order'
                },
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Date',
                    key: 'date'
                },
                {
                    label: 'Subscription',
                    key: 'subscription'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Total',
                    key: 'total'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            total: tableData.length,
            content: this._getTableData(tableData)
        }
        return <>
            <div id="content-box" className="content">
                <h1>My Subscription</h1>
                <Table
                    data={tableContent}
                    pageSize={activeShownTotal}
                />
            </div>
            <div className="modal" id="get-subscriptions-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content big">
                    <Button className="close" onClick={() => miS.closeModal("get-subscriptions-modal")}><i className="hi-close"></i></Button>
                    <div className="modal-body p20">
                        <SubscriptionData /> 
                    </div>
                </div>
            </div>
        </>
    }
}