import React, {Component} from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import {CSVLink} from 'react-csv'
import {Table} from '../../../components'
import makeAnimated from 'react-select/animated';
import {activeDateFilters} from '../../../constants/Enums'
import {UserService, MetaService, DataService, MiscService} from '../../../services'
const animatedComponents = makeAnimated();
const commonPageSize = 50
const rS = new DataService('b2bDailyWorkData'),
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService()
export default class CompanyStockDetailDashboard extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state = {
            searchPostKey: '',
            activeShownTotal: commonPageSize,
            b2bDailyWorkData: [],
            activeDateFilter: activeDateFilters[4],
            branches: [],
            fromFilterDate: miS.getPreviousDate(2),
            toFilterDate: miS.getPreviousDate(1),
            filteredList: [],
            activeBranches: []
        }
    }
    
    componentDidMount () {
        mS.setTitle('Daily Work Detail Report', 'public')
        let orgId = false
        if (this.user?.role === 'representative') {
            orgId = this.user.parent?.value
            this._getCompanyBranches(orgId)
        } else if (this.user?.role === 'company') {
            orgId = this.user.uid
            this._getCompanyBranches(orgId)
        } else {
            this._getB2bDailyWorkData()
        }
        document.getElementsByClassName('table-wrapper')[0].addEventListener('scroll', this._trackScrolling)
    }

    componentWillUnmount() {
        document.getElementsByClassName('table-wrapper')[0].removeEventListener('scroll', this._trackScrolling)
    }
    _trackScrolling = () => {
        let { filteredList, activeShownTotal } = this.state,
        wrappedElement = document.getElementsByClassName('table-wrapper')[0]
        if (this._isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                this._showMore()
        }
    }
    _isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    _showMore = () => {
        let { activeShownTotal } = this.state
        miS.showPreloader()
        setTimeout(() => {
            miS.hidePreloader()
            this.setState({ activeShownTotal: activeShownTotal + commonPageSize})
        }, 300)
    }
    _searchPost = () => {
        let { searchPostKey, b2bDailyWorkData } = this.state,
        tempTitle = '', filteredList = []
        b2bDailyWorkData.forEach(e => {
            searchPostKey = searchPostKey.toLowerCase()
            tempTitle = e.registrationNo.toLowerCase()
            if (tempTitle.search(searchPostKey) !== -1) {
                filteredList.push(e)
            }
        })
        this.setState({ filteredList })
    }
    _getCompanyBranches = async v => {
        let query = {
            parentId: v
        }, branches = [], { activeDateFilter } = this.state
        const result = await uS.getCompanyBranches({ query: JSON.stringify(query) })
        if (result.status) {
            result.data.forEach(e => {
                branches.push({
                    label: e.name,
                    value: e.uid
                })
            });
            let activeBranches = uS.getActiveBranches() === null ? uS.getActiveBranches() : branches
            this.setState({ branches, activeBranches }, () => {
                uS.setGlobalActiveBranches(activeBranches)
                this._getDashboardData(activeDateFilter, activeBranches)
            })
        }
    }
    _getDashboardData = (activeDateFilter, activeBranches) => {
        let activeBranchIds = []
        activeBranches?.forEach( e => {
            activeBranchIds.push(e.value)
        })
        this._getB2bDailyWorkData(activeBranchIds)
    }
    _getB2bDailyWorkData = async (branches = false) => {
        let query = {
            ownerId: ['manager','representative'].includes(this.user.role) ? this.user.parent?.value : this.user.uid
        }
        if (branches) {
            query.ownerId = {
                $in: branches
            }
        }
        const result = await rS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data.length === 0) {
                this.setState({ b2bDailyWorkData: [] })
            } else {
                let b2bDailyWorkData = []
                result.data.reverse().forEach(e => {
                    b2bDailyWorkData.push(e)
                });
                this.setState({ b2bDailyWorkData, filteredList: b2bDailyWorkData })
            }
        }
    }
    _getTableData = filterData => {
        let data = [], { fromFilterDate, toFilterDate, activeBranches } = this.state, i = 0
        filterData.forEach((e) => {
            if(new Date(e['Date']).getTime() >= fromFilterDate && new Date(e['Date']).getTime() <= toFilterDate) {
                i++;
                let yardName = activeBranches.length === 0 ? this.user.name : ''
                activeBranches.forEach(a => {
                    if (a.value === e.ownerId) {
                        yardName = a.label
                    }
                })
                let el = {
                    'Sr. No': i,
                    'Date': e['Date'] ?? '',
                    'Vehicle No (In Capital Letters)': e['Vehicle No (In Capital Letters)'] ?? '',
                    'Yard Name': yardName ?? '',
                    'Cleaning Work Done': e['Cleaning Work Done'] ?? '',
                    'Vehicle Inspection Done': e['Vehicle Inspection Done'] ?? '',
                    'Engine Starting': e['Engine Starting'] ?? '',
                    'Status [Switches Working]': e['Status ']['Switches Working'] ?? '',
                    'Status [AC Working]': e['Status ']['AC Working'] ?? '',
                    'Status [Lights Working]': e['Status ']['Lights Working'] ?? '',
                    'Battery Voltage': e['Battery Voltage'] ?? '',
                    'Vehicle Tyre Status': e['Vehicle Tyre Status'] ?? '',
                    'Service Done [Puncture Repair]': e['Service Done ']['Puncture Repair'] ?? '',
                    'Service Done [Battery Charging]': e['Service Done ']['Battery Charging'] ?? '',
                    'Vehicle Running Condition': e['Vehicle Running Condition'] ?? ''
                }
                data.push(el)
            }
        })
        return data
    }

    render() {
        let { activeBranches, branches, activeDateFilter, fromFilterDate, toFilterDate, filteredList, activeShownTotal, searchPostKey } = this.state
        let tableContent = {
            headers: [
                {
                    label: 'Sr. No',
                    key: 'Sr. No'
                },
                {
                    label: 'Date',
                    key: 'Date'
                },
                {
                    label: 'Vehicle No (In Capital Letters)',
                    key: 'Vehicle No (In Capital Letters)'
                },
                {
                    label: 'Yard Name',
                    key: 'Yard Name'
                },
                {
                    label: 'Cleaning Work Done',
                    key: 'Cleaning Work Done'
                },
                {
                    label: 'Vehicle Inspection Done',
                    key: 'Vehicle Inspection Done'
                },
                {
                    label: 'Engine Starting',
                    key: 'Engine Starting'
                },
                {
                    label: 'Status [Switches Working]',
                    key: 'Status [Switches Working]'
                },
                {
                    label: 'Status [AC Working]',
                    key: 'Status [AC Working]'
                },
                {
                    label: 'Status [Lights Working]',
                    key: 'Status [Lights Working]'
                },
                {
                    label: 'Battery Voltage',
                    key: 'Battery Voltage'
                },
                {
                    label: 'Vehicle Tyre Status',
                    key: 'Vehicle Tyre Status'
                },
                {
                    label: 'Service Done [Puncture Repair]',
                    key: 'Service Done [Puncture Repair]'
                },
                {
                    label: 'Service Done [Battery Charging]',
                    key: 'Service Done [Battery Charging]'
                },
                {
                    label: 'Vehicle Running Condition',
                    key: 'Vehicle Running Condition'
                }
            ],
            content: this._getTableData(filteredList)
        }, exportedData = [], exportedFileName = 'exported_daily_stock_'+new Date().getDate()+'_'+new Date().getMonth()+'_'+new Date().getFullYear()+'.csv', i = 0
        filteredList.forEach(e => {
            if(new Date(e['Date']).getTime() >= fromFilterDate && new Date(e['Date']).getTime() <= toFilterDate) {
                i++;
                let yardName = activeBranches.length === 0 ? this.user.name : ''
                activeBranches.forEach(a => {
                    if (a.value === e.ownerId) {
                        yardName = a.label
                    }
                })
                let el = {
                    'Sr. No': i,
                    'Date': e['Date'] ?? '',
                    'Vehicle No (In Capital Letters)': e['Vehicle No (In Capital Letters)'] ?? '',
                    'Yard Name': yardName ?? '',
                    'Cleaning Work Done': e['Cleaning Work Done'] ?? '',
                    'Vehicle Inspection Done': e['Vehicle Inspection Done'] ?? '',
                    'Engine Starting': e['Engine Starting'] ?? '',
                    'Status [Switches Working]': e['Status ']['Switches Working'] ?? '',
                    'Status [AC Working]': e['Status ']['AC Working'] ?? '',
                    'Status [Lights Working]': e['Status ']['Lights Working'] ?? '',
                    'Battery Voltage': e['Battery Voltage'] ?? '',
                    'Vehicle Tyre Status': e['Vehicle Tyre Status'] ?? '',
                    'Service Done [Puncture Repair]': e['Service Done ']['Puncture Repair'] ?? '',
                    'Service Done [Battery Charging]': e['Service Done ']['Battery Charging'] ?? '',
                    'Vehicle Running Condition': e['Vehicle Running Condition'] ?? ''
                }
                exportedData.push(el)
            }
        })
        return <>
            <div className="right-side-profile-content">
                <div className="header-title-box">
                    <div className='overflow-visible'>
                        <div className='flex'>
                            <h1 className="title">
                                Daily Work Detail Report
                                <p className='company-title'>{this.user.name}</p>
                            </h1>
                            <span className="mlauto">
                                <CSVLink data={exportedData} filename={exportedFileName} className="btn btn-primary btn-small export-btn" target="_blank">Export</CSVLink>
                            </span>
                        </div>
                        {/* <div className="ml20 mt10 select-filter">
                            <Select
                                value={activeDateFilter}
                                defaultValue={activeDateFilter}
                                onChange={activeDateFilter => this.setState({ activeDateFilter }, () => this._getDashboardData(activeDateFilter, activeBranches))}
                                options={activeDateFilters}
                            />
                        </div> */}
                    </div>
                    {
                        // activeDateFilter?.value === 'specificDuration' &&
                        <div className="select-filter">
                            <ul className='mb20 ml-20'>
                                {
                                    ['company', 'representative'].includes(this.user.role) && branches.length > 0 &&
                                    <li>
                                        <label>Select Branches</label>
                                        <Select
                                            isClearable
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            placeholder='Select Company'
                                            defaultValue={activeBranches}
                                            onChange={e => {
                                                this.setState({ activeBranches: e }, () => {
                                                    uS.setGlobalActiveBranches(e)
                                                    this._getDashboardData(activeDateFilter, e)
                                                })
                                            }}
                                            options={branches}
                                            isMulti
                                        />
                                    </li>
                                    }
                                <li>
                                    <label>From Date</label>
                                    <DatePicker
                                        showMonthDropdown={true}
                                        showYearDropdown={true}
                                        dateFormat="MMMM d, yyyy"
                                        onChange={fromFilterDate => this.setState({ fromFilterDate })}
                                        className="date-picker"
                                        selected={fromFilterDate}
                                        maxDate={new Date()}
                                        placeholderText="From Date"
                                    />
                                </li>
                                <li>
                                    <label>To Date</label>
                                    <DatePicker
                                        showMonthDropdown={true}
                                        showYearDropdown={true}
                                        dateFormat="MMMM d, yyyy"
                                        onChange={toFilterDate => this.setState({ toFilterDate })}
                                        className="date-picker"
                                        selected={toFilterDate}
                                        maxDate={new Date()}
                                        placeholderText="To Date"
                                    />
                                </li>
                                <li className='filter-box flex column'>
                                    <label>Car Registration No.</label>
                                    <input type="text" required name="searchPostKey" value={searchPostKey || ''} placeholder="Search..." onChange={e => {
                                        const reg = /^[A-Za-z0-9 ]+$/
                                        if (e.target.value === '' || reg.test(e.target.value))
                                            this.setState({ searchPostKey: e.target.value }, this._searchPost)
                                    }} className="form-control" />
                                </li>
                            </ul>
                        </div>
                    }
                </div>
                <Table
                    data={tableContent}
                    pageSize={activeShownTotal}
                />
            </div>
        </>
    }
}