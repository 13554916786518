import React, {Component} from 'react'
import {base} from '../../../constants/Data'
import DatePicker from 'react-datepicker'
import {CSVLink} from 'react-csv'
import Select from 'react-select'
import {OrderService, MiscService, DataService, MetaService} from '../../../services'
import {Img, Button} from '../../../elements'
import {Table} from '../../../components'
const sWS = new DataService('serviceWork'),
mS = new MetaService(),
miS = new MiscService(),
oS = new OrderService()
const commonPageSize = 50
const filterTags = require('./filterTags.json')
export default class ServiceOrders extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeFilterIndex: 0,
            tableData: [],
            activeItem: null,
            showTrashConfirm: false,
            endDate: '',
            startDate: '',
            searchKey: '',
            activeShownTotal: commonPageSize,
            filterTags: [{
                id: '1',
                label: 'All',
                value: 'all'
            }],
            filteredList: [],
            searchList: [],
            serviceWorkAwaitedStatus: false,
            serviceData: '',
            servicework: null,
            activeFilter: null
        }
    }
    _trackScrolling = () => {
        let { filteredList, activeShownTotal } = this.state,
        wrappedElement = document.getElementById('list-wrapper')
        if (this._isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
            this._showMore()
        }
    }
    componentDidMount() {
        mS.setTitle('Service Orders', 'admin')
        let {filterTags}= this.state
        this.setState({ activeFilter: filterTags[0] })
        this._getOrdersData()
        document.getElementById('content-box').addEventListener('scroll', this._trackScrolling)
    }
    componentWillUnmount() {
        document.getElementById('content-box').removeEventListener('scroll', this._trackScrolling)
    }
    _isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    _showMore = () => {
        let { activeShownTotal } = this.state
        miS.showPreloader()
        setTimeout(() => {
            miS.hidePreloader()
            this.setState({ activeShownTotal: activeShownTotal + commonPageSize})
        }, 300)
    }
    _getOrdersData = async () => {
        let { activeFilterIndex } = this.state
        const result = await oS.getOrders()
        if (result.status) {
            let tableData = [];
            result.data.reverse().forEach((item) => {
                if (item.status !== 'initialised' && item.status !== 'payment-fail') {
                    let orderData = JSON.parse(item.orderData)
                    orderData.cartData.forEach(e => {
                        if (e.type === 'service') {
                            e.orderData = item
                            tableData.push(e)
                        }
                    })
                }
            })
            this.setState({ tableData, filteredList: tableData }, () => {
                let activefilterValue = 'all'
                filterTags.forEach(e => {
                    if (Number(e.id) === activeFilterIndex + 1)
                        activefilterValue = e.value
                })
                this._filterItems(activefilterValue, activeFilterIndex)
            })
            if (result.data.length === 0)
                miS.showAlert({ type: 'error', msg: 'No orders placed yet!' })
        } else
            miS.showAlert({ type: 'error', msg: 'No orders placed yet!' })
    }
    _getTableData = filterData => {
        let data = [], { startDate, endDate } = this.state
        filterData.forEach(( item ) => {
            let orderTime = item.orderData.timing,
                startTime = startDate === '' ? '' : new Date(startDate),
                endTime = endDate === '' ? new Date() : new Date(endDate)
            if (orderTime >= startTime && orderTime <= endTime) {
                let e = {
                    image: <div className="product-thumbnail">
                        {
                            item.type === 'servicePackage' ?
                                <ion-icon name="cube" class="service-package-icon"></ion-icon> :
                                item.type === 'subscription' ?
                                    <ion-icon name="ribbon-outline" class="service-package-icon"></ion-icon> :
                                    <Img zoom={true} src={base.imageBaseUrl + item.image} alt={item.title} />
                        }
                    </div>,
                    title: <div className="product-name" data-title="Product">
                        {item.title}
                        <p className="active-time-slot"><b>Time Slot:</b> {(item.activeTimeSlot).slot}</p>
                    </div>,
                    price: <div className="product-price" data-title="Price">
                        <span className="Price-amount amount">₹ {Number(item.price).toFixed(2)}</span>
                    </div>,
                    orderId: <>
                        #{item.orderData.uid}
                    </>,
                    userName: JSON.parse(item.orderData.userData).name,
                    timing: <>
                        {miS.getFormattedDate(item.orderData.timing)}
                        <p className="status-btn" onClick={() => this._getCompletedWorkData(item.tempUid)}>Status</p>
                    </>
                }
                data.push(e)
            }
        })
        return data
    }
    _getCompletedWorkData = tempUid => {
        let query = {
            tempUid: String(tempUid)
        }
        const result = sWS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data.length > 0) {
                this.setState({ serviceWorkAwaitedStatus: false, servicework: result.data[result.data.length - 1] })
            } else {
                this.setState({ serviceWorkAwaitedStatus: true })
            }
        }
        miS.openModal("car-service-status-modal")
    }
    _renderImages = serviceImages => {
        return serviceImages.map(( item, index ) => {
            return <li key={index}><Img src={base.imageBaseUrl + item} alt={item} /></li>
        })
    }
    _filterItems = ( key, activeFilterIndex ) => {
        let { tableData } = this.state, filteredList = []
        if (key === 'all') {
            filteredList = tableData
        } else {
            filteredList = tableData.filter(e => {
                return e.status === key
            })
        }
        this.setState({ filteredList, activeFilterIndex: activeFilterIndex })
    }
    _renderFilterTags = filterData => {
        let { activeFilterIndex, tableData } = this.state
        return filterData.map(( item, index ) => {
            return <span className={index === activeFilterIndex ? 'link active' : 'link item'} key={index} onClick={() => this._filterItems(item.key, index)}>{item.item} ({
                (item.key === 'all') ? tableData.length :
                    tableData.reduce((count, e) => {
                        return item.key === e.status ? count + 1 : count
                    }, 0)})
            </span>
        })
    }
    _search = () => {
        let { searchKey, tableData } = this.state,
            filteredList = [], tempTitle = ''
        tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = (JSON.parse(e.userData).name).toLowerCase()
            if (e.uid.search(searchKey) !== -1 || tempTitle.search(searchKey) !== -1) {
                filteredList.push(e)
            }
        })
        this.setState({ filteredList, activeFilterIndex: 0 })
    }
    render() {
        let { activeFilter, tableData, filteredList, searchKey, startDate, endDate, servicework, serviceWorkAwaitedStatus, activeShownTotal } = this.state,
        serviceData = null, workData = null, exportedData = [], cartTotalTax = 0
        filteredList.forEach(e => {
            // let orderData = JSON.parse(e.orderData)
            // if (!orderData.discount) {
            //     orderData.discount = 0
            // }
            // if (typeof orderData.cartTotal !== "number")
            //     orderData.cartTotal = Number(orderData.cartTotal)
            // if (typeof orderData.discount !== "number")
            //     orderData.discount = Number(orderData.discount)
            // cartTotalTax = 0
            // orderData.cartData.forEach(( c, i ) => {
            //     c.tax = Number(c.tax);
            //     c.price = Number(c.price).toFixed(2)
            //     c.taxAmount = (c.tax * c.price/(100+c.tax)).toFixed(2)
            //     cartTotalTax += c.taxAmount
            //     if (c.type === 'service' && e.status !== 'initialised' && e.status !== 'payment-fail') {
            //         let ex = {
            //             Customer_Id: (i === 0) ? JSON.parse(e.userData).uid: '',
            //             Order_Id: (i === 0) ? e.uid: '',
            //             Customer_Name: (i === 0) ? JSON.parse(e.userData).name: '',
            //             Mobile_No: (i === 0) ? JSON.parse(e.userData).mobile: '',
            //             Address: (e.activeAddress && e.activeAddress !== "undefined") ?((i === 0) ? miS.parseAddress(JSON.parse(e.activeAddress)): ''): '',
            //             Pin_Code: (e.activeAddress && e.activeAddress !== "undefined")? ((i === 0) ? JSON.parse(e.activeAddress).pinCode: ''): '',
            //             Status: (i === 0) ? e.status: '',
            //             Vehicle_Details: c.activeCar ? c.activeCar.name + ' ' + c.activeCar.Segment: '',
            //             Vehicle_No: c.activeCar ? c.activeCar.registrationNo ?c.activeCar.registrationNo: '': '',
            //             Service: c.type === 'service'?c.title: '',
            //             Total: (i === 0) ? (['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?(orderData.cartTotal).toFixed(2): ''): '',
            //             Discount: (i === 0) ? (['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?Number((orderData.discount).toFixed(2)): ''): '',
            //             Net_Payment: (i === 0) ? (['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?(orderData.cartTotal - Number((orderData.discount).toFixed(2))).toFixed(2): ''): '',
            //             GST: ( e.status === "initialised") ? '': (i === 0) ? cartTotalTax: ''
            //         }
            //         exportedData.push(ex)
            //     }
            // })
        })
        let exportedFileName = 'export_' + new Date().getDate() + '_' + new Date().getMonth() + '_' + new Date().getFullYear() + '.csv'
        if (servicework !== null) {
            serviceData = JSON.parse(servicework.activeCar)
            if (typeof workData !== 'undefined')
                workData = JSON.parse(servicework.workData)
        }
        let tableContent = {
            headers: [
                {
                    template: <div className="product-thumbnail">Preview</div>
                },
                {
                    template: <div className="product-name">Product</div>
                },
                {
                    template: <div className="product-price">Price</div>
                },
                {
                    label: 'Order Id',
                    key: 'orderId'
                },
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Date',
                    key: 'date'
                }
            ],
            content: this._getTableData(filteredList)
        }
        return <>
            <h1 className="flex title">
                Service Orders
                {
                    filteredList.length !== 0 &&
                    <span className="btn btn-primary btn-small ml20 export-btn">
                        <CSVLink data={exportedData} filename={exportedFileName} className="btn export-btn" target="_blank">Export</CSVLink>
                    </span>
                }
            </h1>
            <div className="filter-box">
                <div className="flex date-filter overflow-visible">
                    <div className="form-group">
                        <DatePicker
                            selected={startDate}
                            onChange={startDate => this.setState({ startDate })}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            className="datePicker-filter"
                            placeholderText="From:"
                        />
                    </div>
                    <div className="form-group">
                        <DatePicker
                            selected={endDate}
                            onChange={endDate => this.setState({ endDate })}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            className="datePicker-filter"
                            placeholderText="To:"
                        />
                    </div>
                    <div className="form-group ml10">
                        <Select
                            value={activeFilter}
                            defaultValue={activeFilter}
                            onChange={(e, i) => this.setState({ activeFilter: e }, () => this._filterItems(e.value, i) )}
                            options={filterTags}
                        />
                    </div>
                </div>
                <div className="search-box mlauto">
                    <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                        const reg = /^[A-Za-z0-9 ]+$/
                        if (e.target.value === '' || reg.test(e.target.value))
                            this.setState({ searchKey: e.target.value }, this._search)
                    }} />
                    <Button type="button" onClick={() => this.setState({ filteredList: tableData, searchKey: '' }, this._search)}><ion-icon name="close"></ion-icon></Button>
                </div>
            </div>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <div className="modal" id="car-service-status-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content content medium">
                    <Button className="close" onClick={() => miS.closeModal("car-service-status-modal")}><i className="hi-close"></i></Button>
                    <div className="modal-body p20">
                        {
                            serviceWorkAwaitedStatus ?
                            <>
                            <div className="no-data-icon">
                                <ion-icon name="hourglass-outline"></ion-icon>
                            </div>
                            <p className="awited-text">Work is still awaited for this service</p></> :
                            <div>
                                {
                                    servicework !== null &&
                                    <>
                                        <div className="form-group flex">
                                            <label className="col-3 control-label">Title</label>
                                            <div className="col-9 controls ml20">
                                                {servicework.title}
                                            </div>
                                        </div>
                                        <div className="form-group flex">
                                            <label className="col-3 control-label">Service Date</label>
                                            <div className="col-9 controls ml20">
                                                {miS.getFormattedDate(new Date(servicework.serviceDate))}
                                            </div>
                                        </div>
                                        <div className="form-group flex">
                                            <label className="col-3 control-label">Start Time</label>
                                            <div className="col-9 controls ml20">
                                                {miS.getFormattedTime(new Date(workData[0].startTime))}
                                            </div>
                                        </div>
                                        <div className="form-group flex">
                                            <label className="col-3 control-label">Active Car</label>
                                            <ul className="col-9 controls ml20">
                                                <li className="my-packages-assign-car">
                                                    {
                                                        serviceData.image_path !== null && typeof serviceData.image_path !== 'undefined' && serviceData.image_path !== '' &&
                                                        <Img src={base.carImageBase + (JSON.parse(servicework.activeCar).image_path)} alt={(servicework.activeCar).name} />
                                                    }
                                                    <div className="ml10">
                                                        <p>{serviceData.name + ' ' + serviceData.Segment}</p>
                                                        {
                                                            serviceData.registrationNo && serviceData.registrationNo !== '' && serviceData.registrationNo !== null && <small>{serviceData.registrationNo}</small>
                                                        }
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="form-group flex">
                                            <label className="col-3 control-label">Comments</label>
                                            <div className="col-9 controls ml20">
                                                {workData[0].comments}
                                            </div>
                                        </div>
                                        <div className="form-group flex">
                                            <label className="col-3 control-label">Images</label>
                                            <div className="col-9 controls ml20">
                                                <ul className="media-box">
                                                    {this._renderImages(workData[0].carImages || [] )}
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    }
}