import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import {CSVLink} from 'react-csv'
import {Table} from '../../../components'
import makeAnimated from 'react-select/animated';
import { Actor } from '../../../elements'
import {activeDateFilters} from '../../../constants/Enums'
import {UserService, MetaService, DataService, RouterService, MiscService} from '../../../services'
const animatedComponents = makeAnimated(),
activeShownTotal = 50,
bwS = new DataService('b2bDailyWorkData'),
dS = new DataService('b2bDailyStockData'),
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
const CompanyWorkDashboard = props => {
    let user = uS.getLoggedInUserData()
    const exportedFileName = 'exported_daily_work_'+new Date().getDate()+'_'+new Date().getMonth()+'_'+new Date().getFullYear()+'.csv'
    let [totalData, settotalData] = useState({
        'Opening Stock': 0,
        'Stock In': 0,
        'Stock Out': 0,
        'Closing Stock': 0,
        'Inspection Done': 0
    })
    let [tableContent, settableContent] = useState(null)
    let [exportedData, setexportedData] = useState([])
    let [b2bDailyWorkData, setb2bDailyWorkData] = useState([])
    let [b2bDailyStockData, setb2bDailyStockData] = useState([])
    let [b2bTotalWorkData, setb2bTotalWorkData] = useState([])
    let [activeDateFilter, setactiveDateFilter] = useState(activeDateFilters[4])
    let [branches, setbranches] = useState([])
    let [fromFilterDate, setfromFilterDate] = useState(miS.getPreviousDate(2))
    let [toFilterDate, settoFilterDate] = useState(miS.getPreviousDate(1))
    let [activeBranches, setactiveBranches] = useState([])

    useEffect(() => {
        mS.setTitle('Daily Work Report', 'public')
        let orgId = false
        if (user?.role === 'representative') {
            orgId = user.parent?.value
            _getCompanyBranches(orgId)
        } else if (user?.role === 'company') {
            orgId = user.uid
            _getCompanyBranches(orgId)
        } else {
            _getB2bDailyWorkData()
            _getB2bDailyStockData()
        }
    }, [])

    useEffect(() => {
        let btw = []
        let td = {
            'Opening Stock': 0,
            'Stock In': 0,
            'Stock Out': 0,
            'Closing Stock': 0,
            'Inspection Done': 0
        }
        activeBranches?.forEach( e => {
            btw.push({
                ownerId: e.value,
                'Yard Name': e.label,
                'Closing Stock': 0,
                'Non Running': 0,
                'Running': 0,
                'Battery Issue': 0,
                'Tyre Issue': 0,
                'Waterless cleaning': 0,
                'Gold Wash': 0,
                'Battery Charging': 0,
                'Puncture Repaired': 0
            })
        })
        btw.push({
            'Yard Name': 'Grand Total',
            'Closing Stock': 0,
            'Non Running': 0,
            'Running': 0,
            'Battery Issue': 0,
            'Tyre Issue': 0,
            'Waterless cleaning': 0,
            'Gold Wash': 0,
            'Battery Charging': 0,
            'Puncture Repaired': 0
        })
        b2bDailyStockData.forEach(e => {
            if(new Date(e['Date']).getTime() >= fromFilterDate && new Date(e['Date']).getTime() <= toFilterDate) {
                e['Inventory Status'] = e['Inventory Status'].trim()
                if (e['Inventory Status'] === 'Opening Stock') {
                    td['Opening Stock']++
                } else if (['Stock Out', 'Vehicle Out'].includes(e['Inventory Status'])) {
                    td['Stock Out']++
                } else if (['Stock In', 'Vehicle In'].includes(e['Inventory Status'])) {
                    td['Stock In']++
                }
                btw.forEach(f => {
                    if (e.ownerId === f.ownerId) {
                        if (e['Inventory Status'] === 'Opening Stock') {
                            f['Closing Stock']++
                            btw[btw.length - 1]['Closing Stock']++
                        } else if (['Stock Out', 'Vehicle Out'].includes(e['Inventory Status'])) {
                            f['Closing Stock']--
                            btw[btw.length - 1]['Closing Stock']--
                        } else if (['Stock In', 'Vehicle In'].includes(e['Inventory Status'])) {
                            f['Closing Stock']++
                            btw[btw.length - 1]['Closing Stock']++
                        }
                    }
                })
            }
        })
        td['Closing Stock'] = td['Opening Stock'] - td['Stock Out'] + td['Stock In']
        b2bDailyWorkData.forEach(e => {
            if(new Date(e['Date']).getTime() >= fromFilterDate && new Date(e['Date']).getTime() <= toFilterDate) {
                if (e['Vehicle Inspection Done'] === 'Yes') {
                    td['Inspection Done']++
                }
                btw.forEach(f => {
                    if (e.ownerId === f.ownerId) {
                        if(e['Vehicle Running Condition'] === 'Non Running') {
                            f['Non Running']++
                            btw[btw.length - 1]['Non Running']++
                        }
                        if(e['Cleaning Work Done'] === 'Gold Wash') {
                            f['Gold Wash']++
                            btw[btw.length - 1]['Gold Wash']++
                        } else if(e['Cleaning Work Done'] === 'Waterless Cleaning') {
                            f['Waterless cleaning']++
                            btw[btw.length - 1]['Waterless cleaning']++
                        }
                        if(e['Service Done ']['Battery Charging'] === 'Done') {
                            f['Battery Charging']++
                            btw[btw.length - 1]['Battery Charging']++
                        }
                        if(e['Service Done ']['Battery Charging'] === 'Need Replacement') {
                            f['Battery Issue']++
                            btw[btw.length - 1]['Battery Issue']++
                        }
                        if(e['Vehicle Tyre Status'] === 'Puncture') {
                            f['Tyre Issue']++
                            btw[btw.length - 1]['Tyre Issue']++
                        }
                        if(e['Service Done ']['Puncture Repair'] === 'Done') {
                            f['Puncture Repaired']++
                            btw[btw.length - 1]['Puncture Repaired']++
                        }
                    }
                })
            }
        });
        settotalData(td)
        btw.forEach(f => {
            f['Running'] = f['Closing Stock'] - f['Non Running']
        })
        btw[btw.length - 1]['Running'] = btw[btw.length - 1]['Closing Stock'] - btw[btw.length - 1]['Non Running'] 
        settableContent({
            headers: [
                {
                    label: 'Yard Name',
                    key: 'Yard Name'
                },
                {
                    label: 'Closing Stock',
                    key: 'Closing Stock'
                },
                {
                    label: 'Non Running',
                    key: 'Non Running'
                },
                {
                    label: 'Running',
                    key: 'Running'
                },
                {
                    label: 'Battery Issue',
                    key: 'Battery Issue'
                },
                {
                    label: 'Tyre Issue',
                    key: 'Tyre Issue'
                },
                {
                    label: 'Waterless cleaning',
                    key: 'Waterless cleaning'
                },
                {
                    label: 'Gold Wash',
                    key: 'Gold Wash'
                },
                {
                    label: 'Battery Charging',
                    key: 'Battery Charging'
                },
                {
                    label: 'Puncture Repaired',
                    key: 'Puncture Repaired'
                },
                {
                    label: 'Action',
                    key: 'action'
                }
            ],
            content: _getTableData(btw)
        })
        setb2bTotalWorkData(btw)
        setb2bDailyWorkData(b2bDailyWorkData)
        setexportedData(btw)
    }, [activeBranches, fromFilterDate, toFilterDate])

    const _getCompanyBranches = async v => {
        let query = {
            parentId: v
        }, bs = [], activeDateFilter
        const result = await uS.getCompanyBranches({ query: JSON.stringify(query) })
        if (result.status) {
            result.data.forEach(e => {
                bs.push({
                    label: e.name,
                    value: e.uid
                })
            });
            let activeBranches = uS.getActiveBranches() === null ? uS.getActiveBranches() : bs
            setbranches(bs)
            setactiveBranches(activeBranches)
            uS.setGlobalActiveBranches(activeBranches)
            _getDashboardData(activeDateFilter, activeBranches, true)
        }
    }
    const _getDashboardData = (activeDateFilter, activeBranches, fetch = false) => {
        let activeBranchIds = []
        activeBranches?.forEach( e => {
            activeBranchIds.push(e.value)
            b2bTotalWorkData.push({
                ownerId: e.value,
                'Yard Name': e.label,
                'Closing Stock': 0,
                'Non Running': 0,
                'Running': 0,
                'Battery Issue': 0,
                'Tyre Issue': 0,
                'Waterless cleaning': 0,
                'Gold Wash': 0,
                'Battery Charging': 0,
                'Puncture Repaired': 0
            })
        })
        b2bTotalWorkData.push({
            'Yard Name': 'Grand Total',
            'Closing Stock': 0,
            'Non Running': 0,
            'Running': 0,
            'Battery Issue': 0,
            'Tyre Issue': 0,
            'Waterless cleaning': 0,
            'Gold Wash': 0,
            'Battery Charging': 0,
            'Puncture Repaired': 0
        })
        setb2bTotalWorkData(b2bTotalWorkData)
        if (fetch) {
            _getB2bDailyWorkData(activeBranchIds)
            _getB2bDailyStockData(activeBranchIds)
        }
    }
    const _getB2bDailyStockData = async (branches = false) => {
        let query = {
            ownerId: ['manager','representative'].includes(user.role) ? user.parent?.value : user.uid
        }
        if (branches) {
            query.ownerId = {
                $in: branches
            }
        }
        const result = await dS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data.length === 0) {
                setb2bDailyStockData(b2bDailyStockData)
            } else {
                setb2bDailyStockData(result.data.reverse())
            }
        }
    }
    const _getB2bDailyWorkData = async (branches = false) => {
        let query = {
            ownerId: ['manager','representative'].includes(user.role) ? user.parent?.value : user.uid
        }
        if (branches) {
            query.ownerId = {
                $in: branches
            }
        }
        const result = await bwS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data.length === 0) {
                setb2bDailyWorkData([])
            } else {
                if(!branches) {
                    setb2bTotalWorkData([
                        {
                            ownerId: user.uid,
                            'Yard Name': user.name,
                            'Closing Stock': 0,
                            'Non Running': 0,
                            'Running': 0,
                            'Battery Issue': 0,
                            'Tyre Issue': 0,
                            'Waterless cleaning': 0,
                            'Gold Wash': 0,
                            'Battery Charging': 0,
                            'Puncture Repaired': 0
                        }
                    ])
                }
                setb2bDailyWorkData(result.data.reverse())
            }
        }
    }
    const _getTableData = filterData => {
        let data = []
        filterData.map((e, i) => {
            let ownerId = e.ownerId ?? ''
            let el = {
                'Yard Name': e['Yard Name'],
                'Closing Stock': e['Closing Stock'],
                'Non Running': e['Non Running'],
                'Running': e['Running'],
                'Battery Issue': e['Battery Issue'],
                'Tyre Issue': e['Tyre Issue'],
                'Waterless cleaning': e['Waterless cleaning'],
                'Gold Wash': e['Gold Wash'],
                'Battery Charging': e['Battery Charging'],
                'Puncture Repaired': e['Puncture Repaired'],
                action: <div className="action-bar">
                    <Actor title="View Work Detail" type='view' onClick={() => rS.navigateTo('/company/dashboard/work/detail/'+ownerId, true)} />
                    <Actor title="View Stock Detail" type='car' onClick={() => rS.navigateTo('/company/dashboard/stock/detail/'+ownerId, true)} />
                </div>
            }
            data.push(el)
        })
        return data
    }

    return <>
        <div className="right-side-profile-content">
            <div className="header-title-box">
                <div className='overflow-visible'>
                    <div className='flex'>
                        <h1 className="title">
                            Daily Work Report
                            <p className='company-title'>{user.name}</p>
                        </h1>
                        <span className="mlauto">
                            <CSVLink data={exportedData} filename={exportedFileName} className="btn btn-primary btn-small export-btn" target="_blank">Export</CSVLink>
                        </span>
                    </div>
                    {/* <div className="ml20 mt10 select-filter">
                        <Select
                            value={activeDateFilter}
                            defaultValue={activeDateFilter}
                            onChange={activeDateFilter => {setactiveDateFilter(activeDateFilter); _getDashboardData(activeDateFilter, activeBranches))}}
                            options={activeDateFilters}
                        />
                    </div> */}
                </div>
                {
                    // activeDateFilter?.value === 'specificDuration' &&
                    <div className="select-filter">
                        <ul className='mb20 ml-20'>
                            {
                                ['company', 'representative'].includes(user.role) && branches.length > 0 &&
                                <li>
                                    <label>Select Branches</label>
                                    <Select
                                        isClearable
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        placeholder='Select Company'
                                        defaultValue={activeBranches}
                                        onChange={e => {
                                            setactiveBranches(e)
                                            uS.setGlobalActiveBranches(e)
                                            _getDashboardData(activeDateFilter, e)
                                        }}
                                        options={branches}
                                        isMulti
                                    />
                                </li>
                                }
                            <li>
                                <label>From Date</label>
                                <DatePicker
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    dateFormat="MMMM d, yyyy"
                                    onChange={fromFilterDate => setfromFilterDate(fromFilterDate)}
                                    className="date-picker"
                                    selected={fromFilterDate}
                                    maxDate={new Date()}
                                    placeholderText="From Date"
                                />
                            </li>
                            <li>
                                <label>To Date</label>
                                <DatePicker
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    dateFormat="MMMM d, yyyy"
                                    onChange={toFilterDate => settoFilterDate(toFilterDate)}
                                    className="date-picker"
                                    selected={toFilterDate}
                                    maxDate={new Date()}
                                    placeholderText="To Date"
                                />
                            </li>
                        </ul>
                    </div>
                }
            </div>
            <div className='blocks mb20'>
                <div className='block'>
                    <h3>Opening Stock</h3>
                    <p>{totalData['Opening Stock']}</p>
                </div>
                <div className='block'>
                    <h3>Vehicle In</h3>
                    <p>{totalData['Stock In']}</p>
                </div>
                <div className='block'>
                    <h3>Vehicle Out</h3>
                    <p>{totalData['Stock Out']}</p>
                </div>
                <div className='block'>
                    <h3>Closing Stock</h3>
                    <p>{totalData['Closing Stock']}</p>
                </div>
                <div className='block'>
                    <h3>Inspection Done</h3>
                    <p>{totalData['Inspection Done']}</p>
                </div>
            </div>
            {tableContent && <Table
                tableClass="has-total"
                showTotal={false}
                data={tableContent}
                pageSize={activeShownTotal}
            />}
        </div>
    </>
}

export default CompanyWorkDashboard