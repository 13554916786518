import React, {Component} from 'react'
import {TrashConfirmModal, Table} from '../../../components'
import {base} from '../../../constants/Data'
import Select from 'react-select'
import {MicroServiceService, MiscService, RouterService, MetaService} from '../../../services'
import {Img, Button, Badge, Actor} from '../../../elements'
const commonPageSize = 50
const filterTags = require('./filterTags.json'),
mS = new MetaService(),
miS = new MiscService(),
micS = new MicroServiceService(),
rS =new RouterService()
export default class MicroServicesList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableData: [],
            subscriptionData: null,
            filteredList: [],
            activeFilterIndex: 0,
            showTrashConfirm: false,
            checkedAll: false,
            searchList: [],
            checkedList: [],
            trashAction: false,
            isMultipleDelete: false,
            searchKey: '',
            items: '',
            activeShownTotal: commonPageSize,
            activeFilter: null
        }
    }
    componentDidMount () {
        mS.setTitle('Micro Services List', 'admin')
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        this.setState({ trashAction: urlData[2] === 'trash', activeFilter: filterTags[0] }, this._getServicesListData)
        document.getElementById('content-box').addEventListener('scroll', this._trackScrolling)
    }
    componentWillUnmount() {
        document.getElementById('content-box').removeEventListener('scroll', this._trackScrolling)
    }
    _trackScrolling = () => {
        let { filteredList, activeShownTotal } = this.state,
        wrappedElement = document.getElementById('list-wrapper')
        if (this._isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                this._showMore()
        }
    }
    _isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    _showMore = () => {
        let { activeShownTotal } = this.state
        miS.showPreloader()
        setTimeout(() => {
            miS.hidePreloader()
            this.setState({ activeShownTotal: activeShownTotal + commonPageSize})
        }, 300)
    }
    _cloneService = async item => {
        let microServiceObject = {
            title: item.title,
            slug: item.slug+'-copy',
            content: item.content,
            excerpt: item.excerpt,
            css: item.css,
            js: item.js,
            canonical: item.canonical,
            robots: item.robots,
            metaDescription: item.metaDescription,
            metaKeyword: item.metaKeyword,
            author: item.author,
            publishDate: item.publishDate,
            status: item.status,
            categories: item.categories,
            tag: item.tag,
            prices: item.prices,
            image: item.image
        }
        microServiceObject.uid = new Date().getTime()
        const result = await micS.addMicroService(microServiceObject)
        if (result.status) {
            miS.showAlert({ type: 'success', msg: 'micro-Service clone successfully!' })
            this._getServicesListData()
        } else miS.showAlert({ type: 'error', msg: 'Unable to clone micro-Service!' })
    }
       
    _getServicesListData = async () => {
        let { activeFilterIndex, trashAction } = this.state
        const result = await micS.getMicroServices()
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(e => {
                if (!trashAction) {
                    if (!e.trash)
                        tableData.push(e)
                } else {
                    if (e.trash)
                        tableData.push(e)
                }
            })
            this.setState({ tableData, filteredList: tableData }, () => {
                let activefilterValue = 'all'
                filterTags.forEach(e => {
                    if (Number(e.id) === activeFilterIndex+1) {
                        activefilterValue = e.value
                    }
                })
                this._filterItems( activefilterValue, activeFilterIndex)
            })
            if (result.data.length === 0)
                miS.showAlert({ type: 'error', msg: 'No micro-Services added yet!' })
        } else miS.showAlert({ type: 'error', msg: 'No micro-Services added yet!' })
    }
    _trashCancel = () => {
        this.setState({ activeItem: null, showTrashConfirm: false })
    }
    _deleteSelectService = () => {
        let { activeItem } = this.state
        this._deleteMicroService(activeItem.uid)
    }
    _deleteMicroService = async (uid, task = 'trash') => {
        let query = {
            uid
        }, { trashAction, tableData } = this.state
        if (trashAction && task === 'trash') {
            const result = await micS.deleteMicroService({ query: JSON.stringify(query) })
            if (result.status) {
                miS.showAlert({ type: 'success', msg: 'micro-Service deleted successfully!' })
                this.setState({ activeItem: null, showTrashConfirm: false }, this._getServicesListData)
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to delete micro-Service!' })
        } else {
            let microserviceObject = null
            tableData.forEach(e => {
                if (e.uid === uid)
                    microserviceObject = e
            })
            microserviceObject.trash = task === 'trash'
            delete microserviceObject._id
            let data = {
                query: JSON.stringify({uid}),
                microServiceData: JSON.stringify(microserviceObject)
            }
            const result = await micS.updateMicroService(data)
            if (result.status) {
                miS.showAlert({ type: 'success', msg: 'Micro-service '+task+'d successfully!' })
                this.setState({ activeItem: null, showTrashConfirm: false }, this._getServicesListData)
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to '+task+' micro-service!' })
        }
    }
    _toggleCheck = uid => {
        let {checkedList, filteredList} = this.state, newCheckedList = [], found = false
        checkedList.forEach(e => {
            if (e === uid)
                found = true
            else
                newCheckedList.push(e)
        })
        if (!found)
            newCheckedList.push(uid)
        this.setState({ checkedAll: (filteredList.length === newCheckedList.length), checkedList: newCheckedList })
    }
    _renderFilterTags = filterData => {
        let { activeFilterIndex, tableData } = this.state
        return filterData.map(( item, index ) => {
            return <span className={ index === activeFilterIndex ? 'link active' : 'link item'} key={index} onClick={() => this._filterItems(item.key, index)}>{item.item} ({
                (item.key === 'all') ? tableData.length:
                tableData.reduce(( count, e) => {
                return item.key === e.status ? count+1:count
            },0) })</span>
        })
    }
    _filterItems = ( key, activeFilterIndex ) => {
        let { tableData } = this.state, filteredList = []
        if (key === 'all') {
            filteredList = tableData
        } else {
            filteredList = tableData.filter(e => {
                return e.status === key
            })
        }
        this.setState({ filteredList, activeFilterIndex: activeFilterIndex })
    }
    _getTableData = filterData => {
        let data = [], { checkedAll, checkedList, trashAction } = this.state
        filterData.forEach(( item, index ) => {
            let e = {
                checkAction: <span className="check-all" onClick={() => this._toggleCheck(item.uid)}>
                    {
                        checkedAll || checkedList.includes(item.uid) ?
                        <ion-icon class="orange" name="checkmark-circle-outline"></ion-icon>
                        :<ion-icon name="ellipse-outline"></ion-icon>
                    }
                </span>,
                title: item.title,
                image: item.image?
                    <Img alt="Blank Placeholder" src={base.imageBaseUrl+JSON.parse(item.image)[0]} style={{maxHeight: 40, maxWidth: 40}} />:
                    <p>No Image</p>,
                publishDate: miS.getFormattedDate(new Date(item.publishDate)),
                status: <Badge item={item.status} />,
                action: trashAction ?
                <div className="action-bar">
                    <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                    <Actor type='undo' onClick={() => this._deleteMicroService(item.uid, 'restore')} />
                </div>:
                <div className="action-bar">
                    <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                    {item.status === "publish" && <Actor type='view' onClick={() => rS.navigateTo('/microservices/'+item.slug)} />}
                    <Actor type='edit' onClick={() => rS.navigateTo('/admin/microservice/edit/'+item.uid)} />
                    <Actor type='copy' onClick={() => this._cloneService(item)} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    _search = () => {
        let { searchKey, tableData } = this.state,
        tempTitle = '', filteredList = []
        tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = e.title.toLowerCase()
            if (tempTitle.search(searchKey) !== -1) {
                filteredList.push(e)
            }
        })
        this.setState({ filteredList })
    }
    _deleteChecked = () => {
        let { checkedList, checkedAll, filteredList } = this.state
        if (checkedAll) {
            checkedList = filteredList
        }
        checkedList.forEach(e => {
            this._deleteMicroService(e.uid)
        })
        this.setState({ checkedList: [], isMultipleDelete: false })
    }
    render() {
        let { activeFilter, tableData, trashAction, checkedAll, isMultipleDelete, checkedList, filteredList, searchKey, showTrashConfirm, activeShownTotal } = this.state
        let tableContent = {
            headers: [
                {
                    template: <span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll)
                            this.setState({ checkedAll: !checkedAll, checkedList: [] })
                        else {
                            filteredList.forEach(e => {
                                newCheckedList.push(e.uid)
                            })
                            this.setState({ checkedAll: !checkedAll, checkedList: newCheckedList })
                        }
                    }}>
                        {
                            checkedAll || checkedList.length === filteredList.length?
                            <ion-icon class="orange" name="checkmark-circle-outline"></ion-icon>:
                            <ion-icon name="ellipse-outline"></ion-icon>
                        }
                    </span>
                },
                {
                    label: 'Title',
                    key: 'title'
                },
                {
                    label: 'Image',
                    key: 'image'
                },
                {
                    label: 'Publish Date',
                    key: 'pubishDate'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Action',
                    key: 'action'
                }
            ],
            content: this._getTableData(filteredList)
        }
        return <>
            <h1 className="flex title mb50">
                {
                    trashAction?'Trashed': 'All'
                } Micro Services
                {
                    !trashAction &&
                    <>
                        <a className="btn btn-primary btn-small ml20" href="/admin/microservice/add">Add</a>
                        {
                            (checkedList.length !== 0 || checkedAll) &&
                            <Button type="button" onClick={() => {
                                this.setState({ isMultipleDelete:true, showTrashConfirm: true})
                            }} className="btn btn-outline btn-small">Delete</Button>
                        }
                    </>
                }
            </h1>
            <div className="filter-box">
                <div className="filters relative">
                <label className="filter-label-add">Status: </label>
                    <Select
                        value={activeFilter}
                        defaultValue={activeFilter}
                        onChange={(e, i) => this.setState({ activeFilter: e }, () => this._filterItems(e.value, i) )}
                        options={filterTags}
                    />
                </div>
                <div className="search-box">
                    <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                        const reg = /^[A-Za-z0-9 ]+$/
                        if (e.target.value === '' || reg.test(e.target.value))
                            this.setState({ searchKey: e.target.value }, this._search)
                        }} />
                    <Button type="button" onClick={() => this.setState({ filteredList: tableData, searchKey: '' }, this._search)}><ion-icon name="close"></ion-icon></Button>
                </div>
            </div>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <TrashConfirmModal trashOkAction={() => {
                isMultipleDelete?this._deleteChecked():this._deleteSelectService()
            }} trashCancelAction={this._trashCancel} showTrashConfirm={showTrashConfirm} isMultipleDelete={isMultipleDelete} />
        </>
    }
}