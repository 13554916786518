import React, {Component} from 'react'
import Select from 'react-select'
import { Editor } from '@tinymce/tinymce-react'
import {base,mainUrl} from '../../../constants/Data'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import CreatableSelect from 'react-select/creatable'
import {robotsoptions,authoroptions,statusOptions} from '../../../constants/Enums'
import {MiscService, CarService, DataService, MicroServiceService, RouterService, UserService, MetaService} from '../../../services'
import {Img, Button, BackIcon} from '../../../elements'
import {Box, MediaBox} from '../../../components'
const tS = new DataService('taxClass'),
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
micS = new MicroServiceService(),
rS = new RouterService(),
cS = new CarService(),
keywordOptions =  [],
tagOptions = []
export default class AddMicroService extends Component {
    constructor(props) {
        super(props)
        this.state = {
            companies: [],
            company: null,
            activeCar: null,
            activePrice: '',
            saleprice: '',
            activeRobotsIndex: 0,
            activeStatusIndex: 2,
            activeAuthorIndex: 0,
            searchKey: '',
            tableData: [],
            prices: '[]',
            image: '[]',
            icon: '[]',
            categoriesOptions: [],
            activeFile: null,
            showCarListBox: false,
            vehicleClassList: [],
            assignWorkers: [],
            uid: '',
            renderSlug: true,
            mode: 'Add',
            title: '',
            slug: '',
            content: "",
            excerpt: "",
            css: '',
            js: '',
            canonical: '',
            robots: '',
            metaDescription: '',
            metaKeyword: '',
            author: '',
            publishDate: new Date(),
            status: '',
            categories: '',
            sku: '',
            hsn: '',
            tag: '',
            tax: '18',
            userNote: '',
            serviceTime: '',
            mediaFiles: [],
            isFeatured: false,
            selectedCarList: true,
            inclusiveTax: false,
            taxClassData: [],
            fuelPrices: {
                cng: '',
                petrol: '',
                diesel: ''
            }
        }
    }
    componentDidMount () {
        mS.setTitle('Add Micro Service', 'admin')
        this._getServiceCategoryData()
        this._getTaxClassesListData()
        let { activeStatusIndex } = this.state
        this.setState({ status: statusOptions[activeStatusIndex].value })
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        if (urlData[3] === 'edit') {
            let query = {
                uid: urlData[4]
            }
            this._getServiceData(query)
        }
        this._getVehicleClassList()
        this._getUsersData()
    }
    _getTaxClassesListData = async () => {
        const result = await tS.fetchAll()
        if (result.status) {
            let taxClassData = []
            result.data.forEach(e => {
                taxClassData.push({
                    label: e.label,
                    value: e.amount
                })
            })
            this.setState({ taxClassData: taxClassData})
            if (result.data.length === 0)
                miS.showAlert({ type: 'error', msg: 'No tax-classes added yet!' })
        } else
            miS.showAlert({ type: 'error', msg: 'No tax-classes added yet!' })
    }
    _getVehicleClassList = async () => {
        const result = await cS.getVehicleClassList()
        if (result.status) {
            if (result.data.length === 0)
                miS.showAlert({ type: 'error', msg: 'No car manufacturers added yet!' })
            else
                this.setState({ vehicleClassList: result.data })
        }
    }
    _getUsersData = async () => {
        let companies = []
        const result = await uS.getUsers()
        if (result.status) {
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No companies added yet!' })
            } else {
                result.data.forEach(result => {
                    if (result.role === 'company')
                        companies.push({ label: result.name, value: result.uid })
                })
                this.setState({ companies })
            }
        }
    }
    _getServiceData = async query => {
        const result = await micS.getMicroService({ query: JSON.stringify(query) })
        if (result.status) {
            let microServiceData = result.data[0],
            metaKeyword = [],
            tag = [],
            categories = [],
            activeRobotsIndex = 0,
            activeAuthorIndex = 0,
            activeStatusIndex = 0
            robotsoptions.forEach(( e, i ) => {
                if (e.value === microServiceData.robots)
                    activeRobotsIndex = i
            })
            authoroptions.forEach(( e, i ) => {
                if (e.value === microServiceData.author)
                    activeAuthorIndex = i
            })
            statusOptions.forEach(( e, i ) => {
                if (e.value === microServiceData.status)
                    activeStatusIndex = i
            })
            if (microServiceData.metaKeyword === '""' || microServiceData.metaKeyword.includes('"\\"') || microServiceData.metaKeyword === null || microServiceData.metaKeyword === 'null') {
                metaKeyword = []
            } else {
                metaKeyword = JSON.parse(microServiceData.metaKeyword)
            }
            if (microServiceData.tag === '""' || microServiceData.tag.includes('"\\"') || microServiceData.tag === null || microServiceData.tag === 'null') {
                tag = []
            } else {
                tag = JSON.parse(microServiceData.tag)
            }
            if (microServiceData.categories === '""' || microServiceData.categories.includes('"\\"') || microServiceData.categories === null || microServiceData.categories === 'null') {
                categories = []
            } else {
                categories = JSON.parse(microServiceData.categories)
            }
            this.setState({
                title: microServiceData.title,
                mode: 'Edit',
                slug: microServiceData.slug,
                content: microServiceData.content,
                excerpt: microServiceData.excerpt,
                css: microServiceData.css,
                js: microServiceData.js,
                canonical: microServiceData.canonical,
                robots: microServiceData.robots,
                metaDescription: microServiceData.metaDescription,
                metaKeyword,
                author: microServiceData.author,
                publishDate: new Date(microServiceData.publishDate),
                status: microServiceData.status,
                categories,
                tag,
                serviceTime: microServiceData.serviceTime,
                sku: (microServiceData.sku)?microServiceData.sku: '',
                hsn: (microServiceData.hsn)?microServiceData.hsn: '',
                tax: (microServiceData.tax)?microServiceData.tax: '',
                isFeatured: microServiceData.isFeatured,
                inclusiveTax: microServiceData.inclusiveTax,
                uid: microServiceData.uid,
                userNote: microServiceData.userNote,
                image: microServiceData.image,
                icon: microServiceData.icon ? microServiceData.icon : '[]',
                activeRobotsIndex,
                activeAuthorIndex,
                activeStatusIndex,
                prices: JSON.parse(microServiceData.prices)
            })
            if (microServiceData.sku.includes("fuel-refill") && microServiceData.fuelPrices)
                this.setState({ fuelPrices: JSON.parse(microServiceData.fuelPrices) })
            if (microServiceData.status === 'private' && microServiceData.company)
                this.setState({ company: JSON.parse(microServiceData.company) })
        } else
            miS.showAlert({ type: 'error', msg: 'No services added yet!' })
    }
    _addService = async e => {
        e.preventDefault()
        let { fuelPrices, company, isFeatured, userNote, serviceTime, inclusiveTax, sku, tax, mode, hsn, title, slug, content, excerpt, css, js, canonical, robots, metaDescription, metaKeyword, prices, author, publishDate, status, categories, tag, uid, image, icon } = this.state,
        error = false,
        errorMsg = '',
        microserviceObject = {
            title,
            slug,
            content,
            excerpt,
            css,
            sku,
            hsn,
            tax,
            userNote,
            serviceTime,
            isFeatured,
            inclusiveTax,
            js,
            canonical,
            robots,
            metaDescription,
            metaKeyword: JSON.stringify(metaKeyword),
            author,
            publishDate,
            status: status,
            categories: JSON.stringify(categories),
            tag: JSON.stringify(tag),
            prices: JSON.stringify(prices),
            image,
            icon
        }
        if (sku.includes("fuel-refill") && fuelPrices) {
            microserviceObject.fuelPrices = JSON.stringify(fuelPrices)
        }
        if (status === 'private' && company) {
            microserviceObject.company = JSON.stringify(company)
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (slug === '') {
            errorMsg = 'Please enter the slug'
            error = true
        } else if (image === '') {
            errorMsg = 'Please select image'
            error = true
        } else if (icon === '') {
            errorMsg = 'Please select icon'
            error = true
        } else if (tax === '') {
            errorMsg = 'Please enter the tax'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                microserviceObject.uid = new Date().getTime()
                const result = await micS.addMicroService(microserviceObject)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Micro-service added successfully!' })
                    rS.navigateTo('/admin/microservice/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to add micro-service!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    microServiceData: JSON.stringify(microserviceObject)
                }
                const result = await micS.updateMicroService(data)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Micro-service updated successfully!' })
                    rS.navigateTo('/admin/microservice/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to update micro-service!' })
                }
            }
        } else miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _selectIcon = file => {
        let icons = document.getElementById('icon-input').value === '' ? JSON.stringify([]) : document.getElementById('icon-input').value
        if (!icons.includes(file)) {
            icons = JSON.parse(icons)
            icons.push(file)
        } else {
            icons = JSON.parse(icons)
            icons.pop(file)
        }
        this.setState({ icon:JSON.stringify(icons) })
    }
    _renderIcons = mediaFiles => {
        let { icon } = this.state
        return mediaFiles.map(( item, index ) => {
            return <li key={index} className={icon.includes(item.fileName)?"selected": ''}>
                <ion-icon name="checkmark-circle" class="select" onClick={() => this._selectIcon(item.fileName)}></ion-icon>
                <Img alt={item.fileName} src={base.imageBaseUrl + item.fileName} />
            </li>
        })
    }
    _renderCarList = carList => {
        let { activePrice, activeCar } = this.state
        return carList.map(( item, index ) => {
            let activeClass = ''
            if (activeCar !== null)
                activeClass = activeCar.slug === item.slug? 'active': ''
            if (item.slug === item.priceGroup)
                return <li key={index} className={activeClass} onClick={() => {
                    item.price = activePrice
                    this.setState({ activeCar: item, selectedCarList: false, showCarListBox: false })
                }}>
                    <Img alt={item.name} src={base.carImageBase + item.image} />
                    <p>{item.name}</p>
                </li>
            else return false
        })
    }
    _renderPriceList = priceList => {
        return priceList.map(( item, index ) => {
            return <li key={index}>
                <Img alt={item.name} src={base.carImageBase + item.image} />
                <p className="car-name">{item.name}</p>
                <p className="service-price">₹ {item.price}</p>
                <p className="service-price">₹ {item.saleprice}</p>
                <ion-icon name="trash-bin" class="remove" onClick={() => this._removePrice(item)}></ion-icon>
            </li>
        })
    }
    _removePrice = item => {
        let { prices } = this.state, newPrices = []
        prices = JSON.parse(prices)
        prices.forEach(e => {
            if (e.uid !== item.uid)
                newPrices.push(e)
        })
        this.setState({ prices: JSON.stringify(newPrices) })
    }
    _renderUploadedIcons = mediaFiles => {
        return mediaFiles.map(( item, index ) => {
            return <li key={index}>
                <ion-icon class="preview" onClick={() => this._preview(item)} name="eye"></ion-icon>
                <Img alt={item.fileName} src={base.iconBaseUrl + item} />
            </li>
        })
    }
    _getServiceCategoryData = async () => {
        let tableData = []
        const result = await micS.getMicroServiceCategory()
        if (result.data.length !== 0) {
            result.data.forEach(e => {
                tableData.push({label: e.catName, value: e.catSlug})
            })
            this.setState({ categoriesOptions: tableData })
        }
    }
    _showCarList = () => {
        let { showCarListBox } = this.state
        this.setState({ showCarListBox: !showCarListBox})
    }
    render() {
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname),
        { fuelPrices, company, companies, taxClassData, robots,status, searchKey, tableData, saleprice, showCarListBox, categoriesOptions, userNote, serviceTime, activeCar,isFeatured, inclusiveTax, sku, tax, hsn, activeFile, activePrice, mode, renderSlug, title, slug, content, excerpt, css, js, canonical, activeRobotsIndex, activeStatusIndex, metaDescription, metaKeyword, categories, tag, image, mediaFiles, prices, vehicleClassList } = this.state,
        serviceTitle = mode + ' MicroService'
        prices= JSON.parse(prices)
        let activeRobots = robotsoptions[activeRobotsIndex],
        activeStatus = statusOptions[activeStatusIndex]
        statusOptions.forEach(e => {
            if (e.value === status) {
                activeStatus = e
            }
        })
        robotsoptions.forEach(e => {
            if (e.value === robots) {
                activeRobots = e
            }
        })
        if (canonical === '') {
            canonical = mainUrl+ urlData[2]+'/'+slug
        }
        let selectedTaxClass = {}
        if (taxClassData.length>0 && tax === '') {
            selectedTaxClass = taxClassData[0]
        }
        taxClassData.forEach(e => {
            if (e.value === tax) {
                selectedTaxClass = e
            }
        })
        return <>
            <h1>
                <BackIcon backLink='/admin/microservice/list' />
                {serviceTitle}
            </h1>
            <form className="columns" noValidate onSubmit={this._addService}>
                <div className="left">
                    <Box title="Title" required>
                        <input autoFocus type="text" required name="title" value={title} onChange={e => {
                            let tempTitle = e.target.value, tempSlug = slug
                            if (renderSlug) {
                                tempSlug = miS.slugify(tempTitle)
                            }
                            this.setState({ title: tempTitle, slug: tempSlug })
                        }} />
                    </Box>
                    <Box title="Slug" required>
                        <input type="text" required name="slug" value={slug} onChange={(e) => this.setState({ slug: e.target.value })} />
                    </Box>
                    <Box title="SKU">
                        <input type="text" name="sku" value={sku} onChange={(e) => this.setState({ sku: e.target.value })} />
                    </Box>
                    <Box title="HSN Code">
                        <input type="text" name="hsn" value={hsn} onChange={(e) => this.setState({ hsn: e.target.value })} />
                    </Box>
                    <Box title="Content" required>
                        <Editor
                            apiKey={base.tinyMceApiKey}
                            init={base.tinyMceEditorOptions}
                            value={content}
                            onEditorChange={(content, editor) => {
                                this.setState({ content })
                            }}
                        />
                    </Box>
                    <Box title="User Note">
                        <Editor
                            apiKey={base.tinyMceApiKey}
                            initialValue={userNote}
                            init={base.tinyMceEditorOptions}
                            value={userNote}
                            onEditorChange={(userNote, editor) => {
                                this.setState({ userNote })
                            }}
                        />
                    </Box>
                    <Box title="Excerpt" required>
                        <Editor
                            apiKey={base.tinyMceApiKey}
                            init={base.tinyMceEditorOptions}
                            value={excerpt}
                            onEditorChange={(excerpt, editor) => {
                                this.setState({ excerpt })
                            }}
                        />
                    </Box>
                    {
                    !sku.includes("fuel-refill") ?
                    <Box title="Prices" required>
                        <div className="form-inline">
                            {
                                activeCar === null ?
                                <Button type="button" className="showCarList" onClick={this._showCarList}>Show Car List</Button>
                                :
                                <div className="selected-car-type" onClick={this._showCarList}>
                                    <Img alt={activeCar.name} src={base.carImageBase + activeCar.image} />
                                    <p>{activeCar.name}</p>
                                </div>
                            }
                            {
                                showCarListBox &&
                                <ul className="scroll-box">
                                    { this._renderCarList(vehicleClassList || []) }
                                </ul>
                            }
                            <input className="input" type="number" required name="activePrice" value={activePrice||''} placeholder="Enter MRP" onChange={e => {
                                this.setState({ activePrice: e.target.value })
                            }} />
                            <input className="input" type="number" required name="saleprice" value={saleprice||''} placeholder="Enter Sale Price" onChange={e => {
                                this.setState({ saleprice: e.target.value })
                            }} />
                            <span className="btn-icon" type="button" onClick={() => {
                                let car = activeCar
                                if (activePrice !== '' && activeCar !== null) {
                                    car.price = activePrice;
                                    car.saleprice = saleprice;
                                    car.uid = new Date().getTime()
                                    prices.push(car)
                                    this.setState({ prices: JSON.stringify(prices), activePrice: '', saleprice: '', activeCar: null })
                                }
                            }}><ion-icon name="add-circle-outline"></ion-icon></span>
                        </div>
                        {
                            prices.length !== 0 &&
                            <ul className="prices-list">
                                { this._renderPriceList(prices||[]) }
                            </ul>
                        }
                        <input type="text" className="hidden" required name="prices" value={JSON.stringify(prices)||'[]'} onChange={e => {
                            this.setState({ prices: e.target.value })
                        }} />
                    </Box>:
                    <Box title="Prices (Per lt.)">
                        <div className='form-group'>
                            <label>Petrol</label>
                            <input type="text" name="petrol" value={fuelPrices.petrol || ''} onChange={(e) => {
                                fuelPrices.petrol = e.target.value
                                this.setState({ fuelPrices })
                            }} />
                        </div>
                        <div className='form-group'>
                            <label>Diesel</label>
                            <input type="text" name="diesel" value={fuelPrices.diesel || ''} onChange={(e) => {
                                fuelPrices.diesel = e.target.value
                                this.setState({ fuelPrices })
                            }} />
                        </div>
                        <div className='form-group'>
                            <label>CNG</label>
                            <input type="text" name="cng" value={fuelPrices.cng || ''} onChange={(e) => {
                                fuelPrices.cng = e.target.value
                                this.setState({ fuelPrices })
                            }} />
                        </div>
                    </Box>
                    }
                    {status.value === 'private' && <>
                    <Box title="CSS">
                        <textarea className="description" placeholder=".selector {property:value}" required name="css" value={css} onChange={(e) => this.setState({ css: e.target.value })}></textarea>
                    </Box>
                    <Box title="JS">
                        <textarea className="description" placeholder="alert('Type your script here')" required name="js" value={js} onChange={(e) => this.setState({ js: e.target.value })}></textarea>
                    </Box>
                    <Box title="Canonical URL">
                        <input type="text" required name="canonical" value={canonical} onChange={(e) => this.setState({ canonical: e.target.value })} />
                    </Box>
                    <Box title="Robots">
                        <Select
                            value={activeRobots}
                            defaultValue={activeRobots}
                            onChange={e => this.setState({ robots: e.value })}
                            options={robotsoptions}
                        />
                    </Box>
                    <Box title="Meta Description">
                        <textarea placeholder="Enter meta description here" required name="meta descrition" value={metaDescription} onChange={(e) => this.setState({ metaDescription: e.target.value })}></textarea>
                    </Box>
                    <Box title="Meta Keywords">
                        <CreatableSelect
                            isMulti
                            onChange={e => this.setState({ metaKeyword: e })}
                            options={keywordOptions}
                            value={metaKeyword}
                        />
                    </Box>
                    </>}
                </div>
                <div className="right">
                    <Box title="">
                        <h4 className="head">Meta</h4>
                        <div className="body">
                            <p>
                                Render Slug:
                                <span className={renderSlug?"switch on":"switch off"} title="Click to Enable/Disable auto slug rendering" onClick={() => this.setState({ renderSlug:!renderSlug})}></span>
                            </p>
                            <Button className="btn-primary btn btn-yellow has-icon" type="submit" title="Click here to save">Save</Button>
                        </div>
                    </Box>
                    {
                        taxClassData.length > 0 &&
                        <Box title="Tax % (Tax Class)" required>
                            <Select
                                value={selectedTaxClass}
                                defaultValue={selectedTaxClass}
                                onChange={e => this.setState({ tax: e.value })}
                                options={taxClassData}
                            />
                        </Box>
                    }
                    <Box title="Service Time">
                        <input type="text" required name="serviceTime" min={0} value={serviceTime||''} onChange={(e) => this.setState({ serviceTime: e.target.value })} />
                    </Box>
                    <Box title="Publish Date">
                        <DatePicker
                            onChange={publishDate => this.setState({ publishDate })}
                            selected={this.state.publishDate}
                        />
                    </Box>
                    <Box title="Price Meta" required>
                        <span className="pointer isFeatured" onClick={() => this.setState({ inclusiveTax: !inclusiveTax})}>
                            {
                                inclusiveTax ?
                                <ion-icon class="icon-check orange" name="checkmark-circle-outline"></ion-icon>:
                                <ion-icon class=" icon-check" name="ellipse-outline"></ion-icon> 
                            }
                            Inclusive of all taxes </span> 
                    </Box>
                    <Box title="Featured">
                        <span className="pointer isFeatured" onClick={() => this.setState({ isFeatured: !isFeatured})}>
                            {
                                isFeatured ?
                                <ion-icon class="icon-check orange" name="checkmark-circle-outline"></ion-icon>:
                                <ion-icon class=" icon-check" name="ellipse-outline"></ion-icon> 
                            }
                            Is Featured </span>
                    </Box>
                    <Box title="Status">
                        <Select
                            value={activeStatus}
                            defaultValue={activeStatus}
                            onChange={e => this.setState({ status: e.value })}
                            options={statusOptions}
                        />
                    </Box>
                    {status === 'private' &&
                    <Box title="Company" required>
                        <Select
                            value={company}
                            defaultValue={company}
                            onChange={e => this.setState({ company: e })}
                            options={companies}
                        />
                    </Box>}
                    <Box title="Categories">
                        <Select
                            value={categories}
                            onChange={e => { this.setState({ categories: e }) }}
                            options={categoriesOptions}
                            isMulti
                        />
                    </Box>
                    <Box title="Tags">
                        <CreatableSelect
                            isMulti
                            value={tag}
                            onChange={e => this.setState({ tag: e })}
                            options={tagOptions}
                        />
                    </Box>
                    <MediaBox image={image} setimage={icon => this.setState({icon})} label='Add Icon' />
                </div>
            </form>
        </>
    }
}