import { base } from "../../constants/Data"
import {Button, Img} from '../../elements'
import {MiscService, RouterService, UserService} from "../../services"
const uS = new UserService(),
miS = new MiscService(),
rS = new RouterService()
const ProductBox = ({
    product
}) => {
    return <div className="grid-list-column-item">
        {
            product.isAssured && <Img className="assuredImg" src={base.imageBaseUrl+'assured.png'} alt={product.title} />
        }
        <div className="thumb">
            {
                product.image === '' || product.image === '[]' || typeof product.image === "undefined"?
                <Img src={base.imageBaseUrl+'product-placeholder.png'} alt={product.title} />:
                <Img className="pointer" onClick={() => rS.navigateTo('/product/'+product.slug)} src={base.imageBaseUrl+JSON.parse(product.image)[0]} alt={product.title} />
            }
        </div>
        <h5 className="title pt10"><a href={"/product/"+product.slug}>{product.title}</a></h5>
        {
            product.feedbacks && <div className="common-rating-style">
                { miS.renderRatingSystem(Math.ceil(product.averageRating)) }
            </div>
        }
        <div className="common-price-style">
            {
                typeof product.saleprice === "undefined" || product.saleprice === 0 || product.saleprice === '' ?
                <div className="mrp">
                    <span className="black">₹ {product.price}</span>
                </div>:
                <>
                    <div className="mrp">
                        <span className="black">₹ {product.saleprice}</span>
                    </div>
                    <div className="sale-price">
                        <span className="black">₹ {product.price}</span>
                    </div>
                    <div className="per-off">
                        <span className="green">{(( product.price - product.saleprice)/product.price * 100).toFixed(0)} % off</span>
                    </div>
                </>
            }
        </div>
        {(!uS.isUserLoggedIn() || (uS.isUserLoggedIn() && this.user?.role && !['manager', 'company', 'branch', 'representative'].includes(this.user?.role))) && <div className="main-btn-wrap pt20">
            {
                product.stock === 0 || product.stock === '0' || typeof product.stock === "undefined"?
                <Button type="button" disabled className="btn-gray btn add-to-cart mauto" title="Out Of Stock">Out Of Stock</Button>:
                <Button onClick={() => this._addToCart(product)} className="btn btn-primary btn-box mauto uppercase">Add to Cart</Button>
            }
        </div>}
    </div>
}

export default ProductBox