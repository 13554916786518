import React, { useEffect, useState } from 'react'
import {MiscService, MetaService, RouterService, UserService} from '../../../services'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import {Button} from '../../../elements'

const uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
const paidStatus = [
    'completed',
    'paid',
    'processing',
    'shipped',
    'delivered'
]

// fake data generator
const renderWidgets = (count, offset = 0) => {
    return Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k + offset}-${new Date().getTime()}`,
        content: `item ${k + offset}`
    }));
}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
};
const grid = 10;

const renderWidgetstyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    cursor: "move",
    // change background colour if dragging
    background: isDragging ? "lightgreen" : '#fff',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "transparent",
    padding: grid,
    width: '100%'
});

const AdminDashboard = () => {
    let user = uS.getLoggedInUserData()
    const [widgets, setwidgets] = useState([renderWidgets(10), renderWidgets(5, 10)]),
    [dashboardData, setdashboardData] = useState(null)

    useEffect(() => {
        mS.setTitle('Dashboard', 'admin')
        _getDashboardData()
    }, [])

    let registeredCustomers = 0,
    registeredCompanies = 0,
    registeredBranches = 0,
    totalPaymentReceived = 0,
    completedOrders = 0,
    pendingOrders = 0,
    awaitingProcessing = 0
    if (dashboardData !== null) {
        registeredCustomers = dashboardData.userData.reduce((t, i ) => {
            if (i.role === 'user') t++;
            return t
        }, 0)
        registeredCompanies = dashboardData.userData.reduce((t, i ) => {
            if (i.role === 'company') t++;
            return t
        }, 0)
        registeredBranches = dashboardData.userData.reduce((t, i ) => {
            if (i.role === 'branch') t++;
            return t
        }, 0)
        totalPaymentReceived = dashboardData.orderData.reduce((t, i ) => {
            if (paidStatus.includes(i.status) && typeof i.paymentData !== 'undefined')
                if (!isNaN(i.paymentData.TXNAMOUNT))
                    t += Number(i.paymentData.TXNAMOUNT)
            return t
        }, 0)
        completedOrders = dashboardData.orderData.reduce((t, i ) => {
            if (i.status === 'completed') t++;
            return t
        }, 0)
        pendingOrders = dashboardData.orderData.reduce((t, i ) => {
            if (i.status === 'initialised') t++;
            return t
        }, 0)
        awaitingProcessing = dashboardData.orderData.reduce((t, i ) => {
            if (i.status === 'paid') t++;
            return t
        }, 0)
    }

    const _getDashboardData = async () => {
        const result = await miS.getDashboardData()
        if (result.status)
            setdashboardData(result.data)
    }

    const onDragEnd = result => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        if (sInd === dInd) {
            const items = reorder(widgets[sInd], source.index, destination.index);
            const newState = [...widgets];
            newState[sInd] = items;
            setwidgets(newState);
        } else {
            const result = move(widgets[sInd], widgets[dInd], source, destination);
            const newState = [...widgets];
            newState[sInd] = result[sInd];
            newState[dInd] = result[dInd];
            setwidgets(newState.filter(group => group.length));
        }
    }

    const _openWidgetMonitor = () => {
        miS.openModal('widget-modal')
    }

    return <>
        <h1 className="title flex">
            Dashboard
            <Button className='btn btn-small btn-primary ml20' onClick={_openWidgetMonitor}>Add New Widget</Button>
        </h1>
        <div className="flex mv10 mh-10">
            <DragDropContext onDragEnd={onDragEnd}>
            {widgets.map((el, ind) => (
                <Droppable key={ind} droppableId={`${ind}`}>
                {(provided, snapshot) => (
                    <div
                        className='grid-area-section'
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                    >
                    {el.map((item, i) => (
                        <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={i}
                        >
                        {(provided, snapshot) => (
                            <div
                                className='section'
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={renderWidgetstyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                )}
                            >
                                {item.content}
                                <Button
                                    className="hi-close delete"
                                    type="button"
                                    onClick={() => {
                                        const newState = [...widgets];
                                        newState[ind].splice(i, 1);
                                        setwidgets(
                                            newState.filter(group => group.length)
                                        );
                                    }}
                                >
                                </Button>
                            </div>
                        )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                    </div>
                )}
                </Droppable>
            ))}
            </DragDropContext>
        </div>
        {
            user?.role && user.role === 'admin' && dashboardData !== null &&
            <>
                <div className="grid-area-section">
                    <div className="section pointer" onClick={() => rS.navigateTo('/admin/user/list#user')}>
                        <div className="body">
                            <div className="icon-section"> <i className='hi-user'></i></div>
                            <div className="description">
                                <div className="title">Customers</div>
                                <div className="description-stats">{registeredCustomers}</div>
                            </div>
                        </div>
                    </div>
                    <div className="section pointer" onClick={() => rS.navigateTo('/admin/user/list#company')}>
                        <div className="body">
                            <div className="icon-section"> <i className='hi-briefcase'></i></div>
                            <div className="description">
                                <div className="title">Companies</div>
                                <div className="description-stats">{registeredCompanies}</div>
                            </div>
                        </div>
                    </div>
                    <div className="section pointer" onClick={() => rS.navigateTo('/admin/user/list#branch')}>
                        <div className="body">
                            <div className="icon-section"> <ion-icon class="register-user" name="business-outline"></ion-icon></div>
                            <div className="description">
                                <div className="title">Branches</div>
                                <div className="description-stats">{registeredBranches}</div>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="body">
                            <div className="icon-section"><i className="hi-wallet wallet"></i></div>
                            <div className="description">
                                <div className="title">Payment Received</div>
                                <div className="description-stats">{miS.toIndianCurrency(totalPaymentReceived)}</div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="section pointer" onClick={() => rS.navigateTo('/admin/order/list/completed')}>
                        <div className="body">
                            <div className="icon-section"> <i className="hi-checkbox success"></i></div>
                            <div className="description">
                                <div className="title">Completed Orders</div>
                                <div className="description-stats">{completedOrders}</div>
                            </div>
                        </div>
                    </div>
                    <div className="section pointer" onClick={() => rS.navigateTo('/admin/order/list#initialised')}>
                        <div className="body">
                            <div className="icon-section"> <ion-icon class="pending-orders" name="alert-circle-outline"></ion-icon></div>
                            <div className="description">
                                <div className="title">Pending Orders</div>
                                <div className="description-stats">{pendingOrders}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="orders-frame mv20">
                    <div className="inside-box">
                        <ul className="inside">				
                            <li className="processing-orders" onClick={() => rS.navigateTo('/admin/order/list#paid')}>
                                <span className="flex">
                                    <div className="icon-box">
                                        <ion-icon class="user-icon" name="ellipsis-horizontal-circle-outline"></ion-icon>
                                    </div>
                                    <div className="name-title">
                                        <strong>{awaitingProcessing} paid orders</strong> awaiting processing
                                    </div>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        }
        <div className="modal widget-modal" id="widget-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content">
                <Button className="close" onClick={() => miS.closeModal("widget-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body">
                    <div className='flex'>
                        <Button className="btn btn-small btn-primary" onClick={() => setwidgets([...widgets, []])}>
                            Add new group
                        </Button>
                        <Button className="btn btn-small btn-primary ml20" onClick={() => setwidgets([...widgets, renderWidgets(1)])}>
                            Add new item
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default AdminDashboard