import React, {Component} from 'react'
import {TrashConfirmModal, Table} from '../../../components'
import {DataService, MiscService, RouterService, MetaService} from '../../../services'
import {Button, Actor} from '../../../elements'
const dS = new DataService('taxClass'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
export default class TaxClassList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableData: [],
            filteredList: [],
            checkedList: [],
            searchKey: '',
            activeFilterIndex: 0,
            showTrashConfirm: false,
            trashAction: false,
            isMultipleDelete: false,
            checkedAll: false,
            searchList: []
        }
    }
    componentDidMount () {
        mS.setTitle('TaxClasses List', 'admin')
        this._getTaxClassesListData()
    }
    _getTaxClassesListData = async () => {
        const result = await dS.fetchAll()
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(e => {
                tableData.push(e)
            })
            this.setState({ tableData, filteredList: tableData})
            if (result.data.length === 0)
                miS.showAlert({ type: 'error', msg: 'No tax-classes added yet!' })
        } else
            miS.showAlert({ type: 'error', msg: 'No tax-classes added yet!' })
    }
    _trashCancel = () => {
        this.setState({ activeItem: null, showTrashConfirm: false })
    }
    _deleteSelecthTaxClasse = () => {
        let { activeItem } = this.state
        this._deleteTaxClasse(activeItem.uid)
    }
    _deleteTaxClasse = async (uid, task = 'trash') => {
        let query = {
            uid
        }, { trashAction, tableData } = this.state
        if (trashAction && task === 'trash') {
            const result = await dS.delete({ query: JSON.stringify(query) })
            if (result.status) {
                this._getTaxClassesListData()
                miS.showAlert({ type: 'success', msg: 'TaxClasse deleted successfully!' })
                this.setState({ activeItem: null, showTrashConfirm: false }, this._getTaxClassesListData)
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to delete tax-class!' })
        } else {
            let taxClassObject = null
            tableData.forEach(e => {
                if (e.uid === uid)
                    taxClassObject = e
            })
            taxClassObject.trash = task === 'trash'
            delete taxClassObject._id
            let data = {
                query: JSON.stringify({uid}),
                taxClassData: JSON.stringify(taxClassObject)
            }
            const result = await dS.update(data)
            if (result.status) {
                miS.showAlert({ type: 'success', msg: 'TaxClasse '+task+'d successfully!' })
                this.setState({ activeItem: null, showTrashConfirm: false }, this._getTaxClassesListData)
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to '+task+' tax-class!' })
        }
    }
    _toggleCheck = uid => {
        let {checkedList, filteredList} = this.state, newCheckedList = [], found = false
        checkedList.forEach(e => {
            if (e === uid)
                found = true
            else
                newCheckedList.push(e)
        })
        if (!found)
            newCheckedList.push(uid)
        this.setState({ checkedAll: (filteredList.length === newCheckedList.length), checkedList: newCheckedList })
    }
    _deleteChecked = () => {
        let { checkedList, checkedAll, filteredList } = this.state
        if (checkedAll)
            checkedList = filteredList
        checkedList.forEach(e => {
            this._deleteTaxClasse(e.uid)
        })
        this.setState({ checkedList: [], isMultipleDelete: false })
    }
    _cloneTaxClasse = async item => {
        let taxClassObject = {
            label: item.label,
            className: item.className,
            amount: item.amount
        }
        taxClassObject.uid = new Date().getTime()
        const result = await dS.save(taxClassObject)
        if (result.status) {
            miS.showAlert({ type: 'success', msg: 'TaxClasse clonsed successfully!' })
            this._getTaxClassesListData()
        } else
            miS.showAlert({ type: 'error', msg: 'Unable to add tax-class!' })
    }
    _getTableData = filterData => {
        let data = [], { checkedAll, checkedList } = this.state
        filterData.map(( item, index ) => {
            let e = {
                checkAction: <span className="check-all" onClick={() => this._toggleCheck(item.uid)}>
                    {
                        checkedAll || checkedList.includes(item.uid) ?
                        <ion-icon class="orange" name="checkmark-circle-outline"></ion-icon>:<ion-icon name="ellipse-outline"></ion-icon>
                    }
                </span>,
                label: item.label,
                className: item.className,
                amount: item.amount,
                action: <div className="action-bar">
                    <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                    <Actor type='edit' onClick={() => rS.navigateTo('/admin/tax-class/edit/'+item.uid)} />
                    <Actor type='copy' onClick={() => this._cloneTaxClasse(item)} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    _search = () => {
        let { searchKey, tableData } = this.state,
        tempTitle = '', filteredList = []
        tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = e.title.toLowerCase()
            if (tempTitle.search(searchKey) !== -1) {
                filteredList.push(e)
            }
        })
        this.setState({ filteredList })
    }
    render() {
        let { showTrashConfirm, trashAction, checkedList, checkedAll, isMultipleDelete, filteredList, searchKey, activeShownTotal } = this.state
        let tableContent = {
            headers: [
                {
                    template: <span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll)
                            this.setState({ checkedAll: !checkedAll, checkedList: [] })
                        else {
                            filteredList.forEach(e => {
                                newCheckedList.push(e.uid)
                            })
                            this.setState({ checkedAll: !checkedAll, checkedList: newCheckedList })
                        }
                    }}>
                        {
                            checkedAll || checkedList.length === filteredList.length?
                            <ion-icon class="orange" name="checkmark-circle-outline"></ion-icon>:
                            <ion-icon name="ellipse-outline"></ion-icon>
                        }
                    </span>
                },
                {
                    label: 'Label',
                    key: 'label'
                },
                {
                    label: 'Class Name',
                    key: 'className'
                },
                {
                    label: 'Amount',
                    key: 'amount'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: this._getTableData(filteredList)
        }
        return <>
            <h1 className="title flex mb50">
                {
                    trashAction?'Trashed': 'All'
                } TaxClasses
                {
                    !trashAction && <>
                        <a className="btn btn-primary btn-small ml20" href="/admin/tax-class/add">Add</a>
                        {
                            (checkedList.length !== 0 || checkedAll) &&
                            <Button type="button" onClick={() => this.setState({ isMultipleDelete:true, showTrashConfirm: true})} className="btn btn-outline btn-small">Delete</Button>
                        }
                    </>
                }
            </h1>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <TrashConfirmModal trashOkAction={() => {
                isMultipleDelete?this._deleteChecked():this._deleteSelecthTaxClasse()
            }} trashCancelAction={this._trashCancel} showTrashConfirm={showTrashConfirm} isMultipleDelete={isMultipleDelete} />
        </>
    }
}