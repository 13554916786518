import React from 'react'
import 'chart.js/auto';
import { Line, Bar } from 'react-chartjs-2'
import 'chart.js'
import {MetaService} from '../../../services'
const Data = [[new Date(), 5], [1368174456, 4], ["2017-01-01 00:00:00 UTC", 7]],
mS = new MetaService()
const Settings = () => {
    mS.setTitle('Settings', 'admin')
    return <>
        <h1 className="title">Settings</h1>
        <div className="grid-area">
            <div className="section">
                <h4 className="head">
                    Title
                    <ion-icon name="chevron-down-circle-outline"></ion-icon>
                </h4>
                <div className="body">
                    <Line data={Data} />
                </div>
            </div>
            <div className="section">
                <h4 className="head">
                    Title
                    <ion-icon name="chevron-down-circle-outline"></ion-icon>
                </h4>
                <div className="body">
                    <Bar data={{"Blueberry": 44, "Strawberry": 23}} />
                </div>
            </div>
            <div className="section">
                <h4 className="head">
                    Title
                    <ion-icon name="chevron-down-circle-outline"></ion-icon>
                </h4>
                <div className="body">
                    <Bar data={[["Blueberry", 44], ["Strawberry", 23]]} />
                </div>
            </div>
            <div className="section">
                <h4 className="head">
                    Title
                    <ion-icon name="chevron-down-circle-outline"></ion-icon>
                </h4>
                <div className="body">
                    <Line data={Data} />
                </div>
            </div>
            <div className="section">
                <h4 className="head">
                    Title
                    <ion-icon name="chevron-down-circle-outline"></ion-icon>
                </h4>
                <div className="body">
                    <Line data={Data} />
                </div>
            </div>
            <div className="section">
                <h4 className="head">
                    Title
                    <ion-icon name="chevron-down-circle-outline"></ion-icon>
                </h4>
                <div className="body">
                    <Line data={Data} />
                </div>
            </div>
        </div>
    </>
}
export default Settings