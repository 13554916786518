import React, {Component} from 'react'
import {TrashConfirmModal, Table} from '../../../components'
import {Img, Button, Actor} from '../../../elements'
import { base } from '../../../constants/Data'
import {MiscService, CarService, MetaService, DataService} from '../../../services'

const meS = new DataService('media'),
mS = new MetaService(),
miS = new MiscService(),
cS = new CarService()
export default class CarModelManufacturerList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            uploaded: false,
            list: [],
            name: '',
            slug: '',
            activeItem: '',
            image: '',
            uid: '',
            showTrashConfirm: false,
            categories: ''
        }
    }
    componentDidMount () {
        mS.setTitle('Car Manufacturers', 'admin')
        this._getListData()
    }
    _uploadCall = async e => {
        e.preventDefault()
        let error = false, { image } = this.state,
        errorMsg = ''
        if (!error) {
            image.uid = new Date().getTime()
            const fileResult = await meS.save(image)
            if (fileResult.status) {
                this._addCarManufacturer()
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to update profile image!' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _getListData = async () => {
        const result = await cS.getCarManufacturers()
        if (result.status) {
            let list = []
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No Car Manufacturers added yet!' })
            } else {
                list = result.data.reverse()
            }
            this.setState({ list })
        }
    }
    _manufacturerCancel = () => {
        this.setState({ activeItem: null, showTrashConfirm: false })
    }
    _trashOne = async () => {
        let { activeItem } = this.state,
        query = {
            uid: activeItem.uid
        }
        const result = await cS.deleteCarManufacturer({ query: JSON.stringify(query) })
        if (result.status) {
            this._getListData()
            miS.showAlert({ type: 'success', msg: 'Car Manufacturer deleted successfully!' })
            this.setState({ activeItem: null, showTrashConfirm: false })
        } else
            miS.showAlert({ type: 'error', msg: 'Unable to delete Car Manufacturer!' })
    }
    _addCarManufacturer = async () => {
        let { name, slug, image } = this.state,
        error = false,
        errorMsg = '',
        manufacturerObject = {
            name,
            slug,
            image: image.fileName
        }
        if (name === '' || slug === '') {
            errorMsg = 'Please enter Car Manufacturer content!'
            error = true
        }
        if (!error) {
            manufacturerObject.uid = new Date().getTime()
            const result = await cS.addCarManufacturer(manufacturerObject)
            if (result.status)
                miS.showAlert({ type: 'success', msg: 'Car Manufacturer added successfully!' })
            else
                miS.showAlert({ type: 'error', msg: 'Unable to add Car Manufacturer!' })
            this.setState({ image: '', name: '', slug: '', uid: '' }, () => {
                miS.closeModal("manufacturer-modal")
                this._getListData()
            })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _getTableData = filterData => {
        let data = []
        filterData.forEach(( item, index ) => {
            let e = {
                name: <p className="query mb40">{item.name}</p>,
                slug: <p className="query mb40">{item.slug}</p>,
                image: <Img src={base.imageBaseUrl+item.image} />,
                action: <div className="action-bar" style={{bottom: 10}}>
                    <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    _selectFiles = async e => {
        let fileData = e.target.files[0]
        const result = await miS.getBase64(fileData)
        let image = {
            url: URL.createObjectURL(fileData),
            data: result,
            type: fileData.type,
            fileName: fileData.name,
            size: fileData.size
        }
        if (fileData.size <= 4000000)
            this.setState({ image, uploaded: true })
        else
            miS.showAlert({ type: 'error', msg: 'File is too large.' })
    }
    render() {
        let { uploaded, image, list, slug, name, showTrashConfirm, activeShownTotal } = this.state
        let tableContent = {
            headers: [
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Slug',
                    key: 'slug'
                },
                {
                    label: 'Image',
                    key: 'image'
                },
                {
                    label: 'Action',
                    key: 'action'
                }
            ],
            content: this._getTableData(list)
        }
        return <>
            <h1 className="title flex">Car Manufacturers <Button onClick={() => {
                this.setState({ mode: 'Add' }, () => miS.openModal("manufacturer-modal"))
            }} className="btn btn-primary btn-yellow btn-small ml20">Add</Button></h1>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <TrashConfirmModal trashOkAction={this._trashOne} trashCancelAction={this._manufacturerCancel} showTrashConfirm={showTrashConfirm} />
            <div className="modal manufacturer-modal" id="manufacturer-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content small">
                    <Button className="close" onClick={() => 
                    this.setState({ name: '', slug: '', uid: '' }, () => miS.closeModal("manufacturer-modal"))}>
                    <i className="hi-close"></i></Button>
                    <div className="modal-body">
                        <div className="manufacturer-modal-header">
                            <div className="modal-header p0">
                                <div className="heading">Add Manufacturer</div>
                                <form noValidate onSubmit={this._uploadCall}> 
                                    <div className="form-group mb40">
                                        <input type="text" className="input" placeholder="Please enter a Car Manufacturer Name" required name="name" value={name}
                                        onChange={e => {
                                            let tempTitle = e.target.value, slug = miS.slugify(tempTitle)
                                            this.setState({ name: tempTitle, slug })
                                        }} />
                                        <label>Name</label>
                                        <small className="msg text-danger">Please enter a Car Manufacturer</small>
                                    </div>
                                    <div className="form-group mb40">
                                        <input type="text" className="input" placeholder="Please enter slug" required name="slug" value={slug} onChange={(e) => this.setState({ slug: e.target.value }) } />
                                        <label>Slug</label>
                                        <small className="msg text-danger">Please enter slug</small>
                                    </div>
                                    { image !== '' && <div className="form-group mb40">
                                        <label>Image</label>
                                        <Img className='input-img' src={ uploaded ? image.url : base.imageBaseUrl+image} />
                                    </div>}
                                    <div className="form-group mb40">
                                        <label htmlFor="choose-file" className="btn btn-blank btn-small">Choose Files</label>
                                        <div className='clearfix'></div>
                                        <input type="text" className="hidden" placeholder="Please select image" required name="image" value={image} onChange={(e) => this.setState({ image: e.target.value }) } />
                                        <input type="file" className="hidden" onChange={this._selectFiles} multiple id="choose-file" accept="image/x-png,image/gif,image/jpeg,image/webp" />
                                    </div>
                                    <div className="buttons">
                                        <Button type="submit" title="Click here to Add Car Manufacturer" className="btn btn-primary btn-small">Submit</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}