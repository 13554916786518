import React from 'react'
import { base } from '../../constants/Data'
import {RouterService, UserService} from '../../services'
const uS = new UserService(),
rS = new RouterService()
const TopNavbarBanner = props => {
    return <div className="top-nav-bar-banner">
        <div className="main-box jumbo-container" style={{backgroundImage: `url("${base.imageBaseUrl}car-icon.png")`}}>
            <div className="text-banner">
                <h1>10%</h1>
                <div className="right-content">
                    <label>Discount</label>
                    <p>On Quarterly Package Subscriptions</p>
                </div>
            </div>
            {( !uS.isUserLoggedIn() || !['manager', 'company', 'branch', 'representative'].includes(uS.getLoggedInUserData().role)) && <div className="shop-now-btn">
                <button onClick={()=> {
                    props.onClick()
                    rS.navigateTo('/packages')
                }} className="btn btn-primary">Avail Now</button>
            </div>}
        </div>
    </div>
}
export default TopNavbarBanner