import React, {Component} from 'react'
import {LocationService, MiscService, UserService, MetaService} from '../../../services'
import {Button} from '../../../elements'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import {activeDateFilters} from '../../../constants/Enums'
const animatedComponents = makeAnimated(),
uS = new UserService(),
mS = new MetaService(),
locS = new LocationService(),
miS = new MiscService()
export default class ManageAddress extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state = {
            activeDateFilter: activeDateFilters[0],
            activeBranches: [],
            branches: [],
            addressList: []
        }
    }
    componentDidMount () {
        mS.setTitle('Manage Address', 'public')
        let orgId = false
        if (this.user?.role === 'representative') {
            orgId = this.user.parent?.value
            this._getCompanyBranches(orgId)
        } else if (this.user?.role === 'company') {
            orgId = this.user.uid
            this._getCompanyBranches(orgId)
        } else {
            this._getLocationsData()
        }
    }
    _getDashboardData = (activeDateFilter, activeBranches) => {
        let activeBranchIds = []
        activeBranches?.forEach( e => {
            activeBranchIds.push(e.value)
        })
        this._getLocationsData(activeBranchIds)
    }
    _getCompanyBranches = async v => {
        let query = {
            parentId: v
        }, branches = [], { activeDateFilter } = this.state
        const result = await uS.getCompanyBranches({ query: JSON.stringify(query) })
        if (result.status) {
            result.data.forEach(e => {
                branches.push({
                    label: e.name,
                    value: e.uid
                })
            });
            let activeBranches = uS.getActiveBranches() === null ? uS.getActiveBranches() : branches
            this.setState({ branches, activeBranches }, () => {
                uS.setGlobalActiveBranches(activeBranches)
                this._getDashboardData(activeDateFilter, activeBranches)
            })
        }
    }
    _getLocationsData = async (branches = false) => {
        let query = {
            ownerId: ['manager','representative'].includes(this.user.role) ? this.user.parent?.value : this.user.uid
        }
        if (branches) {
            query.ownerId = {
                $in: branches
            }
        }
        const result = await locS.getLocations({ query: JSON.stringify(query) })
        if (result.status)
            this.setState({ addressList: result.data })
    }
    _trashLocation = async locationData => {
        let query = {
            uid: locationData.uid
        }
        const result = await locS.deleteLocation({ query: JSON.stringify(query) })
        if (result.status) {
            this._getLocationsData()
            miS.showAlert({ type: 'success', msg: 'Location deleted successfully!' })
        } else
            miS.showAlert({ type: 'error', msg: 'Unable to delete location!' })
    }
    _renderAddressList = addressFiles => {
        return addressFiles.map(( item, index ) => {
            return <div className="form-box" key={index}>
                <div className="account-detail service-single-item locationlistdetail">
                    <div className="service-content">
                        <div className="item-number">{index>9?(index+1): '0'+(index+1)}</div>
                    </div>
                    <div className="form-group col">
                        <label className="control-label initial">Address</label>
                        <p>{miS.parseAddress(item)}</p>
                    </div>
                    <div className="row">
                        <div className="form-group col">
                            <label className="control-label initial">Landmark </label>
                            <p>{item.landmark}</p>
                        </div>
                        <div className="form-group col">
                            <label className="control-label initial">Alternate Phone No. </label>
                            <p>{item.alternateNo}</p>
                        </div>
                    </div>
                    { !['company', 'representative'].includes(this.user.role) && <div className="flex">
                        <a className="btn btn-outline btn-box" href={"/address/edit/"+item.uid}>Edit</a>
                        <Button type="button" className="btn btn-primary btn-box ml20" onClick={() => this._trashLocation(item)}>Remove</Button>
                    </div>}
                </div>
            </div>
        })
    }
    render() {
        let { addressList, branches, activeBranches, activeDateFilter } = this.state
        return <>
            <div className="right-side-profile-content">
                <div className='flex overflow-visible'>
                    <h1 className="nowrap">
                        <span className='mr20'>Manage Address</span>
                        {
                            !['company', 'representative'].includes(this.user.role) && ((addressList.length < 1 && ['manager', 'branch'].includes(this.user?.role)) || !['manager', 'branch'].includes(this.user?.role)) && <a className="btn btn-primary btn-yellow btn-small locationbtn" href="/address/add">Add</a>
                        }
                    </h1>
                    {
                        ['company', 'representative'].includes(this.user.role) && branches.length > 0 &&
                        <Select
                            isClearable
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            placeholder='Select Company'
                            defaultValue={activeBranches}
                            onChange={e => {
                                this.setState({ activeBranches: e }, () => {
                                    uS.setGlobalActiveBranches(e)
                                    this._getDashboardData(activeDateFilter, e)
                                })
                            }}
                            options={branches}
                            isMulti
                        />
                    }
                </div>
                <div className="profile-sec wrapper">
                    <div className="form-editable-section">
                        { this._renderAddressList(addressList || []) }
                    </div>
                </div>
            </div>
        </>
    }
}