import React, {Component} from 'react'
import {Banner} from '../../../widgets'
import {RouterService, OrderService, MetaService} from '../../../services'
import {base} from '../../../constants/Data'
import {Img, Button} from '../../../elements'
import {Table} from '../../../components'
const mS = new MetaService(),
rS = new RouterService(),
oS = new OrderService()
export default class Cart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cartData: [],
            cartTotal: 0
        }
    }
    componentDidMount () {
        mS.setTitle('Cart', 'public')
        this._getCartData()
    }
    _getCartData = () => {
        let cartData = oS.getCartData(),
        cartTotal = 0, serviceTotal = 0, productTotal = 0
        cartData.forEach(e => {
            cartTotal += Number(e.displayPrice * e.purchaseQuantity)
            if (e.type === "service") {
                serviceTotal += Number(e.displayPrice * e.purchaseQuantity)
            } else {
                productTotal += Number(e.displayPrice * e.purchaseQuantity)
            }
        })
        this.setState({ cartData, cartTotal, serviceTotal, productTotal })
    }
    _removeProduct = async item => {
        await oS.removeFromCart(item)
        this._getCartData()
    }
    _getTableData = filterData => {
        let data = []
        filterData.map(( item, index ) => {
            let e = {
                action: <span className="product-remove" onClick={() => {this._removeProduct(item)}}>×</span>,
                image: <div className="product-thumbnail">
                    {
                        item.type === 'servicePackage'?
                        <ion-icon name="cube" class="service-package-icon"></ion-icon>:
                        item.type === 'subscription'?
                        <ion-icon name="ribbon-outline" class="service-package-icon"></ion-icon>:
                        <Img src={base.imageBaseUrl+item.image} alt={item.title} />
                    }
                </div>,
                title: item.title,
                price: typeof item.saleprice === "undefined" || item.saleprice === 0 || item.saleprice === '' || item.saleprice === item.mrp ?
                    <div className="mrp">
                        <p className="black m0">₹ {Number(item.price).toFixed(2)} </p>
                        <p className="gray fs10 mt-5 mb0"> + ₹ {Number(item.taxAmount).toFixed(2)} (tax)</p>
                    </div>:
                    <>
                        <div className="mrp">
                            <p className="black m0">₹ {Number(item.price).toFixed(2)} </p>
                            <p className="gray fs10 mt-5 mb0"> + ₹ {Number(item.taxAmount).toFixed(2)} (tax)</p>
                        </div>
                        <div className="sale-price">
                            <p className="black">₹ {Number(item.mrp).toFixed(2)} </p>
                        </div>
                        <div className="per-off">
                            <p className="green m0">{((Number(item.mrp) - Number(item.saleprice))/Number(item.mrp) * 100).toFixed(0)} % off</p>
                        </div>
                    </>,
                purchaseQuantity: <div className="quantity">
                    {item.purchaseQuantity}
                </div>,
                displayPrice: `₹ ${(item.displayPrice * item.purchaseQuantity).toFixed(2)}`
            }
            data.push(e)
        })
        return data
    }
    _proceedToCheckOut = () => {
        let { cartData, cartTotal, serviceTotal, productTotal } = this.state
        oS.createOrderData({
            cartData,
            cartTotal,
            serviceTotal,
            productTotal
        })
        setTimeout(() => {
            rS.navigateTo('/checkout')
        }, 1000)
    }
    render() {
        let { cartData, cartTotal, serviceTotal, productTotal } = this.state
        let cartDataContent = {
            headers: [
                {
                    template: <div className="product-remove"></div>
                },
                {
                    template: <div className="product-thumbnail">Preview</div>
                },
                {
                    template: <div className="product-name">Product</div>
                },
                {
                    template: <div className="product-price">Price</div>
                },
                {
                    template: <div className="product-quantity">Quantity</div>
                },
                {
                    template: <div className="product-subtotal">Total</div>
                }
            ],
            content: this._getTableData(cartData),
            addonBodyContent: <tr>
                <td colSpan="6" className="actions">
                    <Button className="btn mlauto btn-primary" onClick={() => {
                        oS.emptyCart()
                        cartData=[]
                        this.setState({ cartData }, () => {
                            rS.getLocationData().reload()
                        })
                    }}><ion-icon name="trash-bin"></ion-icon>Empty Cart</Button>
                </td>
            </tr>
        }
        let cartTotalContent = {
            content: [],
            addonBodyContent: <>
                {
                    serviceTotal > 0 &&
                    <tr>
                        <th>Service Total</th>
                        <td className="priceProductPage">₹ {serviceTotal.toFixed(2)}</td>
                    </tr>
                }
                {
                    productTotal > 0 &&
                    <tr>
                        <th>Product Total</th>
                        <td className="priceProductPage">₹ {productTotal.toFixed(2)}</td>
                    </tr>
                }
                <tr>
                    <th><strong>Total</strong></th>
                    <td className="priceProductPage"><strong>₹ {cartTotal.toFixed(2)}</strong></td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <div className="wc-proceed-to-checkout">
                            <Button className="btn-block btn btn-primary wc-forward" onClick={this._proceedToCheckOut}>Proceed to checkout</Button>
                        </div>
                    </td>
                </tr>
            </>
        }
        return <>
            <Banner title='Cart' image="blue-road-1.jpg" />
            <div className="cart-page">
                <section className="jumbo-container">
                    <div className="product-details">
                        {
                            cartTotal !== 0?
                            <div className="cart-form">
                                <Table
                                    data={cartDataContent}
                                />
                            </div>:
                            <div className="empty-cart">
                                <Img src={require('../../../../assets/images/emptycart.png')} alt="empty-cart" />
                                <p className="tac">No items in the cart yet!</p>
                                <Button className="btn-primary btn mauto mv20">Back to Shop</Button>
                            </div>
                        }
                    </div>
                    {
                        cartTotal !== 0 &&
                        <div className="calculate-cart-feild">
                            <div className="cart-total mlauto">
                                <div className="cart_totals">
                                    <h4 className="box-header">Cart Totals</h4>
                                    <div className="clearfix mt20">
                                        <Table
                                            data={cartTotalContent}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </section>
            </div>
        </>
    }
}