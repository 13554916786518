import React, {Component} from 'react'
import {base} from '../../../constants/Data'
import {CSVLink} from 'react-csv'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import {Img, Button, Actor} from '../../../elements'
import {Table} from '../../../components'
import {OrderService, MiscService, RouterService, MetaService, ValidationService} from '../../../services'
const commonPageSize = 50
const filterTags = require('./filterTags.json'),
mS = new MetaService(),
miS = new MiscService(),
vS = new ValidationService(),
rS = new RouterService(),
oS = new OrderService()
export default class ProductOrders extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeFilterIndex: 0,
            tableData: [],
            activeItem: null,
            showTrashConfirm: false,
            startDate: '',
            endDate: '',
            searchKey: '',
            activeShownTotal: commonPageSize,
            filterTags: [{
                id: '1',
                label: 'All',
                value: 'all'
            }],
            filteredList: [],
            searchList: [],
            activeFilter: null
        }
    }
    componentDidMount () {
        mS.setTitle('Package Orders', 'admin')
        let {filterTags} = this.state
        this.setState({ activeFilter: filterTags[0] })
        this._getOrdersData()
        document.getElementById('content-box').addEventListener('scroll', this._trackScrolling)
    }
    componentWillUnmount() {
        document.getElementById('content-box').removeEventListener('scroll', this._trackScrolling)
    }
    _trackScrolling = () => {
        let { filteredList, activeShownTotal } = this.state,
        wrappedElement = document.getElementById('list-wrapper')
        if (this._isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                this._showMore()
        }
    }
    _isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    _showMore = () => {
        let { activeShownTotal } = this.state
        miS.showPreloader()
        setTimeout(() => {
            miS.hidePreloader()
            this.setState({ activeShownTotal: activeShownTotal + commonPageSize})
        }, 300)
    }
    _getOrdersData = async () => {
        let { activeFilterIndex } = this.state
        const result = await oS.getOrders()
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach((item, index) => {
                if (item.status !== 'initialised' && item.status !== 'payment-fail') {
                    let orderData = JSON.parse(item.orderData), packagesData = [], unpgradedPackages = [], newPackagesData = []
                    orderData.cartData.forEach(e => {
                        if (e.type === 'servicePackage') {
                            e.orderId = item.uid
                            e.orderData = item
                            if (e.previousPackageId) {
                                unpgradedPackages.push(e)
                            } else {
                                packagesData.push(e)
                            }
                            packagesData.forEach(el => {
                                unpgradedPackages.forEach(e => {
                                    if (e.previousPackageId === String(el.tempUid))
                                        el.title = e.title
                                })
                                newPackagesData.push(el)
                            })
                        }
                    })
                    tableData = [...tableData, ...newPackagesData]
                }
            })
            this.setState({ tableData, filteredList: tableData }, () => {
                let activefilterValue = 'all'
                filterTags.forEach(e => {
                    if (Number(e.id) === activeFilterIndex+1) {
                        activefilterValue = e.value
                    }
                })
                this._filterItems( activefilterValue, activeFilterIndex)
            })
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No orders placed yet!' })
            }
        } else
            miS.showAlert({ type: 'error', msg: 'No orders placed yet!' })
    }
    _getTableData = filterData => {
        let data = [], { startDate, endDate } = this.state
        filterData.forEach(( item, index ) => {
            let orderTime = item?.orderData?.timing
            let startTime = startDate === ''?'':new Date(startDate)
            let endTime = endDate === ''?new Date():new Date(endDate)
            if (orderTime >= startTime && orderTime <= endTime) {
                let e = {
                    type: <div className="product-thumbnail">
                        {
                            item.type === 'servicePackage'?
                            <ion-icon name="cube" class="service-package-icon"></ion-icon>:
                            item.type === 'subscription'?
                            <ion-icon name="ribbon-outline" class="service-package-icon"></ion-icon>:
                            <Img src={base.imageBaseUrl+item.image} alt={item.title} />
                        }
                    </div>,
                    title: <div className="product-name" data-title="Product">
                        {item.title}
                    </div>,
                    price: <div className="product-price" data-title="Price">
                        <span className="Price-amount amount">₹ {Number(item.price).toFixed(2)}</span>
                    </div>,
                    orderId: '#'+item.orderId,
                    name: JSON.parse(item.orderData.userData).name,
                    startDate: miS.getFormattedDate(new Date(item.startDate)),
                    endDate: miS.getFormattedDate(new Date(item.endDate)),
                    action: <div className="action-bar">
                        <Actor type='view' onClick={() => rS.navigateTo('/admin/package/order/view/' + btoa(item.orderId + '/' + item.tempUid), true)} />
                    </div>
                }
                data.push(e)
            }
        })
        return data
    }
    _filterItems = ( key, activeFilterIndex ) => {
        let { tableData } = this.state, filteredList = []
        if (key === 'all')
            filteredList = tableData
        else {
            filteredList = tableData.filter(e => {
                return e.status === key
            })
        }
        this.setState({ filteredList, activeFilterIndex: activeFilterIndex })
    }
    _renderFilterTags = filterData => {
        let { activeFilterIndex, tableData } = this.state
        return filterData.map(( item, index ) => {
            return <span className={index === activeFilterIndex ? 'link active' : 'link item'} key={index} onClick={() => this._filterItems(item.key, index)}>{item.item} ({
            (item.key === 'all') ? tableData.length :
                tableData.reduce(( count, e) => {
                    return item.key === e.status ? count + 1 : count
                }, 0) })
            </span>
        })
    }
    _search = () => {
        let { searchKey, tableData } = this.state,
        filteredList = [], tempTitle = ''
        tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = vS.isValidJson(e.orderData.userData) ? (JSON.parse(e.orderData.userData).name).toLowerCase() : ''
            if (e.uid.search(searchKey) !== -1 || tempTitle.search(searchKey) !== -1)
                filteredList.push(e)
        })
        this.setState({ filteredList, activeFilterIndex: 0 })
    }
    render() {
        let { activeFilter, tableData, filteredList, searchKey, startDate, endDate , activeShownTotal} = this.state,
        exportedData = []
        filteredList.forEach(e => {
            let orderData = e.orderData
            if (!orderData.discount) {
                orderData.discount = 0
            }
            if (typeof orderData.cartTotal !== "number")
                orderData.cartTotal = Number(orderData.cartTotal)
            if (typeof orderData.discount !== "number")
                orderData.discount = Number(orderData.discount)
            if (vS.isValidJson(orderData.orderData)) {
                JSON.parse(orderData.orderData).cartData?.forEach(( c, i ) => {
                    c.tax = Number(c.tax);
                    c.price = Number(c.price).toFixed(2)
                    c.taxAmount = (c.tax * c.price/(100+c.tax)).toFixed(2)
                    if (c.type === 'servicePackage' && c.status !== 'initialised' && c.status !== 'payment-fail') {
                        let ex = {
                            Customer_Id: (i === 0) ? JSON.parse(e.orderData.userData).uid: '',
                            Order_Id: (i === 0) ? e.uid: '',
                            Customer_Name: (i === 0) ? JSON.parse(e.orderData.userData).name: '',
                            Mobile_No: (i === 0) ? JSON.parse(e.orderData.userData).mobile: '',
                            Address: (e.activeAddress && e.activeAddress !== "undefined") ?((i === 0) ? miS.parseAddress(JSON.parse(e.activeAddress)): ''): '',
                            Pin_Code: (e.activeAddress && e.activeAddress !== "undefined")? ((i === 0) ? JSON.parse(e.activeAddress).pinCode: ''): '',
                            Status: (i === 0) ? e.status: '',
                            Vehicle_Details: c.activeCar ? c.activeCar.name + ' ' + c.activeCar.Segment: '',
                            Vehicle_No: c.activeCar ? c.activeCar.registrationNo?c.activeCar.registrationNo: '': '',
                            Package: c.type === 'servicePackage'?c.title: '',
                            Package_Frequency: c.billing? c.billing: '',
                            Start_Date: miS.getFormattedDate(new Date(c.startDate)),
                            End_Date: miS.getFormattedDate(new Date(c.endDate))
                        }
                        exportedData.push(ex)
                    }
                })
            }
        })
        let exportedFileName = 'export_' + new Date().getDate() + '_' + new Date().getMonth() + '_' + new Date().getFullYear() + '.csv'
        let tableContent = {
            headers: [
                {
                    template: <div className="product-thumbnail">Preview</div>
                },
                {
                    template: <div className="product-name">Product</div>
                },
                {
                    template: <div className="product-price">Price</div>
                },
                {
                    label: 'Order Id',
                    key: 'orderId'
                },
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Start Date',
                    key: 'startDate'
                },
                {
                    label: 'End Date',
                    key: 'endDate'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: this._getTableData(filteredList)
        }
        return <>
            <h1 className="flex title">Package Orders
            {
                filteredList.length !== 0 &&
                <span className="btn btn-primary btn-small ml20 export-btn">
                    <CSVLink data={exportedData} filename={exportedFileName} className="btn export-btn" target="_blank">Export</CSVLink>
                </span>
            }
            </h1>
            <div className="filter-box">
                <div className="flex date-filter overflow-visible">
                    <div className="form-group">
                        <DatePicker
                            selected={startDate}
                            onChange={startDate => this.setState({ startDate })}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            className="datePicker-filter"
                            placeholderText="From:"
                        />
                    </div>
                    <div className="form-group">
                        <DatePicker
                            selected={endDate}
                            onChange={endDate => this.setState({ endDate })}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            className="datePicker-filter"
                            placeholderText="To:"
                        />
                    </div>
                    <div className="form-group ml10">
                        <Select
                            value={activeFilter}
                            defaultValue={activeFilter}
                            onChange={(e, i) => this.setState({ activeFilter: e }, () => {
                                this._filterItems(e.value, i)
                            })}
                            options={filterTags}
                        />
                    </div>
                </div>
                <div className="search-box mlauto">
                    <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                        const reg = /^[A-Za-z0-9 ]+$/
                        if (e.target.value === '' || reg.test(e.target.value))
                            this.setState({ searchKey: e.target.value }, this._search)
                    }} />
                    <Button type="button" onClick={() => this.setState({ filteredList: tableData, searchKey: '' }, this._search)}><ion-icon name="close"></ion-icon></Button>
                </div>
            </div>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
        </>
    }
}