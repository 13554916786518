import React, { useEffect, useState } from 'react'
import './style.scss';
const Img = ({
    src,
    hasZoom = false,
    style,
    alt = '',
    title = '',
    className = ''
}) => {
    const [zoom, setzoom] = useState(false)
    let [imageSrc, setimageSrc] = useState('/blank.png')
    useEffect(() => {
        setimageSrc(src)
    }, [src])
    if (hasZoom)
    return <>
        <picture className='picture' style={style}>
            <span className='preview-icon' onClick={() => setzoom(true)}>
                <ion-icon name="eye-outline"></ion-icon>
            </span>
            <img className={className} alt={alt} style={style} title={title} src={imageSrc} />
        </picture>
        {
            zoom &&
            <div className='zoomed'>
                <span className='close-icon' onClick={() => setzoom(false)}>
                    <i className="hi-close"></i>
                </span>
                <img className={className} alt={alt} style={style} title={title} src={imageSrc} />
            </div>
        }
    </>
    return <img className={className} alt={alt} style={style} title={title} src={imageSrc} />
}
export default Img