import React, {Component} from 'react'
import Slider from 'react-slick'
import {DataService, MiscService, ServiceService} from '../../services'
import {base} from '../../constants/Data'
import {Img} from '../../elements'
const dS = new DataService('setting'),
sbS = new DataService('subscription'),
sS = new ServiceService(),
miS = new MiscService()
export default class HomeSlider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            serviceSliderList: null,
            subscriptionData: null,
            banners: []
        }
    }
    componentDidMount () {
        this._getWebBanners()
        this._getServicesData()
        this._getSubscriptionData()
    }
    _getWebBanners = async () => {
        let query = {
            type: 'web-banner'
        }
        const result = await dS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            let serviceData = result.data[0]
            if(serviceData?.image) {
                this.setState({ banners: JSON.parse(serviceData.image) })
            }
        } else
            miS.showAlert({ type: 'error', msg: 'No banners added yet!' })
    }
    _getSubscriptionData = async () => {
        const result = await sbS.fetchAll()
        if (result.status) {
            this.setState({ subscriptionData: result.data })
        }
    }
    _getServicesData = async () => {
        const result = await sS.getServices()
        if (result.status) {
            this.setState({ serviceSliderList: result.data })
        }
    }
    _renderBanners = banners => {
        return banners.map(( item, index ) => {
            return <Img className="box-banner" key={index} src={base.imageBaseUrl+item} alt={item} />
        })
    }
    render() {
        let { serviceSliderList, subscriptionData, banners } = this.state,
        settings = {
            dots: false,
            infinite: true,
            speed: 500,
            arrows: true,
            slidesToShow: 1,
            pauseOnHover:false,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 10000
        }
        return <div className="slider-items mb60-mbs40">
            {
                serviceSliderList !== null && subscriptionData !== null &&
                <Slider {...settings}>
                    {miS.isModuleEnabled(['Car']) && <div className="view-top-counterup" tabIndex="0">
                        <div className="counterup-wrap">
                            <div className="spinlines-first">
                                <p className="name1">Car Wash</p>
                                <p className="name2">Deodorization</p>
                                <p className="name3">Sanitization</p>
                                <p className="name4">Jump Start</p>
                                <p className="name5">Insect Removing</p>
                                <p className="name6">Battery Care</p>
                                <p className="name7">Clean & Non toxic</p>
                                <p className="name8">Car Vacuuming</p>
                            </div>
                            <div className="spinlines-second">
                                <p className="name9">Wheel Care</p>
                                <p className="name10">Interior Dusting</p>
                                <p className="name11">Odour Cleaning</p>
                                <p className="name12">Bacteria Free</p>
                                <p className="name14">Fluid Check</p>
                                <p className="name15">Sanitization</p>
                                <p className="name16">Deep Car Cleaning</p>
                                <p className="name17">Battery Care</p>
                            </div>
                        </div>
                    </div>}
                    { this._renderBanners(banners||[]) }
                </Slider>
            }
        </div>
    }
}