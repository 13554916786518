const SettingModel = [
    {
        key: 'type',
        dataType: String
    },
    {
        key: 'image',
        dataType: String
    },
    {
        key: 'uid',
        dataType: String
    }
]
export default SettingModel