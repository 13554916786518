import React, {Component} from 'react'
import {MiscService, DataService, MetaService} from '../../../services'
import {Table} from '../../../components'
const dS = new DataService('feedback'),
mS = new MetaService(),
miS = new MiscService()
const commonPageSize = 50
export default class FeedbackOrders extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeShownTotal: commonPageSize,
            feedbackList: [],
            filteredList: []
        }
    }
    componentDidMount () {
        mS.setTitle('Order Feedbacks', 'admin')
        this._getFeedbacksData()
        document.getElementById('content-box').addEventListener('scroll', this._trackScrolling)
    }
    componentWillUnmount() {
        document.getElementById('content-box').removeEventListener('scroll', this._trackScrolling)
    }
    _trackScrolling = () => {
        let { filteredList, activeShownTotal } = this.state,
        wrappedElement = document.getElementById('list-wrapper')
        if (this._isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                this._showMore()
        }
    }
    _isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    _showMore = () => {
        let { activeShownTotal } = this.state
        miS.showPreloader()
        setTimeout(() => {
            miS.hidePreloader()
            this.setState({ activeShownTotal: activeShownTotal + commonPageSize})
        }, 300)
    }
    _getFeedbacksData = async () => {
        const result = await dS.fetchAll()
        if (result.status) {
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No feedbacks added yet!' })
            } else {
                this.setState({ feedbackList: result.data.reverse() })
            }
        }
    }
    _getTableData = filterData => {
        let data = []
        filterData.forEach(( item ) => {
            let e = {
                data: <div className='mb40'>
                    <p><b><a className="dark" href={"/admin/order/view/"+item.orderId}>#{item.orderId}</a></b></p>
                    <p><b>Date:</b> {miS.getFormattedDate(item.uid)+' '+miS.getFormattedTime(item.uid)}</p>
                    <div className="ans">
                        <div className="common-rating-style">
                            { miS.renderRatingSystem(Math.ceil(item.rating)) }
                        </div>
                    </div>
                    <p><b>Comments:</b> {item.comments}</p>
                </div>
            }
            data.push(e)
        })
        return data
    }
    render() {
        let { feedbackList, activeShownTotal } = this.state
        let tableContent = {
            content: this._getTableData(feedbackList)
        }
        return <>
            <h1 className="title flex">Feedbacks</h1>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
        </>
    }
}