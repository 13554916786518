import React, {Component} from 'react'
import * as XLSX from 'xlsx'
import {Button} from '../../../elements'
import {Box, ImportResult, Table} from '../../../components'
import {MiscService, MetaService, DataService, RouterService} from '../../../services'
const make_cols = refstr => {
	let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1;
	for(var i = 0; i < C; ++i) o[i] = {name:XLSX.utils.encode_col(i), key:i}
	return o
},
SheetJSFT = [
	"xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
].map(function(x) { return "." + x; }).join(",")
const dS = new DataService('b2bDailyWorkData'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
export default class ImportB2BWorkData extends Component {
    constructor(props) {
        super(props)
        this.state = {
            file: {},
            data: [],
            cols: [],
            excelWorkList: [],
            fileImported: false,
            fileSelected: false,
            renderSlug: true,
            mode: 'add',
            importWorkData: [],
            tableData: [],
            status: 'publish',
            publishDate: miS.getFormattedDate(new Date()),
            showTable: false,
            importDone: false,
            carModels: [],
            resultWorkList: []
        }
    }
    componentDidMount() {
        mS.setTitle('Import Daily B2b Work Data', 'admin')
    }
    _handleChange = e => {
        const files = e.target.files;
        if (files && files[0]) this.setState({ file: files[0], fileSelected: true })
    }
    _handleFile = () => {
        miS.showPreloader()
        this.setState({ showTable: true})
        let excelWorkList = [],
        reader = new FileReader(),
        rABS = !!reader.readAsBinaryString
        reader.onload = e => {
            let bstr = e.target.result,
            wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true, cellDates: true }),
            wsname = wb.SheetNames[0],
            ws = wb.Sheets[wsname],
            data = XLSX.utils.sheet_to_json(ws, {raw: false, rawNumbers: true})
            this.setState({ data, cols: make_cols(ws['!ref']) }, () => {
                data.forEach(e => {
                    if (e['Vehicle No (In Capital Letters)'] !== '') {
                        e['Timestamp'] = String(miS.getFormattedDate(new Date(e['Timestamp'])))
                        e['Date'] = String(miS.getFormattedDate(new Date(e['Date'])))
                        e['Yard Name'] = String(e['Yard Name'])
                        e.ownerId = e['Yard Name']
                        e.registrationNo = e['Vehicle No (In Capital Letters)'].toUpperCase()
                        excelWorkList.push(e)
                    }
                })
                this.setState({ excelWorkList, fileImported: true }, miS.hidePreloader)
            })
        }
        if (rABS) {
            reader.readAsBinaryString(this.state.file)
        } else {
            reader.readAsArrayBuffer(this.state.file)
        }
    }
    _processExcel = () => {
        let _this = this
        this.setState({showTable: false}, () => {
            let { excelWorkList, tableData } = _this.state,
            mode = 'add', activeItem = null
            excelWorkList.forEach((e, i) => {
                tableData.forEach(item => {
                    if (String(e.uid) === item.uid) {
                        mode = 'edit'
                        activeItem = item;
                    }
                })
                setTimeout(() => {
                    _this._addWork(e, mode, activeItem, i)
                }, i * 10);
            })
        })
    }
    _addWork = async (item, mode, oldCar = null, i) => {
        let { resultWorkList, excelWorkList } = this.state, success = false
        if(typeof(item.ownerId) === Number) {
            item.ownerId = String(item.ownerId)
        }
        let b2bDailyWorkDataObject = item
        try {
            if (mode === 'add') {
                b2bDailyWorkDataObject.uid = new Date().getTime()
                const result = await dS.save(b2bDailyWorkDataObject)
                if (result.status) {
                b2bDailyWorkDataObject.uid = new Date().getTime()
                    success = true
                    this.setState({ importWorkData: result })
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to add WorkData!' })
                }
            } else {
                if(typeof(oldCar.uid) === Number) {
                    oldCar.uid = String(oldCar.uid)
                }
                let data = {
                    query: JSON.stringify({uid: oldCar.uid}),
                    b2bDailyWorkDataData: JSON.stringify(b2bDailyWorkDataObject)
                }
                const result = await dS.update(data)
                if (result.status) {
                    success = true
                    this.setState({ importWorkData: result })
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to update WorkData!' })
                }
            }
            if (excelWorkList.length - 1 === i) {
                this.setState({ showTable: false, importDone: true })
            }
            resultWorkList.push({...item, ...{ success }})
            this.setState({ resultWorkList })
        } catch (error) {
            console.log('error', error);
        }
    }
    _getTableData = filterData => {
        let data = []
        try {
            filterData.forEach(( item, i ) => {
                let e = {
                    'Sr. No.' : i + 1,
                    'Timestamp' : item['Timestamp'],
                    'Date' : item['Date'],
                    'Vehicle No (In Capital Letters)' : item['Vehicle No (In Capital Letters)'].toUpperCase(),
                    'Yard Name' : item['Yard Name'],
                    'Cleaning Work Done' : item['Cleaning Work Done'],
                    'Vehicle Inspection Done' : item['Vehicle Inspection Done'],
                    'Engine Starting' : item['Engine Starting'],
                    'Status [Switches Working]' : item['Status [Switches Working]'],
                    'Status [AC Working]' : item['Status [AC Working]'],
                    'Status [Lights Working]' : item['Status [Lights Working]'],
                    'Battery Voltage' : item['Battery Voltage'],
                    'Vehicle Tyre Status' : item['Vehicle Tyre Status'],
                    'Service Done [Puncture Repair]' : item['Service Done [Puncture Repair]'],
                    'Service Done [Battery Charging]' : item['Service Done [Battery Charging]'],
                    'Vehicle Running Condition' : item['Vehicle Running Condition']
                }
                data.push(e)
            })
        } catch(e) {
            console.log('error', e)
        }
        return data
    }

    render() {
        let { resultWorkList, fileSelected, fileImported, excelWorkList, showTable, importDone, activeShownTotal } = this.state
        let tableContent = {
            headers: [
                {
                    label: 'Sr. No.',
                    key: 'Sr. No.'
                },
                {
                    label: 'Timestamp',
                    key: 'Timestamp'
                },
                {
                    label: 'Date',
                    key: 'Date'
                },
                {
                    label: 'Vehicle No (In Capital Letters)',
                    key: 'Vehicle No (In Capital Letters)'
                },
                {
                    label: 'Yard Name',
                    key: 'Yard Name'
                },
                {
                    label: 'Cleaning Work Done',
                    key: 'Cleaning Work Done'
                },
                {
                    label: 'Vehicle Inspection Done',
                    key: 'Vehicle Inspection Done'
                },
                {
                    label: 'Engine Starting',
                    key: 'Engine Starting'
                },
                {
                    label: 'Status [Switches Working]',
                    key: 'Status [Switches Working]'
                },
                {
                    label: 'Status [AC Working]',
                    key: 'Status [AC Working]'
                },
                {
                    label: 'Status [Lights Working]',
                    key: 'Status [Lights Working]'
                },
                {
                    label: 'Battery Voltage',
                    key: 'Battery Voltage'
                },
                {
                    label: 'Vehicle Tyre Status',
                    key: 'Vehicle Tyre Status'
                },
                {
                    label: 'Service Done [Puncture Repair]',
                    key: 'Service Done [Puncture Repair]'
                },
                {
                    label: 'Service Done [Battery Charging]',
                    key: 'Service Done [Battery Charging]'
                },
                {
                    label: 'Vehicle Running Condition',
                    key: 'Vehicle Running Condition'
                }
            ],
            content: this._getTableData(excelWorkList)
        }
        return <>
            {
                fileSelected?
                <>
                    {
                        fileImported?
                        <>
                        {
                            !importDone &&
                            <div className='flex'>
                                <Button type='submit' className="btn btn-primary mv20" onClick={this._processExcel}>Import</Button>
                                <span className='mlauto'>Rows Attempted: <b>{excelWorkList.length}</b></span>
                            </div>
                        }
                        </>:
                        <Button type='submit' className="btn btn-primary mv20" onClick={this._handleFile}>Parse</Button>
                    }
                </>:
                <Box title="Import Daily B2b Work Data">
                    <input type="file" className="hidden form-control inputfile-1" id="file" accept={SheetJSFT} onChange={this._handleChange} />
                    <label className="btn btn-primary" htmlFor="file">
                        <ion-icon name="cloud-upload-outline"></ion-icon>
                        <span>Choose a file…</span>
                    </label>
                </Box>
            }
            {
                showTable?
                <>
                    <Table
                        className="import-table"
                        data={tableContent}
                        pageSize={activeShownTotal}
                    />
                </>
                :<>
                    {
                        importDone &&
                        <>
                            <p>All Cars imported successfully</p>
                            <div className="flex">
                                <Button className="btn btn-outline ml20" onClick={() => rS.getLocationData().reload()}>Import More</Button>
                            </div>
                        </>
                    }
                </>
            }
            <ImportResult resultList={resultWorkList} excelList={excelWorkList} />
        </>
    }
}