import {postData,getData} from '../Ops'
export default class OrderService {
    addOrder = async data => {
        const formData = new FormData()
        formData.append('activeAddress', data.activeAddress)
        formData.append('userData', data.userData)
        formData.append('subscriptionData', data.subscriptionData)
        formData.append('orderData', data.orderData)
        formData.append('status', data.status)
        formData.append('timing', data.timing)
        formData.append('source', 'web')
        formData.append('uid', data.uid)
        return await postData('order/save', formData)
    }
    updateOrder = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        formData.append('orderData', data.orderData)
        return await postData('order/updatedata', formData)
    }
    forceRegenrateInvoice = async data => {
        const formData = new FormData()
        formData.append('orderId', data.orderId)
        return await postData('order/invoice/force-regenerate', formData)
    }
    getOrder = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('order/get', formData)
    }
    getUpgradedPackage = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('order/package/check-upgrade', formData)
    }
    deleteOrder = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('order/delete', formData)
    }
    getOrders = async () => {
        return await getData('order/list')
    }
    getInvoices = async () => {
        return await getData('order/invoice/list')
    }
    getOrderInvoice = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('order/invoice/get', formData)
    }
    getUserOrders = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('order/user', formData)
    }
    getUserRecommendation = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('order/user/recommendation', formData)
    }
    updateUniversalCartCount = async cartCount => {
        if (document.getElementsByClassName('cartCount').length > 0) {
            for (let i = 0; i < document.getElementsByClassName('cartCount').length; i++) {
                document.getElementsByClassName('cartCount')[i].innerHTML = '<span>'+cartCount+'</span>'
            }
        }
    }
    addToCart = item => {
        let cartData = this.getCartData(), found = false, newCartData = []
        if (cartData === null) { cartData = [] }
        if (item.type !== 'product') {
            if (item.type === 'subscription') {
                cartData.forEach(e => {
                    if (e.type !== 'subscription') {
                        newCartData.push(e)
                    }
                })
                if (!found)
                newCartData.push(item)
            } else {
                cartData.forEach(e => {
                    newCartData.push(e)
                })
                newCartData.push(item)
            }
        } else {
            cartData.forEach(e => {
                if (e.uid === item.uid) {
                    found = true
                    e.purchaseQuantity += 1
                }
                newCartData.push(e)
            })
            if (!found)
                newCartData.push(item)
        }
        let cartCount = 0
        newCartData.forEach(e => {
            cartCount += e.purchaseQuantity
        })
        this.updateUniversalCartCount(cartCount)
        return new Promise( (resolve, reject) => {
            localStorage.setItem('cartData', JSON.stringify(newCartData))
            resolve(newCartData)
        })
    }
    createOrderData = orderData => {
        return new Promise( (resolve, reject) => {
            localStorage.setItem('orderData', JSON.stringify(orderData))
            resolve(orderData)
        })
    }
    getOrderData = () => {
        let orderData = localStorage.getItem('orderData')
        return orderData === null ? [] : JSON.parse(localStorage.getItem('orderData'))
    }
    removeFromCart = item => {
        let cartData = this.getCartData(), newCartData = []
        cartData.forEach(e => {
            if (e.tempUid === item.tempUid)
                e.purchaseQuantity -= 1
            if (e.purchaseQuantity !== 0)
                newCartData.push(e)
        })
        let cartCount = 0
        newCartData.forEach(e => {
            cartCount += e.purchaseQuantity
        })
        this.updateUniversalCartCount(cartCount)
        return new Promise( (resolve, reject) => {
            localStorage.setItem('cartData', JSON.stringify(newCartData))
            resolve(newCartData)
        })
    }
    emptyCart = () => {
        localStorage.removeItem('cartData')
        return true
    }
    emptyOrder = () => {
        localStorage.removeItem('orderData')
        return true
    }
    getCartData = () => {
        let cartData = localStorage.getItem('cartData')
        return cartData === null ? [] : JSON.parse(localStorage.getItem('cartData'))
    }
    getSubscriptionData = () => {
        let subscriptionData = localStorage.getItem('subscriptionData')
        return subscriptionData === null ? [] : JSON.parse(localStorage.getItem('subscriptionData'))
    }
    collectKey = async data => {
        const formData = new FormData()
        formData.append('supervisorId', data.supervisorId)
        formData.append('userId', data.userId)
        formData.append('carId', data.carId)
        formData.append('status', data.status)
        formData.append('timing', data.timing)
        formData.append('uid', data.uid)
        return await postData('order/key/add', formData)
    }
    getKeysList = async () => {
        return await getData('order/key/list')
    }
    getUserKeys = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('order/key/user', formData)
    }
    updateKeyStatus = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        formData.append('keyData', data.keyData)
        return await postData('order/key/update', formData)
    }
    getServiceDates = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('order/serviceDate/get', formData)
    }
    updateServiceDates = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        formData.append('serviceDatesData', data.serviceDatesData)
        return await postData('order/serviceDate/update', formData)
    }
    addServiceDates = async data => {
        const formData = new FormData()
        formData.append('userId', data.userId)
        formData.append('tempUid', data.tempUid)
        formData.append('serviceDates', data.serviceDates)
        formData.append('preventiveCheckListDates', data.preventiveCheckListDates)
        formData.append('uid', data.uid)
        return await postData('order/serviceDate/add', formData)
    }
}