import React from 'react'
import {Img} from '../../elements'
const secureIndex = [
    {
        image: require('../../../assets/images/trustpay.png'),
        title: 'TRUSTPAY'
    },
    {
        image: require('../../../assets/images/staff.png'),
        title: 'PROFESSIONAL & TRAINED STAFF'
    },
    {
        image: require('../../../assets/images/happy.png'),
        title: '100% HAPPINESS GUARANTEE'
    },
    {
        image: require('../../../assets/images/quality.png'),
        title: 'HIGH QUALITY'
    },
    {
        image: require('../../../assets/images/best.png'),
        title: 'BEST IN CLASS SERVICES / PRODUCTS'
    }
],
SecureSection = () => {
    return <section className="secure_Sec mb60-mbs40">
        <div className="main-secure">
            {
                secureIndex.map((item, index) => {
                    return <div className={"item item"+(index+1)} key={index}>
                        <div className="main-box">
                            <div className="icon">
                                <Img alt="" src={item.image} />
                            </div>
                            <div id="content-box" className="content">
                                <h1 className="icon-title">
                                    <span>{item.title}</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
    </section>
}
export default SecureSection