import React, {Component} from 'react'
import {TrashConfirmModal, Table} from '../../../components'
import Select from 'react-select'
import {MiscService, ProductService, MetaService} from '../../../services'
import {Button, Actor} from '../../../elements'
const mS = new MetaService(),
miS = new MiscService(),
prS = new ProductService()
export default class ProductCategories extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categoryList: [],
            catName: '',
            catSlug: '',
            parentCat: '',
            renderSlug: true,
            activeItem: '',
            uid: '',
            showTrashConfirm: false,
            categories: '',
            categoriesOptions: []
        }
    }
    componentDidMount () {
        mS.setTitle('Product Categories', 'admin')
        this._getProductCategoryData()
    }
    _getProductCategoryData = async () => {
        const result = await prS.getProductCategory()
        if (result.status) {
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No chats placed yet!' })
                this.setState({ categoryList: [], categoriesOptions: [] })
            } else {
                let categoryList = [],
                categoriesOptions = []
                result.data.forEach(e => {
                    categoryList.push(e)
                    categoriesOptions.push({label: e.catName, value: e.catSlug})
                })
                this.setState({ categoryList:categoryList, categoriesOptions:categoriesOptions })
            }
        }
    }
    _categoryCancel = () => {
        this.setState({ activeItem: null, showTrashConfirm: false })
    }
    _trashCategory = async () => {
        let { activeItem } = this.state,
        query = {
            uid: activeItem.uid
        }
        const result = await prS.deleteProductCategory({ query: JSON.stringify(query) })
        if (result.status) {
            this._getProductCategoryData()
            miS.showAlert({ type: 'success', msg: 'Category deleted successfully!' })
            this.setState({ activeItem: null, showTrashConfirm: false })
        } else
            miS.showAlert({ type: 'error', msg: 'Unable to delete chat!' })
    }
    _addProductCategory = async e => {
        e.preventDefault()
        let { catName, catSlug, parentCat } = this.state,
        error = false,
        errorMsg = '', parentCategory = ''
        if (parentCat !== '')
            parentCategory = parentCat.label
        let categoryObject = {
            catName: catName,
            catSlug: catSlug,
            parentCat: parentCategory
            
        }
        if (catName === '' || catSlug === '') {
            errorMsg = 'Please enter chat content!'
            error = true
        }
        if (!error) {
            categoryObject.uid = new Date().getTime()
            const result = await prS.addProductCategory(categoryObject)
            if (result.status)
                miS.showAlert({ type: 'success', msg: 'Category added successfully!' })
            else
                miS.showAlert({ type: 'error', msg: 'Unable to add chat!' })
            this.setState({ catName: '', catSlug: '', parentCat: '', uid: '' }, () => {
                miS.closeModal("category-modal")
                this._getProductCategoryData()
            })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _getTableData = filterData => {
        let data = []
        filterData.forEach(( item ) => {
            let e = {
                catName: <p className="query mb40">{item.catName}</p>,
                catSlug: <p className="ans mb40">{item.catSlug}</p>,
                parentCat: <p className="ans mb40">{item.parentCat}</p>,
                action: <div className="action-bar" style={{bottom: 10}}>
                    <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    render() {
        let { categoryList, catSlug, parentCat, categoriesOptions, renderSlug, catName, showTrashConfirm, activeShownTotal } = this.state
        let tableContent = {
            headers: [
                {
                    label: 'Category Name',
                    key: 'categoryName'
                },
                {
                    label: 'Slug',
                    key: 'slug'
                },
                {
                    label: 'Parent Category',
                    key: 'parentCategory'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: this._getTableData(categoryList)
        }
        return <>
            <h1 className="title flex">Category <Button onClick={() => {
                this.setState({ mode: 'Add' }, () => miS.openModal("category-modal"))
            }} className="btn btn-primary btn-yellow btn-small ml20">Add</Button></h1>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <TrashConfirmModal trashOkAction={this._trashCategory} trashCancelAction={this._categoryCancel} showTrashConfirm={showTrashConfirm} />
            <div className="modal category-modal" id="category-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content small">
                    <Button className="close" onClick={() => 
                    this.setState({ catName: '', catSlug: '', uid: '' }, () => miS.closeModal("category-modal"))}>
                    <i className="hi-close"></i></Button>
                    <div className="modal-body">
                        <div className="category-modal-header">
                            <div className="modal-header">
                                <div className="heading">Add</div>
                                <form noValidate onSubmit={this._addProductCategory}> 
                                    <div className="form-group mb40">
                                        <input type="text" className="input" placeholder="Please enter a Category Name" required name="catName" value={catName}
                                        onChange={e => {
                                            let tempTitle = e.target.value, tempSlug = catSlug
                                            if (renderSlug) {
                                                tempSlug = miS.slugify(tempTitle)
                                            }
                                            this.setState({ catName: tempTitle, catSlug: tempSlug})
                                        }} />
                                        <label>Category</label>
                                        <small className="msg text-danger">Please enter a Category</small>
                                    </div>
                                    <div className="form-group mb40">
                                        <input type="text" className="input" placeholder="Please enter slug" required name="catSlug" value={catSlug} onChange={(e) => this.setState({ catSlug: e.target.value }) } />
                                        <label>Slug</label>
                                        <small className="msg text-danger">Please enter slug</small>
                                    </div>
                                    <div className="form-group mb40">
                                        <Select
                                            value={parentCat}
                                            onChange={e => {
                                                this.setState({ parentCat: e })
                                            }}
                                            options={categoriesOptions}
                                        />
                                        <label>Parent Category</label>
                                        <small className="msg text-danger">Please select Parent Category</small>
                                    </div>
                                    <div className="buttons">
                                        <Button type="submit" title="Click here to Add Category" className="btn btn-primary btn-small">Submit</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}