import React from 'react'
import {base} from '../../constants/Data'
const Banner = props => {
    let activeImage = 'header-back-car.jpg', isWhite = true
    if (props.image)
        activeImage = props.image
    if (props.color)
        isWhite = false
    return <div className="blog-banner sub-header-banner" style={{backgroundImage: 'url("'+base.imageBaseUrl+activeImage+'")'}}>
        <div className="sub-banner">
            <div className="sub-header-content p-relative jumbo-container">
                <h1 className={isWhite?"text-custom-white": ''}>{props.title}</h1>
            </div>
        </div>
    </div>
}
export default Banner