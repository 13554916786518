import { postData,getData } from '../Ops'
import { FaqModel, PageModel, ChatModel, ComplaintModel, EventModel, FeedbackModel, LogModel, NotificationModel, SettingModel, TestimonialModel, PostModel, JobModel, GarageModel, PackageWorkModel, PaymentModel, SubscriptionModel, OfferModel, ServiceWorkModel, MediaModel, InvoiceModel, TaxClassModel, RecommendationModel, CompanyOrderModel, BusinessWorkModel, B2BDailyWorkModel, CompanyDataModel, CarModelModel, B2BDailyStockModel, MsgModel, AppModel } from '../../model'
const dataModels = [
    {
        name: 'carModel',
        model: CarModelModel
    },
    {
        name: 'companyData',
        model: CompanyDataModel
    },
    {
        name: 'faq',
        model: FaqModel
    },
    {
        name: 'media',
        model: MediaModel
    },
    {
        name: 'taxClass',
        model: TaxClassModel
    },
    {
        name: 'invoice',
        model: InvoiceModel
    },
    {
        name: 'chat',
        model: ChatModel
    },
    {
        name: 'complaint',
        model: ComplaintModel
    },
    {
        name: 'event',
        model: EventModel
    },
    {
        name: 'feedback',
        model: FeedbackModel
    },
    {
        name: 'log',
        model: LogModel
    },
    {
        name: 'notification',
        model: NotificationModel
    },
    {
        name: 'setting',
        model: SettingModel
    },
    {
        name: 'testimonial',
        model: TestimonialModel
    },
    {
        name: 'page',
        model: PageModel
    },
    {
        name: 'post',
        model: PostModel
    },
    {
        name: 'job',
        model: JobModel
    },
    {
        name: 'garage',
        model: GarageModel
    },
    {
        name: 'packageWork',
        model: PackageWorkModel
    },
    {
        name: 'serviceWork',
        model: ServiceWorkModel
    },
    {
        name: 'payment',
        model: PaymentModel
    },
    {
        name: 'offer',
        model: OfferModel
    },
    {
        name: 'subscription',
        model: SubscriptionModel
    },
    {
        name: 'recommendation',
        model: RecommendationModel
    },
    {
        name: 'companyOrder',
        model: CompanyOrderModel
    },
    {
        name: 'businessWork',
        model: BusinessWorkModel
    },
    {
        name: 'b2bDailyWorkData',
        model: B2BDailyWorkModel
    },
    {
        name: 'b2bDailyStockData',
        model: B2BDailyStockModel
    },
    {
        name: 'msg',
        model: MsgModel
    },
    {
        name: 'app',
        model: AppModel
    }
]
export default class DataService {
    static model
    constructor(model) {
        this.model = model
    }
    save = async data => {
        const formData = new FormData()
        dataModels.forEach(e => {
            if (e.name === this.model) {
                e.model.forEach(d => {
                    formData.append(d.key, data[d.key])
                })
            }
        })
        try {
            const res = await postData(this.model+'/save', formData)
            return res
        } catch (e) {
            return e
        }
    }
    update = async data => {
        const formData = new FormData()
        formData.append('query', data['query'])
        formData.append(this.model+'Data', data[this.model+'Data'])
        try {
            const res = await postData(this.model+'/update', formData)
            return res
        } catch (e) {
            return e
        }
    }
    fetchByAttr = async data => {
        const formData = new FormData()
        formData.append('query', data['query'])
        try {
            const res = await postData(this.model+'/get', formData)
            return res
        } catch (e) {
            return e
        }
    }
    delete = async data => {
        const formData = new FormData()
        formData.append('query', data['query'])
        try {
            const res = await postData(this.model+'/delete', formData)
            return res
        } catch (e) {
            return e
        }
    }
    fetchAll = async () => {
        try {
            const res = await getData(this.model+'/list')
            return res
        } catch (e) {
            return e
        }
    }
}