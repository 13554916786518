import React, {Component} from 'react'
import {base} from '../../../constants/Data'
import {MiscService, DataService, RouterService, MetaService} from '../../../services'
import {Img, Button, BackIcon} from '../../../elements'
import {Box} from '../../../components'
const dS = new DataService('media'),
sS = new DataService('setting'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
export default class WebBanners extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mediaFiles: [],
            image: '[]',
            mode: 'Add',
            activeFile: ''
        }
    }
    componentDidMount () {
        mS.setTitle('Web Banners', 'admin')
        this._getWebBanners()
    }
    _selectImage = file => {
        let images = document.getElementById('image-input').value === '' ? JSON.stringify([]) : document.getElementById('image-input').value
        if (!images.includes(file)) {
            images = JSON.parse(images)
            images.push(file)
        } else {
            images = JSON.parse(images)
            images.pop(file)
        }
        this.setState({ image: JSON.stringify(images) })
    }
    _renderImages = mediaFiles => {
        let { image } = this.state
        return mediaFiles.map(( item, index ) => {
            return <li key={index} className={image.includes(item.fileName)?"selected": ''}>
                <ion-icon name="checkmark-circle" class="select" onClick={() => this._selectImage(item.fileName)}></ion-icon>
                <Img alt={item.fileName} src={base.imageBaseUrl + item.fileName} />
            </li>
        })
    }
    _getMedias = async () => {
        const result = await dS.fetchAll()
        if (result.status) {
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No medias added yet!' })
            } else {
                let medias = result.data.reverse().filter(e=>{
                    return (e.fileName.includes('.'))
                })
                this.setState({ mediaFiles: medias })
            }
        }
    }
    _preview = filePath => {
        this.setState({ activeFile: filePath}, () => {
            miS.openModal('preview-modal')
        })
    }
    _trashSelectedImage = item => {
        let { image } = this.state, newImages = []
        JSON.parse(image).forEach(i => {
            if (i !== item) {
                newImages.push(i)
            }
        })
        this.setState({ image: JSON.stringify(newImages) })
    }
    _renderUploadedImages = mediaFiles => {
        return mediaFiles.map(( item, index ) => {
            return <li key={index}>
                <ion-icon class="preview" onClick={() => this._preview(item)} name="eye"></ion-icon>
                <ion-icon class="trash" name="trash-outline" onClick={() => this._trashSelectedImage(item)}></ion-icon>
                <Img alt={item.fileName} src={base.imageBaseUrl + item} />
            </li>
        })
    }
    _getWebBanners = async () => {
        let query = {
            type: 'web-banner'
        }
        const result = await sS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            let serviceDate = result.data[0]
            this.setState({
                mode: 'Edit',
                image: serviceDate.image,
                uid: serviceDate.uid
            })
        } else miS.showAlert({ type: 'error', msg: 'No banners added yet!' })
    }
    _saveBanners = async () => {
        let { uid, mode, image } = this.state,
        error = false,
        errorMsg = '',
        settingObject = {
            type: 'web-banner',
            image
        }
        if (!error) {
            if (mode === 'Add') {
                settingObject.uid = new Date().getTime()
                const result = await sS.save(settingObject)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Banners added successfully!' })
                    rS.getLocationData().reload()
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to add user!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    settingData: JSON.stringify(settingObject)
                }
                const result = await sS.update(data)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Banners updated successfully!' })
                    rS.getLocationData().reload()
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to update user!' })
                }
            }
        } else miS.showAlert({ type: 'error', msg: errorMsg })
    }
    render() {
        let { activeFile, mediaFiles, image } = this.state
        return <>
            <h1>
                <BackIcon backLink='/admin' />
                Web Banners
            </h1>
            <form className="columns" noValidate onSubmit={this._addService}>
                <div className="left">
                    <Box title="Web Banner" required>
                        <Button type="button" className="btn-primary btn btn-small" title="Click here to show images" onClick={() => {
                            this._getMedias()
                            miS.openModal("media-modal")
                        }}>Add Image</Button>
                        <input className="hidden" type="text" required name="image" value={image||''} onChange={(e) => this.setState({ image: e.target.value })} id="image-input" />
                        <div className="upload-box mt20 mb0">
                            <ul className="media-box banner-box">
                                {
                                    JSON.parse(image).length !== 0 &&
                                    this._renderUploadedImages(JSON.parse(image)||[])
                                }
                            </ul>
                        </div>
                        {
                            JSON.parse(image).length > 0 &&
                            <Button type="button" className="btn-primary btn btn-small mt20" title="Click here to show images" onClick={this._saveBanners}>Save</Button>
                        }
                    </Box>
                </div>
            </form>
            <div className="modal media-modal" id="media-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content">
                    <Button className="close" onClick={() => miS.closeModal("media-modal")}>
                        <i className="hi-close"></i>
                    </Button>
                    <div className="modal-body">
                        <div className="modal-header">
                            <h2 className="heading">Select Image</h2>
                        </div>
                        <div className="modal-box">
                            <ul className="media-box banner-box">
                                {
                                    JSON.parse(image).length !== 0 &&
                                    this._renderUploadedImages(JSON.parse(image)||[])
                                }
                            </ul>
                        </div>
                        {
                            JSON.parse(image).length > 0 &&
                            <Button type="button" className="btn-primary btn btn-small mt20" title="Click here to show images" onClick={this._saveBanners}>Save</Button>
                        }
                    </div>
                </div>
            </div>
            <div className="modal media-modal" id="media-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content">
                    <Button className="close" onClick={() => miS.closeModal("media-modal")}>
                        <i className="hi-close"></i>
                    </Button>
                    <div className="modal-body">
                        <div className="modal-header">
                            <h2 className="heading">Select Image</h2>
                        </div>
                        <div className="modal-box">
                            <ul className="media-box banner-box">
                                {
                                    mediaFiles.length !== 0 &&
                                    this._renderImages(mediaFiles||[])
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal preview-modal" id="preview-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content">
                    <Button className="close" onClick={() => miS.closeModal("preview-modal")}><i className="hi-close"></i></Button>
                    <div className="modal-body">
                        {
                            activeFile !== null &&
                            <Img alt={activeFile.item} src={base.imageBaseUrl+activeFile} className="mauto" />
                        }
                    </div>
                </div>
            </div>
        </>
    }
}