import { brandInfo } from "../../constants/Data"
import './style.scss'
const CopyrightSection = () => {
    return <section>
        <div className="copyright-area">
            <div className="copyright-area-inner">Copyright &copy; {new Date().getFullYear()} {brandInfo.companyName} | All rights reserved.</div>
        </div>
    </section>
}

export default CopyrightSection