import React, {useState, useEffect} from 'react'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { mainUrl } from '../../constants/Data'
import {MiscService} from '../../services';
const miS = new MiscService()
const Share = props => {
    let {shareUrl} = props;
    let [url, seturl] = useState(mainUrl)
    useEffect(() => {
        seturl(shareUrl)
    }, [shareUrl, url])
    return <div className="share">
        <span className="share-text">{miS.translate('share')}</span>
        <div className="line-shape">
            <span className="bloget"></span>
        </div>
        <div className="share-icon">
            <ul className="icon-wrap">
                <li><FacebookShareButton url={mainUrl+shareUrl} children={<ion-icon name="logo-facebook"></ion-icon>} /></li>
                <li><WhatsappShareButton url={mainUrl+shareUrl} children={<ion-icon name="logo-whatsapp"></ion-icon>} /></li>
                <li><TwitterShareButton url={mainUrl+shareUrl} children={<ion-icon name="logo-twitter"></ion-icon>} /></li>
                <li><LinkedinShareButton url={mainUrl+shareUrl} children={<ion-icon name="logo-linkedin"></ion-icon>} /></li>
            </ul>
        </div>
    </div>
}
export default Share