import React, {Component} from 'react'
import {TrashConfirmModal, Box} from '../../../components'
import Select from 'react-select'
import {MiscService, DataService, MetaService} from '../../../services'
import {Badge, Button, Actor} from '../../../elements'
const dS = new DataService('complaint'),
mS = new MetaService(),
miS = new MiscService(),
complaintOptions = [
    { value: 'raised', label: 'Raised' },
    { value: 'processing', label: 'Processing' },
    { value: 'rejected', label: 'Rejected' },
    { value: 'resolved', label: 'Resolved' }
]
const filterTags = require('./filterTags.json')
const commonPageSize = 50
export default class ComplaintAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            complaintList: [],
            uid: '',
            showTrashConfirm: false,
            activeStatusIndex: 0,
            email: '',
            message: '',
            number: '',
            complaintType: '',
            orderId: '',
            status: '',
            searchKey: '',
            productName: '',
            name: '',
            complaintNotes: [],
            activeItem: '',
            activeShownTotal: commonPageSize,
            filteredList: [],
            searchList: [],
            activeFilterIndex: 0,
            activeFilter: null
        }
    }
    componentDidMount () {
        mS.setTitle('Complaints', 'admin')
        let { activeStatusIndex } = this.state
        this.setState({ status: complaintOptions[activeStatusIndex].value, activeFilter: filterTags[0] })
        this._getComplaintsData()
        document.getElementById('content-box').addEventListener('scroll', this._trackScrolling)
    }
    componentWillUnmount() {
        document.getElementById('content-box').removeEventListener('scroll', this._trackScrolling)
    }
    _trackScrolling = () => {
        let { filteredList, activeShownTotal } = this.state,
        wrappedElement = document.getElementById('list-wrapper')
        if (this._isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                this._showMore()
        }
    }
    _isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    _showMore = () => {
        let { activeShownTotal } = this.state
        miS.showPreloader()
        setTimeout(() => {
            miS.hidePreloader()
            this.setState({ activeShownTotal: activeShownTotal + commonPageSize})
        }, 300)
    }
    _getComplaintsData = async () => {
        let {activeFilterIndex} = this.state
        const result = await dS.fetchAll()
        if (result.status) {
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No complaints placed yet!' })
            } else {
                let complaintList = []
                result.data.forEach(e => {
                    complaintList.push(e)
                })
                this.setState({ tableData: result.data, filteredList: result.data, complaintList: complaintList}, () => {
                    let activefilterValue = 'all'
                    filterTags.forEach(e => {
                        if (Number(e.id) === activeFilterIndex+1) {
                            activefilterValue = e.value
                        }
                    })
                    this._filterItems(activefilterValue, activeFilterIndex)
                })
                let activeStatusIndex = 0
                complaintOptions.forEach(( e, i ) => {
                    if (e.value === complaintList.status) {
                        activeStatusIndex = i
                    }
                })
                this.setState({ activeStatusIndex })
            }
        }
    }
    _trashCancel = () => {
        this.setState({ activeItem: null, showTrashConfirm: false })
    }
    _trashComplaint = async () => {
        let { activeItem } = this.state,
        query = {
            uid: activeItem.uid
        }
        const result = await dS.delete({ query: JSON.stringify(query) })
        if (result.status) {
            this._getComplaintsData()
            miS.showAlert({ type: 'success', msg: 'Complaint deleted successfully!' })
            this.setState({ activeItem: null, showTrashConfirm: false })
        } else miS.showAlert({ type: 'error', msg: 'Unable to delete complaint!' })
    }
    _renderNotes = complaintNotes => {
        return complaintNotes.map(( item, index ) => {
            return <li rel="102" className="note system-note"key={index}>
                <div className="note-content">
                    <p>{item.note}</p>
                </div>
                <p className="note-meta">
                    <abbr className="exact-date">{miS.getFormattedDate(item.timing)}</abbr>
                </p>
                <p className="note-status">{item.status}</p>
            </li>
        })
    }
    _renderComplaintList = complaintFiles => {
        let {activeShownTotal} = this.state
        return complaintFiles.map(( item, index ) => {
            if (index < activeShownTotal)
            return <tr key={index}>
                <td style={{paddingBottom: 40}}>
                    #{item.uid}
                </td>
                <td style={{paddingBottom: 40}}>
                    {item.name}
                </td>
                <td style={{paddingBottom: 40}}>
                    {item.number}
                </td>
                <td style={{paddingBottom: 40}}>
                    {miS.getFormattedDate(new Date(item.date))}
                </td>
                <td style={{paddingBottom: 40}}>
                    <Badge item={item.status} />
                </td>
                <td style={{paddingBottom: 40}}>
                    {item.complaintType}
                </td>
                <td>
                    <div className="action-bar" style={{bottom: 10}}>
                        <Actor onClick={() => this.setState({ complaintType: item.complaintType, uid:item.uid, number: item.number, email: item.email, message: item.message, name: item.name, orderId: item.orderId, productName: item.productName, activeItem: item }, () => {
                            miS.openModal("complaint-modal")
                        })} type='edit' />
                        <Actor onClick={() => this.setState({ complaintType: item.complaintType, uid:item.uid, number: item.number, email: item.email, message: item.message, name: item.name, orderId: item.orderId, productName: item.productName, activeItem: item }, this._trashComplaint)} type='trash' />
                    </div>
                </td>
            </tr>
        })
    }
    _addNote = async e => {
        e.preventDefault()
        let { status, note, activeItem } = this.state,
        query = {
            uid: activeItem.uid
        }
        activeItem.status = status
        let complaintNotes = typeof activeItem.complaintNotes === 'undefined'? []: activeItem.complaintNotes
        complaintNotes.push({
            status,
            note,
            timing: new Date().getTime()
        })
        activeItem.complaintNotes = complaintNotes
        delete activeItem._id
        let data = {
            query: JSON.stringify({uid: activeItem.uid}),
            complaintData: JSON.stringify(activeItem)
        }
        const result = await dS.update(data)
        if (result.status) {
            miS.showAlert({ type: 'success', msg: 'Complaint updated successfully!' })
            this.setState({ note: '', status: '' }, () => {
                this._getComplaintsData(query)
                miS.closeModal("complaint-modal")
            })
        } else miS.showAlert({ type: 'error', msg: 'Unable to update Complaint!' })
    }
    _filterItems = ( key, activeFilterIndex ) => {
        let { complaintList } = this.state, filteredList = []
        if (key === 'all') {
            filteredList = complaintList
        } else {
            filteredList = complaintList.filter(e => {return e.status === key })
        }
        this.setState({ filteredList, activeFilterIndex: activeFilterIndex })
    }
    _searchPost = () => {
        let { searchKey, complaintList } = this.state,
            filteredList = [], tempTitle= ''
        complaintList.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = (e.name).toLowerCase()
            if (e.uid.search(searchKey) !== -1 || tempTitle.search(searchKey) !== -1)
                filteredList.push(e)
        })
        this.setState({ filteredList, activeFilterIndex: 0 })
    }
    render() {
        let {activeFilter, status, activeStatusIndex, complaintList, email, uid, complaintType, number, message, name, orderId, productName, showTrashConfirm, activeItem, note, filteredList, searchKey } = this.state,
        activeStatus = complaintOptions[activeStatusIndex]
        complaintOptions.forEach(e => {
            if (e.value === status)
                activeStatus = e
        })
        return <>
            <h1 className="title flex mb50">Complaints </h1>
            <div className="filter-box">
                <div className="filters relative">
                <label className="filter-label-add">Status: </label>
                    <Select
                        value={activeFilter}
                        defaultValue={activeFilter}
                        onChange={(e, i) => this.setState({ activeFilter: e }, () => this._filterItems(e.value, i) )}
                        options={filterTags}
                    />
                </div>
                <TrashConfirmModal trashOkAction={this._trashComplaint} trashCancelAction={this._trashCancel} showTrashConfirm={showTrashConfirm} />
            <div className="modal complaint-modal" id="complaint-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content">
                    <Button className="close" onClick={() => 
                        this.setState({ orderId: '', name: '', email: '', message: '', number: '', complaintType: '', uid: '' }, () => miS.closeModal("complaint-modal"))}>
                        <i className="hi-close"></i>
                    </Button>
                    <div className="modal-body">
                        <div className="complaint-modal-header">
                            <div className="modal-header">
                                <div className="heading">View Complaint</div>
                                    <div className="form-group">
                                        <label>Order Id</label>
                                        <p>{orderId}</p>
                                    </div>
                                    <div className="form-group">
                                        <label>Complaint Id</label>
                                        <p>#{uid}</p>
                                    </div>
                                    <div className="form-group">
                                        <label>Name</label>
                                        <p>{name}</p>
                                    </div>
                                    <div className="form-group">
                                        <label>Mobile Number</label>
                                        <p>{number}</p>
                                    </div>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <p>{email}</p>
                                    </div>
                                    <div className="form-group">
                                        <label>Complaint Type</label>
                                        <p>{complaintType}</p>
                                    </div>
                                    <div className="form-group">
                                        <label>Product Name</label>
                                        <p>{productName}</p>
                                    </div>
                                    <div className="form-group">
                                        <label>Message</label>
                                        <p>{message}</p>
                                    </div>
                                    {
                                        activeItem.complaintNotes &&
                                        <Box title='Notes'>
                                            <ul className="notes">
                                                { this._renderNotes(activeItem.complaintNotes||[]) }
                                            </ul>
                                        </Box>
                                    }
                                    <Box title='Add Notes'>
                                        <form onSubmit={this._addNote}>
                                            <textarea className="mb20" placeholder="Type the note here" required value={note} name="content" onChange={(e) => this.setState({ note: e.target.value })}></textarea>
                                            <Select
                                                value={activeStatus}
                                                defaultValue={activeStatus}
                                                onChange={e => this.setState({ status: e.value })}
                                                options={complaintOptions}
                                            />
                                            <Button type="submit" className="btn btn-primary">Add</Button>
                                        </form>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}