import {postData,getData} from '../Ops'
export default class ServiceService {
    addService = async data => {
        const formData = new FormData()
        formData.append('title', data.title)
        formData.append('slug', data.slug)
        formData.append('content', data.content)
        formData.append('excerpt', data.excerpt)
        formData.append('css', data.css)
        formData.append('js', data.js)
        formData.append('canonical', data.canonical)
        formData.append('robots', data.robots)
        formData.append('userNote', data.userNote)
        formData.append('metaDescription', data.metaDescription)
        formData.append('metaKeyword', data.metaKeyword)
        formData.append('author', data.author)
        formData.append('publishDate', data.publishDate)
        formData.append('status', data.status)
        formData.append('categories', data.categories)
        formData.append('isFeatured', data.isFeatured)
        formData.append('inclusiveTax', data.inclusiveTax)
        formData.append('sku', data.sku)
        formData.append('serviceTime', data.serviceTime)
        formData.append('hsn', data.hsn)
        formData.append('tax', data.tax)
        formData.append('tag', data.tag)
        formData.append('uid', data.uid)
        formData.append('image', data.image)
        formData.append('prices', data.prices)
        if (data.company) {
            formData.append('company', JSON.stringify(data.company))
        }
        return await postData('service/save', formData)
    }
    updateService = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        formData.append('serviceData', data.serviceData)
        return await postData('service/update', formData)
    }
    getService = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('service/get', formData)
    }
    deleteService = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('service/delete', formData)
    }
    getServices = async () => {
        return await getData('service/list')
    }
    getServiceCategory = async () => {
        return await getData('service/category/list')
    }
    addServiceCategory = async categoryObject => {
        const formData = new FormData()
        formData.append('catName', categoryObject.catName)
        formData.append('catSlug', categoryObject.catSlug)
        formData.append('parentCat', categoryObject.parentCat)
        formData.append('uid', categoryObject.uid)
        return await postData('service/category/add', formData)
    }
    deleteServiceCategory = async data => {
        const formData = new FormData()
        formData.append('query', data.query)
        return await postData('service/category/delete', formData)
    }
}