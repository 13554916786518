import React, {Component} from 'react'
import {TrashConfirmModal, Table} from '../../../components'
import {MiscService, UserService, OrderService, MetaService} from '../../../services'
import {Button, Badge} from '../../../elements'
const uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
oS = new OrderService()
export default class KeyListAdmin extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state = {
            tableData: [],
            image: '',
            filteredList: [],
            activeFilterIndex: 0,
            showTrashConfirm: false,
            searchList: []
        }
    }
    componentDidMount () {
        mS.setTitle('Car Keys List', 'admin')
        this._getKeysList()
    }
    _getKeysList = async () => {
        const result = await oS.getKeysList()
        if (result.status) {
            let tableData = result.data.reverse()
            this.setState({ tableData, filteredList: tableData })
        }
    }
    _getTableData = filterData => {
        let data = []
        filterData.forEach(( item, index ) => {
            let e = {
                registrationNo: item.registrationNo?item.registrationNo.toUpperCase(): '',
                supervisorId: item.supervisorId,
                timing: <>
                    {miS.getFormattedDate(item.timing)} <br /> {miS.getFormattedTime(item.timing)}
                </>,
                status: <Badge item={item.status} />
            }
            data.push(e)
        })
        return data
    }
    _search = () => {
        let { searchKey, tableData } = this.state,
        tempTitle = '', filteredList = []
        tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = e.registrationNo? e.registrationNo.toLowerCase(): ''
            if (tempTitle.search(searchKey) !== -1)
                filteredList.push(e)
        })
        this.setState({ filteredList })
    }
    render() {
        let { tableData, showTrashConfirm, filteredList, searchKey, activeShownTotal } = this.state
        let tableContent = {
            headers: [
                {
                    label: 'Registration No.',
                    key: 'registrationNo'
                },
                {
                    label: 'Supervisor Id.',
                    key: 'superviseId'
                },
                {
                    label: 'Timing',
                    key: 'timing'
                },
                {
                    label: 'Status',
                    key: 'status'
                }
            ],
            content: this._getTableData(filteredList)
        }
        return <>
            <h1 className="title flex">
                All Cars Keys
            </h1>
            <div className="filter-box">
                <div className="filters">
                </div>
                <div className="search-box">
                    <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                        const reg = /^[A-Za-z0-9 ]+$/
                        if (e.target.value === '' || reg.test(e.target.value))
                            this.setState({ searchKey: e.target.value }, this._search)
                    }} />
                    <Button type="button" onClick={() => this.setState({ filteredList: tableData, searchKey: '' }, this._search)}><ion-icon name="close"></ion-icon></Button>
                </div>
            </div>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <TrashConfirmModal trashOkAction={this._trashEvent} trashCancelAction={this._trashCancel} showTrashConfirm={showTrashConfirm} />
        </>
    }
}