import React from 'react'
import {MetaService} from '../../../services'
import {Button} from '../../../elements'
import {Box} from '../../../components'
const mS = new MetaService()
const Robots = () => {
    mS.setTitle('Robots', 'admin')
    return <>
        <h1 className="title flex">robots.txt <a href="/robots.txt" target="_blank" title="Click to view"><span className="back-icon"><i className='hi-paper-plane'></i></span></a></h1>
        <form className="columns ng-untouched ng-pristine ng-valid" noValidate>
            <div className="left">
                <Box title="File Content">
                    <textarea className="description ng-untouched ng-pristine ng-valid" formcontrolname="urls" placeholder="Type your robots.txt here"></textarea>
                </Box>
            </div>
            <div className="right">
                <Box title="Meta">
                    <Button className="btn btn-primary btn-yellow has-icon" type="submit" disabled title="Please make some chnages to save">Save</Button>
                </Box>
            </div>
        </form>
    </>
}
export default Robots