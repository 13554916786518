import { base } from '../../constants/Data'
import {Img} from '../../elements'
const UserImage = ({user}) => {
    return <>
        {
            user.image ?
            <Img src={base.imageBaseUrl+JSON.parse(user.image)[0]} title={user.name} />:
            <ion-icon name="person-circle"></ion-icon>
        }
    </>
}

export default UserImage