import React, {Component} from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import {TrashConfirmModal, Table} from '../../../components'
import {CSVLink} from 'react-csv'
import {mainUrl} from '../../../constants/Data'
import {OrderService, UserService, MiscService, LocationService, ValidationService, DataService, RouterService, MetaService} from '../../../services'
import {Button, Badge, Actor} from '../../../elements'
const dS = new DataService('log'),
uS = new UserService(),
mS = new MetaService(),
locS = new LocationService(),
miS = new MiscService(),
vS = new ValidationService(),
rS = new RouterService(),
oS = new OrderService()
const commonPageSize = 50
const filterTags = require('./filterTags.json')
export default class Orders extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state = {
            societies: [],
            activeFilterIndex: 0,
            workers: [],
            worker: null,
            tableData: [],
            activeItem: null,
            showTrashConfirm: false,
            checkedAll: false,
            trashAction: false,
            startDate: '',
            endDate: '',
            checkedList: [],
            isMultipleDelete: false,
            searchKey: '',
            activeShownTotal: commonPageSize,
            filteredList: [],
            searchList: [],
            activeFilter: null
        }
    }
    componentDidMount () {
        mS.setTitle('Orders List', 'admin')
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        this._getWorkersData()
        this.setState({ trashAction: urlData[2] === 'trash', activeFilter: filterTags[0] }, this._getSocietiesListData)
        document.getElementById('content-box').addEventListener('scroll', this._trackScrolling)
    }
    componentWillUnmount() {
        document.getElementById('content-box').removeEventListener('scroll', this._trackScrolling)
    }
    _trackScrolling = () => {
        let { filteredList, activeShownTotal } = this.state,
        wrappedElement = document.getElementById('list-wrapper')
        if (this._isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                this._showMore()
        }
    }
    _isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    _showMore = () => {
        let { activeShownTotal } = this.state
        miS.showPreloader()
        setTimeout(() => {
            miS.hidePreloader()
            this.setState({ activeShownTotal: activeShownTotal + commonPageSize})
        }, 300)
    }
    _getSocietiesListData = async () => {
        const result = await locS.getSocities()
        if (result.status) {
            let tableData = result.data.reverse()
            this.setState({ societies: tableData }, () => {
                let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
                if (urlData[4])
                    this._getOrdersData(urlData[4])
                else
                    this._getOrdersData()
            })
            if (result.data.length === 0)
                miS.showAlert({ type: 'error', msg: 'No societies added yet!' })
        } else miS.showAlert({ type: 'error', msg: 'No societies added yet!' })
    }
    _getWorkersData = async () => {
        let workers = []
        const result = await uS.getUsers()
        if (result.status) {
            if (result.data.length === 0) {
                miS.showAlert({ type: 'error', msg: 'No users added yet!' })
            } else {
                result.data.forEach(result => {
                    if (result.role === 'worker')
                        workers.push({label: result.name, value: result.uid})
                })
                this.setState({ workers })
            }
        }
    }
    _getOrdersData = async (hash = '') => {
        let { societies, activeFilterIndex, trashAction } = this.state
        let invoicesList = await oS.getInvoices(), newtableData = []
        const result = await oS.getOrders()
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(e => {
                invoicesList.data.forEach(el => {
                    if (e.uid === el.orderId) {
                        e.invoice = el.invoiceNo
                    }
                })
                if (!trashAction) {
                    if (typeof e.trash === "undefined") {
                        tableData.push(e)
                    } else if (!e.trash)
                        tableData.push(e)
                } else {
                    if (e.trash)
                        tableData.push(e)
                }
            })
            tableData.forEach(e => {
                e.workerdIds = ''
                e.workerNames = ''
                if (e.status !== 'initialised' && e.status !== 'payment-fail') {
                    if (typeof e.assignWorker === "undefined") { // Not Direclty assigned
                        let activeSociety = ''
                        try {
                            activeSociety = JSON.parse(e.activeAddress).activeSociety
                        } catch (e) {
                            console.log('error', e)
                            activeSociety = ''
                        }
                        if (['null', null, 'undefined',''].includes(activeSociety) || typeof activeSociety === 'undefined') {
                            e.workerNames = 'Not Assigned'
                        } else {
                            societies.forEach(s => {
                                activeSociety = vS.isValidJson(activeSociety) ? JSON.parse(activeSociety): activeSociety;
                                if (s.uid === activeSociety.uid)
                                    e.assignWorker = s.assignWorker // old worker === activeSociety.assignWorker
                            })
                        }
                    }
                    if (e.assignWorker) {
                        let workers = JSON.parse(e.assignWorker)
                        if (Array.isArray(workers)) {
                            workers.forEach((w, l) => {
                                e.workerNames += w.label
                                e.workerdIds += w.value
                                if (l !== workers.length - 1) {
                                    e.workerdIds += ', '
                                    e.workerNames += ', '
                                }
                            })
                        }
                    } else {
                        e.workerNames = 'Not Assigned'
                    }
                }
                newtableData.push(e)
            })
            this.setState({ tableData: newtableData, filteredList: newtableData }, () => {
                let activefilterValue = 'all'
                if (hash !== '')
                    activefilterValue = hash
                filterTags.forEach(( e, i ) => {
                    if (e.value === activefilterValue)
                        activeFilterIndex = i
                })
                filterTags.forEach(e => {
                    if (Number(e.id) === activeFilterIndex+1)
                        activefilterValue = e.value
                })
                this._filterItems( activefilterValue, activeFilterIndex)
            })
            if (result.data.length === 0)
                miS.showAlert({ type: 'error', msg: 'No orders placed yet!' })
        } else miS.showAlert({ type: 'error', msg: 'No orders placed yet!' })
    }
    _trashCancel = () => {
        this.setState({ activeItem: null, showTrashConfirm: false })
    }
    _deleteSelectOrder = () => {
        let { activeItem } = this.state
        this._deleteOrder(activeItem.uid)
    }
    _deleteOrder = async (uid, task = 'trash') => {
        let query = {
            uid
        }, { trashAction, tableData } = this.state
        if (trashAction && task === 'trash') {
            const result = await oS.deleteOrder({ query: JSON.stringify(query) })
            if (result.status) {
                miS.showAlert({ type: 'success', msg: 'Order deleted successfully!' })
                this.setState({ activeItem: null, showTrashConfirm: false }, this._getOrdersData)
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to delete order!' })
        } else {
            let orderContent = null
            tableData.forEach(e => {
                if (e.uid === uid)
                    orderContent = e
            })
            orderContent.trash = task === 'trash'
            delete orderContent._id
            let data = {
                query: JSON.stringify({uid: orderContent.uid}),
                orderData: JSON.stringify(orderContent)
            }
            const result = await oS.updateOrder(data)
            if (result.status) {
                let logData = {
                    log: this.user.name+' updated by the order status to '+task+' with website for order Id #'+orderContent.uid,
                    timing: new Date().getTime(),
                    type: 'info',
                    userId: this.user.uid,
                    uid: new Date().getTime()
                }
                dS.save(logData)
                miS.showAlert({ type: 'success', msg: 'Order '+task+'d successfully!' })
                this.setState({ activeItem: null, showTrashConfirm: false }, this._getOrdersData)
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to '+task+' Order!' })
        }
    }
    _toggleCheck = uid => {
        let {checkedList, filteredList} = this.state, newCheckedList = [], found = false
        checkedList.forEach(e => {
            if (e === uid)
                found = true
            else
                newCheckedList.push(e)
        })
        if (!found)
            newCheckedList.push(uid)
        this.setState({ checkedAll: (filteredList.length === newCheckedList.length), checkedList: newCheckedList })
    }
    _getTableData = filterData => {
        let data = [], allOrder = '', { worker, checkedAll, startDate,trashAction, endDate, checkedList } = this.state
        filterData.forEach(( item, index ) => {
            let orderType = []
            allOrder = JSON.parse(item.orderData).cartData
            allOrder.forEach(e => {
                if (orderType.includes(e.type))
                    orderType.push(e.type)
            })
            let orderTime = item.timing,
            startTime = startDate === ''?'':new Date(startDate),
            endTime = endDate === ''?new Date():new Date(endDate);
            if (orderTime >= startTime && orderTime <= endTime &&
                (
                    (['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(item.status) || ['cancelled', 'deactivate', 'refund', 'initialised','payment-fail'].includes(item.status)) && (worker === null || (worker !== null && item.workerNames.includes(worker.label)))
                )
            ) {
                let e = {
                    checkAction: <span className="check-all" onClick={() => this._toggleCheck(item.uid)}>
                        {
                            checkedAll || checkedList.includes(item.uid) ?
                            <ion-icon class="orange" name="checkmark-circle-outline"></ion-icon>
                            :<ion-icon name="ellipse-outline"></ion-icon>
                        }
                    </span>,
                    orderId: <>
                        #{item.uid}
                        <span className="order-type">
                        {
                            orderType.includes('service') &&
                            <span className="hammer-outline">
                                <ion-icon name="hammer-outline"></ion-icon> 
                                <span className="hover-action">Service</span>
                            </span>
                        }
                        {
                            orderType.includes('servicePackage') &&
                            <span className="cube-outline">
                                <ion-icon name="cube-outline"></ion-icon>
                                <span className="hover-action">Service Package</span>
                            </span>
                        }
                        {
                            orderType.includes('subscription') &&
                            <span className="ribbon-outline">
                                <ion-icon name="ribbon-outline"></ion-icon>
                                <span className="hover-action">Subscription</span>
                            </span>
                        }
                        {
                            orderType.includes('product') &&
                            <span className="list-circle-outline">
                                <ion-icon name="list-circle-outline"></ion-icon>
                                <span className="hover-action">Product</span>
                            </span>
                        }</span>
                    </>,
                    name: JSON.parse(item.userData).name,
                    timing: miS.getFormattedDate(item.timing),
                    status: <Badge item={item.status} />,
                    discount: JSON.parse(item.orderData).discount ?
                        <>₹ {(JSON.parse(item.orderData).cartTotal - JSON.parse(item.orderData).discount).toFixed(2)}</>:
                        <>₹ {(JSON.parse(item.orderData).cartTotal)?.toFixed(2)}</>,
                    workerNames:item.workerNames === 'Not Assigned'?<p className="error">{item.workerNames}</p>:item.workerNames,
                    action: trashAction ?
                        <div className="action-bar">
                            <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                            <Actor type='undo' onClick={() => this._deleteOrder(item.uid, 'restore')} />
                        </div>:
                        <div className="action-bar">
                            <Actor type='trash' onClick={() => this.setState({ activeItem: item, showTrashConfirm: true })} />
                            <Actor type='view' onClick={() => rS.navigateTo('/admin/order/view/' + item.uid, true)} />
                            {item.invoice ? <Actor type='download' onClick={() => rS.navigateTo(mainUrl+"invoices/invoice_"+item.invoice+".pdf", true)} /> : ''}
                        </div>
                }
                data.push(e)
            }
        })
        return data
    }
    _filterItems = ( key, activeFilterIndex ) => {
        let { tableData } = this.state, filteredList = []
        if (key === 'all')
            filteredList = tableData
        else {
            filteredList = tableData.filter(e => {
                return e.status === key
            })
        }
        this.setState({ filteredList, activeFilterIndex: activeFilterIndex })
    }
    _renderFilterTags = filterData => {
        let { activeFilterIndex, tableData } = this.state
        return filterData.map(( item, index ) => {
            return <span className={index === activeFilterIndex ? 'link active' : 'link item'} key={index} onClick={() => this._filterItems(item.key, index)}>{item.item} ({
                (item.key === 'all') ? tableData.length :
                    tableData.reduce(( count, e) => {
                        return item.key === e.status ? count + 1 : count
                    }, 0) })</span>
        })
    }
    _search = () => {
        let { searchKey, tableData, startDate, endDate } = this.state,
            filteredList = [], tempTitle = ''
            tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = (JSON.parse(e.userData).name).toLowerCase()
            if (e.uid.search(searchKey) !== -1 || tempTitle.search(searchKey) !== -1) {
                let orderTime = e.timing,
                startTime = startDate === ''?'':new Date(startDate),
                endTime = endDate === ''?new Date():new Date(endDate)
                if (orderTime >= startTime && orderTime <= endTime)
                filteredList.push(e)
            }
        })
        this.setState({ filteredList, activeFilterIndex: 0 })
    }
    _startDate = () => {
        let { filteredList, startDate, endDate } = this.state,
        item = []
        filteredList.forEach(e => {
            item = (miS.getFormattedDate(e.timing))
            if (item  >= miS.getFormattedDate(new Date(startDate))) {
                let orderTime = e.timing,
                startTime = startDate === ''?'':new Date(startDate),
                endTime = endDate === ''?new Date():new Date(endDate)
                if (orderTime >= startTime && orderTime <= endTime)
                    filteredList.push()
            }
        })
    }
    _deleteChecked = () => {
        let { checkedList, checkedAll, filteredList } = this.state
        if (checkedAll)
            checkedList = filteredList
        checkedList.forEach(e => this._deleteOrder(e.uid))
        this.setState({ checkedList: [], isMultipleDelete: false })
    }
    render() {
        let { activeFilter, worker, workers, checkedList, tableData, startDate, trashAction, endDate, checkedAll, showTrashConfirm, filteredList, searchKey, isMultipleDelete, activeShownTotal } = this.state,
        exportedFileName = 'export_'+new Date().getDate()+'_'+new Date().getMonth()+'_'+new Date().getFullYear()+'.csv',
        exportedData = [], cartTotalTax = 0
        filteredList.forEach((e, j) => {
            let orderData = JSON.parse(e.orderData)
            if (!orderData.discount) {
                orderData.discount = 0
            }
            if (typeof orderData.cartTotal !== "number")
                orderData.cartTotal = Number(orderData.cartTotal)
            if (typeof orderData.discount !== "number")
                orderData.discount = Number(orderData.discount)
            cartTotalTax = 0
            orderData.cartData.forEach(( c, i ) => {
                c.tax = Number(c.tax);
                c.price = Number(c.price).toFixed(2)
                c.taxAmount = (c.tax * c.price/(100+c.tax)).toFixed(2)
                cartTotalTax += c.taxAmount
                let ex = {
                    Customer_Id: (i === 0) ? JSON.parse(e.userData).uid: '',
                    Order_Id: (i === 0) ? e.uid: '',
                    Customer_Name: (i === 0) ? JSON.parse(e.userData).name: '',
                    Mobile_No: (i === 0) ? JSON.parse(e.userData).mobile: '',
                    Address: ( e.activeAddress && e.activeAddress !== "undefined") ?((i === 0) ? miS.parseAddress(JSON.parse(e.activeAddress)): ''): '',
                    Pin_Code: ( e.activeAddress && e.activeAddress !== "undefined")? ((i === 0) ? JSON.parse(e.activeAddress).pinCode: ''): '',
                    Status: (i === 0) ? e.status: '',
                    Vehicle_Details: c.activeCar ? c.activeCar.name + ' ' + c.activeCar.Segment: '',
                    Vehicle_No: c.activeCar ? c.activeCar.registrationNo ?c.activeCar.registrationNo: '': '',
                    Package: c.type === 'servicePackage'?c.title: '',
                    Package_Frequency: c.billing ? c.billing: '',
                    Service: c.type === 'service'?c.title: '',
                    Product: c.type === 'product'?c.title: '',
                    Subscription: c.type === 'subscription'?c.title: '',
                    Total: (i === 0) ? (['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?(orderData.cartTotal).toFixed(2): ''): '',
                    Discount: (i === 0) ? (['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?Number((orderData.discount).toFixed(2)): ''): '',
                    Net_Payment: (i === 0) ? (['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?(orderData.cartTotal - Number((orderData.discount).toFixed(2))).toFixed(2): ''): '',
                    GST: ( e.status === "initialised") ? '': (i === 0) ? cartTotalTax: '',
                    Payment_Date: (i === 0) ?( e.status === "initialised") ? '': (e.paymentData && ['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?miS.getFormattedDate(e.timing): ''): '',
                    TransactionId: (i === 0) ?( e.status === "initialised") ? '': (e.paymentData && ['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?e.paymentData.TXNID: ''): '',
                    Payment_Mode: (i === 0) ?( e.status === "initialised") ? '': (e.paymentData && ['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?'Payment Gateway': 'Manual'): '',
                    Invoice: (i === 0) ? "invoice_"+e.invoice+".pdf": '',
                }
                exportedData.push(ex)
            })
        })
        let tableContent = {
            headers: [
                {
                    template: <span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll)
                            this.setState({ checkedAll: !checkedAll, checkedList: [] })
                        else {
                            filteredList.forEach(e => {
                                newCheckedList.push(e.uid)
                            })
                            this.setState({ checkedAll: !checkedAll, checkedList: newCheckedList })
                        }
                    }}>
                        {
                            checkedAll || checkedList.length === filteredList.length?
                            <ion-icon class="orange" name="checkmark-circle-outline"></ion-icon>:
                            <ion-icon name="ellipse-outline"></ion-icon>
                        }
                    </span>
                },
                {
                    label: 'Order',
                    key: 'order'
                },
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Date',
                    key: 'date'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Total',
                    key: 'total'
                },
                {
                    label: 'Assigned Worker',
                    key: 'assignedWorker'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: this._getTableData(filteredList)
        }
        return <>
            <h1 className="flex title">
                {
                    trashAction?'Trashed': 'All'
                } Orders
                {
                    !trashAction &&
                    <>
                    {
                        filteredList.length !== 0 &&
                        <span className="btn btn-primary btn-small ml20 export-btn white">
                            <CSVLink data={exportedData} filename={exportedFileName} className="btn export-btn" target="_blank">Export</CSVLink>
                        </span>
                    }
                    </>
                }
                {
                    (checkedList.length !== 0 || checkedAll) &&
                    <Button type="button" onClick={() => {
                        this.setState({ isMultipleDelete:true, showTrashConfirm: true})
                    }} className="btn btn-outline btn-small">Delete</Button>
                }
            </h1>
            <div className="filter-box">
                <div className="filter-box column">
                    <div className="flex date-filter overflow-visible">
                        <div className="form-group">
                            <DatePicker
                                selected={startDate}
                                onChange={startDate => this.setState({ startDate })}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                className="datePicker-filter"
                                placeholderText="From:"
                            />
                        </div>
                        <div className="form-group">
                            <DatePicker
                                selected={endDate}
                                onChange={endDate => this.setState({ endDate })}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                className="datePicker-filter"
                                placeholderText="To:"
                            />
                        </div>
                        <div className="form-group ml10">
                            <label className="filter-label-add">Status: </label>
                        <Select
                            value={activeFilter}
                            defaultValue={activeFilter}
                            onChange={(e, i) => this.setState({ activeFilter: e }, () => this._filterItems(e.value, i))}
                            options={filterTags}
                        />
                    </div>
                    </div>
                    
                </div>
                <div className="worker-filter-box relative">
                <label className="filter-label-add">Assigned Worker: </label>
                    <div className="body overflown">
                        {
                            workers.length !== 0 &&
                            <Select
                                value={worker}
                                defaultValue={worker}
                                onChange={worker => this.setState({ worker })}
                                options={workers}
                            />
                        }
                    </div>
                </div>
                <div className="search-box">
                    <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                        const reg = /^[A-Za-z0-9 ]+$/
                        if (e.target.value === '' || reg.test(e.target.value))
                            this.setState({ searchKey: e.target.value }, this._search)
                    }} />
                    <Button type="button" onClick={() => this.setState({ filteredList: tableData, searchKey: '' }, this._search)}><ion-icon name="close"></ion-icon></Button>
                </div>
            </div>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <TrashConfirmModal trashOkAction={() => {
                isMultipleDelete?this._deleteChecked():this._deleteSelectOrder()
            }} trashCancelAction={this._trashCancel} showTrashConfirm={showTrashConfirm} isMultipleDelete={isMultipleDelete} />
        </>
    }
}