import React from 'react'
import { badge } from '../../constants/Enums';
import './style.scss';
const Badge = ({item}) => {
    return <span className={"badge "+item}>
        {item !== '' && <>
            <ion-icon name={badge[item]['icon']}></ion-icon>
            <span>{badge[item]['label']}</span>
        </>}
    </span>
}
export default Badge