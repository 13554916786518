import React, {Component} from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import {activeDateFilters, chartColors, b2bServices, fuelOptions} from '../../../constants/Enums'
import 'chart.js/auto';
import {CSVLink} from 'react-csv'
import { Bar, Line } from 'react-chartjs-2'
import {Img, Button, Actor} from '../../../elements'
import { base } from '../../../constants/Data'
import {UserService, MetaService, MiscService, DataService, CarService, ValidationService} from '../../../services'
import {Table, Box} from '../../../components'
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();
const rS = new DataService('recommendation'),
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
coS = new DataService('companyOrder'),
cdS = new DataService('companyData'),
bwS = new DataService('businessWork'),
vS = new ValidationService(),
cS = new CarService(),
defaultCompanyData = {
    adHocData: {},
    carStatusData: {
        'Active': [0, 0, 0, 0, 0, 0, 0],
        'Inactive': [0, 0, 0, 0, 0, 0, 0]
    },
    data: {
        'Services': [0, 0, 0, 0, 0, 0, 0],
        'Products': [0, 0, 0, 0, 0, 0, 0]
    },
    recommendationData: {
        'Approved': [0, 0, 0, 0, 0, 0, 0],
        'Denied': [0, 0, 0, 0, 0, 0, 0],
        'Completed': [0, 0, 0, 0, 0, 0, 0]
    },
    serviceData: {
        'Tyre Inflation': [0, 0, 0, 0, 0, 0, 0],
        'Waterless Clean': [0, 0, 0, 0, 0, 0, 0],
        'Physical Damage Observed': [0, 0, 0, 0, 0, 0, 0],
        'Physical Damage Not Observed': [0, 0, 0, 0, 0, 0, 0],
        'Cranking Ok': [0, 0, 0, 0, 0, 0, 0],
        'Cranking Not Ok': [0, 0, 0, 0, 0, 0, 0],
        'Gold Wash': [0, 0, 0, 0, 0, 0, 0],
        'Health Checkup / Inspection': [0, 0, 0, 0, 0, 0, 0],
        'Battery Charging': [0, 0, 0, 0, 0, 0, 0],
        'Puncture Repair': [0, 0, 0, 0, 0, 0, 0]
    }
}
export default class CompanyDashboard extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state = {
            completed: [],
            activeBranches: [],
            branches: [],
            weekArray: miS.getLast7Days(),
            companyData: defaultCompanyData,
            showOrderConfirm: false,
            activeRecommendation: null,
            denied: [],
            approved: [],
            todayDenied: 30,
            todayApproved: 20,
            filteredList: [],
            companyDashboardData: false,
            recommendData: [],
            companyOrders: [],
            activeDateFilter: activeDateFilters[0],
            fromFilterDate: miS.getPreviousDate(2),
            toFilterDate: miS.getPreviousDate(1),
            carsData: {
                inactiveCars: 0,
                activeCars: 0
            }
        }
    }
    
    componentDidMount () {
        mS.setTitle('Dashboard', 'public')
        let orgId = false
        if (this.user?.role === 'representative') {
            orgId = this.user.parent?.value
            this._getCompanyBranches(orgId)
        } else if (this.user?.role === 'company') {
            orgId = this.user.uid
            this._getCompanyBranches(orgId)
        } else {
            this._getCompanyData()
            this._getCompanyOrders()
            this._getCompanyRecommendation()
            this._getCarsData()
        }
    }
    _getCompanyBranches = async v => {
        let query = {
            parentId: v
        }, branches = [], { activeDateFilter } = this.state
        const result = await uS.getCompanyBranches({ query: JSON.stringify(query) })
        if (result.status) {
            result.data.forEach(e => {
                branches.push({
                    label: e.name,
                    value: e.uid
                })
            });
            let activeBranches = uS.getActiveBranches() === null ? uS.getActiveBranches() : branches
            this.setState({ branches, activeBranches }, () => {
                uS.setGlobalActiveBranches(activeBranches)
                this._getDashboardData(activeDateFilter, activeBranches)
            })
        }
    }
    _getCarsData = async (branches = false) => {
        let query = {
            ownerId: ['manager','representative'].includes(this.user.role) ? this.user.parent?.value : this.user.uid
        }
        if (branches) {
            query.ownerId = {
                $in: branches
            }
        }
        const result = await cS.getCarsStatusCount({ query: JSON.stringify(query) })
        if (result.status)
            this.setState({ carsData: result.data })
    }
    _getDashboardData = (activeDateFilter, activeBranches) => {
        let activeBranchIds = []
        activeBranches?.forEach( e => {
            activeBranchIds.push(e.value)
        })
        this._getCompanyData(activeBranchIds)
        this._getCompanyOrders(activeBranchIds)
        this._getCompanyRecommendation(activeBranchIds)
        this._getCarsData(activeBranchIds)
    }
    _getCompanyRecommendation = async (branches = false) => {
        let query = {
            ownerId: ['manager','representative'].includes(this.user.role) ? this.user.parent?.value : this.user.uid
        }
        if (branches) {
            query.ownerId = {
                $in: branches
            }
        }
        const result = await rS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data.length === 0) {
                this.setState({ recommendData: [] })
            } else {
                let recommendData = []
                result.data.reverse().forEach(e => {
                    e.carDetails = JSON.parse(e.carDetails)
                    e.carImages = JSON.parse(e.carImages)
                    e.recommendData = JSON.parse(e.recommendData)
                    recommendData.push(e)
                });
                this.setState({ recommendData, filteredList: recommendData })
            }
        }
    }
    _getCompanyData = async (branches = false) => {
        let { weekArray, companyData } = this.state
        let query = {
            ownerId: ['manager','representative'].includes(this.user.role) ? this.user.parent?.value : this.user.uid,
            statusDate: { $in: weekArray },
            dataType: 'car'
        }
        if (branches) {
            query.ownerId = {
                $in: branches
            }
        }
        let result = await cdS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data.length === 0) {
                this.setState({ companyOrders: null })
            } else {
                result.data.forEach(e => {
                    weekArray.forEach((d, di) => {
                        if (miS.isSameDate(Number(e.actionDate), d)) {
                            if (e.status === 'active')
                                companyData.companyData['Active'][di]++
                            if (e.status === 'inactive')
                                companyData.companyData['Inactive'][di]++
                        }
                    });
                })
            }
        }
    }
    _getCompanyOrders = async (branches = false) => {
        let query = {
            ownerId: ['manager','representative'].includes(this.user.role) ? this.user.parent?.value : this.user.uid
        }
        if (branches) {
            query.ownerId = {
                $in: branches
            }
        }
        let {approved, denied, weekArray, completed} = this.state
        let companyData = defaultCompanyData
        const result = await coS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data.length === 0) {
                this.setState({ companyOrders: null })
            } else {
                let companyOrders = []
                result.data.reverse().forEach(e => {
                    if (e.carDetails && e.carDetails !== "undefined" && vS.isValidJson(e.carDetails) && e.orderData && vS.isValidJson(e.orderData)) {
                        e.carDetails = JSON.parse(e.carDetails)
                        e.carImages = JSON.parse(e.carImages)
                        e.orderData = vS.isValidJson(e.orderData) ? JSON.parse(e.orderData) : false
                        if (['approved', 'assigned'].includes(e.status)) {
                            weekArray.forEach((d, di) => {
                                if (miS.isSameDate(Number(e.actionDate), d))
                                companyData.recommendationData['Approved'][di]++
                            });
                            approved.push(e.uid)
                        } else if (e.status === 'denied') {
                            weekArray.forEach((d, di) => {
                                if (miS.isSameDate(Number(e.actionDate), d))
                                companyData.recommendationData['Denied'][di]++
                            });
                            denied.push(e.uid)
                        } else if (e.status === 'completed') {
                            weekArray.forEach((d, di) => {
                                if (miS.isSameDate(Number(e.actionDate), d))
                                companyData.recommendationData['Completed'][di]++
                            });
                            if (e.type === 'product') {
                                weekArray.forEach((d, di) => {
                                    if (miS.isSameDate(Number(e.actionDate), d)) {
                                        let c = e.orderData[0]
                                        if (companyData.adHocData[c.title ? c.title : c.label]) {
                                            companyData.adHocData[c.title ? c.title : c.label][di]++
                                        } else {
                                            companyData.adHocData[c.title ? c.title : c.label] = [0,0,0,0,0,0,0]
                                            companyData.adHocData[c.title ? c.title : c.label][di] = 1
                                        }
                                        companyData.data['Products'][di]++
                                    }
                                });
                            } else {
                                weekArray.forEach((d, di) => {
                                    if (miS.isSameDate(Number(e.actionDate), d)) {
                                        let c = e.orderData[0]
                                        if (companyData.adHocData[c.title ? c.title : c.label]) {
                                            companyData.adHocData[c.title ? c.title : c.label][di]++
                                        } else {
                                            companyData.adHocData[c.title ? c.title : c.label] = [0,0,0,0,0,0,0]
                                            companyData.adHocData[c.title ? c.title : c.label][di] = 1
                                        }
                                        companyData.data['Services'][di]++
                                    }
                                });
                            }
                            completed.push(e.uid)
                        }
                    }
                    companyOrders.push(e)
                });
                this.setState({ companyOrders, filteredList: companyOrders, approved, denied }, () => this._getCompanyWorkData(companyData, branches))
            }
        }
    }
    _getCompanyWorkData = async (companyData, branches = false) => {
        let query = {
            ownerId: ['manager','representative'].includes(this.user.role) ? this.user.parent?.value : this.user.uid
        }
        if (branches) {
            query.ownerId = {
                $in: branches
            }
        }
        let {weekArray} = this.state
        const result = await bwS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data.length !== 0) {
                weekArray.forEach((d, di) => {
                result.data.reverse().forEach(e => {
                        if (miS.isSameDate(Number(e.uid), d)) {
                            b2bServices.forEach(b => {
                                companyData.serviceData[b.value][di]++
                            })
                        }
                    });
                });
            }
            this.setState({ companyData })
        }
    }
    _browsePurchase = item => {
        this.setState({activeRecommendation: item}, () => miS.openModal('recommendation-modal'))
    }
    _approvePurchase = async item => {
        let {approved, todayApproved} = this.state, purchaseData = {}
        approved.push(item.uid)
        purchaseData.recommendData = []
        purchaseData.comments = item.comments
        purchaseData.type = item.type ? item.type : 'service'
        purchaseData.recommendData[0] = item
        purchaseData.orderData = JSON.stringify(purchaseData.recommendData)
        delete purchaseData.recommendData
        purchaseData.carDetails = JSON.stringify(item.carDetails)
        purchaseData.carImages = JSON.stringify(item.carImages)
        purchaseData.ownerId = item.ownerId
        purchaseData.actionDate = new Date().getTime()
        purchaseData.uid = item.uid
        purchaseData.status = 'approved'
        const result = await coS.save(purchaseData)
        if (result.status) {
            miS.closeModal('recommendation-modal')
            this._getCompanyOrders()
            this.setState({ approved, todayApproved: todayApproved+1, activeRecommendation: null },() => {
                miS.showAlert({ type: 'success', msg: 'Recommendation has been approved and an order is generated for the same!' })
            })
        }
    }
    _denyPurchase = async item => {
        let {denied, todayDenied} = this.state, purchaseData = {}
        denied.push(item.uid)
        purchaseData.recommendData = []
        purchaseData.comments = item.comments
        purchaseData.type = item.type ? item.type : 'service'
        purchaseData.recommendData[0] = item
        purchaseData.orderData = JSON.stringify(purchaseData.recommendData)
        delete purchaseData.recommendData
        purchaseData.carDetails = JSON.stringify(item.carDetails)
        purchaseData.carImages = JSON.stringify(item.carImages)
        purchaseData.ownerId = item.ownerId
        purchaseData.actionDate = new Date().getTime()
        purchaseData.uid = item.uid
        purchaseData.status = 'denied'
        const result = await coS.save(purchaseData)
        if (result.status) {
            miS.closeModal('recommendation-modal')
            this._getCompanyOrders()
            this.setState({ denied, todayDenied: todayDenied+1, activeRecommendation: null },() => {
                miS.showAlert({ type: 'error', msg: 'Recommendation is denied' })
            })
        }
    }
    _getTableData = filterData => {
        let data = [], {approved, denied, completed} = this.state
        filterData.map(( item, index ) => {
            let car = item.carDetails
            let carData = car.carData
            item?.recommendData?.map((e, i) => {
                e.carImages = item.carImages
                e.carDetails = item.carDetails
                e.comments = item.comments
                e.ownerId = item.ownerId
                e.uid = typeof e.uid === 'string' ? e.uid : String(e.uid)
                if (!approved.includes(e.uid) && !denied.includes(e.uid) && !completed.includes(e.uid)) {
                    let el = {
                        carData: <div className='flex'>
                            {
                                carData.image_path?
                                <Img alt="Blank Placeholder" src={base.carImageBase+carData.image_path} style={{maxHeight: 40, maxWidth: 64, marginRight: 10}} />:
                                <p>No Image</p>
                            }
                            <div className='fs12'>
                                {(car.registrationNo).toUpperCase()}<br />
                                {carData.name+' '+carData.Segment}
                            </div>
                        </div>,
                        cartitle: <div className='flex'>
                            {e.image && <Img src={base.imageBaseUrl+JSON.parse(e.image)[0]} alt={e.title} style={{ width: 50, borderRadius: 5, marginRight: 5, marginBottom: 'auto' }} />}
                            {
                                e.title ?
                                <span>{ e.title}{e.fuelQuantity && e.fuelType?.value && ' ('+e.fuelQuantity+' lt. '+e.fuelType.value+')'}</span>
                                : e.value
                            }
                        </div>,
                        action: <div className="action-bar">
                            <Actor type='view' onClick={() => this._browsePurchase(e)} />
                            {
                                ['company', 'representative'].includes(this.user.role) && <>
                                    <Actor type='check' onClick={() => this._approvePurchase(e)} />
                                    <Actor type='close' onClick={() => this._denyPurchase(e)} />
                                </>
                            }
                        </div>
                    }
                    data.push(el)
                }
            })
        })
        return data
    }
    render() {
        let { activeBranches, branches, carsData, activeRecommendation, activeDateFilter, fromFilterDate, toFilterDate, recommendData, companyData, weekArray, activeShownTotal } = this.state,
        {activeCars, inactiveCars} = carsData
        let adHocDataSet = []
        let i = 0;
        Object.entries(companyData.adHocData).forEach((el, v) => {
            adHocDataSet.push(
            {
                data: el[1],
                fill: true,
                borderColor:chartColors[i],
                backgroundColor: chartColors[i],
                label: el[0]
            })
            i++
        });
        let adHocData = {
            labels: weekArray,
            datasets: adHocDataSet
        }
        let companyDashboardData = {
            carServiceCount: 33,
            averageCarServiceCount: 33
        },
        carStatusData = {
            labels: weekArray,
            datasets: [
                {
                    data: companyData['carStatusData']['Active'],
                    fill: true,
                    backgroundColor: chartColors[0],
                    label: 'Active'
                },
                {
                    data: companyData['carStatusData']['Inactive'],
                    fill: true,
                    backgroundColor: chartColors[1],
                    label: 'Inactive'
                }
            ]
        },
        data = {
            labels: weekArray,
            datasets: [
                {
                    data: companyData['data']['Services'],
                    fill: true,
                    backgroundColor: chartColors[0],
                    label: 'Services'
                },
                {
                    data: companyData['data']['Products'],
                    fill: true,
                    backgroundColor: chartColors[1],
                    label: 'Products'
                }
            ]
        },
        recommendationData = {
            labels: weekArray,
            datasets: [
                {
                    data: companyData['recommendationData']['Approved'],
                    fill: true,
                    backgroundColor: chartColors[0],
                    label: 'Approved'
                },
                {
                    data: companyData['recommendationData']['Denied'],
                    fill: true,
                    backgroundColor: chartColors[4],
                    label: 'Denied'
                },
                {
                    data: companyData['recommendationData']['Completed'],
                    fill: true,
                    backgroundColor: chartColors[1],
                    label: 'Completed'
                }
            ]
        },
        serviceData = {
            labels: weekArray,
            datasets: [
                {
                    data: companyData['serviceData']['Tyre Inflation'],
                    fill: true,
                    backgroundColor: chartColors[0],
                    label: 'Tyre Inflation'
                },
                {
                    data: companyData['serviceData']['Waterless Clean'],
                    fill: true,
                    backgroundColor: chartColors[1],
                    label: 'Waterless Clean'
                },
                {
                    data: companyData['serviceData']['Physical Damage Observed'],
                    fill: true,
                    backgroundColor: chartColors[0],
                    label: 'Physical Damage Observed'
                },
                {
                    data: companyData['serviceData']['Physical Damage Not Observed'],
                    fill: true,
                    backgroundColor: chartColors[1],
                    label: 'Physical Damage Not Observed'
                },
                {
                    data: companyData['serviceData']['Cranking Ok'],
                    fill: true,
                    backgroundColor: chartColors[0],
                    label: 'Cranking Ok'
                },
                {
                    data: companyData['serviceData']['Cranking Not Ok'],
                    fill: true,
                    backgroundColor: chartColors[1],
                    label: 'Cranking Not Ok'
                },
                {
                    data: companyData['serviceData']['Gold Wash'],
                    fill: true,
                    backgroundColor: chartColors[0],
                    label: 'Gold Wash'
                },
                {
                    data: companyData['serviceData']['Health Checkup / Inspection'],
                    fill: true,
                    backgroundColor: chartColors[1],
                    label: 'Health Checkup / Inspection'
                },
                {
                    data: companyData['serviceData']['Battery Charging'],
                    fill: true,
                    backgroundColor: chartColors[0],
                    label: 'Battery Charging'
                },
                {
                    data: companyData['serviceData']['Puncture Repair'],
                    fill: true,
                    backgroundColor: chartColors[1],
                    label: 'Puncture Repair'
                }
            ]
        }
        let options = {
            scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true
                  }
                }]
            },
            responsive: true
        }
        let tableContent = {
            headers: [
                {
                    label: 'Car',
                    key: 'car'
                },
                {
                    label: 'Product / Service',
                    key: 'product_Service'
                },
                {
                    label: 'Action',
                    key: 'action'
                }
            ],
            content: this._getTableData(recommendData)
        }, exportedData = [], exportedFileName = 'exported_weekly_orders_'+new Date().getDate()+'_'+new Date().getMonth()+'_'+new Date().getFullYear()+'.csv'
        if(companyData.serviceData && Array.isArray(companyData.serviceData)) {
            companyData.serviceData.forEach(e => {
                let ex = {
                    ownerId: e.ownerId,
                    uid: e.uid,
                    registrationNo: e.registrationNo,
                    registrationDate: e.registrationDate,
                    chassisNo: e.chassisNo,
                    engineNo: e.engineNo,
                    ownerName: e.ownerName,
                    vehicleClass: e.vehicleClass,
                    maker: e.maker,
                    fitness: e.fitness,
                    mvTax: e.mvTax,
                    insurance: e.insurance,
                    pucc: e.pucc,
                    fuelType: e.fuelType?.label ?? fuelOptions[0].label,
                    emission: e.emission,
                    rcStatus: e.rcStatus,
                    financed: e.financed,
                    rcImage: e.rcImage,
                    status: e.status ?? 'active',
                    shoot360: e.shoot360 ?? false,
                    shoot360Url: e.shoot360Url,
                    modelKey: e.modelKey
                }
                exportedData.push(e)
            })
        }
        return <>
            <div className="right-side-profile-content">
                <div className="header-title-box">
                    <div className='flex overflow-visible'>
                        <h1 className="title">
                            {miS.translate('dashboard')}
                            <p className='company-title'>{this.user.name}</p>
                        </h1>
                        {
                            companyData.serviceData.length !== 0 &&
                            <span className="btn btn-primary btn-small ml20 export-btn">
                                <CSVLink data={exportedData} filename={exportedFileName} className="btn export-btn" target="_blank">Export</CSVLink>
                            </span>
                        }
                        {
                            ['company', 'representative'].includes(this.user.role) && branches.length > 0 &&
                            <div className='mt20'>
                                <Select
                                    isClearable
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    placeholder='Select Branch(s)'
                                    defaultValue={activeBranches}
                                    onChange={e => {
                                        this.setState({ activeBranches: e }, () => {
                                            uS.setGlobalActiveBranches(e)
                                            this._getDashboardData(activeDateFilter, e)
                                        })
                                    }}
                                    options={branches}
                                    isMulti
                                />
                            </div>
                        }
                        {/* <div className="ml20 mt10 select-filter">
                            <Select
                                value={activeDateFilter}
                                defaultValue={activeDateFilter}
                                onChange={activeDateFilter => this.setState({ activeDateFilter }, () => this._getDashboardData(activeDateFilter, activeBranches))}
                                options={activeDateFilters}
                            />
                        </div> */}
                    </div>
                    {
                        activeDateFilter.value === 'specificDuration' &&
                        <div className="select-filter">
                            <ul>
                                <li>
                                    <label>From Date</label>
                                    <DatePicker
                                        showMonthDropdown={true}
                                        showYearDropdown={true}
                                        dateFormat="MMMM d, yyyy"
                                        onChange={fromFilterDate => this.setState({ fromFilterDate })}
                                        className="date-picker"
                                        selected={fromFilterDate}
                                        maxDate={new Date()}
                                        placeholderText="From Date"
                                    />
                                </li>
                                <li>
                                    <label>To Date</label>
                                    <DatePicker
                                        showMonthDropdown={true}
                                        showYearDropdown={true}
                                        dateFormat="MMMM d, yyyy"
                                        onChange={toFilterDate => this.setState({ toFilterDate })}
                                        className="date-picker"
                                        selected={toFilterDate}
                                        maxDate={new Date()}
                                        placeholderText="To Date"
                                    />
                                </li>
                            </ul>
                        </div>
                    }
                </div>
                {companyDashboardData && <div>
                    <div className='grid-area-section col-1-2'>
                        <div className="box box-1 blank">
                            <h4 className="head flex">
                                <span className='mrauto gray'>No. of Active cars</span>
                                <span className='mlauto bold success'>{activeCars}</span>
                            </h4>
                            <h4 className="head flex">
                                <span className='mrauto gray'>No. of Inactive cars</span>
                                <span className='mlauto bold warning'>{inactiveCars}</span>
                            </h4>
                        </div>
                        <Box title='No. of Cars' boxClass='box-2'>
                            <Bar data={carStatusData} options={options} />
                        </Box>
                    </div>
                    <div className="grid-area-section">
                        <Box title='Daily Services' boxClass='box-2'>
                            <Bar data={serviceData} options={options} />
                        </Box>
                    </div>
                    <div className="grid-area-section">
                        <Box title='Ad-hoc Services / Products'>
                            <div onClick={() => miS.openModal('ad-hoc-graph-modal')}>
                                <Bar data={data} options={options} />
                            </div>
                        </Box>
                    </div>
                    <div className="grid-area-section">
                        <Box title='Recommendations Status'>
                            <Bar data={recommendationData} options={options} />
                        </Box>
                    </div>
                    <div className="grid-area-section">
                        <Box title='Recommendations'>
                            <Table
                                data={tableContent}
                                pageSize={activeShownTotal}
                            />
                        </Box>
                    </div>
                </div>}
            </div>
            <div className="modal" id="ad-hoc-graph-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content big">
                    <Button className="close" onClick={() => miS.closeModal('ad-hoc-graph-modal')}><i className="hi-close"></i></Button>
                    <Line data={adHocData} options={options} />
                </div>
            </div>
            <div className="modal" id="recommendation-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content big">
                    <Button className="close" onClick={() => miS.closeModal('recommendation-modal')}><i className="hi-close"></i></Button>
                    {activeRecommendation && <div className="modal-body p20">
                        <h3>{['service', 'micro-service'].includes(activeRecommendation.type)?'Service':'Product'} Details</h3>
                        <div className='product-box'>
                            {activeRecommendation.image && <Img className={"mbauto"} src={base.imageBaseUrl+JSON.parse(activeRecommendation.image)[0]} alt={activeRecommendation.title} style={{ width:'100%', borderRadius: 5, marginRight: 5, maxHeight: 240 }} />}
                            <div className='description pl20'>
                                <h4>{
                                    activeRecommendation.title ?
                                    <>{
                                        activeRecommendation.title ?
                                        <span>{ activeRecommendation.title}{activeRecommendation.fuelQuantity && activeRecommendation.fuelType?.value && ' ('+activeRecommendation.fuelQuantity+' lt. '+activeRecommendation.fuelType?.value+') '}</span>
                                        : activeRecommendation.value
                                    }</>
                                    : activeRecommendation.value
                                }</h4>
                                {activeRecommendation.title && <div className="content-detail pb40" dangerouslySetInnerHTML={{__html: activeRecommendation.excerpt}}></div>}
                            </div>
                        </div>
                        <div className='clearfix'></div>
                        <hr />
                        <h3>Car Details</h3>
                        <div className='flex'>
                            {
                                activeRecommendation.carDetails.carData.image_path?
                                <Img alt="Blank Placeholder" src={base.carImageBase+activeRecommendation.carDetails.carData.image_path} style={{maxHeight: 40, maxWidth: 64, marginRight: 10}} />:
                                <p>No Image</p>
                            }
                            <div className='fs12'>
                                {activeRecommendation.carDetails.registrationNo.toUpperCase()}<br />
                                {activeRecommendation.carDetails.carData.name+' '+activeRecommendation.carDetails.carData.Segment}
                            </div>
                        </div>
                        <hr />
                        <h3>Recent Car Images</h3>
                        <ul className='car-images'>
                            {
                                activeRecommendation.carImages.map((e, i) => <li key={i}>
                                    <Img hasZoom src={base.imageBaseUrl+e} alt={activeRecommendation.title} style={{ width: 120, borderRadius: 5, }} />
                                </li>)
                            }
                        </ul>
                        <hr />
                        <h3>Service Guy Comments</h3>
                        <p>{activeRecommendation.comments}</p>
                        <hr />
                        { !['company', 'representative'].includes(this.user.role) && <div className="flex btn-box-order">
                            <Button className="mb0 btn btn-success btn-box" onClick={() => this._approvePurchase(activeRecommendation)}>Approve</Button>
                            <Button className="mb0 btn btn-box btn-error mlauto" onClick={() => this._denyPurchase(activeRecommendation)}>Deny</Button>
                        </div>}
                    </div>}
                </div>
            </div>
        </>
    }
}