const userRoles = require('./userRoles.json'),
footerNav = require('./footerNav.json'),
adminNav = require('./adminNav.json'),
orderOptions = require('./orderOptions.json'),
garageoption = require('./garageoption.json'),
robotsoptions = require('./robotsoptions.json'),
authoroptions = require('./authoroptions.json'),
emailOptions = require('./emailOptions.json'),
faqCategories = require('./faqCategories.json'),
timeSlots = require('./timeSlots.json'),
profileNav = require('./profileNav.json'),
headerNav = require('./headerNav.json'),
fuelOptions = require('./fuelOptions.json'),
packagesOptions = require('./packagesOptions.json'),
activeDateFilters = require('./activeDateFilters.json'),
statusOptions = require('./statusOptions.json'),
badge = require('./badge.json'),
actor = require('./actor.json'),
sortOptions = require('./sortOptions.json'),
carStatusOptions = require('./carStatusOptions.json'),
inactiveCarOptions = require('./inactiveCarOptions.json'),
chartColors = require('./chartColors.json'),
b2bServices = require('./b2bServices.json'),
featuredSettings = require('./featuredSettings.json')
export {
    b2bServices,
    featuredSettings,
    badge,
    actor,
    chartColors,
    inactiveCarOptions,
    carStatusOptions,
    sortOptions,
    activeDateFilters,
    fuelOptions,
    packagesOptions,
    timeSlots,
    profileNav,
    headerNav,
    userRoles,
    footerNav,
    adminNav,
    orderOptions,
    statusOptions,
    robotsoptions,
    authoroptions,
    faqCategories,
    emailOptions,
    garageoption
}