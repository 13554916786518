import React, {Component} from 'react'
import {base,brandInfo, brandName} from '../../constants/Data'
import {CarSelector, GuestCarQueryModal, TopNavbarBanner} from '../../components'
import {MiscService, OrderService, ProductService, RouterService, UserService} from '../../services'
import SideCart from '../SideCart'
import {Img, Button} from '../../elements'
import { headerNav } from '../../constants/Enums'
import routes from '../Content/routes.json'
const uS = new UserService(),
miS = new MiscService(),
rS = new RouterService(),
oS = new OrderService(),
prS = new ProductService()
export default class Header extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.props = props
        this.state = {
            temporaryUser: false,
            showSubscribeModal: false,
            showUserNav: false,
            searchList: [],
            filteredList: [],
            userProfileImage: '',
            searchKey: '',
            activeCar: miS.getActiveCar(),
            cityDetail: false,
            showSideCart: false,
            showNavBar: false,
            showSearchResult: false,
            cartCount: 0,
            activeTopBar: true
        }
    }
    componentDidMount () {
        if (localStorage.getItem('tempLogin') === 'true') {
            this.setState({ temporaryUser: true })
        }
        this._getProductsData()
        this._getCartData()
        if(miS.isModuleEnabled(["TopNavbarBanner"])) {
            if (localStorage.getItem('topBarBanner') !== 'no')
                miS.toggleBodyClass('pt100')
            else
                miS.toggleBodyClass('pt100', 'pt0')
        }
    }
    _toggleTopNavBar = () => {
        this.setState({ activeTopBar: false}) 
        localStorage.setItem('topBarBanner', 'no')
        miS.toggleBodyClass('pt100', 'pt0')
    }
    _sendBackToAdmin = () => {
        localStorage.removeItem('tempUserData')
        localStorage.removeItem('tempLogin')
        rS.navigateTo('/admin/dashboard')
    }
    _getCartData = () => {
        let cartData = oS.getCartData(),
        cartCount = 0
        cartData.forEach(e => {
            cartCount += e.purchaseQuantity
        })
        this.setState({ cartCount })
    }
    _getProductsData = async () => {
        const result = await prS.getProducts()
        if (result.status) {
            if (result.data.length !== 0)
                this.setState({ searchList: result.data })
        }
    }
    _toggleUserNav = () => {
        let { showUserNav } = this.state
        this.setState({ showUserNav: !showUserNav })
    }
    _logout = () => {
        this.setState({ showUserNav: false }, () => {
            uS.logout('/home')
        })
    }
    _searchProduct = () => {
        let { searchKey, searchList } = this.state,
        tempTitle = '', tempSku = '', filteredList = []
        searchList.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = e.title.toLowerCase()
            tempSku = e.sku.toLowerCase()
            if (tempSku.search(searchKey) !== -1 || tempTitle.search(searchKey) !== -1)
                filteredList.push(e)
        })
        this.setState({ filteredList })
        if (searchKey === '')
            document.getElementsByTagName('body')[0].classList = 'searchbar-open'
        else
            document.getElementsByTagName('body')[0].classList = 'searchbar-open search-result-open'
    }
    _renderFilteredList = filteredList => {
        return filteredList.map(( item, index ) => {
            let productimg = JSON.parse(item.image)
            return <li key={index} className="blog-product-search-box">
                <a href={"/product/" + item.slug} title={item.title} className="flex">
                {
                    item.image === '' || item.image === '[]' || typeof item.image === "undefined"?
                    <Img className="blog-product-search-img" src={base.imageBaseUrl+'product-placeholder.png'} alt={item.title} />:
                    <Img className="blog-product-search-img" src={base.imageBaseUrl+productimg[0]} alt={item.title} />
                }
                    <div className="ml10 mb0">
                        <p className="m0">{item.title}</p>
                        <span className="black"><b>Item Code: </b><span className="gray">{item.sku}</span> </span> <span className="black ml10"> <b>Stock: </b> <span className="gray">{item.stock} </span></span>
                    </div>
                </a>
            </li>
        })
    }
    _getSelectedCar = activeCar => {
        this.setState({ activeCar })
    }
    _toggleCart = () => {
        let { showSideCart } = this.state
        this.setState({ showSideCart: !showSideCart })
        miS.toggleCartBox()
    }
    _cityDescription = () => {
        let { cityDetail } = this.state
        this.setState({ cityDetail: !cityDetail })
        miS.toggleBodyClass('locationbar-open')
    }
    _navBackGround = () => {
        let { showNavBar } = this.state
        this.setState({ showNavBar: !showNavBar })
        miS.toggleBodyClass('nav-open')
    }
    _searchResultBackGround = () => {
        this.setState({ showSearchResult: false, searchKey: '' })
        document.getElementsByTagName('body')[0].classList = ''
    }
    _renderPublicMenu = () => {
        return headerNav.map(( item, index ) => {
            if(miS.isModuleEnabled([item.module])) {
                if (uS.isUserLoggedIn()) {
                    if (!item.rejectRoles || !item.rejectRoles.includes(this.user?.role)) {
                        // routes.forEach(e => {
                        //     if (item.link === e.pathName && miS.isModuleEnabled(e.module)) {
                                return <li title={item.title} key={index}><a href={item.link}>{item.icon ? <ion-icon name={item.icon}></ion-icon> : item.title}</a></li>
                        //     }
                        // })
                    }
                } else {
                    // routes.forEach(e => {
                    //     if (item.link === e.pathName && miS.isModuleEnabled(e.module)) {
                            return <li title={item.title} key={index}><a href={item.link}>{item.icon ? <ion-icon name={item.icon}></ion-icon> : item.title}</a></li>
                    //     }
                    // })
                }
            }
        })
    }
    render() {
        let { temporaryUser, activeCar, cartCount, showSideCart, cityDetail, showUserNav, userProfileImage, filteredList, searchKey } = this.state, currentUrl = rS.getLocationData().href
        return <>
            {
                this.state.activeTopBar && (localStorage.getItem('topBarBanner') !== 'no') &&
                <>
                    {miS.isModuleEnabled(["TopNavbarBanner"]) && <TopNavbarBanner activeTopBar={true} onClick={this._toggleTopNavBar} />}
                    <div className="close-top-ads-box" onClick={this._toggleTopNavBar}>
                        <ion-icon class="close-top-ads" name="close-circle-outline"></ion-icon>
                    </div>
                </>
            }
            <div className= {this.state.activeTopBar && (localStorage.getItem('topBarBanner') !== 'no') && miS.isModuleEnabled(["TopNavbarBanner"]) ? "header-container-active-top-bar": "header-container"}>
                <div className="header-background">
                    <div className="main-header jumbo-container">
                        <div className="left-header">
                            <a href="/" title={brandName.capital}>
                                <Img alt={brandName.capital} src={brandInfo.logo} className="logo" />
                            </a>
                            {miS.isModuleEnabled(['CitySwitch']) && (!uS.isUserLoggedIn() || (uS.isUserLoggedIn() && this.user?.role && !['manager', 'company', 'branch', 'representative'].includes(this.user?.role))) && <p className="city-name" onClick={() => this._cityDescription() }>Jaipur/Mohali <ion-icon name="caret-down-outline"></ion-icon></p>}
                        </div>
                            {
                            cityDetail &&
                                <>
                                    <div onClick={this._cityDescription} className="backgroundFixedLocation"></div>
                                    <div className="dropDown-City">
                                        <p className='mb0'>We are servicing in Jaipur and Mohali only, upcoming cities will be updated here</p>
                                    </div>
                                </>
                            }
                            { miS.isModuleEnabled(["Ecommerce"]) && (uS.isUserLoggedIn() ? !['manager', 'company', 'branch', 'representative'].includes(this.user?.role) : true) &&  <span className="mobile-cart-header-top">
                            {
                                !currentUrl.includes('cart') && !currentUrl.includes('checkout') &&
                                <li className="navbarMobile-cart"><span onClick={this._toggleCart}><ion-icon name="cart-outline"></ion-icon><p className="cartCount"><span>{cartCount}</span></p></span></li>
                            }
                            </span>}
                        <ion-icon name="menu-outline" class="btn-menu" onClick={() => miS.toggleBodyClass('nav-open')}></ion-icon>
                        <div className="right-header">
                            <ion-icon name="chevron-forward-circle-outline" class="btn-menu" onClick={() => miS.toggleBodyClass('nav-open')}></ion-icon>
                            <ul>
                                { this._renderPublicMenu() }
                                {
                                    miS.isModuleEnabled(['Ecommerce']) && (!uS.isUserLoggedIn() || (uS.isUserLoggedIn() && this.user?.role && !['manager', 'company', 'branch', 'representative'].includes(this.user?.role))) && !currentUrl.includes('cart') && !currentUrl.includes('checkout') &&
                                    <li><span onClick={this._toggleCart}><ion-icon name="cart-outline"></ion-icon><p className="cartCount"><span>{cartCount}</span></p></span></li>
                                }
                                <li className="search-bar-mobile-hide"><span onClick={() => miS.toggleBodyClass('searchbar-open')}><ion-icon name="search-outline"></ion-icon></span></li>
                                {miS.isModuleEnabled(["User"]) && <>{
                                    uS.isUserLoggedIn() ?
                                    <li>
                                        <nav className="profile-setup">
                                            {uS.isUserLoggedIn() && <div className="profile-box" onClick={this._toggleUserNav}>
                                                {
                                                    userProfileImage ?
                                                    <ion-icon name="person-circle-outline"></ion-icon> :
                                                    <Img className="user-profile-picture" src={base.imageBaseUrl + JSON.parse(this.user.image)[0]} alt={this.user.name} />
                                                }
                                                <span title={this.user.name}>{this.user.name}</span>
                                            </div>}
                                            {
                                                showUserNav &&
                                                <ul>
                                                    {
                                                        ['manager','representative'].includes(this.user.role) &&
                                                        <li>
                                                            <a href="/company/profile">
                                                                <ion-icon name="person-outline"></ion-icon>
                                                                <span>Company Profile</span>
                                                            </a>
                                                        </li>
                                                    }
                                                    <li>
                                                        <a href="/user/profile">
                                                            <ion-icon name="person-outline"></ion-icon>
                                                            <span>Profile</span>
                                                        </a>
                                                    </li>
                                                    {
                                                        (localStorage.getItem('tempLogin') === 'true' || uS.isAllowedAdmin()) &&
                                                        <li>
                                                            <a href="/admin/dashboard" onClick={this._sendBackToAdmin}>
                                                                <ion-icon name="person-circle-outline"></ion-icon>
                                                                <span>Admin Panel</span>
                                                            </a>
                                                        </li>
                                                    }
                                                    {!temporaryUser && <li>
                                                        <i onClick={this._logout}>
                                                            <ion-icon name="log-out-outline"></ion-icon>
                                                            <span>Logout</span>
                                                        </i>
                                                    </li>}
                                                </ul>
                                            }
                                        </nav>
                                    </li>:
                                    <li><a className="btn btn-outline mh0" href="/login">Login</a></li>
                                }</>}
                            </ul>
                        </div>
                        {miS.isModuleEnabled(["MobileSearch"]) && <div className="for-mobile-search">
                            <form className="search-bar" noValidate onSubmit={this._searchProduct}>
                                <input type="text" required value={searchKey || ''} placeholder="Search..." onChange={e => {
                                    const reg = /^[A-Za-z0-9 ]+$/
                                    if (e.target.value === '' || reg.test(e.target.value))
                                        this.setState({ searchKey: e.target.value }, this._searchProduct)
                                }} />
                                <Button type="button" onClick={() => this.setState({ filteredList: [], searchKey: '' }, this._searchProduct)}><ion-icon name="close"></ion-icon></Button>
                            </form>
                            {
                                searchKey !== '' &&
                                <div className="search-result for-mobile-search-result">
                                    <ul>
                                        {this._renderFilteredList(filteredList || [] )}
                                    </ul>
                                </div>
                            }
                            <div onClick={this._searchResultBackGround} className="background-fixed-search-result"></div>
                        </div>}
                    </div>
                </div>
                {(!uS.isUserLoggedIn() || (uS.isUserLoggedIn() && this.user?.role && !['manager', 'company', 'branch', 'representative'].includes(this.user?.role))) && <div id="menu-cart" className="menu-cart">
                    {
                        miS.isModuleEnabled(['Ecommerce']) && showSideCart && <SideCart toggleCart={this._toggleCart} />
                    }
                </div>}
                <div onClick={this._navBackGround} className="backgroundFixedNavBar"></div>
                <div onClick={this._toggleCart} className="backgroundFixed"></div>
                <div className="open-nav-searchbar">
                    <div className="cart-title-wrap">
                        <div className="title">
                            <h3 className="heading-03">Search </h3>
                        </div>
                        <div className="show-cart">
                            <span onClick={() => miS.toggleBodyClass('searchbar-open')} className="closebtn" title="Close Overlay"><i className="hi-close"></i></span>
                        </div>
                    </div>
                    <form className="search-bar" noValidate onSubmit={this._searchProduct}>
                        <input type="text" required name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                            const reg = /^[A-Za-z0-9 ]+$/
                            if (e.target.value === '' || reg.test(e.target.value))
                                this.setState({ searchKey: e.target.value }, this._searchProduct)
                        }} />
                        <Button type="button" onClick={() => this.setState({ filteredList: [], searchKey: '' })}><ion-icon name="close"></ion-icon></Button>
                        <div className="search-result">
                            <ul>
                                {this._renderFilteredList(filteredList || [] )}
                            </ul>
                        </div>
                    </form>
                </div>
                {
                    miS.isModuleEnabled(["Car"]) && !currentUrl.includes('car/edit') && !currentUrl.includes('car/add') && (!uS.isUserLoggedIn() || !['manager', 'company', 'branch', 'representative'].includes(this.user?.role)) &&
                    <section className="car-select-form">
                        <CarSelector getSelectedCar={this._getSelectedCar} getNewCars={false} />
                    </section>
                }
                {
                    localStorage.getItem('tempLogin') === 'true' &&
                    <>
                        {
                            temporaryUser &&
                            <div className="temporary-login">
                                <span>Temporary Login</span>
                            </div>
                        }
                        <span className={temporaryUser ? "top-to-bottom-icon ": "top-to-bottom-icon bottom-to-top"} onClick={() => this.setState({ temporaryUser: !temporaryUser })}>
                            <ion-icon name="arrow-down-outline"></ion-icon>
                        </span>
                    </>
                }
                {
                    miS.isModuleEnabled(["Car"]) && !uS.isUserLoggedIn() && activeCar === null && <GuestCarQueryModal />
                }
            </div>
        </>
    }
}