import React, {Component} from 'react'
import {Banner} from '../../../widgets'
import {OrderService, UserService, RouterService, MetaService} from '../../../services'
import {Img} from '../../../elements'
import './style.scss'
const uS = new UserService(),
mS = new MetaService(),
rS = new RouterService(),
oS = new OrderService()
export default class Payment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            status: 1
        }
    }
    componentDidMount () {
        mS.setTitle('Payment Status', 'public')
        this._updateUserData()
    }
    _updateUserData= async () => {
        let query = {
            uid: uS.getLoggedInUserData().uid
        }
        const result = await uS.getUser({ query: JSON.stringify(query) })
        if (result.status) {
            await uS.login(result.data[0])
            this._emptyOrderData()
        }
    }
    _emptyOrderData = () => {
        oS.emptyCart()
        oS.emptyOrder()
    }
    render() {
        let { status } = this.state,
        urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        return <>
            <Banner title='Payment Successful' image="yellow-back-1.jpg"/>
            {
                status?
                <div className="form-box-setup payment-success jumbo-container">
                    <ion-icon name="checkmark" class="big-icon"></ion-icon>
                    <div className="tac mt40">
                        <h2 className="success-Text-payment">Order Placed successfully!</h2>
                        <p className="order-text-payment">Thanks for choosing us, we wish you enjoy our services / products</p>
                        <p className="mail-text-payment">An email confirmation will be sent to you shortly with your order details </p>
                        <p className="order-id-text">Your Order Id: <span className="orange">#{urlData[2]}</span></p>
                        <div className="btn-box-payment">
                            <a href="/order/list" className="btn btn-outline mauto">My Orders</a>
                            <a href="/accessories" className="btn btn-primary mauto">Continue Shopping</a>
                        </div>
                    </div>
                </div>:
                <div className="form-box-setup payment-success jumbo-container">
                    <Img alt="Payment Success" src={require('../../../../assets/images/Payment-failed1.png')} />
                    <div className="fail-text">
                        <h2>Failed</h2>
                        <p>Your payment was proceed unsuccessfully.</p>
                    </div>
                </div>
            }
        </>
    }
}