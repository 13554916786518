import React, {Component} from 'react'
import {MiscService, ValidationService, CarService, UserService, RouterService, DataService} from '../../services'
import {base} from '../../constants/Data'
import {Img} from '../../elements'
import Modal from '../Modal'
const dS = new DataService('carModel')
const uS = new UserService(),
miS = new MiscService(),
vS = new ValidationService(),
rS = new RouterService(),
cS = new CarService()
export default class CarSelector extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchCarKey: '',
            carSeleted: false,
            carManufactureList: [],
            filteredManufacturerList: [],
            carModelList: [],
            activeCar: {},
            carList: [],
            registrationNo: '',
            userCarList: [],
            showCarSelectModal: false,
            showCarListModal: false
        }
        this.props = props
    }
    componentDidMount () {
        let activeCar = null,
        props = this.props
        if (props.activeCar !== null)
            activeCar = miS.getActiveCar()
        if (activeCar !== null)
            this.setState({ activeCar, carSeleted: true})
        this._getCarManufacturers()
        if (uS.isUserLoggedIn() && !props.getNewCars)
            this._getCarsData()
        if (props.activeCar !== this.state.activeCar && props.activeCar !== null && props.activeCar)
            this.setState({ activeCar: props.activeCar, carSeleted: true })
    }
    componentDidUpdate () {
        let props = this.props
        if (props.activeCar !== this.state.activeCar && props.activeCar !== null && props.activeCar)
            this.setState({ activeCar: props.activeCar, carSeleted: true })
    }
    _getCarManufacturers = async () => {
        const result = await cS.getCarManufacturers()
        if (result.status) {
            if (result.data.length !== 0)
                this.setState({ carManufactureList: result.data, filteredManufacturerList: result.data })
        }
    }
    _getCarManufacturerCars = async manufacturer => {
        let query = {
            manufacturer
        }
        const result = await dS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.data.length !== 0) {
            this.setState({ carList: result.data, showCarSelectModal: false, showCancel: true, showCarListModal: true })
            miS.closeModal('car-select-modal')
        }
    }
    _getCarsData = async () => {
        let query = {
            ownerId: uS.getLoggedInUserData().uid
        }
        const result = await cS.getCars({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data.length !== 0) {
                this.setState({ userCarList: result.data })
                let activeCar = miS.getActiveCar() ? miS.getActiveCar() : JSON.parse(result.data[0].carData)
                this.setState({ activeCar, carSeleted: true }, () => miS.setActiveCar(activeCar))
            }
        }
    }
    _renderCarManufactureList = carManufactureFiles => {
        return carManufactureFiles.map(( item, index ) => {
            return <li className="car-manufacturing-list" onClick={() => this._openCarList(item.name)} key={index}>
                <Img src={base.carImageBase+item.image} alt={item.name} />
                <span>{item.name}</span>
            </li>
        })
    }
    _renderCarModelList = carModelList => {
        return carModelList.map(( item, index ) => {
            return <li className="car-manufacturing-list" onClick={() => this._showSelectedCar(item, 'new')} key={index}>
                <Img src={base.carImageBase+item.image_path} alt={item.name} />
                <span>{item.name+' '+item.Segment}</span>
            </li>
        })
    }
    _renderCarListData = carModelList => {
        return carModelList.map(( item, index ) => {
            let carData = JSON.parse(item.carData)
            carData.registrationNo = item.registrationNo.toUpperCase()
            return <li className="car-manufacturing-list" onClick={() => this._showSelectedCar(carData)} key={index}>
                <Img src={base.carImageBase+carData.image_path} alt={carData.name} />
                <span>{carData.name+' '+carData.Segment}<br /><small>{carData.registrationNo}</small></span>
            </li>
        })
    }
    _openCarList = fileName => {
        this._getCarManufacturerCars(fileName)
    }
    _showSelectedCar = (activeCar, carSelector = 'old') => {
        // if (carSelector === 'new' && !props.getNewCars) {
        //     this.setState({ carSeleted: true, activeCar })
        // } else {
            this.setState({ carSeleted: true, activeCar, showCarListModal: false }, () => {
                miS.setActiveCar(activeCar)
                this.props.getSelectedCar(activeCar)
                if ((rS.getLocationData().href.includes('/services')) || (rS.getLocationData().href.includes('/packages')))
                    rS.getLocationData().reload()
            })
        // }
    }
    _deselectCar = () => {
        this.setState({ carSeleted: false }, () => {
            localStorage.removeItem('activeCar')
            // if ((rS.getLocationData().href.includes('/services')) || (rS.getLocationData().href.includes('/packages')))
            //     rS.getLocationData().reload()
        })
    }
    _closeCarList = () => {
        let { registrationNo, activeCar } = this.state,
        error = false,
        errorMsg = ''
        if (!vS.isValidVehicleRegistrationNumber(registrationNo)) {
            errorMsg = 'Please enter a valid car Registration Number!'
            error = true
        }
        if (!error) {
            activeCar.registrationNo = registrationNo
            this.setState({ activeCar, showCarListModal: false }, () => {
                miS.setActiveCar(activeCar)
                this.props.getSelectedCar(activeCar)
                if ((rS.getLocationData().href.includes('/services')) || (rS.getLocationData().href.includes('/packages'))) {
                    rS.getLocationData().reload()
                }
            })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _search = () => {
        let { searchCarKey, carManufactureList } = this.state,
        tempTitle = '', filteredManufacturerList = []
        carManufactureList.forEach(e => {
            searchCarKey = searchCarKey.toLowerCase()
            tempTitle = e.name.toLowerCase()
            if (tempTitle.search(searchCarKey) !== -1) {
                filteredManufacturerList.push(e)
            }
        })
        this.setState({ filteredManufacturerList: filteredManufacturerList })
    }
    render() {
        let { showCarListModal, showCarSelectModal, searchCarKey, filteredManufacturerList, carSeleted, activeCar, carList, userCarList } = this.state,
        props = this.props,
        targetModal = "car-select-modal"
        return <>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                {
                    carSeleted ?
                    <li className="nav-item">
                        <span className="nav-link active selectedCar">
                            <Img src={base.carImageBase+activeCar.image_path} alt={activeCar.name+' '+activeCar.Segment} />
                            <span>{activeCar.name+' '+activeCar.Segment}</span>
                        </span>
                        <ion-icon title="Change Car" name="refresh-circle-sharp" class="refresh-icon" onClick={this._deselectCar}></ion-icon>
                    </li>:
                    <li className="nav-item" onClick={() => {
                        if (uS.isUserLoggedIn() && !props.getNewCars && userCarList.length!== 0)
                            this.setState({showCarListModal: true})
                        else if (targetModal === "car-select-modal")
                            this.setState({showCarSelectModal: true})
                        else
                            this.setState({showCarListModal: true})
                    }} title="Select Car">
                        <span className="nav-link active selectCar">
                            <ion-icon name="car-sport-outline"></ion-icon>
                            <span>Select Your Car </span>
                        </span>
                    </li>
                }
            </ul>
            <Modal id="car-select-modal" showCancel={false} showSuccess={false} onCancel={() => this.setState({showCarSelectModal: false})} showModal={showCarSelectModal}>
                <div className='p20'>
                    <div className="car-select-header">
                        <div className="modal-header">
                            <div className="car-select-manufacture">
                                <ion-icon name="car-sport-outline"></ion-icon>
                                <span className="store-name">Select Manufactures</span>
                            </div>
                        </div>
                        <div className="down-arrow"></div>
                    </div>
                    <div className="select-manufacturing">
                        <div className="search-form">
                            <div className="form-group">
                                <input type="text" required name="searchCarKey" value={searchCarKey || ''} placeholder="Search Manufactures" onChange={e => {
                                    const reg = /^[A-Za-z0-9 ]+$/
                                    if (e.target.value === '' || reg.test(e.target.value))
                                        this.setState({ searchCarKey: e.target.value }, this._search)
                                }} className="input" />
                            </div>
                        </div>
                        <ul>
                            {this._renderCarManufactureList(filteredManufacturerList || [] )}
                        </ul>
                    </div>
                </div>
            </Modal>
            <Modal id="car-list-modal" showCancel={false} showSuccess={false} onCancel={() => this.setState({showCarListModal: false})} showModal={showCarListModal}>
                <div className='p20'>
                    <div className="car-select-header">
                        <div className="modal-header">
                            <div className="car-select-manufacture">
                                <ion-icon name="car-sport-outline"></ion-icon>
                                <span className="store-name">Select Car</span>
                            </div>
                        </div>
                        <div className="down-arrow"></div>
                    </div>
                    <div className="select-manufacturing">
                        <ul>
                            {
                                uS.isUserLoggedIn() && !props.getNewCars && userCarList.length!== 0?
                                this._renderCarListData(userCarList || []):
                                this._renderCarModelList(carList || [])
                            }
                        </ul>
                    </div>
                </div>
            </Modal>
        </>
    }
}