import React, {Component} from 'react'
import {Banner} from '../../widgets'
import {base, brandName} from '../../constants/Data'
import {BlogSideBar, Share} from '../../components'
import {robotsoptions,authoroptions,statusOptions} from '../../constants/Enums'
import {MiscService, DataService, RouterService, MetaService} from '../../services'
import {Img} from '../../elements'
const dS = new DataService('post'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
export default class Business extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeRobotsIndex: 0,
            activeStatusIndex: 0,
            activeAuthorIndex: 0,
            image: '[]',
            uid: '',
            renderSlug: true,
            mode: 'Add',
            title: '',
            slug: '',
            content: '',
            excerpt: '',
            css: '',
            js: '',
            canonical: '',
            robots: '',
            metaDescription: '',
            metaKeyword: '',
            author: '',
            publishDate: new Date(),
            status: '',
            categories: [],
            tag: '',
            mediaFiles: []
        }
    }
    componentDidMount () {
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname),
        query = {
            slug: urlData[2]
        }
        this._getBusinessData(query)
    }
    _getBusinessData = async query => {
        const result = await dS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data[0].status !== 'publish')
                rS.navigateTo('/error404')
            else {
                let postData = result.data[0],
                metaKeyword = [],
                tag = [],
                categories = [],
                activeRobotsIndex = 0,
                activeAuthorIndex = 0,
                activeStatusIndex = 0
                robotsoptions.forEach(( e, i ) => {
                    if (e.value === postData.robots)
                        activeRobotsIndex = i
                })
                authoroptions.forEach(( e, i ) => {
                    if (e.value === postData.robots)
                        activeAuthorIndex = i
                })
                statusOptions.forEach(( e, i ) => {
                    if (e.value === postData.robots)
                        activeStatusIndex = i
                })
                if (postData.metaKeyword === '""' || postData.metaKeyword === '') {
                    metaKeyword = []
                } else {
                    JSON.parse(postData.metaKeyword).forEach(e => {
                        metaKeyword.push(e)
                    })
                }
                if (postData.categories === '""' || postData.categories === '') {
                    categories = []
                } else {
                    JSON.parse(postData.categories).forEach(e => {
                        categories.push(e)
                    })
                }
                if (postData.tag === '""' || postData.tag === '') {
                    tag = []
                } else {
                    JSON.parse(postData.tag).forEach(e => {
                        tag.push(e)
                    })
                }
                mS.setTitle(postData.title, 'public')
                this.setState({
                    title: postData.title,
                    slug: postData.slug,
                    content: postData.content,
                    excerpt: postData.excerpt,
                    css: postData.css,
                    js: postData.js,
                    canonical: postData.canonical,
                    robots: postData.robots,
                    metaDescription: postData.metaDescription,
                    metaKeyword,
                    author: postData.author,
                    publishDate: new Date(postData.publishDate),
                    status: postData.status,
                    categories,
                    tag,
                    uid: postData.uid,
                    image: postData.image,
                    activeRobotsIndex,
                    activeAuthorIndex,
                    activeStatusIndex
                })
            }
        }
    }
    render() {
        let { publishDate, title, slug, content, categories, image } = this.state,
        categoriesList = ''
        categories.forEach(e => {
            categoriesList += e.label+', '
        })
        return <>
            <Banner title={title} image="white-car-banner.jpg" />
            <div className="blog-page jumbo-container">
                <div className="blog-page-content pt120 pb100">
                    <div className="wrapper">
                        <div className="col-8">
                            <div className="blog-details-wrap">
                                <div className="blog-details-items">
                                    <div className="content bg-none">
                                        <h3 className="title">{title}</h3>
                                        <div className="post-meta border-none pt20">
                                            <ul className="list-wrap">
                                                <li>
                                                    <div className="author"><ion-icon name="person-outline"></ion-icon>{brandName.capital}</div>
                                                </li>
                                                <li>
                                                    <div className="author"><ion-icon name="calendar-outline"></ion-icon> {miS.getFormattedDate(publishDate)}</div>
                                                </li>
                                                <li>
                                                    <div className="author"><ion-icon name="folder-outline"></ion-icon>{categoriesList}</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="thumb mt20">
                                        <Img src={base.imageBaseUrl+JSON.parse(image)[0]} alt={title} />
                                    </div>
                                    <div className="blog-details-inner">
                                        <div dangerouslySetInnerHTML={{__html: content}}></div>
                                    </div>
                                </div>
                                <Share shareUrl={"/post/"+slug} />
                            </div>
                        </div>
                        <div className="col-4">
                            <BlogSideBar />
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}