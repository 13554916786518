import React, {Component} from 'react'
import Select from 'react-select'
import {base} from '../../../constants/Data'
import {ValidationService, CarService, DataService, UserService, MiscService, RouterService, MetaService} from '../../../services'
import {Img, Button, BackIcon} from '../../../elements'
import { fuelOptions, packagesOptions } from '../../../constants/Enums'
const dS = new DataService('media'),
uS = new UserService(),
mS = new MetaService(),
miS = new MiscService(),
vS = new ValidationService(),
rS = new RouterService(),
cS = new CarService()
export default class AddManager extends Component {
    user = uS.getLoggedInUserData()
    constructor(props) {
        super(props)
        this.state = {
            mode: 'Add',
            registrationNo: '',
            registrationDate: '',
            chassisNo: '',
            engineNo: '',
            ownerName: this.user.name,
            vehicleClass: '',
            activeFuelType: 0,
            fuelType: fuelOptions[0],
            packageType: packagesOptions[0],
            activePackageType: packagesOptions[0],
            maker: '',
            fitness: '',
            mvTax: '',
            insurance: '',
            pucc: '',
            emission: '',
            rcStatus: '',
            financed: '',
            showForm: false,
            additionalCarInfo: false,
            userData: null,
            uid: '',
            showInsurance: true,
            showRc: true,
            rcImage: '',
            insuranceImage: '',
            activeImage: base.imageBaseUrl+JSON.parse(this.user.image)[0]
        }
    }
    componentDidMount () {
        mS.setTitle('Add Manager', 'public')
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        if (urlData[2] === 'edit' || urlData[2] === 'view') {
            let query = {
                uid: urlData[3]
            }
            this._getCarData(query, urlData[2])
        }
    }
    _getCarData = async (query, mode) => {
        let { showInsurance, showRc, activeFuelType } = this.state
        const result = await cS.getCar({ query: JSON.stringify(query) })
        if (result.status && result.data) {
            if (result.data.length > 0) {
                let userData = result.data[0]
                if (typeof userData.rcImage === 'undefined' || userData.rcImage === '' ) {
                    showRc = false
                    userData.rcImage = '[]'
                }
                if (typeof userData.insuranceImage === 'undefined' || userData.insuranceImage === '') {
                    showInsurance = false
                    userData.insuranceImage = '[]'
                }
                this.setState({
                    mode: mode,
                    registrationNo: userData.registrationNo,
                    registrationDate: userData.registrationDate,
                    chassisNo: userData.chassisNo,
                    engineNo: userData.engineNo,
                    ownerName: userData.ownerName,
                    vehicleClass: userData.vehicleClass,
                    maker: userData.maker,
                    fitness: userData.fitness,
                    mvTax: userData.mvTax,
                    insurance: userData.insurance,
                    pucc: userData.pucc,
                    fuelType: typeof userData.fuelType === "object"? JSON.parse(userData.fuelType) : fuelOptions[activeFuelType],
                    emission: userData.emission,
                    rcStatus: userData.rcStatus,
                    financed: userData.financed,
                    userData: JSON.parse(userData.userData),
                    uid: userData.uid,
                    showForm: true,
                    rcImage: userData.rcImage,
                    insuranceImage: userData.insuranceImage,
                    showRc: showRc,
                    showInsurance: showInsurance
                })
            }
        }
    }
    _handleChange = fuelType => {
        this.setState({ fuelType })
    }
    _handleChangePackage = packageType => {
        this.setState({ packageType: packageType, activePackageType: packageType })
    }
    _addManager = async e => {
        e.preventDefault()
        let { mode, uid, rcImage, insuranceImage, registrationNo, registrationDate, chassisNo, engineNo, ownerName, vehicleClass, maker, fitness, mvTax, insurance, pucc, emission, rcStatus, financed, userData, fuelType } = this.state,
            error = false,
            errorMsg = '',
            carObject = {
                registrationNo: registrationNo,
                registrationDate: registrationDate,
                chassisNo: chassisNo,
                engineNo: engineNo,
                ownerName: ownerName,
                vehicleClass: vehicleClass,
                maker: maker,
                fitness: fitness,
                mvTax: mvTax,
                insurance: insurance,
                pucc: pucc,
                fuelType: JSON.stringify(fuelType),
                emission: emission,
                rcImage: rcImage,
                insuranceImage: insuranceImage,
                rcStatus: rcStatus,
                financed: financed,
                userData: JSON.stringify(userData)
            }
        if (registrationNo === '') {
            errorMsg = 'Please enter car Registration Number!'
            error = true
        } else if (fuelType === '') {
            errorMsg = 'Please select car fuel'
            error = true
        } else if (!vS.isValidVehicleRegistrationNumber(registrationNo)) {
            errorMsg = 'Please enter a valid car Registration Number!'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                carObject.uid = new Date().getTime()
                carObject.ownerId = this.user.uid
                const result = await cS.addCar(carObject)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Manager added successfully!' })
                    rS.navigateTo('/manager/list')
                } else
                    miS.showAlert({ type: 'error', msg: 'Unable to add car!' })
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    userData: JSON.stringify(carObject)
                }
                const result = await cS.updateCar(data)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Manager updated successfully!' })
                    localStorage.removeItem('activeCar')
                    rS.navigateTo('/manager/list')
                } else
                    miS.showAlert({ type: 'error', msg: 'Unable to update car!' })
            }
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _getSelectedManager = userData => {
        this.setState({
            vehicleClass: userData.Segment,
            maker: userData.name,
            showForm: true,
            userData
        })
    }
    _selectFiles = e => {
        let _this = this, fileArray = [], fileObj = [],
        activeUploadType = e.target.id === 'choose-rc'?'rc': 'insurance'
        fileObj.push(e.target.files)
        this.setState({ fileObj }, async () => {
            for (let i = 0; i < _this.state.fileObj[0].length; i++) {
                const result = await miS.getBase64(_this.state.fileObj[0][i])
                let fileData = {
                    url: URL.createObjectURL(_this.state.fileObj[0][i]),
                    data: result,
                    fileName: _this.state.fileObj[0][i].name
                }
                fileArray.push(fileData)
                _this.setState({ file: fileArray, fileArray: fileArray, showProfileImageOptions: false }, () => this._uploadFiles(activeUploadType))
            }
        })
    }
    _uploadCall = async (file, activeUploadType) => {
        let _this = this, error = false,
        { fileArray, userData } = this.state,
        errorMsg = ''
        if (!error) {
            file.uid = new Date().getTime()
            const fileResult = await dS.save(file)
            if (fileResult.status) {
                let carObject = {}
                if (activeUploadType === 'rc') {
                    carObject = {
                        rcImage: '["'+fileArray[0].fileName+'"]'
                    }
                } else {
                    carObject = {
                        insuranceImage: '["'+fileArray[0].fileName+'"]'
                    }
                }
                let data = {
                    query: JSON.stringify({uid: userData.uid}),
                    userData: JSON.stringify(carObject)
                }
                const result = await cS.updateCar(data)
                if (result.status) {
                    _this.setState({ fileArray: [], file: [] }, () => {
                        userData.image = carObject.image
                        if (activeUploadType === 'rc')
                            this.setState({ rcImage: carObject.rcImage, showRc: true })
                        else
                            this.setState({ insuranceImage: carObject.insuranceImage, showInsurance: true })
                    })
                } else
                    miS.showAlert({ type: 'error', msg: 'Unable to update image!' })
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to update image!' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _uploadFiles = activeUploadType => {
        let { file } = this.state
        for (let i = 0; i < file.length; i++) {
            this._uploadCall(file[i], activeUploadType)
        }
    }
    render() {
        let { fuelType, rcImage, additionalCarInfo, insuranceImage, activeImage, userData, mode, showInsurance, showRc, registrationNo, registrationDate, chassisNo, engineNo, ownerName, activePackageType, vehicleClass, maker, fitness, mvTax, insurance, pucc, emission, rcStatus, financed, showForm } = this.state
        return <>
            <div className="right-side-profile-content">
                <div className="profile-sec wrapper">
                    <div className="form-editable-section">
                        <div className="form-box">
                            <form className="account-detail flex" noValidate onSubmit={this._addCar}>
                                <div className="col-9 carSelectDetails">
                                    <h1 className="capitalize">
                                        <BackIcon backLink='/manager/list' />
                                        {mode} Manager
                                    </h1>
                                    <>
                                        <div className="form-group">
                                            <label className="col-3 control-label p0">Registration No <sup>*</sup></label>
                                            <div className="col-9 controls">
                                                <input className="input uppercase" placeholder="registration no" type="text" required name="registrationNo" value={registrationNo || ''} onChange={(e) => this.setState({ registrationNo: e.target.value })} />
                                                <small className="help-text ashgray">e.g. RJ14 AB 1234 --OR-- RNX AB 1234</small>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-3 control-label p0">Owner Name: </label>
                                            <div className="col-9 controls">
                                                <input className="input" placeholder="ownerName name" type="text" required name="ownerName" value={ownerName || ''} onChange={(e) => this.setState({ ownerName: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-3 control-label p0">Vehicle Class</label>
                                            <div className="col-9 controls">
                                                <input className="input" placeholder="vehicle class" type="text" disabled name="vehicleClass" value={vehicleClass || ''} onChange={(e) => this.setState({ vehicleClass: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-3 control-label p0">Fuel <sup>*</sup></label>
                                            <div className="col-9 controls">
                                                <Select
                                                    value={fuelType}
                                                    onChange={this._handleChange}
                                                    options={fuelOptions}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-3 control-label p0">Maker / Model</label>
                                            <div className="col-9 controls">
                                                <input className="input" placeholder="maker / model" type="text" disabled name="maker" value={maker || ''} onChange={(e) => this.setState({ maker: e.target.value })} />
                                            </div>
                                        </div>
                                        {mode !== 'view' && <Button className="btn btn-primary btn-yellow btn-small addcarbtn" type="submit">Submit</Button>}
                                    </>
                                </div>
                                {
                                    false &&
                                    <div className="col-3 selectPackageTYpe">
                                        <h3>Assign Service Package</h3>
                                        <div className="col-9 controls">
                                            <Select
                                                value={packagesOptions[0]}
                                                onChange={this._handleChangePackage}
                                                options={packagesOptions}
                                            />
                                            <div className="pricing-table-grid-box">
                                                <div className="pricing-table-grid">
                                                    {
                                                        activePackageType.value === 'BASIC' ?
                                                        <div className="grid-Box">
                                                            <div className="upper-part">
                                                                <h1 className="plan-heading">Buddy</h1>
                                                            </div>
                                                            <div className="middle-part">
                                                                <div className="features box1"> <div className="feature-details"> <i className="hi-check-circle"></i>Monthly</div></div>
                                                                <div className="features box2"> <div className="feature-details"> <i className="hi-check-circle"></i> 1 / Month</div></div>
                                                                <div className="features box3"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                                                                <div className="features box6"> <div className="feature-details"> <i className="hi-check-circle"></i> 1 / Month</div></div>
                                                                <div className="features box7"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                                                                <div className="features box9"> <div className="feature-details"> <i className="hi-check-circle"></i> 1 / Week </div></div>
                                                            </div>
                                                            <div className="bottom-part pb5">
                                                                <Button className="btn btn-primary btn-box" onClick={() => this._purchase('BASIC')}>Assign</Button>
                                                            </div>
                                                        </div> :
                                                        activePackageType.value === 'ADVANCE' ?
                                                        <div className="grid-Box grid-box-center">
                                                            <div className="upper-part">
                                                                <h1 className="plan-heading">Friend</h1>
                                                            </div>
                                                            <div className="middle-part">
                                                                <div className="features box1"> <div className="feature-details"> <i className="hi-check-circle"></i> Monthly </div></div>
                                                                <div className="features box2"> <div className="feature-details"> <i className="hi-check-circle"></i> 2 / Month</div></div>
                                                                <div className="features box3"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                                                                <div className="features box6"> <div className="feature-details"> <i className="hi-check-circle"></i> 2 / Month</div></div>
                                                                <div className="features box7"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                                                                <div className="features box7"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily </div></div>
                                                                <div className="features box9"> <div className="feature-details"> <i className="hi-check-circle"></i> 2 / Week </div></div>
                                                            </div>
                                                            <div className="bottom-part pb5">
                                                                <Button className="btn btn-primary btn-box" onClick={() => this._purchase('ADVANCE')}>Assign</Button>
                                                            </div>
                                                            <div className="most-popular">Most Popular</div>
                                                        </div> :
                                                        <div className="grid-Box">
                                                            <div className="upper-part">
                                                                <h1 className="plan-heading">Best Friend</h1>
                                                            </div>
                                                            <div className="middle-part">
                                                                <div className="features box1"> <div className="feature-details"> <i className="hi-check-circle"></i> Fortnightly</div></div>
                                                                <div className="features box2"> <div className="feature-details"> <i className="hi-check-circle"></i> 2 / Month</div></div>
                                                                <div className="features box3"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                                                                <div className="features box6"> <div className="feature-details"> <i className="hi-check-circle"></i> 3 / Month</div></div>
                                                                <div className="features box7"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily</div></div>
                                                                <div className="features box7"> <div className="feature-details"> <i className="hi-check-circle"></i> Daily </div></div>
                                                                <div className="features box9"> <div className="feature-details"> <i className="hi-check-circle"></i> Alternate Day </div></div>
                                                            </div>
                                                            <div className="bottom-part pb5">
                                                                <Button className="btn btn-primary btn-box" onClick={() => this._purchase('PREMIUM')}>Assign</Button>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}