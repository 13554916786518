import React, {Component} from 'react'
import * as XLSX from 'xlsx'
import {ProductService, MiscService, RouterService, MetaService} from '../../../services'
import {Button} from '../../../elements'
import {Box, Table} from '../../../components'
const make_cols = refstr => {
	let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1;
	for(var i = 0; i < C; ++i) o[i] = {name:XLSX.utils.encode_col(i), key:i}
	return o
},
SheetJSFT = [
	"xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
].map(function(x) { return "." + x; }).join(","),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService(),
prS = new ProductService()
export default class ImportProducts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            file: {},
            data: [],
            cols: [],
            excelProductsList: [],
            fileImported: false,
            fileSelected: false,
            importProducts: '',
            renderSlug: true,
            mode: 'add',
            importProductData: [],
            tableData: [],
            status: 'publish',
            publishDate: miS.getFormattedDate(new Date()),
            showTable: false,
            importDone: false
        }
    }
    componentDidMount () {
        mS.setTitle('Import Products', 'admin')
        this._getProductsListData()
    }
    _handleChange = e => {
        const files = e.target.files;
        if (files && files[0]) this.setState({ file: files[0], fileSelected: true })
    }
    _handleFile = () => {
        miS.showPreloader()
        this.setState({ showTable: true})
        let excelProductsList = [],
        reader = new FileReader(),
        rABS = !!reader.readAsBinaryString
        reader.onload = e => {
            let bstr = e.target.result,
            wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true, cellDates:true }),
            wsname = wb.SheetNames[0],
            ws = wb.Sheets[wsname],
            data = XLSX.utils.sheet_to_json(ws)
            this.setState({ data, cols: make_cols(ws['!ref']) }, () => {
                data.forEach(e => {
                    excelProductsList.push(e)
                })
                this.setState({ excelProductsList: excelProductsList, fileImported: true }, miS.hidePreloader)
            })
        }
        if (rABS) {
            reader.readAsBinaryString(this.state.file)
        } else {
            reader.readAsArrayBuffer(this.state.file)
        }
    }
    _processProducts = () => {
        let { excelProductsList, tableData } = this.state,
        mode = 'add', activeItem = null
        excelProductsList.forEach(e => {
            tableData.forEach(item => {
                if (e.Item_Code === item.sku) {
                    mode = 'edit'
                    activeItem = item;
                }
            })
            this._addProduct(e, mode, activeItem)
        })
    }
    _getProductsListData = async () => {
        const result = await prS.getProducts()
        if (result.status) {
            let tableData = result.data.reverse()
            this.setState({ tableData })
        }
    }
    _addProduct = async (item, mode, oldProduct = null) => {
        let error = false,
        { excelProductsList } = this.state,
        errorMsg = '',
        tempTitle = item.Title, tempSlug = miS.slugify(tempTitle),
        tempCategories = JSON.parse(item.Categories), tempSubCategories = JSON.parse(item.Sub_Categories), tc = []
        tempCategories.forEach(e => {
            tc.push({ value: miS.slugify(e), label: e })
        })
        tempSubCategories.forEach(e => {
            tc.push({ value: miS.slugify(e), label: e })
        })
        item.Categories = JSON.stringify(tc)
        item.Brand = JSON.stringify({ value: miS.slugify(item.Brand), label: item.Brand })
        let productObject = {
            title: item.Title,
            slug: tempSlug,
            content: miS.parseToHtml(item.Description),
            excerpt: miS.parseToHtml(item.Excerpt),
            status: 'publish',
            categories: item.Categories,
            brand: item.Brand,
            price: item.MRP,
            publishDate: miS.getFormattedDate(new Date()),
            sku: item.Item_Code,
            hsn: item.HS_Code,
            stock: item.Stock_Availability,
            isFeatured: item.IsFeatured,
            inclusiveTax: true,
            tax: item.IGST_Rate,
            saleprice: item.Sale_Price,
            image: item.Image_Path
        }
        if (!error) {
            if (mode === 'add') {
                productObject.metaDescription = ''
                productObject.canonical = ''
                productObject.css = ''
                productObject.js = ''
                productObject.robots = ''
                productObject.metaKeyword = ''
                productObject.tag = ''
                productObject.uid = new Date().getTime()
                const result = await prS.addProduct(productObject)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Product added successfully!' })
                    this.setState({ importProductData: result })
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to add product!' })
                }
                if (item.Item_Code === excelProductsList[excelProductsList.length-1].Item_Code) {
                    this.setState({ showTable: false, importDone: true })
                }
            } else {
                productObject.metaDescription = oldProduct.metaDescription;
                productObject.canonical = oldProduct.canonical;
                productObject.css = oldProduct.css;
                productObject.js = oldProduct.js;
                productObject.robots = oldProduct.robots;
                productObject.metaKeyword = oldProduct.metaKeyword;
                productObject.tag = oldProduct.tag;
                let data = {
                    query: JSON.stringify({uid: oldProduct.uid}),
                    productData: JSON.stringify(productObject)
                }
                const result = await prS.updateProduct(data)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Product updated successfully!' })
                    this.setState({ importProductData: result })
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to update product!' })
                }
                if (item.Item_Code === excelProductsList[excelProductsList.length-1].Item_Code) {
                    this.setState({ showTable: false, importDone: true })
                }
            }
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _getTableData = filterData => {
        let data = [], { status, publishDate } = this.state
        filterData.forEach(( item ) => {
            let e = {
                Item_Code: item.Item_Code,
                HS_Code: item.HS_Code,
                Title: <span onClick={() => miS.openModal('get-packages-modal')} className="package-name">{item.Title}</span>,
                Description: miS.parseToHtml(item.Description),
                IGST_Rate: item.IGST_Rate,
                Stock_Availability: item.Stock_Availability,
                MRP: item.MRP,
                Sale_Price: item.Sale_Price,
                Image_Path: item.Image_Path,
                Excerpt: miS.parseToHtml(item.Excerpt),
                Categories: item.Categories,
                Sub_Categories: item.Sub_Categories,
                Brand: item.Brand,
                IsFeatured: String(item.IsFeatured),
                publishDate: publishDate,
                status: status
            }
            data.push(e)
        })
        return data
    }
    render() {
        let { fileSelected, fileImported, excelProductsList, showTable, importDone, activeShownTotal } = this.state
        let tableContent = {
            headers: [
                {
                    label: 'SKU',
                    key: 'sku'
                },
                {
                    label: 'HSN Code',
                    key: 'HSNCode'
                },
                {
                    label: 'Title',
                    key: 'title'
                },
                {
                    label: 'Description',
                    key: 'description'
                },
                {
                    label: 'IGST Rate',
                    key: 'IGSTRate'
                },
                {
                    label: 'Stock Availability',
                    key: 'stockAvailability'
                },
                {
                    label: 'Price',
                    key: 'price'
                },
                {
                    label: 'Sale Price',
                    key: 'salePrice'
                },
                {
                    label: 'Images',
                    key: 'images'
                },
                {
                    label: 'Excerpt',
                    key: 'excerpt'
                },
                {
                    label: 'Categories',
                    key: 'categories'
                },
                {
                    label: 'Sub Categories',
                    key: 'subCategories'
                },
                {
                    label: 'Brand',
                    key: 'brand'
                },
                {
                    label: 'IsFeatured',
                    key: 'isFeatured'
                },
                {
                    label: 'Publish Date',
                    key: 'publishDate'
                },
                {
                    label: 'Status',
                    key: 'status'
                }
            ],
            content: this._getTableData(excelProductsList)
        }
        return <>
                {
                    fileSelected?
                    <>
                        {
                            fileImported?
                            <>
                            {
                                !importDone &&
                                <Button type='submit' className="btn btn-primary mv20" onClick={this._processProducts}>Import</Button>
                            }
                            </>:
                            <Button type='submit' className="btn btn-primary mv20" onClick={this._handleFile}>Parse</Button>
                        }
                    </>:
                    <Box title="Import Excel to Add Products">
                        <input type="file" className="hidden form-control inputfile-1" id="file" accept={SheetJSFT} onChange={this._handleChange} />
                        <label className="btn btn-primary" htmlFor="file">
                            <ion-icon name="cloud-upload-outline"></ion-icon>
                            <span>Choose a file…</span>
                        </label>
                    </Box>
                }
                {
                    showTable?
                    <>
                        <Table
                            className="import-table"
                            data={tableContent}
                            pageSize={activeShownTotal}
                        />
                    </>
                    :<>
                        {
                            importDone &&
                            <>
                                <p>All Products imported successfully</p>
                                <div className="flex">
                                    <a className="btn btn-primary" href="/admin/product/list/">Back to Products List</a>
                                    <Button className="btn btn-outline ml20" onClick={() => rS.getLocationData().reload()}>Import More</Button>
                                </div>
                            </>
                        }
                    </>
                }
        </>
    }
}