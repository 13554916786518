import React, {Component} from 'react'
import Select from 'react-select'
import {base} from '../../../constants/Data'
import {TrashConfirmModal, Table} from '../../../components'
import {Img, Button, Actor} from '../../../elements'
import {CSVLink} from 'react-csv'
import { b2bServices, fuelOptions } from '../../../constants/Enums'
import {DataService, MiscService, RouterService, CarService, MetaService, ProductService, ServiceService, MicroServiceService, ValidationService} from '../../../services'
const dS = new DataService('media'),
reS = new DataService('recommendation'),
bwS = new DataService('businessWork'),
mS = new MetaService(),
sS = new ServiceService(),
miS = new MiscService(),
micS = new MicroServiceService(),
vS = new ValidationService(),
rS = new RouterService(),
cS = new CarService(),
prS = new ProductService()
const commonPageSize = 50
export default class CarListAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fuelType: fuelOptions[0],
            activeItem: null,
            fuelQuantity: '',
            filteredWorkPostList: [],
            workPostList: b2bServices,
            activeAction: '',
            uploadCount: 0,
            carImages: [],
            title: 'All Cars',
            displayedRecommendationsList: [],
            searchPostKey: '',
            filteredPostList: [],
            fileObj: [],
            file: [],
            fileArray: [],
            comments: '',
            activeShownTotal: commonPageSize,
            owner: null,
            users: [],
            activeCar: null,
            tableData: [],
            image: '',
            filteredList: [],
            activeFilterIndex: 0,
            showTrashConfirm: false,
            searchList: [],
            recommendationsList: [],
            activeOwnerId: false,
            companyId: ''
        }
    }
    componentDidMount () {
        mS.setTitle('Car List', 'admin')
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        if (urlData[4]) {
            this._getCarsData(urlData[4])
            this.setState({ companyId: urlData[4] })
        } else {
            this._getCarsData()
        }
        this._getProductsData()
        document.getElementById('content-box').addEventListener('scroll', this._trackScrolling)
    }

    componentWillUnmount() {
        document.getElementById('content-box').removeEventListener('scroll', this._trackScrolling)
    }
    _trackScrolling = () => {
        let { filteredList, activeShownTotal } = this.state,
        wrappedElement = document.getElementById('list-wrapper')
        if (this._isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                this._showMore()
        }
    }
    _isBottom = el => {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    _showMore = () => {
        let { activeShownTotal } = this.state
        miS.showPreloader()
        setTimeout(() => {
            miS.hidePreloader()
            this.setState({ activeShownTotal: activeShownTotal + commonPageSize})
        }, 300)
    }
    _getProductsData = async () => {
        const result = await prS.getProducts()
        if (result.status) {
            if (result.data.length !== 0) {
                let products = []
                result.data.forEach(e => {
                    e.type = 'product'
                    products.push(e)
                })
                this._getServicesData(products)
            }
        }
    }
    _getServicesData = async filteredPostList => {
        const result = await sS.getServices()
        if (result.status) {
            if (result.data.length !== 0) {
                let serviceList = []
                result.data.reverse().forEach(e => {
                    e.type = 'service'
                    if (typeof e.trash === "undefined")
                        serviceList.push(e)
                    else if (!e.trash)
                        serviceList.push(e)
                })
                filteredPostList = [...filteredPostList, ...serviceList]
                this._getMicroServicesData(filteredPostList)
            }
        }
    }
    _getMicroServicesData = async (filteredPostList) => {
        let {companyId} = this.state
        const result = await micS.getMicroServices()
        if (result.status) {
            if (result.data.length !== 0) {
                let microServiceList = []
                result.data.reverse().forEach(e => {
                    e.type = 'micro-service'
                    if (typeof e.trash === "undefined")
                        microServiceList.push(e)
                    else if (!e.trash)
                        microServiceList.push(e)
                })
                filteredPostList = [...filteredPostList, ...microServiceList].filter(e => {
                    if (e.status === 'private' || e.status === 'private' || e.status === 'private') {
                        // if (!e.company) return true
                        // if (e.company) {
                        //     e.company = JSON.parse(e.company)
                        // }
                        // if (e.company?.value) {
                        //     return companyId === e.company.value
                        // }
                        return true
                    }
                })
                this.setState({ recommendationsList: filteredPostList, filteredPostList })
            }
        }
    }
    _getCarsData = async (uid = false) => {
        let result, title = 'Car List'
        if (uid) {
            let query = {
                ownerId: uid
            }
            result = await cS.getCars({ query: JSON.stringify(query) })
        } else {
            result = await cS.getAllCars()
        }
        let tableData = [], users = []
        if (result.status) {
            result.data.reverse().forEach(e => {
                let found = false
                if (e.carData) {
                    if(vS.isValidJson(e.carData)) {
                        e.carData = JSON.parse(e.carData)
                    }
                    e.modelKey = e.modelKey ?? (e.carData.image_path.substring(0, e.carData.image_path.lastIndexOf('.')) || e.carData.image_path)
                }
                users.forEach(u => {
                    if (u.value === e.ownerId)
                        found = true
                })
                if (!found)
                    users.push({ value: e.ownerId, label: e.ownerName })
                tableData.push(e)
            })
            if (uid) {
                users.forEach(e => {
                    if (e.value === uid)
                        title += " for "+e.label
                })
            }
            this.setState({ tableData, filteredList: tableData, users: users, activeOwnerId: uid, title })
        }
    }
    _trashCancel = () => {
        this.setState({ activeItem: null, showTrashConfirm: false })
    }
    _trashEvent = async () => {
        let { activeItem } = this.state,
        query = {
            uid: activeItem.uid
        }
        const result = await dS.delete({ query: JSON.stringify(query) })
        if (result.status) {
            this._getCarsData()
            miS.showAlert({ type: 'success', msg: 'Event deleted successfully!' })
            this.setState({ activeItem: null, showTrashConfirm: false })
        } else
            miS.showAlert({ type: 'error', msg: 'Unable to delete event!' })
    }
    _recommendFor = item => {
        this.setState({ activeCar: item, activeAction: 'recommend' }, () => miS.openModal('recommendation-modal'))
    }
    _addWorkData = item => {
        this.setState({ activeCar: item, activeAction: 'work' }, () => miS.openModal('workdata-modal'))
    }
    _pushRecommendation = async () => {
        let {displayedRecommendationsList, carImages, activeCar, comments} = this.state
        let recommendationData = {
            ownerId: activeCar.ownerId,
            uid: new Date().getTime(),
            carDetails: JSON.stringify(activeCar),
            comments,
            carImages: JSON.stringify(carImages),
            recommendData: JSON.stringify(displayedRecommendationsList)
        }
        const result = await reS.save(recommendationData)
        if (result.status) {
            miS.showAlert({type: 'success', msg: 'Recommendation added successfully!' })
            miS.closeModal('recommendation-modal')
            this.setState({ activeAction: '', activeCar: null, comments: '', fileArray: [], displayedRecommendationsList: [], searchKey: '' })
        }
    }
    _pushWorkData = async () => {
        let {displayedRecommendationsList, carImages, activeCar, comments} = this.state
        let workData = {
            ownerId: activeCar.ownerId,
            uid: new Date().getTime(),
            carDetails: JSON.stringify(activeCar),
            comments,
            carImages: JSON.stringify(carImages),
            recommendData: JSON.stringify(displayedRecommendationsList)
        }
        const result = await bwS.save(workData)
        if (result.status) {
            miS.showAlert({type: 'success', msg: 'Work data on car added successfully!' })
            miS.closeModal('workdata-modal')
            this.setState({ activeAction: '', activeCar: null, comments: '', fileArray: [], displayedRecommendationsList: [], searchKey: '' })
        }
    }
    _getTableData = filterData => {
        let data = [], {activeOwnerId} = this.state
        filterData.forEach(( item, index ) => {
            if (this._filterItems(item)) {
                let e = {
                    srNo: index + 1,
                    registrationNo: item.registrationNo && item.registrationNo.toUpperCase(),
                    image: item.carData?.image_path?
                        <Img alt="Blank Placeholder" src={base.carImageBase+item?.carData?.image_path} style={{maxHeight: 40, maxWidth: 40}} />:
                        <p>No Image</p>,
                    carName: item.carData?.name+' '+item.carData?.Segment,
                    modelKey: item.modelKey,
                    ownerName: !activeOwnerId && item.ownerName,
                    action: item.status === 'active' &&
                        <div className="action-bar">
                            <Actor onClick={() => rS.navigateTo('/admin/car/edit/'+item.uid, true)} type='edit' />
                            <Actor onClick={() => this._recommendFor(item)} type='cart' title='Add Recommendations' />
                            <Actor onClick={() => this._addWorkData(item)} type='settings' title='Add Workdata' />
                        </div>
                }
                data.push(e)
            }
        })
        return data
    }
    _uploadFiles = async () => {
        let { file, carImages, activeAction } = this.state
        if (Array.isArray(file) && file.length > 0) {
            file.forEach(e => {
                carImages.push(e.fileName)
            })
            for (let i = 0; i < file.length; i++) {
                await this._uploadCall(file[i])
                setTimeout(() => {
                    if (i === file.length-1)
                        this.setState({ file: [], fileArray: [], carImages })
                }, 1000)
            }
        } else {
            if (activeAction === 'recommend') {
                this._pushRecommendation()
            } else {
                this._pushWorkData()
            }
        }
    }
    _renderRecommendations = recData => {
        return recData.map(( item, index ) => {
            return <li key={index} className='flex mb10'>
                {
                    item.image?
                    <>
                        <Img src={base.imageBaseUrl+JSON.parse(item.image)[0]} alt={item.title} style={{ width: 50, borderRadius: 5, marginRight: 5 }} />
                        <span>{ item.title}{item.fuelQuantity && item.fuelType?.value && ' ('+item.fuelQuantity+' lt. '+item.fuelType.value+')'}</span>
                    </>:item.value
                }
                <ion-icon class="trash mlauto pointer" name="trash-outline" onClick={() => this.setState({ displayedRecommendationsList: recData.filter(e => {
                    return e.uid !== item.uid
                }) })}></ion-icon>
            </li>
        })
    }
    _selectRecommendation = item => {
        let {displayedRecommendationsList, fuelQuantity, fuelType} = this.state
        item.uid = new Date().getTime()
        if (item.sku?.includes("fuel-refill")) {
            item.fuelQuantity = fuelQuantity
            item.fuelType = fuelType
            miS.closeModal('fuel-modal')
        }
        displayedRecommendationsList.push(item)
        this.setState({ displayedRecommendationsList: displayedRecommendationsList, filteredWorkPostList: [], filteredPostList: [], searchPostKey: '' })
    }
    _renderFilteredList = filteredList => {
        return filteredList.map(( item, index ) => {
            let productimg = [], img = item.image ? JSON.parse(item.image): []
            img.forEach(e => {
                productimg.push(e)
            })
            return <li key={index} className="blog-product-search-box pointer" onClick={() => {
                if (item.sku?.includes("fuel-refill")) {
                    this.setState({ activeItem: item })
                    miS.openModal('fuel-modal')
                } else {
                    this._selectRecommendation(item)
                }
            }}>
                {item.image ? <div className="flex">
                    <Img className="blog-product-search-img" src={
                        item.image === '' || item.image === '[]' || typeof item.image === "undefined"?
                        base.imageBaseUrl+'product-placeholder.png':
                        base.imageBaseUrl+productimg[0]} alt={item.title}
                    />
                    <div className="ml10 mb0">
                        <p className="m0">{item.title}</p>
                    </div>
                </div>:<div className='fixed-service'>{item.value}</div>}
            </li>
        })
    }
    _uploadCall = async file => {
        let _this = this, error = false, { fileArray, uploadCount, activeAction } = this.state, errorMsg = ''
        if (!error) {
            file.uid = new Date().getTime()
            const result = await dS.save(file)
            if (result.status) {
                uploadCount++
                _this.setState({ uploadCount }, () => {
                    if (uploadCount === fileArray.length) {
                        this.setState({ fileArray: [], file: [], uploadCount: 0 }, () => {
                            if (activeAction === 'recommend') {
                                this._pushRecommendation()
                            } else {
                                this._pushWorkData()
                            }
                        })
                    }
                })
            } else
                miS.showAlert({ type: 'error', msg: 'Unable to add media!' })
        } else
            miS.showAlert({ type: 'error', msg: errorMsg })
    }
    _selectFiles = e => {
        let _this = this, fileArray = [], fileObj = []
        fileObj.push(e.target.files)
        this.setState({ fileObj }, async () => {
            for (let i = 0; i < _this.state.fileObj[0].length; i++) {
                const result = await miS.getBase64(_this.state.fileObj[0][i])
                let fileData = {
                    url: URL.createObjectURL(_this.state.fileObj[0][i]),
                    data: result,
                    type: _this.state.fileObj[0][i].type,
                    fileName: _this.state.fileObj[0][i].name,
                    size: _this.state.fileObj[0][i].size
                }
                if (fileData.size <= 4000000)
                    fileArray.push(fileData)
                else miS.showAlert({ type: 'error', msg: 'File is too large.' })
                _this.setState({ file: fileArray, fileArray: fileArray })
            }
        })
    }
    _searchPost = () => {
        let { searchPostKey, recommendationsList, activeAction, workPostList } = this.state,
        tempTitle = '', filteredPostList = []
        if (activeAction === 'work') {
            recommendationsList = workPostList
        }
        recommendationsList.forEach(e => {
            searchPostKey = searchPostKey.toLowerCase()
            tempTitle = e.title ? e.title.toLowerCase() : e.value.toLowerCase()
            if (tempTitle.search(searchPostKey) !== -1) {
                filteredPostList.push(e)
            }
        })
        this.setState({ filteredPostList, filteredWorkPostList: filteredPostList })
    }
    _search = () => {
        let { searchKey, tableData } = this.state,
        tempTitle = '', filteredList = []
        tableData.forEach(e => {
            searchKey = searchKey.toLowerCase()
            tempTitle = e.registrationNo.toLowerCase()
            if (tempTitle.search(searchKey) !== -1)
                filteredList.push(e)
        })
        this.setState({ filteredList })
    }
    _filterItems = e => {
        let {owner} = this.state
        return owner === null || (e.ownerId === owner.value)
    }
    render() {
        let { activeShownTotal, fuelType, activeItem, fuelQuantity, workPostList, displayedRecommendationsList, title, filteredPostList, searchPostKey, fileArray, comments, activeCar, activeOwnerId, tableData, showTrashConfirm, filteredList, searchKey, users, owner } = this.state
        let tableContent = {
            headers: [
                {
                    label: 'Sr. No.',
                    key: 'srNo'
                },
                {
                    label: 'Registration No.',
                    key: 'registrationNo'
                },
                {
                    label: 'Image',
                    key: 'image'
                },
                {
                    label: 'Car Name',
                    key: 'carName'
                },
                {
                    label: 'Model Key',
                    key: 'modelKey'
                },
                {
                    label: !activeOwnerId && 'Owner Name',
                    key: 'ownerName'
                },
                {
                    label: 'Action',
                    key: 'action'
                }
            ],
            content: this._getTableData(filteredList)
        },exportedData = [], exportedFileName = 'exported_cars_'+new Date().getDate()+'_'+new Date().getMonth()+'_'+new Date().getFullYear()+'.csv'
        filteredList.forEach(e => {
            let ex = {
                ownerId: e.ownerId,
                uid: e.uid,
                registrationNo: e.registrationNo,
                registrationDate: e.registrationDate ?? '',
                chassisNo: e.chassisNo ?? '',
                engineNo: e.engineNo ?? '',
                ownerName: e.ownerName ?? '',
                vehicleClass: e.vehicleClass ?? '',
                maker: e.maker ?? '',
                fitness: e.fitness ?? '',
                mvTax: e.mvTax ?? '',
                insurance: e.insurance ?? '',
                pucc: e.pucc ?? '',
                fuelType: e.fuelType?.label ?? fuelOptions[0].label,
                emission: e.emission ?? '',
                rcStatus: e.rcStatus ?? '',
                financed: e.financed ?? false,
                rcImage: e.rcImage ?? '',
                status: e.status ?? 'active',
                shoot360: e.shoot360 ?? false,
                shoot360Url: e.shoot360Url ?? '',
                modelKey: e.modelKey
            }
            exportedData.push(ex)
        })
        return <>
            <h1 className="title flex">
                {title}
                <a className="btn btn-outline btn-small ml20" href="/admin/car/import">Import</a>
                {
                    filteredList.length !== 0 &&
                    <span className="btn btn-primary btn-small ml20 export-btn">
                        <CSVLink data={exportedData} filename={exportedFileName} className="btn export-btn" target="_blank">Export</CSVLink>
                    </span>
                }
            </h1>
            <div className="filter-box">
                {users && !activeOwnerId && <div className="filters relative">
                    <label className="filter-label-add">Owner: </label>
                    <Select
                        value={owner}
                        defaultValue={owner}
                        onChange={owner => this.setState({ owner })}
                        options={users}
                    />
                </div>}
                <div className="search-box mlauto">
                    <input type="text" name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={e => {
                        const reg = /^[A-Za-z0-9 ]+$/
                        if (e.target.value === '' || reg.test(e.target.value))
                            this.setState({ searchKey: e.target.value }, this._search)
                    }} />
                    <Button type="button" onClick={() => this.setState({ filteredList: tableData, searchKey: '' }, this._search)}><ion-icon name="close"></ion-icon></Button>
                </div>
            </div>
            <Table
                data={tableContent}
                pageSize={activeShownTotal}
            />
            <TrashConfirmModal trashOkAction={this._trashEvent} trashCancelAction={this._trashCancel} showTrashConfirm={showTrashConfirm} />
            <div className="modal" id="recommendation-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content medium">
                    <Button className="close" onClick={() => {
                        this.setState({ activeCar: null, comments: '', fileArray: [], displayedRecommendationsList: [], searchKey: '' })
                        miS.closeModal('recommendation-modal')}}><i className="hi-close"></i></Button>
                    {activeCar && <div className="modal-body p20">
                        <h3 className='lh1'>Search to recommend</h3>
                        <div className='widget-box'>
                            <div className="form-group">
                                <input type="text" required name="searchPostKey" value={searchPostKey || ''} placeholder="Search..." onChange={e => {
                                    const reg = /^[A-Za-z0-9 ]+$/
                                    if (e.target.value === '' || reg.test(e.target.value))
                                        this.setState({ searchPostKey: e.target.value }, this._searchPost)
                                }} className="form-control" />
                            </div>
                            <div className="search-result-blog">
                                <ul>
                                    {
                                        searchPostKey !== ''?
                                        this._renderFilteredList(filteredPostList || []) : null
                                    }
                                </ul>
                            </div>
                        </div>
                        <hr />
                        <h3 className='lh1'>Recommendations List</h3>
                        <ul>
                            { this._renderRecommendations(displayedRecommendationsList) }
                        </ul>
                        <hr />
                        <h3 className='lh1'>Car Details</h3>
                        <div className='flex'>
                            {
                                activeCar?.carData?.image_path ?
                                <Img alt="Blank Placeholder" src={base.carImageBase+activeCar?.carData?.image_path} style={{maxHeight: 40, maxWidth: 64, marginRight: 10}} />:
                                <p>No Image</p>
                            }
                            <div className='fs12'>
                                {activeCar?.registrationNo?.toUpperCase()}<br />
                                {activeCar?.carData?.name+' '+activeCar?.carData?.Segment}
                            </div>
                        </div>
                        <h3>Recent Car Images</h3>
                        {
                            fileArray.length < 5 &&
                            <>
                                <label htmlFor="choose-file" className="btn btn-primary btn-small">Car Images</label>
                                <input type="file" className="hidden" onChange={this._selectFiles} multiple id="choose-file" accept="image/x-png,image/gif,image/jpeg,image/jpg" />
                            </>
                        }
                        {
                            fileArray.length !== 0 &&
                            <div className="upload-box">
                                <ul className="media-box mb20">
                                    {(fileArray || []).map((file, index ) => (
                                        <li key={index}>
                                            <Img src={file.url} alt="..." />
                                        </li>
                                    ))}
                                </ul>
                                {fileArray.length > 0 && <Button type="button" className="btn-outline btn btn-small" onClick={() => this.setState({ fileArray: [] })}>Reset</Button>}
                            </div>
                        }
                        <hr />
                        <h3 className='lh1'>Comments</h3>
                        <textarea className="comments" placeholder="Type your comments here" required name="comments" value={comments} onChange={(e) => this.setState({ comments: e.target.value })}></textarea>
                        <Button className="mb0 btn btn-success btn-box" onClick={this._uploadFiles}>Submit</Button>
                    </div>}
                </div>
            </div>
            <div className="modal" id="workdata-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content medium">
                    <Button className="close" onClick={() => {
                        this.setState({ activeCar: null, comments: '', fileArray: [], displayedRecommendationsList: [], searchKey: '' })
                        miS.closeModal('workdata-modal')}}><i className="hi-close"></i></Button>
                    {activeCar && <div className="modal-body p20">
                        <h3 className='lh1'>Search service name</h3>
                        <div className='widget-box'>
                            <div className="form-group mb0">
                                <input type="text" required name="searchPostKey" value={searchPostKey || ''} placeholder="Search..." onChange={e => {
                                    const reg = /^[A-Za-z0-9 ]+$/
                                    if (e.target.value === '' || reg.test(e.target.value))
                                        this.setState({ searchPostKey: e.target.value }, this._searchPost)
                                }} className="form-control" />
                            </div>
                            <div className="search-result-blog">
                                <ul>
                                    {
                                        searchPostKey !== ''?
                                        this._renderFilteredList(workPostList || []) : null
                                    }
                                </ul>
                            </div>
                        </div>
                        <hr />
                        <h3 className='lh1'>Services List</h3>
                        <ul>
                            { this._renderRecommendations(displayedRecommendationsList) }
                        </ul>
                        <hr />
                        <h3 className='lh1'>Car Details</h3>
                        <div className='flex'>
                            {
                                activeCar?.carData?.image_path ?
                                <Img alt="Blank Placeholder" src={base.carImageBase+activeCar?.carData?.image_path} style={{maxHeight: 40, maxWidth: 64, marginRight: 10}} />:
                                <p>No Image</p>
                            }
                            <div className='fs12'>
                                {activeCar?.registrationNo?.toUpperCase()}<br />
                                {activeCar?.carData?.name+' '+activeCar?.carData?.Segment}
                            </div>
                        </div>
                        <h3>Post Work Car Images</h3>
                        {
                            fileArray.length < 5 &&
                            <>
                                <label htmlFor="choose-file" className="btn btn-primary btn-small">Car Images</label>
                                <input type="file" className="hidden" onChange={this._selectFiles} multiple id="choose-file" accept="image/x-png,image/gif,image/jpeg,image/jpg" />
                            </>
                        }
                        {
                            fileArray.length !== 0 &&
                            <div className="upload-box">
                                <ul className="media-box mb20">
                                    {(fileArray || []).map((file, index ) => (
                                        <li key={index}>
                                            <Img src={file.url} alt="..." />
                                        </li>
                                    ))}
                                </ul>
                                {fileArray.length > 0 && <Button type="button" className="btn-outline btn btn-small" onClick={() => this.setState({ fileArray: [] })}>Reset</Button>}
                            </div>
                        }
                        <hr />
                        <h3 className='lh1'>Comments</h3>
                        <textarea className="comments" placeholder="Type your comments here" required name="comments" value={comments} onChange={(e) => this.setState({ comments: e.target.value })}></textarea>
                        <Button className="mb0 btn btn-success btn-box" onClick={this._uploadFiles}>Submit</Button>
                    </div>}
                </div>
            </div>
            <div className="modal" id="fuel-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content medium">
                    <Button className="close" onClick={() => miS.closeModal('fuel-modal')}><i className="hi-close"></i></Button>
                    <div className="modal-body p20">
                        <div className='box'>
                            <h4 className="head">Fuel Capacity <small>(in Liters)</small></h4>
                            <div className='body overflown'>
                                <input type="text" name="fuelQuantity" value={fuelQuantity} onChange={(e) => this.setState({ fuelQuantity: e.target.value })} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-3 control-label p0">Fuel <sup>*</sup></label>
                            <div className="col-9 controls">
                                <Select
                                    value={fuelType}
                                    defaultValue={fuelType}
                                    onChange={(e) => this.setState({ fuelType: e })}
                                    options={fuelOptions}
                                />
                            </div>
                        </div>
                        <Button className="btn btn-success btn-box mauto" onClick={() => this._selectRecommendation(activeItem)}>Submit</Button>
                    </div>
                </div>
            </div>
        </>
    }
}