import React, {Component} from 'react'
import {Banner} from '../../widgets'
import {robotsoptions,authoroptions,statusOptions} from '../../constants/Enums'
import {DataService, RouterService, MetaService} from '../../services'
const dS = new DataService('page'),
mS = new MetaService(),
rS = new RouterService()
export default class Page extends Component {
    constructor(props) {
        super(props)
        this.state = {
            recentPage: [],
            activeRobotsIndex: 0,
            activeStatusIndex: 0,
            activeAuthorIndex: 0,
            image: [],
            uid: '',
            renderSlug: true,
            mode: 'Add',
            title: '',
            slug: '',
            content: "",
            excerpt: "",
            css: '',
            js: '',
            canonical: '',
            robots: '',
            metaDescription: '',
            metaKeyword: '',
            author: '',
            publishDate: new Date(),
            status: '',
            tag: '',
            mediaFiles: []
        }
    }
    componentDidMount () {
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname),
        query = {
            slug: urlData[2]
        }
        this._getPageData(query)
    }
    _getPageData =async query => {
        const result = await dS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            if (result.data[0].status !== 'publish') {
                rS.navigateTo('/error404')
            } else {
                let pageData = result.data[0],
                metaKeyword = [],
                tag = [],
                activeRobotsIndex = 0,
                activeAuthorIndex = 0,
                activeStatusIndex = 0
                robotsoptions.forEach(( e, i ) => {
                    if (e.value === pageData.robots) {
                        activeRobotsIndex = i
                    }
                })
                authoroptions.forEach(( e, i ) => {
                    if (e.value === pageData.robots) {
                        activeAuthorIndex = i
                    }
                })
                statusOptions.forEach(( e, i ) => {
                    if (e.value === pageData.robots) {
                        activeStatusIndex = i
                    }
                })
                if (pageData.metaKeyword) {
                    if (pageData.metaKeyword === '""' || pageData.metaKeyword === '' || pageData.metaKeyword === null || pageData.metaKeyword === 'null') {
                        metaKeyword = []
                    } else {
                        JSON.parse(pageData.metaKeyword).forEach(e => {
                            metaKeyword.push(e)
                        })
                    }
                } else {
                    metaKeyword = []
                }
                if (pageData.tag) {
                    if (pageData.tag === '""' || pageData.tag === '' || pageData.tag === null || pageData.tag === 'null') {
                        tag = []
                    } else {
                        JSON.parse(pageData.tag).forEach(e => {
                            tag.push(e)
                        })
                    }
                } else {
                    tag = []
                }
                mS.setTitle(pageData.title, 'public')
                this.setState({
                    title: pageData.title,
                    mode: 'Edit',
                    slug: pageData.slug,
                    content: pageData.content,
                    excerpt: pageData.excerpt,
                    css: pageData.css,
                    js: pageData.js,
                    canonical: pageData.canonical,
                    robots: pageData.robots,
                    metaDescription: pageData.metaDescription,
                    metaKeyword,
                    author: pageData.author,
                    publishDate: new Date(pageData.publishDate),
                    status: pageData.status,
                    tag,
                    uid: pageData.uid,
                    image: JSON.parse(pageData.image),
                    activeRobotsIndex,
                    activeAuthorIndex,
                    activeStatusIndex
                })
            }
        }
    }
    render() {
        let { title, content, image } = this.state
        return <>
            <Banner title={title} image={image[0] ?? "red-back-1.jpg"}/>
            <div className="blog-page jumbo-container">
                <div className="blog-page-content pt120 pb100">
                    <div className="wrapper">
                        <div className="blog-details-wrap">
                            <div className="blog-details-items">
                                <div className="content bg-none">
                                    <h3 className="title">{title}</h3>
                                </div>
                                <div className="blog-details-inner">
                                    <div dangerouslySetInnerHTML={{__html: content}}></div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}