import React, {Component} from 'react'
import 'chart.js/auto';
import {Chart} from 'react-chartjs-2';
import {MiscService, MetaService} from '../../../services'
import Select from 'react-select'
import {Box} from '../../../components'
const filterTags = require('./filterTags.json'),
mS = new MetaService(),
miS = new MiscService()
export default class Report extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dashboardData: null
        }
    }
    componentDidMount () {
        mS.setTitle('Report', 'admin')
        this._getDashboardData()
    }
    _getDashboardData = async () => {
        const result = await miS.getDashboardData()
        if (result.status)
            this.setState({ dashboardData: result.data })
    }
    render() {
        let { dashboardData } = this.state
        let data = {
            labels: ['1', '2', '3', '4', '5', '6'],
            datasets: [
                {
                    data: [12, 19, 3, 5, 2, 3],
                    fill: true,
                    backgroundColor: 'rgba(241, 91, 41, 0.2)',
                    borderColor: 'rgb(241, 91, 41)'
                }
            ]
        };
        let options = {
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true
                        }
                    }
                ]
            }
        };
        let {activeFilter} = this.state
        return <>
                    <div className="flex">
                        <h1 className="title">Report</h1>
                        <div className="form-group mlauto ">
                            <label className="filter-label-add">Status: </label>
                            <Select
                                value={activeFilter}
                                defaultValue={activeFilter}
                                onChange={(e, i) => this.setState({ activeFilter: e }, () => {
                                    this._filterItems(e.value, i)
                                })}
                                options={filterTags}
                            />
                        </div>
                    </div>
                    {
                        dashboardData !== null &&
                        <>
                            <div className="grid-area-section">
                                <Box title='Recent Users'>
                                    <Chart
                                        type='line'
                                        data={data}
                                        options={options}
                                    />
                                </Box>
                                <Box title='Recent Orders'>
                                        <Chart
                                        type='line'
                                        data={data}
                                        options={options}
                                    />
                                </Box>
                            </div>
                            <div className="grid-area-section">
                                <Box title='Recent Users'>
                                    <Chart
                                        type='line'
                                        data={data}
                                        options={options}
                                    />
                                </Box>
                                <Box title='Recent Orders'>
                                    <Chart
                                        type='line'
                                        data={data}
                                        options={options}
                                    />
                                </Box>
                            </div>
                        </>
                    }
        </>
    }
}