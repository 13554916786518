const AppModel = [
    {
        key: 'uid',
        dataType: String
    },
    {
        key: 'id',
        dataType: String
    },
    {
        key: 'secret',
        dataType: String
    },
    {
        key: 'status',
        dataType: String
    },
    {
        key: 'author',
        dataType: String
    },
    {
        key: 'title',
        dataType: String
    },
    {
        key: 'description',
        dataType: String
    },
    {
        key: 'allowedApis',
        dataType: String
    }
]
export default AppModel