import React, {Component} from 'react'
import {MiscService, DataService, MetaService, RouterService} from '../../../services'
import {Button, BackIcon} from '../../../elements'
import { Box } from '../../../components'
const tS = new DataService('campaign'),
mS = new MetaService(),
miS = new MiscService(),
rS = new RouterService()
export default class AddCampaign extends Component {
    constructor(props) {
        super(props)
        this.state = {
            content: '',
            uid: '',
            mode: 'Add',
            name: '',
            mediaFiles: '',
            searchKey: '',
            tableData: [],
            image: '[]',
            activeFile: null
        }
    }
    componentDidMount () {
        mS.setTitle('Add Campaign', 'admin')
        let urlData = rS.urlToSplitData(rS.getLocationData().pathname)
        if (urlData[3] === 'edit') {
            let query = {
                uid: urlData[4]
            }
            this._getCampaignData(query)
        }
    }
    _getCampaignData = async query => {
        const result = await tS.fetchByAttr({ query: JSON.stringify(query) })
        if (result.status) {
            let campaignData = result.data[0]
            this.setState({
                name: campaignData.name,
                mode: 'Edit',
                content: campaignData.content,
                image: campaignData.image,
                uid: campaignData.uid
            })
        } else miS.showAlert({ type: 'error', msg: 'No campaigns added yet!' })
    }
    _addCampaign = async e => {
        e.preventDefault()
        let { content, name, image, mode, uid } = this.state,
        error = false,
        errorMsg = '',
        campaignObject = {
            content,
            name,
            image
        }
        if (content === '') {
            errorMsg = 'Please enter campaign content!'
            error = true
        }
        if (name === '') {
            errorMsg = 'Please enter user name!'
            error = true
        }
        if (!error) {
            if (mode === 'Add') {
                campaignObject.uid = new Date().getTime()
                const result = await tS.save(campaignObject)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Campaign added successfully!' })
                    rS.navigateTo('/admin/campaign/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to add campaign!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    campaignData: JSON.stringify(campaignObject)
                }
                const result = await tS.update(data)
                if (result.status) {
                    miS.showAlert({ type: 'success', msg: 'Campaign updated successfully!' })
                    rS.navigateTo('/admin/campaign/list')
                } else {
                    miS.showAlert({ type: 'error', msg: 'Unable to update campaign!' })
                }
            }
        } else miS.showAlert({ type: 'error', msg: errorMsg })
    }
    render() {
        let { content, mode, name } = this.state,
        title = mode + ' Campaign'
        return <>
            <h1>
                <BackIcon backLink='/admin/campaign/list' />
                {title}
            </h1>
            <div className="columns">
                <div className="left">
                    <Box title="Name" required>
                        <input type="text" required name="name" value={name} onChange={(e) => this.setState({ name: e.target.value })} />
                    </Box>
                    <Box title="Content" required>
                        <textarea className="description" placeholder="Type the campaign here" required name="content" value={content} onChange={(e) => this.setState({ content: e.target.value })}></textarea>
                    </Box>
                </div>
                <div className="right">
                    <form noValidate onSubmit={this._addCampaign}>
                        <Box title="Meta">
                            <Button className="btn-primary btn" type="submit" title="Click here to Add campaign">Publish</Button>
                        </Box>
                    </form>
                </div>
            </div>
        </>
    }
}